<section *ngIf="data$ | async as data" class="mb-2">
  <ul ngbNav #nav="ngbNav" class="nav-tabs" [animation]="false">
    <li *ngFor="let item of data; let index = index" [ngbNavItem]="index">
      <a ngbNavLink>{{ item.title }}</a>
      <ng-template ngbNavContent>
        <div class="brands-tab px-3 px-lg-4">
          <ng-container *ngIf="isDesktop$ | async; else mobi">
            <div class="row">
              <div class="col-2" *ngFor="let brand of item.brands">
                <a
                  [href]="brand.brand.url"
                  [attr.title]="brand.brand.alt_text"
                  class="d-flex bg-white mt-4 p-4">
                  <img
                    [attr.src]="brand.brand.image_file.url"
                    [attr.alt]="brand.brand.alt_text"
                    height="160"
                    width="160" />
                </a>
              </div>
            </div>
          </ng-container>
          <ng-template #mobi>
            <swiper [config]="swiperConfig">
              <ng-template swiperSlide *ngFor="let brand of item.brands">
                <div>
                  <a
                    [href]="brand.brand.url"
                    [attr.title]="brand.brand.alt_text"
                    class="d-flex bg-white mt-4 p-4">
                    <img
                      [attr.src]="brand.brand.image_file.url"
                      [attr.alt]="brand.brand.alt_text"
                      height="160"
                      width="160" />
                  </a>
                </div>
              </ng-template>
            </swiper>
          </ng-template>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
</section>
