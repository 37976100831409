<ng-container *ngIf="rewardsWithCart$ | async as rewardsWithCart">
  <div class="px-2 px-lg-0 mb-4">
    <header class="d-flex align-items-center mb-3">
      <h2 class="fs-5 text-capitalize mb-0">
        {{ 'rrs.account.rewards.rewards' | cxTranslate }}
      </h2>

      <div
        *ngIf="rewardsLoading$ | async"
        class="spinner-border spinner-border-sm ms-3"
        role="status"></div>
    </header>

    <ng-container *ngIf="rewardsWithCart?.rewards?.rewards?.length">
      <form [formGroup]="form">
        <fieldset
          *ngFor="
            let reward of rewardsWithCart?.rewards?.rewards;
            let i = index
          "
          [disabled]="(rewardsLoading$ | async) === true"
          class="form-check">
          <input
            (change)="submitReward(reward, $event)"
            [formControlName]="reward.rewardId"
            [id]="reward?.rewardId"
            class="form-check-input"
            type="checkbox" />

          <label
            class="form-check-label"
            [for]="reward?.rewardId"
            [innerHTML]="reward?.rewardName | rrsBoldPrice">
          </label>
        </fieldset>
      </form>
    </ng-container>
  </div>
</ng-container>
