import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import {
  BREAKPOINT,
  BreakpointService,
  CmsComponentData,
} from '@spartacus/storefront';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cms-placeholder',
  styleUrls: ['./rrs-cms-placeholder.component.scss'],
  templateUrl: './rrs-cms-placeholder.component.html',
})
export class RrsCmsPlaceholderComponent {
  skeletonRows: number[] | null = null;
  data$ = combineLatest([
    this.breakpointService.isUp(BREAKPOINT.lg),
    this.componentData.data$,
  ]).pipe(
    map(([mq, data]) => {
      const predefinedHeight =
        data.type !== 'null'
          ? JSON.parse(data.type)?.height || 'auto'
          : {
              desktop: data?.custom?.desktop?.height || 'auto',
              mobile: data?.custom?.mobile?.height || 'auto',
            };
      const height = this.addPxUnits(
        mq ? predefinedHeight.desktop : predefinedHeight.mobile
      );

      this.prepareSkeletonRows(parseInt(height.replace('px', '')));

      return {
        ...data,
        height,
      };
    })
  );

  constructor(
    protected breakpointService: BreakpointService,
    protected componentData: CmsComponentData<Contentstack.Placeholder>
  ) {}

  addPxUnits(value: string): string {
    return value.includes('px') ? value : `${value}px`;
  }

  prepareSkeletonRows(height: number, max = 12, min = 8): void {
    const targetRows = Math.floor((height - 30) / 21);
    const randomRowClass = (): number =>
      Math.floor(Math.random() * (max - min + 1)) + min;
    this.skeletonRows = new Array(targetRows)
      .fill(0)
      .map(() => randomRowClass());
  }
}
