import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@spartacus/storefront';
import { RrsLoaderComponent } from './rrs-loader/rrs-loader.component';

@NgModule({
  declarations: [RrsLoaderComponent],
  imports: [CommonModule, IconModule],
  exports: [RrsLoaderComponent],
})
export class RrsLoaderModule {}
