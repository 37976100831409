import { RrsAlgolia } from '@app/custom/features/rrs-search/models/rrs-search.model';

export const urlDashEncode = (name: string): string => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  if (!name) {
    name = '';
  }

  return name
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace('.', '-') // Replace . with '-'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const createSlugFromProductName = (name: string): string =>
  urlDashEncode(name);

export const createProductUrl = (hit: RrsAlgolia.Hit): string =>
  `/p/${urlDashEncode(hit.brand)}-${urlDashEncode(hit.name)}/${hit.sku}`;
