import { provideConfig, RoutingConfig } from '@spartacus/core';

export const rrsSetPasswordRoutingConfig = provideConfig({
  routing: {
    routes: {
      setPassword: {
        paths: ['login/set-password'],
      },
    },
  },
} as RoutingConfig);
