<ng-container *ngIf="product$ | async as product">
  <section class="mb-4 px-2 px-lg-0">
    <rrs-accordion [collapsing]="true" class="accordion--donate">
      <accordion-item>
        <ng-template accordionHeader let-toggle="toggle">
          <div
            (click)="toggle()"
            (keydown.enter)="toggle()"
            tabindex="0"
            class="d-flex align-items-center justify-content-between py-2">
            <h2 class="fs-6 text-capitalize fw-normal mb-0">
              {{ 'rrs.donation.donation' | cxTranslate }}
            </h2>

            <rrs-icon
              class="accordion-icon"
              [icon]="iconTypeList.CHEVRON_DOWN"
              [size]="12"></rrs-icon>
          </div>
        </ng-template>

        <ng-template accordionContent>
          <div>
            <p class="mb-1">
              {{ 'rrs.donation.information' | cxTranslate }}
            </p>

            <a
              [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
              class="link"
              target="_blank">
              {{ 'rrs.donation.learnMore' | cxTranslate }}
            </a>

            <form [formGroup]="form" class="mt-3">
              <div class="d-flex gap-2">
                <div class="w-100">
                  <ng-select
                    [clearable]="false"
                    [searchable]="false"
                    formControlName="donation"
                    labelForId="donation"
                    placeholder="Select Donation">
                    <ng-option
                      *ngFor="let variant of product.variantOptions"
                      [value]="variant.code">
                      ${{ variant?.priceData?.value }}
                    </ng-option>
                  </ng-select>
                </div>

                <div class="w-100">
                  <rrs-add-to-cart
                    [isDonation]="true"
                    [productDonationCode]="selectedVariant"
                    [showQuantity]="false"
                    [product]="product"></rrs-add-to-cart>
                </div>
              </div>
            </form>
          </div>
        </ng-template>
      </accordion-item>
    </rrs-accordion>
  </section>
</ng-container>
