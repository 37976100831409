import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Occ,
  OccUserPaymentAdapter,
  PAYMENT_DETAILS_NORMALIZER,
  PaymentDetails,
} from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const CONTENT_TYPE_JSON_HEADER = { 'Content-Type': 'application/json' };

@Injectable({
  providedIn: 'root',
})
export class RrsUserPaymentAdapter extends OccUserPaymentAdapter {
  loadAll(userId: string): Observable<PaymentDetails[]> {
    const url =
      this.occEndpoints.buildUrl('paymentDetailsAll', {
        urlParams: { userId },
      }) + '?fields=payments(DEFAULT, billingAddress(region(FULL)))&saved=true';
    const headers = new HttpHeaders({
      ...CONTENT_TYPE_JSON_HEADER,
    });

    return this.http.get<Occ.PaymentDetailsList>(url, { headers }).pipe(
      catchError((error: any) => throwError(error)),
      map((methodList) => methodList.payments ?? []),
      this.converter.pipeableMany(PAYMENT_DETAILS_NORMALIZER)
    );
  }
}
