import { ChangeDetectionStrategy, Component } from '@angular/core';
import { fadeHeightAnimation } from '@app/shared/animations';
import { RoutingService } from '@spartacus/core';
import { RrsCmsHeaderUiService } from '@app/custom/features/rrs-cms/components/rrs-cms-header/services/rrs-cms-header-ui.service';

@Component({
  animations: [fadeHeightAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-searchbox-mobile',
  styleUrls: ['./rrs-searchbox-mobile.component.scss'],
  templateUrl: './rrs-searchbox-mobile.component.html',
})
export class RrsSearchboxMobileComponent {
  constructor(
    protected routingService: RoutingService,
    public headerUi: RrsCmsHeaderUiService
  ) {}

  launchSearchPage(query: string): void {
    if (!query || query.trim().length === 0) {
      return;
    }

    this.headerUi.toggSearchBoxMobi();

    this.routingService.go({
      cxRoute: 'search',
      params: {
        query,
      },
    });
  }
}
