<div class="orders__title d-flex mb-lg-4 p-3 p-lg-0 align-items-center">
  <h2 class="me-3 mb-0 fw-normal">
    {{ 'rrs.account.navigation.purchaseHistory' | cxTranslate }}
  </h2>
  <rrs-icon
    [size]="32"
    [icon]="icon.RECEIPT"
    class="d-none d-lg-block pb-1"></rrs-icon>
</div>

<ng-container *ngIf="orders$ | async as orders">
  <ng-container *ngIf="orders?.orders?.length; else NoOrders">
    <table class="orders__table container-fluid d-none d-lg-table">
      <thead>
        <tr class="border-bottom border-accent">
          <th>
            {{ 'rrs.orders.purchasedAt' | cxTranslate }}
          </th>
          <th>{{ 'rrs.orders.date' | cxTranslate }}</th>
          <th>{{ 'rrs.orders.orderNumber' | cxTranslate }}</th>
          <th>
            {{ 'rrs.orders.numOfItems' | cxTranslate }}
          </th>
          <th>{{ 'rrs.orders.total' | cxTranslate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let order of orders.orders"
          class="position-relative"
          [class.text-decoration-underline]="order?.orderNumber">
          <td>
            <img
              *ngIf="order.companyNumber === 1; else offBroadWay"
              [attr.alt]="'RackRoomShoes logo'"
              [attr.src]="'/assets/images/rackRoomShoes.svg'"
              height="50" />
            <ng-template #offBroadWay>
              <img
                [attr.alt]="'Off Broadway logo'"
                [attr.src]="'/assets/images/offBroadway.svg'"
                height="50" />
            </ng-template>
          </td>
          <td>{{ order.saleDate }}</td>
          <td>
            {{ order.orderNumber ?? order.invoiceNumber }}
          </td>
          <td>{{ order.orderDetails.length }}</td>
          <td>${{ order.totalAmountDue }}</td>
          <a
            *ngIf="order?.orderNumber"
            [routerLink]="
              {
                cxRoute: 'orderDetail',
                params: { orderCode: order.orderNumber }
              } | cxUrl
            "
            title="Order details"
            class="stretched-link"
            data-cy="orderDetail"></a>
        </tr>
      </tbody>
    </table>

    <section class="d-lg-none container-fluid">
      <div
        *ngFor="let order of orders?.orders"
        class="p-3 border-bottom border-light position-relative">
        <p class="fw-semibold">
          {{ 'rrs.orders.orderNumber' | cxTranslate }}:
          {{ order?.orderNumber ? order.orderNumber : order.invoiceNumber }}
        </p>
        <div
          class="d-flex"
          [class.text-decoration-underline]="order?.orderNumber">
          <img
            *ngIf="order.companyNumber === 1; else offBroadWayMobile"
            [attr.alt]="'RackRoomShoes logo'"
            [attr.src]="'/assets/images/rackRoomShoes.svg'"
            class="col-3"
            height="80" />
          <ng-template #offBroadWayMobile>
            <img
              [attr.alt]="'Off Broadway logo'"
              [attr.src]="'/assets/images/offBroadway.svg'"
              class="col-3"
              height="80" />
          </ng-template>
          <div class="col-9 ps-4">
            <div class="mb-2 d-flex justify-content-between">
              <span>{{ 'rrs.orders.date' | cxTranslate }}</span>
              <span>{{ order.saleDate }}</span>
            </div>
            <div class="mb-2 d-flex justify-content-between">
              <span>{{ 'rrs.orders.numOfItems' | cxTranslate }}</span>
              <span>{{ order.orderDetails.length }}</span>
            </div>
            <div class="mb-2 d-flex justify-content-between">
              <span>{{ 'rrs.orders.total' | cxTranslate }} </span>
              <span>${{ order.totalAmountDue }}</span>
            </div>
          </div>
        </div>
        <a
          *ngIf="order?.orderNumber"
          [routerLink]="
            {
              cxRoute: 'orderDetail',
              params: { orderCode: order.orderNumber }
            } | cxUrl
          "
          title="Order details"
          class="stretched-link"></a>
      </div>
    </section>
  </ng-container>
</ng-container>

<ng-template #NoOrders>
  <p>{{ 'rrs.orders.noOrders' | cxTranslate }}</p>
</ng-template>
