import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '@environments/environment';
import { RrsDynamicYieldService } from '@app/custom/features/rrs-dynamic-yield/services/rrs-dynamic-yield.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [RrsDynamicYieldService],
      useFactory: rrsDynamicYieldInitializer,
      multi: true,
    },
  ],
})
export class RrsDynamicYieldModule {}

function rrsDynamicYieldInitializer(
  rrsDynamicYieldService: RrsDynamicYieldService
) {
  return (): void => {
    if (environment.dynamicYield.enabled) {
      rrsDynamicYieldService.initialize();
    }
  };
}
