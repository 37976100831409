<section *ngIf="product$ | async as product" class="mb-4">
  <h6 class="mb-2">
    {{ 'rrs.product.variants.color' | cxTranslate }}:
    <span
      class="fw-normal"
      >{{ product.variantOptions?.[0]?.colorPrimary }}</span
    >
  </h6>

  <div class="row">
    <div class="col-3 col-xl-2" *ngFor="let color of visibleColorVariants">
      <button
        class="product-variants-colors__item"
        [attr.aria-label]="color?.name || product.name"
        [ngClass]="{
          'product-variants-colors__item--selected':
            color?.code === product?.code
        }"
        (click)="setColor(color?.code)">
        <img
          *ngIf="codeOfVariantBeingLoaded !== color?.code; else spinner"
          alt="{{ color?.name }}"
          class="h-100 w-100"
          src="{{ getColorImage(color?.images) }}"
          title="{{ color?.name }}" />
      </button>
    </div>
  </div>

  <ng-container *ngIf="collapseColorVariants.length">
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div class="row">
        <div class="col-3 col-xl-2" *ngFor="let color of collapseColorVariants">
          <button
            class="product-variants-colors__item"
            [attr.aria-label]="color?.name || product.name"
            [ngClass]="{
              'product-variants-colors__item--selected':
                color?.code === product?.code
            }"
            (click)="setColor(color?.code)">
            <img
              *ngIf="codeOfVariantBeingLoaded !== color?.code; else spinner"
              alt="{{ color?.name }}"
              class="h-100 w-100"
              src="{{ getColorImage(color?.images) }}"
              title="{{ color?.name }}" />
          </button>
        </div>
      </div>
    </div>

    <button
      (click)="collapse.toggle()"
      [attr.aria-expanded]="!isCollapsed"
      [ngClass]="{ collapsed: isCollapsed }"
      type="button"
      class="w-100 d-flex align-items-center justify-content-center mt-3 mb-4">
      <span class="me-3 fw-bold">{{
        isCollapsed
          ? ('rrs.product.variants.moreOptions' | cxTranslate)
          : ('rrs.product.variants.lessOptions' | cxTranslate)
      }}</span>
      <rrs-icon
        class="chevron"
        [icon]="isCollapsed ? icon.CHEVRON_DOWN : icon.CHEVRON_UP"
        [size]="16"></rrs-icon>
    </button>
  </ng-container>
</section>

<ng-template #spinner>
  <div
    class="spinner-border spinner-border-sm"
    role="status"
    style="width: 2.5rem; height: 2.5rem"></div>
</ng-template>
