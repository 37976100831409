<label
  for="CardNumberInput"
  [ngClass]="{ 'opacity-25': disabled }"
  class="form-label d-flex">
  <span class="me-auto">{{ label }}</span>
  <img
    *ngFor="let icon of cardIcons"
    [src]="icon.url"
    [alt]="icon.altText"
    class="rounded-1 ms-1" />
</label>
<input
  [formControl]="control"
  [ngClass]="valid"
  [attr.disabled]="disabled ? '' : null"
  rrsCardNumberInput
  type="text"
  class="form-control"
  id="CardNumberInput"
  [placeholder]="placeholder || '0000-0000-0000-0000'" />
<div id="CardNumberInput" class="invalid-feedback">Form helper</div>
