import { OccConfig, provideConfig } from '@spartacus/core';

export const giftCardsOccConfig = provideConfig({
  backend: {
    occ: {
      endpoints: {
        cardBalance: '/giftcard/balance/${giftCardNumber}',
      },
    },
  },
} as OccConfig);
