<ng-container *ngIf="data$ | async as data">
  <section
    class="my-4 position-relative placeholder__container overflow-hidden"
    [ngClass]="data?.html_element_class || ''"
    [ngStyle]="{ height: data?.height || 'auto' }">
    <div
      *ngIf="data?.enable_loading_skeleton"
      class="d-flex align-items-center justify-content-center position-absolute top-0 left-0 h-100 w-100">
      <div class="placeholder-glow w-100">
        <div>
          <span
            class="placeholder col-{{ row }}"
            *ngFor="let row of skeletonRows"></span>
        </div>
      </div>
    </div>
  </section>
</ng-container>
