import {
  FacetCodes,
  RrsActiveFacets,
  SingleSelectFacets,
} from '@app/custom/features/rrs-product-listing/model';

export const parseFacetsUrlParams = (facetParams?: string): RrsActiveFacets => {
  if (!facetParams) return {};
  return (JSON.parse(facetParams) as string[] | string[][])
    ?.flat()
    ?.reduce<RrsActiveFacets>((acc: RrsActiveFacets, curr: string) => {
      const [facet, value] = curr.split(':');
      acc[facet] = [...(acc[facet] ?? []), value];
      return acc;
    }, {});
};

export const getFacetsUrlParams = (facets: RrsActiveFacets): string => {
  const result = Object.entries(facets)
    .filter(([_, values]) => values?.length)
    .map(([attribute, values]) =>
      values.map((value) => `${attribute}:${value}`)
    );
  return result?.length ? JSON.stringify(result) : '';
};

/**
 * This method generates Algolia facet combinations for conjunction (AND) and a disjunction (OR).
 * https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/?client=javascript#how-to-use
 * @param facets
 */
export const generateAlgoliaFacetConfig = (
  facets: RrsActiveFacets = {}
): any => {
  const orFacetIndexes: number[] = [];
  const andFacetIndexes: number[] = [];

  const result = Object.entries(facets)
    .filter(([_, values]) => values?.length)
    .map(([attribute, values], index) => {
      if (SingleSelectFacets.includes(attribute as FacetCodes)) {
        andFacetIndexes.push(index);
      } else {
        orFacetIndexes.push(index);
      }
      return values.map((value) => `${attribute}:${value}`);
    });

  const orFacets = orFacetIndexes.map((indexValue) => result[indexValue]);
  const andFacets = andFacetIndexes.map((indexValue) => result[indexValue]);

  const facetConfig = [];
  if (orFacets.length) {
    facetConfig.push(...orFacets);
  }
  if (andFacets.length) {
    facetConfig.push(...andFacets.flat());
  }

  if (!facetConfig.length || !facetConfig[0].length) {
    return null;
  }

  return facetConfig;
};

export const milesToMeters = (miles: number): number => miles * 1609.344;
