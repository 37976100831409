<ul
  [ngClass]="{ 'row row-cols-1 row-cols-md-2': displayMode === 'grid' }"
  class="list-none my-4">
  <ng-template
    [ngTemplateOutlet]="validationMessage"
    [ngTemplateOutletContext]="{
    error: errors?.[validationError.LENGTH_RANGE] || errors?.[validationError.REQUIRED],
    message: 'rrs.forms.passwordField.errors.lengthRange' | cxTranslate: passwordLengthRange
  }"></ng-template>
  <ng-template
    [ngTemplateOutlet]="validationMessage"
    [ngTemplateOutletContext]="{
    error: errors?.[validationError.UPPER_CASE],
    messageKey: 'rrs.forms.passwordField.errors.upperCase'
  }"></ng-template>
  <ng-template
    [ngTemplateOutlet]="validationMessage"
    [ngTemplateOutletContext]="{
    error: errors?.[validationError.LOWER_CASE],
    messageKey: 'rrs.forms.passwordField.errors.lowerCase'
  }"></ng-template>
  <ng-template
    [ngTemplateOutlet]="validationMessage"
    [ngTemplateOutletContext]="{
    error: errors?.[validationError.NUMERIC],
    messageKey: 'rrs.forms.passwordField.errors.numeric'
  }"></ng-template>
  <ng-template
    [ngTemplateOutlet]="validationMessage"
    [ngTemplateOutletContext]="{
    error: errors?.[validationError.SPECIAL_CHARS],
    messageKey: 'rrs.forms.passwordField.errors.specialChars'
  }"></ng-template>
</ul>

<ng-template
  #validationMessage
  let-error="error"
  let-message="message"
  let-messageKey="messageKey">
  <li class="d-flex align-items-center">
    <rrs-icon
      size="16"
      class="me-1"
      [classList]="showErrors ? (error ? 'text-danger' : 'text-success') : ''"
      [icon]="
        showErrors && !error ? iconsType.CHECK_CIRCLE : iconsType.DASH_CIRCLE
      "></rrs-icon>
    <span class="fw-bold fs-7">{{
      message ? message : messageKey ? (messageKey | cxTranslate) : ''
    }}</span>
  </li>
</ng-template>
