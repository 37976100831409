<div
  *ngIf="isCustomerEmulationSessionInProgress$ | async"
  class="d-flex flex-column flex-lg-row">
  <div class="form-group col-12 col-lg-3 me-3 mb-3 mb-lg-0">
    <input
      class="form-control"
      formcontrolname="customer"
      type="text"
      disabled="true"
      placeholder="{{ customer?.name }}, {{ customer?.uid }}" />
  </div>
  <button
    class="d-none d-lg-block btn btn-primary btn-sm"
    (click)="logoutCustomer()">
    {{ 'asm.endSession' | cxTranslate }}
  </button>
  <button class="d-block d-lg-none btn btn-primary" (click)="logoutCustomer()">
    {{ 'asm.endSession' | cxTranslate }}
  </button>
</div>
