import { Injectable } from '@angular/core';
import { RrsGlobalMessageService } from '@app/custom/features/rrs-global-message/rrs-global-message.service';
import { RrsOrder } from '@app/custom/features/rrs-order-status/models/rrs-order-status.model';
import { RrsOrderStatusAdapter } from '@app/custom/features/rrs-order-status/occ/rrs-order-status.adapter';
import { GlobalMessageType } from '@spartacus/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsOrderStatusService {
  private _order$ = new BehaviorSubject<RrsOrder | null>(null);
  constructor(
    protected orderStatusAdapter: RrsOrderStatusAdapter,
    protected globalMessageService: RrsGlobalMessageService
  ) {}

  getOrderDetails(orderId: string, email: string): Observable<RrsOrder | null> {
    if (this._order$.getValue()?.orderNumber !== orderId) {
      this.clearOrderDetails();
      this.loadOrderDetails(orderId, email);
    }
    return this._order$.asObservable();
  }

  loadOrderDetails(orderCode: string, email: string): void {
    this.orderStatusAdapter
      .getOrderStatus(orderCode, email)
      .pipe(
        catchError(() => {
          this.globalMessageService.add(
            { key: 'rrs.orderStatus.orderNotFound' },
            GlobalMessageType.MSG_TYPE_ERROR,
            10000
          );
          return of(null);
        }),
        take(1)
      )
      .subscribe((order) => {
        this._order$.next(order);
      });
  }

  clearOrderDetails(): void {
    this._order$.next(null);
  }
}
