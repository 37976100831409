import { NgModule } from '@angular/core';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RrsCustomSerializer } from '@app/custom/features/rrs-routing/store/reducers/rrs-router.reducer';

@NgModule({
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: RrsCustomSerializer,
    },
  ],
})
export class RrsRoutingModule {}
