import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cms-header-logo',
  templateUrl: './rrs-cms-header-logo.component.html',
  styleUrls: ['./rrs-cms-header-logo.component.scss'],
})
export class RrsCmsHeaderLogoComponent {
  @Input() logo!: Contentstack.HeaderBranding;

  isDesktop$ = this.breakpointService.isUp(BREAKPOINT.xl);

  constructor(protected breakpointService: BreakpointService) {}
}
