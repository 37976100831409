import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule, OccConfig, provideConfig } from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { rrRewardsOccConfig } from '@app/custom/features/rrs-rewards/config/occ.config';
import { RrsActivityModule } from '@app/custom/features/rrs-activity/rrs-activity.module';
import { RrsClaimRewardsComponent } from '@app/custom/features/rrs-rewards/components/rrs-claim-rewards/rrs-claim-rewards.component';
import { RrsFormErrorsModule } from '@app/shared/components/rrs-form-errors/rrs-form-errors.module';
import { RrsIconModule } from '@app/shared/components';
import { RrsRewardsComponent } from '@app/custom/features/rrs-rewards/components/rrs-rewards/rrs-rewards.component';
import { RrsSharedPipesModule } from '@app/shared/pipes';

@NgModule({
  declarations: [RrsRewardsComponent, RrsClaimRewardsComponent],
  exports: [RrsRewardsComponent, RrsClaimRewardsComponent],
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot({
      validation: false,
    }),
    RrsActivityModule,
    RrsFormErrorsModule,
    RrsIconModule,
    RrsSharedPipesModule,
  ],
  providers: [provideConfig(rrRewardsOccConfig as OccConfig)],
})
export class RrsRewardsModule {}
