import { SearchBoxConfig } from '@spartacus/storefront';

export const RRS_SEARCH_BOX_CONFIG: SearchBoxConfig = {
  displayProductImages: true,
  displayProducts: true,
  displaySuggestions: false,
  maxProducts: 4,
  maxSuggestions: 4,
  minCharactersBeforeRequest: 3,
};
