<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{
      addressData
        ? ('rrs.common.edit' | cxTranslate)
        : ('rrs.common.add' | cxTranslate)
    }}
    {{ 'rrs.addressBook.shippingAddress' | cxTranslate }}
  </h4>
  <button
    (click)="activeModal.dismiss('Cross click')"
    [attr.aria-label]="'rrs.common.closeModal' | cxTranslate"
    class="btn-close"
    cxModal="dismiss"
    cxModalReason="Cross click"
    type="button"></button>
</div>

<div class="modal-body">
  <form
    (ngSubmit)="verifyAddress()"
    [formGroup]="addressForm"
    data-cy="addressForm"
    #ngForm="ngForm">
    <h5 class="fw-semibold mb-3">
      {{ 'rrs.addressBook.form.addressNickname' | cxTranslate }}
    </h5>

    <div class="form-group mb-3 w-100">
      <label class="form-label" for="address-nickname">
        {{ 'rrs.addressBook.form.nickname' | cxTranslate }}
      </label>
      <input
        aria-required="true"
        class="form-control"
        data-cy="nickname"
        formControlName="nickname"
        id="address-nickname"
        placeholder="{{ 'rrs.addressBook.form.nickname' | cxTranslate }}"
        type="text" />
      <rrs-form-errors
        *ngIf="ngForm.submitted"
        [control]="addressForm.get('nickname')"
        aria-atomic="true"
        aria-live="assertive">
      </rrs-form-errors>
    </div>

    <h5 class="fw-semibold mt-4 mb-3">
      {{ 'rrs.addressBook.form.personalInformation' | cxTranslate }}
    </h5>

    <div class="form-group mb-3 w-100">
      <label class="form-label" for="address-firstName">
        {{ 'rrs.addressBook.form.firstName' | cxTranslate }}
      </label>
      <input
        aria-required="true"
        class="form-control"
        data-cy="firstName"
        formControlName="firstName"
        id="address-firstName"
        placeholder="{{ 'rrs.addressBook.form.firstName' | cxTranslate }}"
        type="text"
        [ngClass]="
          ngForm.submitted
            ? (addressForm.get('firstName') | rrsIsControlValid)
            : ''
        " />
      <rrs-form-errors
        *ngIf="ngForm.submitted"
        [control]="addressForm.get('firstName')"
        aria-atomic="true"
        aria-live="assertive">
      </rrs-form-errors>
    </div>

    <div class="form-group mb-3 w-100">
      <label class="form-label" for="address-lastName">
        {{ 'rrs.addressBook.form.lastName' | cxTranslate }}
      </label>
      <input
        aria-required="true"
        class="form-control"
        data-cy="lastName"
        formControlName="lastName"
        id="address-lastName"
        placeholder="{{ 'rrs.addressBook.form.lastName' | cxTranslate }}"
        type="text"
        [ngClass]="
          ngForm.submitted
            ? (addressForm.get('lastName') | rrsIsControlValid)
            : ''
        " />
      <rrs-form-errors
        *ngIf="ngForm.submitted"
        [control]="addressForm.get('lastName')"
        aria-atomic="true"
        aria-live="assertive">
      </rrs-form-errors>
    </div>

    <div class="form-group mb-3 w-100">
      <label class="form-label" for="address-phone">
        {{ 'rrs.addressBook.form.phone' | cxTranslate }}
      </label>

      <input
        aria-required="true"
        class="form-control"
        data-cy="phone"
        formControlName="phone"
        id="address-phone"
        placeholder="{{ 'rrs.addressBook.form.phone' | cxTranslate }}"
        rrsPhoneNumberInput
        type="text"
        [ngClass]="
          ngForm.submitted ? (addressForm.get('phone') | rrsIsControlValid) : ''
        " />
      <rrs-form-errors
        *ngIf="ngForm.submitted"
        [control]="addressForm.get('phone')"
        aria-atomic="true"
        aria-live="assertive">
      </rrs-form-errors>
    </div>

    <h5 class="fw-semibold mt-4 mb-3">
      {{ 'rrs.addressBook.form.shipTo' | cxTranslate }}
    </h5>

    <div class="form-group mb-3 w-100">
      <label class="form-label" for="addres-line1">
        {{ 'rrs.addressBook.form.addressOne' | cxTranslate }}
      </label>
      <input
        aria-required="true"
        class="form-control"
        data-cy="addressOne"
        formControlName="line1"
        id="addres-line1"
        placeholder="{{ 'rrs.addressBook.form.addressOne' | cxTranslate }}"
        type="text"
        [ngClass]="
          ngForm.submitted ? (addressForm.get('line1') | rrsIsControlValid) : ''
        " />
      <rrs-form-errors
        *ngIf="ngForm.submitted"
        [control]="addressForm.get('line1')"
        aria-atomic="true"
        aria-live="assertive">
      </rrs-form-errors>
    </div>

    <div class="form-group mb-3 w-100">
      <label class="form-label" for="addres-line2">
        {{ 'rrs.addressBook.form.addressTwo' | cxTranslate }}
      </label>
      <input
        aria-required="true"
        class="form-control"
        data-cy="addressTwo"
        formControlName="line2"
        id="addres-line2"
        placeholder="{{ 'rrs.addressBook.form.addressTwo' | cxTranslate }}"
        type="text"
        [ngClass]="
          ngForm.submitted ? (addressForm.get('line2') | rrsIsControlValid) : ''
        " />
      <rrs-form-errors
        *ngIf="ngForm.submitted"
        [control]="addressForm.get('line2')"
        aria-atomic="true"
        aria-live="assertive">
      </rrs-form-errors>
    </div>

    <div class="form-group mb-3 w-100">
      <label class="form-label" for="addres-city">
        {{ 'rrs.addressBook.form.city' | cxTranslate }}
      </label>
      <input
        aria-required="true"
        class="form-control"
        data-cy="city"
        formControlName="town"
        id="addres-city"
        placeholder="{{ 'rrs.addressBook.form.city' | cxTranslate }}"
        type="text"
        [ngClass]="
          ngForm.submitted ? (addressForm.get('town') | rrsIsControlValid) : ''
        " />
      <rrs-form-errors
        *ngIf="ngForm.submitted"
        [control]="addressForm.get('town')"
        aria-atomic="true"
        aria-live="assertive">
      </rrs-form-errors>
    </div>

    <ng-container *ngIf="regions$ | async as regions">
      <div
        *ngIf="regions.length !== 0"
        class="form-group mb-3 w-100"
        formGroupName="region">
        <label class="form-label" for="address-state">
          {{ 'rrs.addressBook.form.state' | cxTranslate }}
        </label>
        <ng-select
          (change)="setHiddenAutofillValue($event)"
          [clearable]="false"
          [items]="regions"
          [searchable]="false"
          aria-required="true"
          bindLabel="{{ regions[0].name ? 'name' : 'isocode' }}"
          bindValue="{{ regions[0].name ? 'isocode' : 'region' }}"
          class="region-select"
          data-cy="state"
          formControlName="isocode"
          labelForId="address-state"
          placeholder="{{ 'rrs.addressBook.form.state' | cxTranslate }}">
        </ng-select>
        <div
          *ngIf="
            addressForm.get('region.isocode').invalid &&
            !addressForm.get('region.isocode')?.untouched
          "
          class="text-danger fst-italic">
          {{ 'formErrors.required' | cxTranslate }}
        </div>
        <input
          class="visually-hidden"
          autcomplete="state"
          type="text"
          id="state"
          name="state"
          [value]="autofillInputValue"
          (change)="setStateAutofillValue($event)" />
      </div>
    </ng-container>

    <div class="form-group mb-3 w-100">
      <label class="form-label" for="addres-zip">
        {{ 'rrs.addressBook.form.zip' | cxTranslate }}
      </label>
      <input
        aria-required="true"
        class="form-control"
        data-cy="postalCode"
        formControlName="postalCode"
        id="addres-zip"
        maxlength="10"
        placeholder="{{ 'rrs.addressBook.form.zip' | cxTranslate }}"
        type="text"
        [ngClass]="
          ngForm.submitted
            ? (addressForm.get('postalCode') | rrsIsControlValid)
            : ''
        " />
      <rrs-form-errors
        *ngIf="ngForm.submitted"
        [control]="addressForm.get('postalCode')"
        aria-atomic="true"
        aria-live="assertive">
      </rrs-form-errors>
    </div>

    <div class="d-flex gap-3">
      <button
        (click)="activeModal.dismiss('Cross click')"
        class="btn btn-secondary"
        type="button">
        {{ 'rrs.common.cancel' | cxTranslate }}
      </button>

      <button class="btn btn-primary" type="submit">
        {{ 'rrs.common.save' | cxTranslate }}
      </button>
    </div>
  </form>
</div>
