import { Pipe, PipeTransform } from '@angular/core';
import { PaginationModel } from '@spartacus/core';

@Pipe({
  name: 'paginationStats',
})
export class RrsPaginationStatsPipe implements PipeTransform {
  transform(pagination: PaginationModel): {
    from: number;
    to: number;
    total: number;
  } {
    const { currentPage, pageSize, totalPages, totalResults } = pagination;
    if (
      currentPage === undefined ||
      pageSize === undefined ||
      totalPages === undefined ||
      totalResults === undefined
    )
      return { from: 0, to: 0, total: 0 };
    const from = currentPage * pageSize + 1;
    const to =
      totalPages === currentPage + 1
        ? totalResults
        : pageSize * (currentPage + 1);
    return {
      from,
      to,
      total: totalResults,
    };
  }
}
