<div class="profile">
  <header class="profile__header d-flex align-items-center px-2 py-3 p-lg-0">
    <h1 class="fs-2 fw-normal d-inline-flex align-items-center mb-0 mb-lg-4">
      {{ 'rrs.account.profile' | cxTranslate }}
      <rrs-icon [icon]="iconTypeList.USER" [size]="32" class="ms-2"></rrs-icon>
    </h1>
  </header>

  <section class="profile-grid">
    <article class="profile-grid__item">
      <header class="profile-grid__header px-2 pt-4 pb-2">
        <h2 class="fs-4 text-capitalize">
          {{ 'rrs.account.personalInformation' | cxTranslate }}
        </h2>
      </header>

      <div class="px-2 py-4">
        <rrs-personal-information></rrs-personal-information>
      </div>

      <footer class="p-2 bg-stripe"></footer>
    </article>

    <article class="profile-grid__item">
      <header class="profile-grid__header px-2 pt-4 pb-2">
        <h2 class="fs-4 text-capitalize">
          {{ 'rrs.account.accountInformation' | cxTranslate }}
        </h2>
      </header>
      <div class="px-2 py-4">
        <rrs-account-information></rrs-account-information>
      </div>
      <footer class="p-2 bg-stripe"></footer>
    </article>

    <article class="profile-grid__item">
      <header class="profile-grid__header px-2 pt-4 pb-2">
        <h2 class="fs-4 text-capitalize">
          <ng-container *ngIf="hasDefaultStore$ | async; else chooseStore">
            {{ 'rrs.account.myStore' | cxTranslate }}
          </ng-container>
          <ng-template #chooseStore>
            {{ 'rrs.account.chooseStore' | cxTranslate }}
          </ng-template>
        </h2>
      </header>
      <div class="px-2 py-4">
        <rrs-account-store></rrs-account-store>
      </div>
      <footer class="p-2 bg-stripe"></footer>
    </article>
  </section>
</div>
