<section *ngIf="data$ | async as data" class="container-fluid footer">
  <div class="row">
    <div
      class="col-lg-4 order-1 order-lg-0 py-4 px-lg-4 footer__cols footer__cols--form">
      <form
        #ngForm="ngForm"
        [formGroup]="newsletterForm"
        class="mb-4"
        (ngSubmit)="submitNewsletter()">
        <p
          class="mb-4"
          [innerHTML]="
            data?.loyalty[0]?.optional_content[0]?.email_list_signup?.copy
          "></p>
        <div class="mb-4">
          <label for="firstName" class="form-label">
            {{
              data?.loyalty[0]?.optional_content[0]?.email_list_signup
                ?.zip_code_label_text
            }}
          </label>

          <input
            id="firstName"
            aria-required="true"
            class="form-control"
            [ngClass]="
              ngForm.submitted
                ? (newsletterForm.get('firstName') | rrsIsControlValid)
                : ''
            "
            data-cy="firstName"
            formControlName="firstName"
            name="firstName"
            type="text" />

          <rrs-form-errors
            *ngIf="ngForm.submitted"
            [control]="newsletterForm.get('firstName')">
          </rrs-form-errors>
        </div>
        <div class="mb-4">
          <label for="email" class="form-label">
            {{
              data?.loyalty[0]?.optional_content[0]?.email_list_signup
                ?.email_label_text
            }}
          </label>

          <input
            id="email"
            aria-required="true"
            class="form-control"
            [ngClass]="
              ngForm.submitted
                ? (newsletterForm.get('email') | rrsIsControlValid)
                : ''
            "
            data-cy="email"
            formControlName="email"
            name="email"
            type="email" />

          <rrs-form-errors
            *ngIf="ngForm.submitted"
            [control]="newsletterForm.get('email')">
          </rrs-form-errors>
        </div>

        <button
          class="text-uppercase btn btn-primary btn-sm"
          data-cy="footer-submit"
          type="submit">
          {{
            data?.loyalty[0]?.optional_content[0]?.email_list_signup
              ?.cta_button_text
          }}
        </button>
      </form>

      <rrs-cms-footer-social-media [socialMedia]="data.social_links[0]">
      </rrs-cms-footer-social-media>
    </div>

    <div
      class="col-lg-8 order-0 order-lg-1 p-0 py-lg-4 px-lg-4 d-flex flex-column footer__cols footer__cols--navi">
      <rrs-cms-footer-navi
        [navigation]="data?.primary_nav[0]"></rrs-cms-footer-navi>

      <div class="mt-auto pt-4 d-none d-lg-block">
        <img
          [attr.src]="data?.copyright[0]?.site_logo?.image_site_logo?.url"
          [attr.alt]="data?.copyright[0]?.site_logo?.alt_text_site_logo"
          data-cy="footer-logo"
          loading="lazy"
          width="320" />
        <p class="fst-italic text-uppercase mb-0" data-cy="footer-copy">
          {{
            data?.copyright[0]?.site_logo?.alt_text_site_logo | rrsFindYearPipe
          }}
        </p>
      </div>
    </div>
  </div>
</section>
