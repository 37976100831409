<button
  (click)="addToCart()"
  [class.no-pointer-events]="isCartProcessing$ | async"
  [disabled]="addToCartStatus?.disabled"
  [ngClass]="{
    'btn-primary btn-block fs-4': !isDonation,
    'btn-sm btn-secondary w-100 h-100': isDonation
  }"
  class="btn"
  type="button">
  <ng-container
    *ngIf="(isCartProcessing$ | async) === true; then load; else text">
  </ng-container>

  <ng-template #load>
    <div
      class="spinner-border spinner-border-sm"
      role="status"
      style="width: 1.5rem; height: 1.5rem"></div>
  </ng-template>

  <ng-template #text>
    {{
      (isDonation ? 'rrs.donation.donate' : addToCartStatus.message)
        | cxTranslate
    }}
  </ng-template>
</button>
