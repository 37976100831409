import { APP_INITIALIZER } from '@angular/core';
import { RrsOnetrustService } from '@app/custom/features/rrs-onetrust/rrs-onetrust.service';

export const RssOnetrustInitializer = {
  provide: APP_INITIALIZER,
  useFactory: appInitializer,
  deps: [RrsOnetrustService],
  multi: true,
};

export function appInitializer(myInitService: RrsOnetrustService): Function {
  return (): void => myInitService.load();
}
