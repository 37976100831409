import {
  accountOccConfig,
  LOYALTY_NORMALIZER,
} from '@app/custom/features/rrs-account/configs';
import { RrsUserPaymentAdapter } from '@app/custom/features/rrs-account/services/rrs-user-payment-adapter';
import {
  I18nModule,
  OccConfig,
  provideConfig,
  UserPaymentAdapter,
} from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RrsAccountComponentsModule } from '@app/custom/features/rrs-account/components/rrs-account-components.module';
import { RrsAccountRoutingModule } from '@app/custom/features/rrs-account/rrs-account-routing.module';
import { RrsLoyaltyNormalizer } from '@app/custom/features/rrs-account/services/rrs-loyalty.normalizer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    I18nModule,
    RrsAccountComponentsModule,
    RrsAccountRoutingModule,
  ],
  providers: [
    provideConfig(accountOccConfig as OccConfig),
    {
      multi: true,
      provide: LOYALTY_NORMALIZER,
      useClass: RrsLoyaltyNormalizer,
    },
    {
      provide: UserPaymentAdapter,
      useClass: RrsUserPaymentAdapter,
    },
  ],
})
export class RrsAccountModule {}
