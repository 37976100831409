import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rrsUrlTrimParams',
})
export class RrsUrlTrimParamsPipe implements PipeTransform {
  transform(url: string): string {
    return url.split('?')[0];
  }
}
