import { Injectable } from '@angular/core';
import { RrsOccUserProfileAdapter } from '@app/custom/features/rrs-profile/services/rrs-profile.adapter';
import { UserProfileConnector } from '@spartacus/user/profile/core';

@Injectable({ providedIn: 'root' })
export class RrsUserProfileConnector extends UserProfileConnector {
  constructor(protected userProfileAdapter: RrsOccUserProfileAdapter) {
    super(userProfileAdapter);
  }
}
