import { rrsPromotion } from '@app/shared/models/promotions.model';
import { PromotionResult } from '@spartacus/cart/base/root';

export function getProductPromotions(
  entryNumber: number,
  promotions: PromotionResult[],
  productPrice: number
): rrsPromotion[] {
  return promotions
    ?.map((promotion) => {
      const promotionsApplied = promotion.consumedEntries?.find(
        (consumedEntry) => {
          return (
            consumedEntry.orderEntryNumber === entryNumber &&
            consumedEntry.adjustedUnitPrice! < productPrice
          );
        }
      );
      if (!promotionsApplied) return;
      return {
        description: promotion.description,
        adjustedUnitPrice: promotionsApplied.adjustedUnitPrice,
      };
    })
    .filter(
      <T>(promotion: T | rrsPromotion | undefined): promotion is T =>
        !!promotion
    );
}
