import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { RrsGlobalMessageService } from '@app/custom/features/rrs-global-message/rrs-global-message.service';
import { fadeInFadeOutAnimation } from '@app/shared/animations';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { GlobalMessageType } from '@spartacus/core';
import { GlobalMessageComponent } from '@spartacus/storefront';

@Component({
  animations: [fadeInFadeOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-global-message',
  styleUrls: ['./rrs-global-message.component.scss'],
  templateUrl: './rrs-global-message.component.html',
})
export class RrsGlobalMessageComponent
  extends GlobalMessageComponent
  implements OnInit
{
  @Input() scopeName: string | undefined;
  iconTypeList = ICON_TYPE_LIST;

  constructor(protected globalMessageService: RrsGlobalMessageService) {
    super(globalMessageService);
  }

  ngOnInit(): void {
    this.messages$ = this.globalMessageService.get(this.scopeName);
  }

  clear(type: GlobalMessageType, index: number): void {
    this.globalMessageService.remove(type, this.scopeName ? undefined : index);
  }
}
