import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CartDetailsComponent } from '@spartacus/cart/base/components';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cart-container',
  styleUrls: ['./rrs-cart-container.component.scss'],
  templateUrl: './rrs-cart-container.component.html',
})
export class RrsCartContainerComponent extends CartDetailsComponent {}
