import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { RrsRemoveFromCartEvent } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/model/events';
import { getProductPromotions } from '@app/shared/utils';
import { CartItemListComponent } from '@spartacus/cart/base/components';
import {
  CartRemoveEntryFailEvent,
  CartRemoveEntrySuccessEvent,
  PromotionResult,
} from '@spartacus/cart/base/root';
import { Address, EventService } from '@spartacus/core';
import { combineLatest, merge } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cart-list',
  styleUrls: ['./rrs-cart-list.component.scss'],
  templateUrl: './rrs-cart-list.component.html',
})
export class RrsCartListComponent extends CartItemListComponent {
  @Input() deliveryAddress?: Address;
  @Input() options = {
    isSaveForLater: false,
    optionalBtn: null,
    isSavedCart: false,
  };
  @Input() appliedPromotions?: PromotionResult;
  eventService = inject(EventService);
  getPromotions = getProductPromotions;

  removeInProgress$ = combineLatest([
    merge(
      this.eventService.get(RrsRemoveFromCartEvent),
      this.eventService.get(CartRemoveEntrySuccessEvent),
      this.eventService.get(CartRemoveEntryFailEvent)
    ),
    this.activeCartService.isStable(),
  ]).pipe(
    distinctUntilChanged(),
    map(([cartEvent, isCartStable]) => {
      return (
        cartEvent instanceof RrsRemoveFromCartEvent ||
        ((cartEvent instanceof CartRemoveEntrySuccessEvent ||
          cartEvent instanceof CartRemoveEntryFailEvent) &&
          !isCartStable)
      );
    })
  );
}
