import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';

@Directive()
export abstract class RrsTagComponent {
  @Input() closeable: boolean = false;
  @Input() disabled: boolean = false;
  @Output() closeTag = new EventEmitter();

  iconTypeList = ICON_TYPE_LIST;

  close(): void {
    this.closeTag.emit();
  }
}
