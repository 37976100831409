<ng-container *ngIf="savedCart$ | async as cart">
  <header
    class="saved-cart__header d-flex align-items-start justify-content-between px-2 py-3 p-lg-0 mb-0 mb-lg-4">
    <div class="d-flex justify-content-between justify-content-lg-start">
      <h1 class="fs-2 fw-normal">
        {{ 'rrs.savedCart.wishlistDetails' | cxTranslate }}
        <rrs-icon
          [icon]="iconTypeList.MAP_PIN"
          [size]="32"
          class="ms-2"></rrs-icon>
      </h1>
    </div>
    <cx-page-slot position="need_help_modal"></cx-page-slot>
  </header>
  <div class="row overview px-3 mt-4 px-lg-0 mt-lg-0">
    <div class="col-lg-3">
      <div class="d-flex align-items-center">
        <h6 class="mb-0">{{ 'rrs.savedCart.name' | cxTranslate }}</h6>
        <div class="ms-3">
          <button class="btn btn-link link-primary" (click)="openDialog(cart)">
            {{ 'rrs.savedCart.edit' | cxTranslate }}
          </button>
        </div>
      </div>
      <p class="overview__article mt-2">
        {{ cart?.name }}
      </p>
    </div>
    <div class="col-lg-9">
      <div class="d-flex align-items-center">
        <h6 class="mb-0">{{ 'rrs.savedCart.description' | cxTranslate }}</h6>
        <div class="ms-3">
          <button class="btn btn-link link-primary" (click)="openDialog(cart)">
            {{ 'rrs.savedCart.edit' | cxTranslate }}
          </button>
        </div>
      </div>
      <p class="overview__article mt-2">
        {{ cart?.description }}
      </p>
    </div>
  </div>
</ng-container>
