import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RrsOrder } from '@app/custom/features/rrs-order-status/models/rrs-order-status.model';
import { OccEndpointsService, normalizeHttpError } from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RrsOrderStatusAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService
  ) {}

  getOrderStatus(orderNumber: string, email: string): Observable<RrsOrder> {
    const url = this.occEndpointsService.buildUrl('orderStatus', {
      urlParams: {
        orderNumber,
        email,
      },
    });

    return this.http
      .get<RrsOrder>(url)
      .pipe(catchError((error) => throwError(normalizeHttpError(error))));
  }
}
