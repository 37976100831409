import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RrsCmsFeaturedBrandsComponent } from './components/rrs-cms-featured-brands/rrs-cms-featured-brands.component';
import { rrsHomePageCmsConfig } from '@app/custom/features/rrs-homepage/configs/cms.config';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [RrsCmsFeaturedBrandsComponent],
  imports: [CommonModule, RouterModule],
  providers: [...rrsHomePageCmsConfig],
})
export class RrsHomepageModule {}
