/* eslint-disable @angular-eslint/directive-selector */
import { ContentChild, Directive, Input } from '@angular/core';
import { AccordionContentDirective } from '@app/shared/components/rrs-accordion/directives/rrs-accordion-content.directive';
import { AccordionHeaderDirective } from '@app/shared/components/rrs-accordion/directives/rrs-accordion-header.directive';
import { AccordionTitleDirective } from '@app/shared/components/rrs-accordion/directives/rrs-accordion-title.directive';

@Directive({
  selector: 'accordion-item',
})
export class AccordionItemDirective {
  @Input() title = '';
  @Input() disabled = false;
  @ContentChild(AccordionContentDirective) content!: AccordionContentDirective;
  @ContentChild(AccordionTitleDirective) customTitle!: AccordionTitleDirective;
  @ContentChild(AccordionHeaderDirective)
  customHeader!: AccordionHeaderDirective;
}
