<form *ngIf="cart" [formGroup]="form">
  <div class="modal-header saved-cart-dialog__header">
    <ng-container [ngSwitch]="layoutOption">
      <h4 class="modal-title">
        <ng-container *ngSwitchCase="savedCartFormType.EDIT">
          {{ 'rrs.savedCart.editWishlist' | cxTranslate }}
        </ng-container>
        <ng-container *ngSwitchCase="savedCartFormType.DELETE">
          {{ 'rrs.savedCart.deleteWishlist' | cxTranslate }}
        </ng-container>
        <ng-container *ngSwitchCase="savedCartFormType.SAVE">
          {{ 'savedCartDialog.saveForLater' | cxTranslate }}
        </ng-container>
        <ng-container *ngSwitchCase="savedCartFormType.RESTORE">
          {{ 'savedCartDialog.restoreSavedCart' | cxTranslate }}
        </ng-container>
      </h4>
    </ng-container>

    <button
      class="btn-close"
      [disabled]="isLoading$ | async"
      [attr.aria-label]="'rrs.common.closeModal' | cxTranslate"
      type="button"
      cxModal="dismiss"
      cxModalReason="Cross click"></button>
  </div>

  <div class="modal-body">
    <div
      *ngIf="
        layoutOption === savedCartFormType.DELETE ||
          layoutOption === savedCartFormType.RESTORE;
        else saveAndEditCart
      ">
      <h5 class="saved-cart__delete-title fw-normal">{{ cart?.name }}</h5>
      <p class="saved-cart__delete-confirmation">
        {{ 'rrs.savedCart.deleteConfirmation' | cxTranslate }}
      </p>

      <ng-container *ngIf="layoutOption === savedCartFormType.RESTORE">
        <div class="form-check">
          <input
            id="rrs-copy-saved-cart"
            type="checkbox"
            [checked]="isCloneSavedCart"
            (change)="toggleIsCloneSavedCart()" />
          <label for="rrs-copy-saved-cart">{{
            'savedCartDialog.keepCopySavedCart' | cxTranslate
          }}</label>
        </div>

        <div *ngIf="isCloneSavedCart">
          <label>
            <span class="label-content">
              {{ 'savedCartDialog.nameOfCloneCart' | cxTranslate }}
            </span>

            <input
              [maxLength]="nameMaxLength"
              class="form-control"
              formControlName="cloneName"
              type="text"
              placeholder="{{
                'savedCartDialog.defaultCloneCartName'
                  | cxTranslate: { name: form.get('name')?.value }
              }}" />
          </label>
        </div>
      </ng-container>
    </div>

    <ng-template #saveAndEditCart>
      <div>
        <label class="form-label" for="savedCartNameInput">
          {{ 'rrs.savedCart.name' | cxTranslate }}
        </label>
        <input
          required="true"
          [maxLength]="nameMaxLength"
          class="form-control"
          formControlName="name"
          [ngClass]="
            isFormSubmitted ? (form.get('name') | rrsIsControlValid) : ''
          "
          required
          [placeholder]="'rrs.savedCart.name' | cxTranslate"
          id="savedCartNameInput"
          type="text" />
        <rrs-form-errors
          *ngIf="isFormSubmitted"
          [control]="form.get('name')"></rrs-form-errors>
      </div>

      <div class="my-3">
        <label class="form-label" for="savedCartDescriptionInput">
          {{ 'rrs.savedCart.description' | cxTranslate }}
        </label>
        <input
          [maxLength]="descriptionMaxLength"
          [ngClass]="
            isFormSubmitted ? (form.get('description') | rrsIsControlValid) : ''
          "
          class="form-control"
          formControlName="description"
          id="savedCartDescriptionInput"
          [placeholder]="'rrs.savedCart.description' | cxTranslate"
          rows="5" />
        <rrs-form-errors
          *ngIf="isFormSubmitted"
          [control]="form.get('description')"></rrs-form-errors>
      </div>
    </ng-template>
    <div class="d-flex gap-3">
      <ng-container
        *ngIf="
          layoutOption === savedCartFormType.DELETE;
          else saveAndEditFooter
        ">
        <button
          *ngIf="cart.code"
          (click)="deleteCart(cart.code)"
          [attr.aria-label]="'rrs.savedCart.delete' | cxTranslate"
          [disabled]="isDisableDeleteButton$ | async"
          class="btn btn-primary text-uppercase"
          type="button">
          {{ 'rrs.savedCart.delete' | cxTranslate }}
        </button>

        <button
          cxModal="dismiss"
          cxModalReason="Cancel"
          [disabled]="isLoading$ | async"
          class="btn btn-secondary">
          {{ 'rrs.savedCart.no' | cxTranslate }}
        </button>
      </ng-container>
      <ng-template #saveAndEditFooter>
        <button
          [disabled]="isLoading$ | async"
          (click)="saveOrEditCart(cart?.code)"
          class="btn btn-primary">
          {{ 'savedCartDialog.save' | cxTranslate }}
        </button>
        <button
          cxModal="dismiss"
          cxModalReason="Cancel"
          [disabled]="isLoading$ | async"
          class="btn btn-secondary">
          {{ 'savedCartDialog.cancel' | cxTranslate }}
        </button>
      </ng-template>
    </div>
  </div>
</form>
