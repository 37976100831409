import { DONATION_PRODUCT_TYPE } from '@app/custom/features/rrs-donation/models/donation.constant';
import { OrderEntry } from '@spartacus/cart/base/root';

/**
 * Function to check if the current order is for pickup only, for all entries: Buy Online Pick-Up In-Store
 * @param cartEntries
 */
export function isBOPUIS(cartEntries: OrderEntry[]): boolean {
  return cartEntries.every((entry) => entry.deliveryMode?.code === 'pickup');
}

/**
 * Function to check if the current order has only donations
 * @param cartEntries
 */
export function isDonationOnly(cartEntries: OrderEntry[]): boolean {
  return cartEntries.every(
    (entry) => entry.product?.baseProduct === DONATION_PRODUCT_TYPE
  );
}
