import { Injectable } from '@angular/core';
import { RrsCheckoutStepsParams } from '@app/custom/features/rrs-cms/configs/contentstack.config';
import { AsmAuthHttpHeaderService } from '@spartacus/asm/root';
import { GlobalMessageType } from '@spartacus/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsAuthHttpHeaderService extends AsmAuthHttpHeaderService {
  public override handleExpiredRefreshToken(): void {
    this.authRedirectService.saveCurrentNavigationUrl();

    this.authService.coreLogout().finally(() => {
      this.routingService
        .getPageContext()
        .pipe(take(1))
        .subscribe((pageContext) => {
          if (
            [...RrsCheckoutStepsParams, 'my-account'].includes(pageContext.id)
          ) {
            this.routingService.go({ cxRoute: 'login' });
            this.globalMessageService.add(
              {
                key: 'httpHandlers.sessionExpired',
              },
              GlobalMessageType.MSG_TYPE_INFO
            );
          }
        });
    });
  }
}
