<form
  (ngSubmit)="onSubmit()"
  [formGroup]="csAgentLoginForm"
  *ngIf="!csAgentTokenLoading"
  class="d-flex flex-column flex-lg-row">
  <div class="form-group col-12 col-lg-3 me-3 mb-3 mb-lg-0">
    <input
      class="form-control"
      required="true"
      type="text"
      formControlName="userId"
      placeholder="{{ 'asm.loginForm.userId.label' | cxTranslate }}" />
    <rrs-form-errors
      [control]="csAgentLoginForm.get('userId')"></rrs-form-errors>
  </div>

  <div class="form-group col-12 col-lg-3 mb-3 mb-lg-0 me-md-3">
    <input
      class="form-control"
      required="true"
      type="password"
      placeholder="{{ 'asm.loginForm.password.label' | cxTranslate }}"
      formControlName="password" />
    <rrs-form-errors
      [control]="csAgentLoginForm.get('password')"></rrs-form-errors>
  </div>
  <div>
    <button type="submit" class="d-none d-lg-block btn btn-primary btn-sm">
      {{ 'asm.loginForm.submit' | cxTranslate }}
    </button>
  </div>

  <button type="submit" class="d-lg-none btn btn-primary">
    {{ 'asm.loginForm.submit' | cxTranslate }}
  </button>
</form>

<div
  *ngIf="csAgentTokenLoading"
  class="d-flex align-items-center justify-content-center p-3">
  <div class="spinner-border" role="status" style="width: 3rem; height: 3rem">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
