import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RrsOrderStatusService } from '@app/custom/features/rrs-order-status/services/rrs-order-status.service';
import { AutoUnsubscribe } from '@app/shared/decorators';
import {
  AuthService,
  GlobalMessageService,
  RoutingService,
} from '@spartacus/core';
import { OccOrderHistoryAdapter } from '@spartacus/order/occ';
import { CustomFormValidators } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'rrs-order-status',
  templateUrl: './rrs-order-status.component.html',
  styleUrls: ['./rrs-order-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsOrderStatusComponent implements OnInit {
  private subscriptions = new Subscription();
  form!: FormGroup;

  constructor(
    protected fb: FormBuilder,
    protected routingService: RoutingService,
    protected orderHistoryAdapter: OccOrderHistoryAdapter,
    protected authService: AuthService,
    protected globalMessageService: GlobalMessageService,
    protected orderStatusService: RrsOrderStatusService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      orderId: ['', Validators.required],
      email: ['', [Validators.required, CustomFormValidators.emailValidator]],
    });
  }

  submitForm(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    const { orderId, email } = this.form.value;
    const submitSub = this.orderStatusService
      .getOrderDetails(orderId, email)
      .pipe(
        tap((order) => {
          if (!order) return;
          this.routingService.go({
            cxRoute: 'orderStatusDetails',
            params: { orderCode: orderId, email },
          });
        })
      )
      .subscribe();

    this.subscriptions.add(submitSub);
  }
}
