import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RrsCheckCardBalanceResponse } from '@app/custom/features/rrs-gift-cards-balance/model';
import { RrsGiftCardsService } from '@app/custom/features/rrs-gift-cards-balance/services/rrs-gift-cards.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'rrs-gift-card-balance',
  templateUrl: './gift-card-balance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftCardBalanceComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  cardBalance?: string;
  isLoading = false;

  cardBalanceSubscription?: Subscription;

  constructor(
    private giftCardsService: RrsGiftCardsService,
    private ref: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.form = new FormGroup({
      cardNumber: new FormControl('', Validators.required),
    });
  }

  public get cardNumber(): string {
    return this.form.value.cardNumber;
  }

  public onSubmit(): void {
    if (!this.form.valid) {
      return;
    }

    this.isLoading = true;
    this.cardBalanceSubscription = this.giftCardsService
      .getCardBalance(this.cardNumber)
      .pipe(
        tap(({ cardBalance }: RrsCheckCardBalanceResponse) => {
          if (cardBalance || cardBalance === 0) {
            this.cardBalance = `$${cardBalance}`;
          }

          this.isLoading = false;
          this.ref.detectChanges();
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.cardBalanceSubscription?.unsubscribe();
  }
}
