import {
  GlobalMessageService,
  GlobalMessageType,
  HttpErrorModel,
  normalizeHttpError,
  OccEndpointsService,
  RoutingService,
  UserIdService,
} from '@spartacus/core';
import { switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import { RrsMultiCartService } from '@app/custom/features/rrs-cart/services/rrs-multi-cart.service';

@Injectable({
  providedIn: 'root',
})
export class RrsSelectRewardsService {
  load$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    protected activeCartService: RrsActiveCartService,
    protected globalMessageService: GlobalMessageService,
    protected http: HttpClient,
    protected multiCartService: RrsMultiCartService,
    protected occEndpointsService: OccEndpointsService,
    protected userIdService: UserIdService,
    protected routingService: RoutingService
  ) {}

  selectRewards(
    rewards: { rewardId: string }[],
    deleteRewards: boolean = false
  ): void {
    this.load$.next(true);
    combineLatest([
      this.activeCartService.getActiveCartId(),
      this.userIdService.getUserId(),
    ])
      .pipe(
        take(1),
        switchMap(([cartId, userId]) => {
          const url = this.occEndpointsService.buildUrl('selectRewards', {
            urlParams: {
              userId,
              cartId,
            },
          });
          if (deleteRewards)
            return this.http.delete(url, { body: { rewards: rewards } });

          return this.http.put(url, { rewards: rewards });
        })
      )
      .subscribe({
        next: () => this.onSuccess(!deleteRewards),
        error: (error: Error) => this.onError(normalizeHttpError(error)),
      });
  }

  protected onError(error: HttpErrorModel | undefined): void {
    this.load$.next(false);
    this.reloadCart();

    (error?.details || []).forEach((error) => {
      this.globalMessageService.add(
        { raw: error.message },
        GlobalMessageType.MSG_TYPE_ERROR
      );
    });
  }

  protected onSuccess(select: boolean = true): void {
    this.load$.next(false);
    this.reloadCart();

    this.routingService
      .getRouterState()
      .pipe(take(1))
      .subscribe((router) => {
        if (!router.state.url.includes('checkout')) {
          this.globalMessageService.add(
            {
              key: select
                ? 'rrs.account.rewards.selectRewardsSuccess'
                : 'rrs.account.rewards.unselectRewardsSuccess',
            },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        }
      });
  }

  protected reloadCart(): void {
    combineLatest([
      this.activeCartService.getActiveCartId(),
      this.userIdService.getUserId(),
    ])
      .pipe(
        take(1),
        tap(([cartId, userId]) =>
          this.multiCartService.loadCart({
            cartId,
            userId,
          })
        )
      )
      .subscribe();
  }
}
