import { Injectable } from '@angular/core';
import { RrsBasePageMetaResolver } from '@app/custom/features/seo/resolvers/rrs-base-page-meta.resolver';
import {
  PageMetaResolver,
  PageTitleResolver,
  PageDescriptionResolver,
  PageRobotsResolver,
  CanonicalPageResolver,
  PageType,
  PageRobotsMeta,
} from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RrsContentPageMetaResolver
  extends PageMetaResolver
  implements
    PageTitleResolver,
    PageDescriptionResolver,
    PageRobotsResolver,
    CanonicalPageResolver
{
  constructor(protected basePageMetaResolver: RrsBasePageMetaResolver) {
    super();
    this.pageType = PageType.CONTENT_PAGE;
  }

  resolveTitle(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveTitle();
  }

  resolveDescription(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveDescription();
  }

  resolveRobots(): Observable<PageRobotsMeta[]> {
    return this.basePageMetaResolver.resolveRobots();
  }

  resolveCanonicalUrl(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveCanonicalUrl();
  }
}
