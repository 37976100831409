import { IconConfig, IconResourceType } from '@spartacus/storefront';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';

let iconTypeList: any = {
  LOADER: 'loader',
};
Object.keys(ICON_TYPE_LIST).forEach((item) => {
  iconTypeList[item] = item.toLowerCase();
});

const rrsIconConfig: IconConfig = {
  icon: {
    symbols: {
      ...iconTypeList,
    },
    resources: [
      {
        type: IconResourceType.SVG,
        types: Object.keys(iconTypeList),
        url: './assets/icons-sheet.svg',
      },
      {
        type: IconResourceType.SVG,
        types: Object.keys(iconTypeList),
        url: './assets/icons-sheet.svg',
      },
      {
        type: IconResourceType.LINK,
        url: './assets/icons/icon-font/icon-font.css',
      },
    ],
  },
};

export { rrsIconConfig };
