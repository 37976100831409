import { Injectable } from '@angular/core';
import { RrsPointOfService } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { RrsStoreFinderService } from '@app/custom/features/rrs-storefinder/services/rrs-store-finder.service';
import { CmsService, RoutingService } from '@spartacus/core';
import { SchemaBuilder } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsStoreDetailsSchemaBuilder implements SchemaBuilder {
  constructor(
    protected storeFinderService: RrsStoreFinderService,
    protected routingService: RoutingService,
    protected cmsService: CmsService
  ) {}

  build(): Observable<any> {
    return combineLatest([
      this.cmsService.getCurrentPage(),
      this.routingService.getParams(),
      this.storeFinderService.getFindStoreById(),
    ]).pipe(
      map(([page, params, store]) => {
        const isStoreDetailsPage =
          page?.template === 'StoreDetailsPageTemplate' &&
          !!params.storeId &&
          params.storeId === store?.name;
        if (!isStoreDetailsPage) return {};
        return page?.schema?.length ? page.schema : this.collect(store);
      })
    );
  }

  protected collect(store: RrsPointOfService): any {
    const schema: any = {
      '@context': 'http://www.schema.org',
      '@type': 'ShoeStore',
    };
    const name = `${store.displayName} ${
      store.isOffBroadway ? 'Off Broadway Shoe Warehouse' : 'Rack Room Shoes'
    }`;

    if (store.displayName) {
      schema.name = name;
    }
    if (store.address) {
      schema.address = {
        '@type': 'PostalAddress',
        streetAddress: store.address?.line1,
        addressLocality: store.address?.town,
        addressRegion: store.address?.region?.isocodeShort,
        postalCode: store.address?.postalCode,
      };
    }
    if (store.geoPoint) {
      schema.geo = {
        '@type': 'GeoCoordinates',
        latitude: store.geoPoint?.latitude,
        longitude: store.geoPoint?.longitude,
      };
    }

    if (store.openingHours?.weekDayOpeningList?.length) {
      schema.openingHours = store.openingHours.weekDayOpeningList
        .map(
          (schedule) =>
            `${schedule.weekDay} ${
              schedule.closed
                ? 'Closed'
                : `${schedule.openingTime?.formattedHour}-${schedule.closingTime?.formattedHour}`
            }`
        )
        .join(', ');
    }
    schema.image = store.storeImages?.[0]?.url;
    schema.telePhone = store.phoneNumber;
    schema.url = `https://www.rackroomshoes.com/store/${store.name}`;
    schema.paymentAccepted = ['cash', 'check', 'credit card'];
    schema.priceRange = '$$';

    return schema;
  }
}
