import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  PageTitleResolver,
  PageDescriptionResolver,
  PageRobotsResolver,
  CanonicalPageResolver,
  CmsService,
  TranslationService,
  RoutingPageMetaResolver,
  PageLinkService,
  Page,
  PageRobotsMeta,
  CanonicalUrlOptions,
} from '@spartacus/core';
import { Observable, defer, of } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsBasePageMetaResolver
  implements
    PageTitleResolver,
    PageDescriptionResolver,
    PageRobotsResolver,
    CanonicalPageResolver
{
  constructor(
    protected cmsService: CmsService,
    protected translation: TranslationService,
    protected routingPageMetaResolver: RoutingPageMetaResolver,
    protected router: Router,
    protected pageLinkService: PageLinkService
  ) {}

  /**
   * Helper to provide access to the current CMS page
   */
  protected page$: Observable<Page> = defer(() =>
    this.cmsService.getCurrentPage()
  ).pipe(filter((p) => Boolean(p)));

  protected title$: Observable<string | undefined> = this.page$.pipe(
    map((p) => p.title)
  );

  protected description$: Observable<string | undefined> = this.page$.pipe(
    map((p) => p.description)
  );

  protected robots$: Observable<PageRobotsMeta[]> = this.page$.pipe(
    map((page) => page.robots || [])
  );

  resolveTitle(): Observable<string | undefined> {
    return this.title$;
  }

  resolveDescription(): Observable<string | undefined> {
    return this.description$;
  }

  resolveRobots(): Observable<PageRobotsMeta[]> {
    return this.robots$;
  }

  resolveCanonicalUrl(options?: CanonicalUrlOptions): Observable<string> {
    return this.page$.pipe(
      switchMap((page) => {
        if (page.canonicalUrl?.length) {
          return of(page.canonicalUrl);
        }
        return this.router.events.pipe(
          filter((ev) => ev instanceof NavigationEnd),
          startWith(null),
          map(() => this.pageLinkService.getCanonicalUrl(options))
        );
      })
    );
  }
}
