import { Injectable } from '@angular/core';
import {
  FOOTER_COMPONENT_ID,
  FOOTER_SECTION_ID,
  HEADER_BANNER_ID,
  HEADER_BANNER_SLOT,
  HEADER_COMPONENT_ID,
  HEADER_SECTION_ID,
  NEED_HELP_DROPDOWN_COMPONENT_ID,
  NEED_HELP_DROPDOWN_SLOT,
  NEED_HELP_MODAL_COMPONENT_ID,
  NEED_HELP_MODAL_SLOT,
  RrsContentstackSlotNamesList,
} from '@app/custom/features/rrs-cms/configs/contentstack.config';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { RrsSeoData } from '@app/custom/features/seo/models/rrs-seo.model';
import { removeUndefinedFields } from '@app/shared/utils';
import {
  CmsComponent,
  CmsStructureModel,
  ContentSlotComponentData,
  ContentSlotData,
  Converter,
  PageRobotsMeta,
} from '@spartacus/core';

@Injectable()
export class RrsCmsPageNormalizer
  implements Converter<Contentstack.Page[], CmsStructureModel>
{
  convert(
    source: Contentstack.Page[],
    target: CmsStructureModel = {}
  ): CmsStructureModel {
    const contentGlobal: Contentstack.Entry | undefined =
      source[0].entries?.[0];
    // use specific page entry if exists. Use generic one [*] if specific page not found.
    const specificPageEntry: Contentstack.Entry | undefined =
      source[1].entries?.find(
        (entry) => entry.url_path[0] === target.page?.pageId
      );

    const contentPage: Contentstack.Entry | undefined =
      specificPageEntry ?? source[1].entries?.[0];

    if (!contentPage) {
      throw new Error('Page not found');
    }

    const contentStructure: CmsStructureModel = {
      components: this.toCmsComponents(contentPage),
      page: {
        label: contentPage.label,
        pageId: contentPage.uid,
        slots: this.toContentSlots(contentPage),
        template: '', // this is overwritten in adapter after normalizer return the structure. This is based on requested content type
        // This title will be overwritten by the object returned from this.getSeoData method.
        // If contentstack entry doesn't have a title, it will use the default `Rack Room Shoes`
        title: 'Rack Room Shoes',
        type: 'ContentPage',
        ...this.getSeoData(contentPage, source[1].entries?.[1]),
      },
    };

    if (contentGlobal) {
      this.addFooterComponent(contentGlobal, contentStructure);
      this.addHeaderComponent(contentGlobal, contentStructure);
      this.addHeaderBannerComponent(contentGlobal, contentStructure);
      this.addNeedHelpComponent(contentGlobal, contentStructure);
    }

    return contentStructure;
  }

  private addFooterComponent(
    contentGlobal: Contentstack.Entry,
    target: CmsStructureModel
  ): void {
    if (target.page?.slots && contentGlobal?.footer) {
      target.page.slots[FOOTER_SECTION_ID] = {
        components: [
          {
            flexType: FOOTER_COMPONENT_ID,
            typeCode: FOOTER_COMPONENT_ID,
            uid: contentGlobal.uid + 'footer',
          },
        ],
      };

      target.components?.push({
        typeCode: FOOTER_COMPONENT_ID,
        uid: contentGlobal.uid + 'footer',
        ...contentGlobal.footer,
      });
    }
  }

  private addHeaderComponent(
    contentGlobal: Contentstack.Entry,
    target: CmsStructureModel
  ): void {
    if (target.page?.slots && contentGlobal?.header) {
      target.page.slots[HEADER_SECTION_ID] = {
        components: [
          {
            flexType: HEADER_COMPONENT_ID,
            typeCode: HEADER_COMPONENT_ID,
            uid: contentGlobal.uid + 'header',
          },
        ],
      };

      target.components?.push({
        uid: contentGlobal.uid + 'header',
        typeCode: HEADER_COMPONENT_ID,
        ...contentGlobal.header,
      });
    }
  }

  private addHeaderBannerComponent(
    contentGlobal: Contentstack.Entry,
    target: CmsStructureModel
  ): void {
    //@ts-ignore - Doublecheck, something is off about the types here
    const headerBannerComponent = contentGlobal.header.bottom_banner[0];
    if (target.page?.slots && headerBannerComponent) {
      target.page.slots[HEADER_BANNER_SLOT] = {
        components: [
          {
            flexType: HEADER_BANNER_ID,
            typeCode: HEADER_BANNER_ID,
            uid: headerBannerComponent.uid + 'header_bottom_banner',
          },
        ],
      };
      target.components?.push({
        ...headerBannerComponent,
        typeCode: HEADER_BANNER_ID,
        uid: headerBannerComponent.uid + 'header_bottom_banner',
      });
    }
  }

  private addNeedHelpComponent(
    contentGlobal: Contentstack.Entry,
    target: CmsStructureModel
  ): void {
    if (target.page?.slots && contentGlobal?.customer_care?.need_help?.[0]) {
      const needHelpComponent = contentGlobal.customer_care.need_help[0];
      target.page.slots[NEED_HELP_DROPDOWN_SLOT] = {
        components: [
          {
            flexType: NEED_HELP_DROPDOWN_COMPONENT_ID,
            typeCode: NEED_HELP_DROPDOWN_COMPONENT_ID,
            uid: needHelpComponent.uid + 'dropdown',
          },
        ],
      };
      target.page.slots[NEED_HELP_MODAL_SLOT] = {
        components: [
          {
            flexType: NEED_HELP_MODAL_COMPONENT_ID,
            typeCode: NEED_HELP_MODAL_COMPONENT_ID,
            uid: needHelpComponent.uid + 'modal',
          },
        ],
      };

      target.components?.push(
        {
          ...needHelpComponent,
          typeCode: NEED_HELP_DROPDOWN_COMPONENT_ID,
          title: needHelpComponent.dropdown_title,
          uid: needHelpComponent.uid + 'dropdown',
        },
        {
          ...needHelpComponent,
          typeCode: NEED_HELP_MODAL_COMPONENT_ID,
          title: needHelpComponent.modal_title,
          uid: needHelpComponent.uid + 'modal',
        }
      );
    }
  }

  private toContentSlots(entry: Contentstack.Entry): {
    [key: string]: ContentSlotData;
  } {
    const target: {
      [key: string]: ContentSlotData;
    } = {};

    RrsContentstackSlotNamesList.forEach((slotName: string) => {
      const slot = entry[slotName];

      if (slot) {
        if (typeof slot === 'object' && slot !== null && !Array.isArray(slot)) {
          const transformedSlot = Object.entries(slot);

          target[slotName] = {
            components: transformedSlot.map((component: any) => {
              const transformedComponent = {
                [component[0]]: { ...component[1][0] },
              };

              return this.toContentSlotComponentData(transformedComponent);
            }),
          };
        } else {
          target[slotName] = {
            components: slot.map((component: any) => {
              return this.toContentSlotComponentData(component);
            }),
          };
        }
      }
    });

    return target;
  }

  private toContentSlotComponentData(
    component: Contentstack.ComponentBlock
  ): ContentSlotComponentData {
    const typeCode = Object.keys(component)[0];

    return {
      flexType: typeCode,
      typeCode: typeCode,
      uid: component[typeCode]?._metadata?.uid || component[typeCode]?.uid,
    };
  }

  private toCmsComponents(entry: Contentstack.Entry): CmsComponent[] {
    const components: CmsComponent[] = [];

    RrsContentstackSlotNamesList.forEach((slotName: string) => {
      const slot = entry[slotName];

      if (typeof slot === 'object' && slot !== null && !Array.isArray(slot)) {
        const transformedSlot = Object.entries(slot);

        transformedSlot?.forEach((component: any) => {
          const transformedComponent = {
            [component[0]]: { ...component[1][0] },
          };

          components.push(this.toCmsComponent(transformedComponent));
        });
      } else {
        if (slot) {
          slot?.forEach((component: any) => {
            components.push(this.toCmsComponent(component));
          });
        }
      }
    });

    return components;
  }

  private toCmsComponent(component: Contentstack.ComponentBlock): CmsComponent {
    const typeCode = Object.keys(component)[0];

    return {
      typeCode: typeCode,
      uid: component[typeCode]?._metadata?.uid || component[typeCode]?.uid,
      ...component[typeCode],
    };
  }

  private getSeoData(
    specificEntry: Contentstack.Entry | undefined,
    generalEntry?: Contentstack.Entry
  ): RrsSeoData {
    if (!generalEntry && !specificEntry) {
      return {};
    }
    const seoData: RrsSeoData = {};

    const globalMetadata =
      specificEntry?.seo_global_metadata || generalEntry?.seo_global_metadata;
    if (globalMetadata) {
      seoData.title =
        specificEntry?.seo_global_metadata?.title ||
        generalEntry?.seo_global_metadata?.title ||
        undefined;
      seoData.description =
        specificEntry?.seo_global_metadata?.description ||
        generalEntry?.seo_global_metadata?.description ||
        undefined;
      seoData.robots = specificEntry?.seo_global_metadata?.robots?.length
        ? specificEntry.seo_global_metadata.robots
            .split(',')
            .map((robot: PageRobotsMeta) => robot.trim())
        : generalEntry?.seo_global_metadata?.robots
        ? generalEntry?.seo_global_metadata.robots
            .split(',')
            .map((robot: PageRobotsMeta) => robot.trim())
        : undefined;
      seoData.canonicalUrl =
        specificEntry?.seo_global_metadata?.canonical_url ||
        generalEntry?.seo_global_metadata?.canonical_url ||
        undefined;
    }

    const schemaMetadata =
      specificEntry?.seo_schema_metadata || generalEntry?.seo_schema_metadata;
    if (schemaMetadata) {
      seoData.schema =
        specificEntry?.seo_schema_metadata?.schema_org ||
        generalEntry?.seo_schema_metadata?.schema_org ||
        undefined;
    }
    seoData.endOfContent =
      specificEntry?.seo_end_of_content?.content ||
      generalEntry?.seo_end_of_content?.content ||
      undefined;
    seoData.heading =
      specificEntry?.seo_headings?.h1 ||
      generalEntry?.seo_headings?.h1 ||
      undefined;

    return removeUndefinedFields(seoData);
  }
}
