export const rrRewardsOccConfig = {
  backend: {
    occ: {
      endpoints: {
        claimRewards: '/users/${userId}/rewards/${order}/claim',
        rewards: '/users/${userId}/rewards',
        selectRewards: '/users/${userId}/carts/${cartId}/selectRewards',
      },
    },
  },
};
