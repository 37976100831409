import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SavedCartDetailsItemsComponent } from '@spartacus/cart/saved-cart/components';

@Component({
  selector: 'rrs-saved-cart-details-items',
  templateUrl: './rrs-saved-cart-details-items.component.html',
  styleUrls: ['./rrs-saved-cart-details-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsSavedCartDetailsItemsComponent extends SavedCartDetailsItemsComponent {}
