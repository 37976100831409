import { Injectable } from '@angular/core';
import { DONATION_PRODUCT_TYPE } from '@app/custom/features/rrs-donation/models/donation.constant';
import { createSlugFromProductName } from '@app/shared/utils';
import { Converter, OccConfig, Product } from '@spartacus/core';
@Injectable({
  providedIn: 'root',
})
export class RrsProductNormalizer implements Converter<Product, Product> {
  constructor(protected config: OccConfig) {}
  convert(source: Product, target: Product): Product {
    target = target ?? { ...source };
    if (source?.baseProduct === DONATION_PRODUCT_TYPE) {
      target.slug = this.getSlug(target);
      return target;
    }
    target.brand = source.categories?.find((category: any) =>
      category.code?.includes('brand')
    )?.name;
    if (source.baseOptions?.length) {
      target.colorPrimary = source.baseOptions![0].selected!?.colorPrimary;
      target.colorSecondary = source.baseOptions![0].selected!?.colorSecondary;
      target.width = source.baseOptions![0].selected!?.width;
      target.size =
        source.baseOptions![0].selected!?.sizeconversion ||
        source.baseOptions![0].selected!?.size;
      target.isProductWithoutSize =
        target.size?.toLowerCase() === '0' &&
        target.width?.toLowerCase() === 'none';
    }
    target.slug = this.getSlug(target);
    return target as Product;
  }

  getSlug(target: Product): string {
    let slug = '';
    if (target.brand) {
      slug = `${createSlugFromProductName(target.brand)}-`;
    }
    if (target.name) {
      slug += `${createSlugFromProductName(target.name)}`;
    }
    return slug;
  }
}
