import { Component } from '@angular/core';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { CmsService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { pluck } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'rrs-empty-cart',
  templateUrl: './rrs-empty-cart.component.html',
})
export class RrsEmptyCartComponent {
  private subscriptions = new Subscription();

  constructor(
    protected activeCartService: RrsActiveCartService,
    protected cmsService: CmsService
  ) {
    const refreshPageSub = this.activeCartService
      .getActive()
      .pipe(pluck('totalItems'))
      .subscribe((totalItems) => {
        if (totalItems) {
          this.cmsService.refreshLatestPage();
        }
      });
    this.subscriptions.add(refreshPageSub);
  }
}
