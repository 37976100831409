import { CartRemoveEntrySuccessEvent } from '@spartacus/cart/base/root';
import {
  EventService,
  GlobalMessageService,
  GlobalMessageType,
} from '@spartacus/core';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';

@Injectable({
  providedIn: 'root',
})
export class RrsCartEventsService implements OnDestroy {
  static factory(rrsCartEvents: RrsCartEventsService): () => void {
    const factoryFunction = (): void => {
      rrsCartEvents.initCartEventListeners();
    };
    return factoryFunction;
  }

  protected readonly unsub$ = new Subject<void>();

  constructor(
    protected readonly eventService: EventService,
    protected readonly globalMessage: GlobalMessageService,
    protected activeCartService: RrsActiveCartService
  ) {}

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  initCartEventListeners(): void {
    this.eventService
      .get(CartRemoveEntrySuccessEvent)
      .pipe(takeUntil(this.unsub$))
      .subscribe(() => {
        this.globalMessage.add(
          { key: 'cart.productRemoved' },
          GlobalMessageType.MSG_TYPE_INFO
        );
      });
  }
}
