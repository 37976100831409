import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import {
  STORE_FINDER_FEATURE,
  StoreFinderRootModule,
} from '@spartacus/storefinder/root';
import {
  storeFinderTranslationChunksConfig,
  storeFinderTranslations,
} from '@spartacus/storefinder/assets';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [StoreFinderRootModule],
  providers: [
    provideConfig({
      featureModules: {
        [STORE_FINDER_FEATURE]: {
          module: () =>
            import('@spartacus/storefinder').then((m) => m.StoreFinderModule),
        },
      },
    } as CmsConfig),
    provideConfig({
      i18n: {
        resources: storeFinderTranslations,
        chunks: storeFinderTranslationChunksConfig,
      },
    } as I18nConfig),
  ],
})
export class StoreFinderFeatureModule {}
