import { APP_INITIALIZER } from '@angular/core';
import { RrsKlarnaBannerService } from '@app/custom/features/rrs-klarna-banner/rrs-klarna-banner.service';
import { scriptsCookieGroups } from '@app/custom/features/rrs-onetrust/rrs-onetrust.config';
import { RrsOnetrustService } from '@app/custom/features/rrs-onetrust/rrs-onetrust.service';
import { Subscription } from 'rxjs';

export const RssKlarnaBannerInitializer = {
  provide: APP_INITIALIZER,
  useFactory: initKlarnaBanner,
  deps: [RrsKlarnaBannerService, RrsOnetrustService],
  multi: true,
};

export function initKlarnaBanner(
  klarnaBannerService: RrsKlarnaBannerService,
  OnetrustService: RrsOnetrustService
): Function {
  return (): Subscription =>
    OnetrustService.checkConsents(scriptsCookieGroups.klarna).subscribe(
      (hasConsent) => {
        if (!hasConsent) return;
        klarnaBannerService.initKlarnaBanner();
      }
    );
}
