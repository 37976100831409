import { CmsConfig, provideConfig } from '@spartacus/core';
import { RrsSetPasswordComponent } from '@app/custom/features/rrs-set-password/components/rrs-set-password/rrs-set-password.component';
import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';

export const rrsSetPasswordCmsConfig = [
  provideConfig({
    slots: {
      SetPasswordSlot: ['RrsSetPasswordComponent'],
    },
  } as CmsComponentsConfig),
  provideConfig({
    cmsComponents: {
      RrsSetPasswordComponent: {
        component: RrsSetPasswordComponent,
      },
    },
  } as CmsConfig),
];
