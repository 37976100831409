import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rrsFindYearPipe',
})
export class RrsFindYearPipePipe implements PipeTransform {
  transform(value: string): string {
    const date = new Date();
    const currentYear = date.getFullYear();

    return value.replace('[year]', currentYear.toString());
  }
}
