import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { RrsPointOfService } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { Time } from '@spartacus/core';
import { convertTime12to24 } from '@app/shared/utils/time';

interface OpeningInfo {
  date?: Date;
  isOpen?: boolean;
  openingTime?: Time;
  closingTime?: Time;
  hoursUntilOpen?: number;
  hoursUntilClose?: number;
  withinWeek?: boolean;
  withinYear?: boolean;
  isToday?: boolean;
}

interface StoreOpenState {
  key: string;
  params?: Record<string, string>;
  isOpen: boolean;
}

@Pipe({ name: 'storeOpenState' })
export class RrsStoreOpenStatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(store?: RrsPointOfService): StoreOpenState {
    if (!store?.openingHours)
      return { key: 'rrs.storeFinder.missingStoreHours', isOpen: false };

    const nextOpening = this.getNextOpening(store);
    if (!nextOpening) {
      return { key: 'rrs.storeFinder.closed', isOpen: false };
    }

    if (nextOpening.isToday) {
      if (nextOpening.isOpen) {
        if (nextOpening.hoursUntilClose === 0) {
          return {
            key: 'rrs.storeFinder.closingSoon',
            isOpen: true,
            params: { time: nextOpening.closingTime?.formattedHour ?? '' },
          };
        } else {
          return {
            key: 'rrs.storeFinder.openUntil',
            isOpen: true,
            params: { time: nextOpening.closingTime?.formattedHour ?? '' },
          };
        }
      } else {
        if (nextOpening.hoursUntilOpen === 0) {
          return {
            key: 'rrs.storeFinder.openingSoon',
            isOpen: false,
            params: { time: nextOpening.openingTime?.formattedHour ?? '' },
          };
        } else {
          return {
            key: 'rrs.storeFinder.openingAt',
            isOpen: false,
            params: { time: nextOpening.openingTime?.formattedHour ?? '' },
          };
        }
      }
    } else if (nextOpening.withinWeek) {
      return {
        key: 'rrs.storeFinder.openingWithinWeek',
        isOpen: false,
        params: {
          time: nextOpening.openingTime?.formattedHour ?? '',
          day: this.datePipe.transform(nextOpening.date, 'EE') ?? '',
        },
      };
    } else if (nextOpening.withinYear) {
      return {
        key: 'rrs.storeFinder.openingWithinYear',
        isOpen: false,
        params: {
          time: nextOpening.openingTime?.formattedHour ?? '',
          day: this.datePipe.transform(nextOpening.date, 'EE') ?? '',
        },
      };
    }
    return { key: 'rrs.storeFinder.closed', isOpen: false };
  }

  private getNextOpening(store?: RrsPointOfService): OpeningInfo | null {
    const weekDayOpeningDays = store?.openingHours?.weekDayOpeningList ?? [];
    const specialOpeningDays = store?.openingHours?.specialDayOpeningList ?? [];

    if (!weekDayOpeningDays.length && !specialOpeningDays.length) {
      return null;
    }

    const now = new Date();
    const [weekDay, hourStr, minutesStr] = this.datePipe
      .transform(now, 'EE,H,m')
      ?.split(',') ?? ['', '0', '0'];
    const minutes = parseInt(minutesStr);
    const hour = parseInt(hourStr);

    const todaySchedule =
      specialOpeningDays.find(
        (d) => d.formattedDate === this.datePipe.transform(now, 'M/d/yy')
      ) || weekDayOpeningDays.find((d) => d.weekDay === weekDay);

    if (todaySchedule) {
      const openTime = todaySchedule.openingTime;
      const closeTime = todaySchedule.closingTime;

      const [openHour, openMinutes] = convertTime12to24(
        openTime?.formattedHour ?? ''
      )
        .split(':')
        .map((str) => parseInt(str));

      const [closeHour, closeMinutes] = convertTime12to24(
        closeTime?.formattedHour ?? ''
      )
        .split(':')
        .map((str) => parseInt(str));

      const isAfterOpenTime =
        hour > openHour || (hour === openHour && minutes >= openMinutes);

      const isBeforeCloseTime =
        hour < closeHour || (hour === closeHour && minutes < closeMinutes);

      if (!isAfterOpenTime || isBeforeCloseTime) {
        return {
          isToday: true,
          isOpen: isAfterOpenTime && isBeforeCloseTime,
          openingTime: !isAfterOpenTime ? openTime : undefined,
          closingTime: isBeforeCloseTime ? closeTime : undefined,
          hoursUntilOpen: !isAfterOpenTime
            ? this.getFullHoursBetweenTimes(
                { hour, minute: minutes },
                { hour: openHour, minute: openMinutes }
              )
            : undefined,
          hoursUntilClose: isAfterOpenTime
            ? this.getFullHoursBetweenTimes(
                { hour, minute: minutes },
                { hour: closeHour, minute: closeMinutes }
              )
            : undefined,
        };
      }
    }

    for (let i = 1; i < 7; i++) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      const currentWeekDay = this.datePipe.transform(date, 'EEE') ?? '';
      const specialOpeningDay = specialOpeningDays.find(
        (d) => d.formattedDate === this.datePipe.transform(date, 'M/d/yy')
      );
      const daySchedule =
        specialOpeningDay ??
        weekDayOpeningDays.find((d) => d.weekDay === currentWeekDay);

      if (!daySchedule?.openingTime) {
        continue;
      }

      const openTime = daySchedule.openingTime;

      return {
        date,
        openingTime: openTime,
        withinWeek: true,
      };
    }

    const nearestOpeningDay = specialOpeningDays.find(
      (specialDay) => specialDay.date?.valueOf() ?? 0 > now.valueOf()
    );

    if (nearestOpeningDay) {
      return {
        date: nearestOpeningDay.date,
        openingTime: nearestOpeningDay.openingTime,
        withinYear: true,
      };
    }

    return null;
  }

  private getFullHoursBetweenTimes(time1: Time, time2: Time): number {
    if (time1.hour === undefined || time2.hour === undefined) {
      return 0;
    }

    const minutes1 = time1.hour * 60 + (time1.minute ?? 0);
    const minutes2 = time2.hour * 60 + (time2.minute ?? 0);
    const minutesDifference = minutes2 - minutes1;
    const hoursDifference = Math.floor(minutesDifference / 60);

    return hoursDifference;
  }
}
