import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Cart } from '@spartacus/cart/base/root';
import { combineLatest, Observable } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import {
  CardRewards,
  RewardInterface,
  RewardsInterface,
} from '@app/custom/features/rrs-rewards/models/rewards.model';
import { RrsRewardsService } from '@app/custom/features/rrs-rewards/serwices/rrs-rewards.service';
import { RrsSelectRewardsService } from '@app/custom/features/rrs-rewards/serwices/rrs-select-rewards.service';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-select-rewards',
  templateUrl: './rrs-select-rewards.component.html',
})
export class RrsSelectRewardsComponent {
  form = new FormGroup({});

  rewardsLoading$ = this.rrsSelectRewardsService.load$;
  appliedRewards: (CardRewards | undefined)[] = [];

  rewardsWithCart$: Observable<{
    cart: Cart;
    rewards: RewardsInterface;
  }> = combineLatest([
    this.activeCartService.getActive(),
    this.rrsRewardsService.getUserRewards(),
  ]).pipe(
    map(([cart, rewards]: [Cart, RewardsInterface]) => {
      const rewardsFiltered = rewards.rewards?.filter(
        (item) => !(item.isCancelled || item.isExpired || item.isRedeemed)
      );

      return { cart, rewards: { rewards: rewardsFiltered } };
    }),
    tap(({ cart, rewards }) => {
      const appliedRewards = cart?.rewards || [];

      this.appliedRewards = (rewards?.rewards || []).map(
        (reward: RewardInterface) => {
          const appliedVoucher = appliedRewards.find(
            (item) => item.couponId == String(reward.rewardId)
          );

          this.form.addControl(
            String(reward.rewardId),
            new FormControl(!!appliedVoucher)
          );
          return appliedVoucher;
        }
      );
    })
  );

  constructor(
    protected activeCartService: RrsActiveCartService,
    protected rrsRewardsService: RrsRewardsService,
    protected rrsSelectRewardsService: RrsSelectRewardsService
  ) {}

  submitReward(
    reward: RewardInterface,
    event: Event & { target: HTMLInputElement }
  ): void {
    if (event.target.checked) {
      const rewards = Object.entries(this.form.value)
        .filter(([_, value]) => value === true)
        .map(([rewardId]) => ({
          rewardId,
        }));

      this.rrsSelectRewardsService.selectRewards(rewards);
    } else {
      this.rrsSelectRewardsService.selectRewards(
        [{ rewardId: `${reward.rewardId}` }],
        true
      );
    }
  }
}
