import { Injector, NgModule, APP_INITIALIZER } from '@angular/core';
import { rrsPageMetaConfig } from '@app/custom/features/seo/config/rrs-page-meta.config';
import { rrsSeoConfig } from '@app/custom/features/seo/config/rrs-seo.config';
import { RrsBasePageMetaResolver } from '@app/custom/features/seo/resolvers/rrs-base-page-meta.resolver';
import { RrsContentPageMetaResolver } from '@app/custom/features/seo/resolvers/rrs-content-page-meta.resolver';
import { RrsOtherPageTypeSchemaBuilder } from '@app/custom/features/seo/schema-builders/other-page-type-schema.builder';
import { RrsStoreDetailsSchemaBuilder } from '@app/custom/features/seo/schema-builders/rrs-store-details-schema.builder';
import { RrsSharedPipesModule } from '@app/shared/pipes';
import {
  BasePageMetaResolver,
  ContentPageMetaResolver,
  provideConfig,
} from '@spartacus/core';
import {
  SCHEMA_BUILDER,
  SeoMetaService,
  StructuredDataModule,
} from '@spartacus/storefront';

export function initSeoService(injector: Injector): () => void {
  const result = (): void => {
    const service = injector.get(SeoMetaService);
    service.init();
  };
  return result;
}

@NgModule({
  imports: [StructuredDataModule, RrsSharedPipesModule],
  providers: [
    provideConfig(rrsSeoConfig),
    provideConfig(rrsPageMetaConfig),
    {
      provide: BasePageMetaResolver,
      useExisting: RrsBasePageMetaResolver,
    },
    {
      provide: ContentPageMetaResolver,
      useExisting: RrsContentPageMetaResolver,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initSeoService,
      deps: [Injector],
      multi: true,
    },
    {
      provide: SCHEMA_BUILDER,
      useExisting: RrsStoreDetailsSchemaBuilder,
      multi: true,
    },
    {
      provide: SCHEMA_BUILDER,
      useExisting: RrsOtherPageTypeSchemaBuilder,
      multi: true,
    },
  ],
})
export class RrsSeoModule {}
