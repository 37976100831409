import { provideConfig, RoutingConfig } from '@spartacus/core';

export const rrsContactUsRoutingConfig = provideConfig({
  routing: {
    routes: {
      contact_us: {
        paths: ['contact-us'],
      },
    },
  },
} as RoutingConfig);
