export function removeUndefinedFields<T extends Record<string, any>>(
  obj: T
): T {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  return obj;
}

export function objectNotEmpty(object?: any): boolean {
  return Boolean(object) && Object.keys(object).length > 0;
}
