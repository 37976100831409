import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rrsBoldPrice',
})
export class RrsBoldPricePipe implements PipeTransform {
  transform(text: string): string {
    return text
      .split(/(\$\d+)/)
      .map((string) =>
        string.includes('$') ? `<strong>${string}</strong>` : string
      )
      .join('');
  }
}
