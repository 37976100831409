<nav class="header-navi">
  <ul class="d-flex align-items-center justify-content-between list-none">
    <li class="header-navi__item header-navi__item--hamburger d-xl-none">
      <button
        (click)="toggleMenu()"
        [ngClass]="{ 'hamburger--active': headerUi.isMenuMobiOpen | async }"
        aria-label="Menu Toggler"
        class="header-navi__link text-decoration-none hamburger">
        <span class="hamburger__container" tabindex="-1">
          <span class="hamburger__bars"></span>
        </span>
      </button>
    </li>

    <li
      [ngClass]="{
        'header-navi__item--active': headerUi.isSearchBoxMobiOpen | async
      }"
      class="header-navi__item flex-fill d-xl-none">
      <button
        (click)="toggleSearch()"
        aria-label="Searchbox Toggler"
        class="header-navi__link text-decoration-none w-100"
        type="button">
        <img
          alt="Searchbox toggler"
          height="24"
          src="/assets/icons/loupe.svg"
          width="24" />
      </button>
    </li>

    <li
      *ngIf="{ defaultStoreId: defaultStoreId$ | async } as defaulStoreData"
      class="header-navi__item flex-fill d-none d-xl-block mx-1">
      <a
        [attr.title]="'rrs.navigation.myStore' | cxTranslate"
        [routerLink]="
          (defaulStoreData.defaultStoreId?.length
            ? {
                cxRoute: 'storeDetails',
                params: { storeId: defaulStoreData.defaultStoreId }
              }
            : { cxRoute: 'storeFinder' }
          ) | cxUrl
        "
        class="header-navi__link text-decoration-none position-relative"
        data-cy="my-store-navi">
        <img
          [attr.alt]="'pin.svg'"
          [attr.src]="'/assets/icons/pin.svg'"
          height="24"
          width="24" />
        <span class="d-none d-xl-inline">
          {{ 'rrs.navigation.myStore' | cxTranslate }}
        </span>
      </a>
    </li>

    <li class="header-navi__item flex-fill mx-1">
      <a
        [attr.title]="'rrs.navigation.myOrders' | cxTranslate"
        class="header-navi__link text-decoration-none position-relative"
        data-cy="my-orders-navi"
        [routerLink]="
          (isUserLogged() | async) ? 'my-account/order-history' : 'order-status'
        ">
        <img
          [attr.alt]="'box.svg'"
          [attr.src]="'/assets/icons/box.svg'"
          height="24"
          width="24" />
        <span class="d-none d-xl-inline">
          {{ 'rrs.navigation.myOrders' | cxTranslate }}
        </span>
      </a>
    </li>

    <li
      *ngIf="isUserLogged() | async; else notLogged"
      class="header-navi__item flex-fill mx-1">
      <a
        [attr.title]="'rrs.navigation.myRewards' | cxTranslate"
        [routerLink]="['my-account', 'rewards']"
        class="header-navi__link text-decoration-none position-relative cursor-pointer"
        data-cy="my-rewards-navi">
        <img
          [attr.alt]="'user.svg'"
          [attr.src]="'/assets/icons/user.svg'"
          height="24"
          width="24" />
        <span
          *ngIf="username$ | async as username; else myRewards"
          class="d-none d-xl-inline">
          {{ 'rrs.account.welcomeShort' | cxTranslate: { username: username } }}
        </span>
        <ng-template #myRewards>
          <span class="d-none d-xl-inline">
            {{ 'rrs.navigation.myRewards' | cxTranslate }}
          </span>
        </ng-template>
      </a>
    </li>

    <li
      (mouseenter)="miniCart.open()"
      (mouseleave)="miniCart.close()"
      class="header-navi__item flex-fill mx-1"
      data-cy="cart-navi">
      <a
        (focus)="miniCart.open()"
        (focusout)="miniCart.close()"
        [attr.title]="'rrs.navigation.myCart' | cxTranslate"
        class="header-navi__link text-decoration-none"
        data-cy="cart-navi"
        routerLink="cart">
        <span class="position-relative">
          <img
            [attr.alt]="'cart.svg'"
            [attr.src]="'/assets/icons/cart.svg'"
            height="24"
            width="24" />
          <div class="header-navi__badge fs-6">
            {{ (totalUnitCount$ | async) || '' }}
          </div>
        </span>
        <span class="d-none d-xl-inline">
          {{ 'rrs.navigation.myCart' | cxTranslate }}
        </span>
      </a>

      <rrs-mini-cart *ngIf="isDesktop$ | async" class="p-0"></rrs-mini-cart>
    </li>
  </ul>
</nav>

<ng-template #notLogged>
  <li class="header-navi__item flex-fill mx-1" id="header-navi-sign-link">
    <a
      (click)="doLogin()"
      (keydown.enter)="doLogin()"
      [attr.title]="'rrs.navigation.signInSignUp' | cxTranslate"
      class="header-navi__link text-decoration-none position-relative cursor-pointer"
      data-cy="login-navi"
      tabindex="0">
      <img
        [attr.alt]="'user.svg'"
        [attr.src]="'/assets/icons/user.svg'"
        height="24"
        width="24" />
      <span class="d-none d-xl-inline">{{
        'rrs.navigation.signInSignUp' | cxTranslate
      }}</span>
    </a>
  </li>
</ng-template>
