import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RrsOccProductStoreStockAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  getStoreStockLevel(productCode: string, storeName: string): Observable<any> {
    const url = this.occEndpoints.buildUrl('getStoreProductStock', {
      urlParams: {
        productCode,
        storeName,
      },
    });

    return this.http.get(url);
  }
}
