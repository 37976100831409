import { first, switchMap } from 'rxjs/operators';
import {
  GlobalMessageService,
  GlobalMessageType,
  HttpErrorModel,
  normalizeHttpError,
  OccEndpointsService,
  UserIdService,
} from '@spartacus/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RrsLoyaltyService } from '@app/custom/features/rrs-account/services/rrs-loyalty.service';

@Injectable({
  providedIn: 'root',
})
export class RrsClaimRewardsService {
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected readonly globalMessageService: GlobalMessageService,
    protected rrsLoyaltyService: RrsLoyaltyService,
    protected userIdService: UserIdService
  ) {}

  putClaimRewards(order: string): void {
    this.userIdService
      .getUserId()
      .pipe(
        first(),
        switchMap((userId) => {
          const url = this.occEndpointsService.buildUrl('claimRewards', {
            urlParams: {
              userId,
              order,
            },
          });

          return this.http.put(url, {});
        })
      )
      .subscribe({
        next: () => this.onSuccess(),
        error: (error: Error) => this.onError(normalizeHttpError(error)),
      });
  }

  protected onError(error: HttpErrorModel | undefined): void {
    (error?.details || []).forEach((error) => {
      this.globalMessageService.add(
        { raw: error.message + (error?.reason ? '. ' + error?.reason : '') },
        GlobalMessageType.MSG_TYPE_ERROR
      );
    });
  }

  protected onSuccess(): void {
    this.rrsLoyaltyService.updateLoyalty();

    this.globalMessageService.add(
      {
        key: 'rrs.account.rewards.selectRewardsSuccess',
      },
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );
  }
}
