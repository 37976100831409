import { CartSharedModule } from '@spartacus/cart/base/components';
import {
  IconModule,
  ItemCounterModule,
  KeyboardFocusModule,
  PromotionsModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RrsAddToCartComponent } from './rrs-add-to-cart.component';
import { RrsIconModule } from '@app/shared/components';

@NgModule({
  declarations: [RrsAddToCartComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CartSharedModule,
    RouterModule,
    SpinnerModule,
    PromotionsModule,
    FeaturesConfigModule,
    UrlModule,
    IconModule,
    I18nModule,
    ItemCounterModule,
    KeyboardFocusModule,
    RrsIconModule,
  ],
  exports: [RrsAddToCartComponent],
})
export class RrsAddToCartModule {}
