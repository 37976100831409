import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule, UrlModule } from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RrsFormErrorsModule } from '@app/shared/components/rrs-form-errors/rrs-form-errors.module';
import { RrsInputModule } from '@app/shared/components/rrs-input';
import { rrsResetPasswordCmsConfig } from '@app/custom/features/rrs-reset-password/configs/cms.config';
import { RrsResetPasswordComponent } from '@app/custom/features/rrs-reset-password/components/rrs-reset-password/rrs-reset-password.component';
import { rrsResetPasswordRoutingConfig } from '@app/custom/features/rrs-reset-password/configs/router.config';
import {
  CmsPageGuard,
  SpinnerModule,
  KeyboardFocusModule,
} from '@spartacus/storefront';
import { RrsSharedPipesModule } from '@app/shared/pipes';

@NgModule({
  declarations: [RrsResetPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    ReactiveFormsModule,
    KeyboardFocusModule,
    RouterModule.forChild([
      {
        path: 'login/reset-password',
        component: RrsResetPasswordComponent,
        canActivate: [CmsPageGuard],
      },
    ]),
    RrsFormErrorsModule,
    RrsInputModule,
    SpinnerModule,
    UrlModule,
    RrsSharedPipesModule,
  ],
  providers: [...rrsResetPasswordCmsConfig, rrsResetPasswordRoutingConfig],
})
export class RrsResetPasswordModule {}
