import { APP_INITIALIZER } from '@angular/core';
import { RrsFreshChatService } from '@app/custom/features/rrs-freshchat/rrs-freshchat.service';
import { scriptsCookieGroups } from '@app/custom/features/rrs-onetrust/rrs-onetrust.config';
import { RrsOnetrustService } from '@app/custom/features/rrs-onetrust/rrs-onetrust.service';
import { Subscription } from 'rxjs';

export const RssFreshChatInitializer = {
  provide: APP_INITIALIZER,
  useFactory: initFreshChat,
  deps: [RrsFreshChatService, RrsOnetrustService],
  multi: true,
};

export function initFreshChat(
  FreshChatService: RrsFreshChatService,
  OnetrustService: RrsOnetrustService
): Function {
  return (): Subscription =>
    OnetrustService.checkConsents(scriptsCookieGroups.freshChat).subscribe(
      (hasConsent) => {
        if (!hasConsent) return;
        FreshChatService.initFreshChat();
      }
    );
}
