import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ErrorModel,
  GlobalMessageType,
  HttpErrorHandler,
  HttpResponseStatus,
} from '@spartacus/core';
import { Priority } from '@spartacus/core/src/util/applicable';

const OAUTH_ENDPOINT = '/authorizationserver/oauth/token';
const OTHER_ERRORS = ['ConversionError'];

@Injectable({
  providedIn: 'root',
})
export class RrsBadRequestErrorHandler extends HttpErrorHandler {
  responseStatus = HttpResponseStatus.BAD_REQUEST;

  handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    this.handlePaymentError(request, response);
    this.handleUnknownIdentifierError(request, response);
    this.handleNullPointerError(request, response);
    this.handleBadPassword(request, response);
    this.handleValidationError(request, response);
    this.handleOtherErrors(request, response);
  }

  protected handleBadPassword(
    request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    if (
      response.url?.includes(OAUTH_ENDPOINT) &&
      response.error?.error === 'invalid_grant' &&
      request.body?.get('grant_type') === 'password'
    ) {
      this.globalMessageService.add(
        {
          key: `rrs.loginForm.httpHandlers.badRequest.bad_credentials`,
          params: {
            errorMessage:
              response.error.error_description || response.message || '',
          },
        },
        GlobalMessageType.MSG_TYPE_ERROR
      );
      this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_CONFIRMATION);
    }
  }

  protected handleValidationError(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter((e) => e.type === 'ValidationError')
      .forEach((error) => {
        this.globalMessageService.add(
          {
            key: `httpHandlers.validationErrors.${error.reason}.${error.subject}`,
          },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      });
  }

  getPriority(): Priority {
    return Priority.HIGH;
  }

  protected getErrors(response: HttpErrorResponse): ErrorModel[] {
    return (response.error?.errors || []).filter(
      (error: ErrorModel) => error.type !== 'JaloObjectNoLongerValidError'
    );
  }

  protected handlePaymentError(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter((e) => e.type === 'PaymentError')
      .forEach((error) => {
        const reason = error.reason || '';
        const message = `${error.message} (${reason})`;
        this.globalMessageService.add(
          error.message ? message : { key: 'httpHandlers.paymentError' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      });
  }

  protected handleUnknownIdentifierError(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter((e) => e.type === 'UnknownIdentifierError')
      .forEach((error) => {
        this.globalMessageService.add(
          error.message
            ? error.message
            : { key: 'httpHandlers.unknownIdentifierError' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      });
  }

  protected handleOtherErrors(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter((e) => OTHER_ERRORS.includes(e.type ?? ''))
      .forEach((error) => {
        this.globalMessageService.add(
          error.message ?? '',
          GlobalMessageType.MSG_TYPE_ERROR
        );
      });
  }

  protected handleNullPointerError(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter((e) => e.type === 'NullPointerError')
      .forEach((error) => {
        this.globalMessageService.add(
          error.message
            ? error.message
            : { key: 'httpHandlers.unknownIdentifierError' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      });
  }
}
