import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalService } from '@app/custom/features/modal';
import { RrsMultiCartService } from '@app/custom/features/rrs-cart/services/rrs-multi-cart.service';
import { RrsAddToWishlistUiEvent } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/model/events';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import {
  Cart,
  CartAddEntryFailEvent,
  CartAddEntrySuccessEvent,
} from '@spartacus/cart/base/root';
import { SavedCartService } from '@spartacus/cart/saved-cart/core';
import {
  EventService,
  GlobalMessageService,
  GlobalMessageType,
  Product,
  UserIdService,
} from '@spartacus/core';
import { BehaviorSubject, Observable, Subscription, race } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'rrs-rrs-add-to-wishlist-modal',
  templateUrl: './rrs-add-to-wishlist-modal.component.html',
  styleUrls: ['./rrs-add-to-wishlist-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsAddToWishlistModalComponent implements OnInit {
  icon = ICON_TYPE_LIST;
  private subscriptions = new Subscription();
  product!: Product;
  cartList$!: Observable<Cart[]>;
  selectedCart!: any;
  userId!: string;
  isLoading$ = new BehaviorSubject<boolean>(false);
  private productCode: string = '';

  @Input() set data(options: { product: Product; productCode: string }) {
    this.product = options.product;
    this.productCode = options.productCode;
  }

  form = new FormGroup({
    savedCartCode: new FormControl(),
    name: new FormControl(),
    description: new FormControl(),
  });

  constructor(
    protected savedCartService: SavedCartService,
    protected modalService: ModalService,
    protected multiCartFacade: RrsMultiCartService,
    protected userIdService: UserIdService,
    protected globalMessage: GlobalMessageService,
    protected eventService: EventService
  ) {}

  ngOnInit(): void {
    this.savedCartService.loadSavedCarts();
    this.cartList$ = this.savedCartService.getList();
    const UserIdSub = this.userIdService.getUserId().subscribe((userId) => {
      this.userId = userId;
    });
    this.subscriptions.add(UserIdSub);
  }

  addProductToWishlist(): void {
    if (!this.product?.code) return;
    const selectedCart: Cart = this.form.get('savedCartCode')?.value;
    if (!selectedCart.entries || !selectedCart.code) return;
    const productCodes = selectedCart.entries.map((entry) => {
      return entry.product?.code;
    });

    if (productCodes.includes(this.product?.code)) {
      this.modalService.closeActiveModal();
      this.globalMessage.add(
        { key: 'rrs.wishlist.duplicateProduct' },
        GlobalMessageType.MSG_TYPE_WARNING
      );
      return;
    }
    this.dispatchAddToWishlistEvent(selectedCart.code ?? '', this.product);
    this.isLoading$.next(true);
    this.multiCartFacade.addEntries(this.userId, selectedCart.code, [
      {
        productCode: this.product?.code ?? this.productCode,
        quantity: 1,
      },
    ]);
    race(
      this.eventService.get(CartAddEntrySuccessEvent),
      this.eventService.get(CartAddEntryFailEvent)
    )
      .pipe(
        take(1),
        tap((event) => {
          this.isLoading$.next(false);
          if (event instanceof CartAddEntrySuccessEvent) {
            this.globalMessage.add(
              { key: 'rrs.wishlist.updatedWishlist' },
              GlobalMessageType.MSG_TYPE_CONFIRMATION
            );
          }

          this.modalService.closeActiveModal();
        })
      )
      .subscribe();
  }

  createWishlist(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    const userId = this.userId;
    this.isLoading$.next(true);
    this.multiCartFacade
      .createCart({ userId })
      .pipe(
        take(1),
        tap((newCart) => {
          this.multiCartFacade.addEntries(this.userId, newCart.code || '', [
            {
              productCode: this.product?.code ?? this.productCode,
              quantity: 1,
            },
          ]);
        }),
        switchMap((newCart) => {
          return race(
            this.eventService.get(CartAddEntrySuccessEvent),
            this.eventService.get(CartAddEntryFailEvent)
          ).pipe(
            take(1),
            tap((event) => {
              this.isLoading$.next(false);
              if (event instanceof CartAddEntrySuccessEvent) {
                this.savedCartService.saveCart({
                  cartId: newCart.code || '',
                  saveCartName: this.form.get('name')?.value!,
                  saveCartDescription:
                    this.form.get('description')?.value || '-',
                });
                this.globalMessage.add(
                  { key: 'rrs.wishlist.createdWishlist' },
                  GlobalMessageType.MSG_TYPE_CONFIRMATION
                );
              }

              this.modalService.closeActiveModal();
            })
          );
        })
      )
      .subscribe();
  }

  private dispatchAddToWishlistEvent(cartId: string, product: Product): void {
    this.eventService.dispatch({ cartId, product }, RrsAddToWishlistUiEvent);
  }
}
