import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address } from '@spartacus/core';
import { environment } from '@environments/environment';
import { map, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoqateResponse } from '@app/custom/features/rrs-loqate/models/rrs-locate.model';
import { ModalService } from '@app/custom/features/modal';
import { RrsLocateModalComponent } from '@app/custom/features/rrs-loqate/components/rrs-locate-modal/rrs-locate-modal.component';

@Injectable({
  providedIn: 'root',
})
export class RrsLoqateService {
  constructor(protected http: HttpClient, protected modal: ModalService) {}
  public loqateSubject = new Subject<{
    isValidated: boolean;
    address: Address;
  }>();
  loqate$ = this.loqateSubject.asObservable();

  validateAddress(address: Address, cartId?: string): void {
    this.http
      .post<LoqateResponse[]>(environment.loqateUrl, {
        Key: environment.loqateApiKey,
        Addresses: [
          {
            Address1: address.line1,
            Address2: address?.line2,
            Locality: address.town,
            AdministrativeArea: address.region?.isocodeShort,
            PostalCode: address.postalCode,
            Country: address.country?.isocode,
          },
        ],
      })
      .pipe(
        take(1),
        map((response: LoqateResponse[]) => {
          if (response[0].Matches[0].AQI === 'A') {
            this.loqateSubject.next({
              isValidated: true,
              address: address,
            });
            return;
          }
          const locateModalRef = this.modal.open(RrsLocateModalComponent);
          locateModalRef.componentInstance.address = address;
          locateModalRef.componentInstance.recommendedAddress =
            this.formatRecommendedAddress(response);
          locateModalRef.componentInstance.cartId = cartId || '';
        })
      )
      .subscribe();
  }

  formatRecommendedAddress(response: LoqateResponse[]): Address {
    let recommendedAddress: Address = {};
    recommendedAddress.line1 = response[0]?.Matches[0]?.DeliveryAddress1;
    recommendedAddress.line2 = response[0]?.Matches[0]?.DeliveryAddress2 ?? '';
    recommendedAddress.town = response[0]?.Matches[0]?.Locality;
    recommendedAddress.region = {
      isocode:
        response[0]?.Matches[0]?.Country +
        '-' +
        response[0]?.Matches[0]?.AdministrativeArea,
      isocodeShort: response[0]?.Matches[0]?.AdministrativeArea,
    };
    recommendedAddress.postalCode = response[0]?.Matches[0]?.PostalCodePrimary;
    return recommendedAddress;
  }
}
