import { NgModule } from '@angular/core';
import { CartBaseModule } from '@spartacus/cart/base';
import {
  ADD_TO_CART_FEATURE,
  CART_BASE_FEATURE,
  MINI_CART_FEATURE,
} from '@spartacus/cart/base/root';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    CartBaseModule,
    provideConfig({
      featureModules: {
        [CART_BASE_FEATURE]: {
          module: () =>
            import('@spartacus/cart/base').then((m) => m.CartBaseModule),
        },
      },
    } as CmsConfig),
    provideConfig({
      featureModules: {
        [MINI_CART_FEATURE]: {
          module: () =>
            import('@spartacus/cart/base/components/mini-cart').then(
              (m) => m.MiniCartModule
            ),
        },
      },
    } as CmsConfig),
    provideConfig({
      featureModules: {
        [ADD_TO_CART_FEATURE]: {
          module: () =>
            import('@spartacus/cart/base/components/add-to-cart').then(
              (m) => m.AddToCartModule
            ),
        },
      },
    } as CmsConfig),
  ],
})
export class CartBaseFeatureModule {}
