<ng-container *ngIf="storeEntities$ | async as storesData">
  <ng-container *ngIf="(isLoading$ | async) === false; else loading">
    <div
      [ngClass]="{ 'border border-1': storesData.pagination?.totalResults > 0 }"
      class="stores__list mt-4">
      <p class="text-center" *ngIf="!storesData.pagination?.totalResults">
        {{ 'rrs.storeFinder.noResults' | cxTranslate }}
      </p>
      <div
        data-cy="store-finder-list-item"
        class="store d-flex justify-content-between align-items-center px-3 py-2 cursor-pointer"
        (click)="onSelectStore(store)"
        (keydown.enter)="onSelectStore(store)"
        [class.store--selected]="store.name === selected?.name"
        tabindex="0"
        *ngFor="let store of storesData.stores">
        <div class="store__logo me-3">
          <rrs-store-logo
            [class.store__logo--ob]="store.isOffBroadway"
            [store]="store"
            [size]="56"></rrs-store-logo>
        </div>
        <div class="store__details flex-grow-1">
          <div class="d-flex justify-content-between align-items-center">
            <h6 data-cy="store-finder-list-item-title" class="mb-0">
              {{ store.displayName }}
            </h6>
            <span>{{ store.formattedDistance }}</span>
          </div>
          <p class="mb-0">
            {{ store.address?.line1 }} {{ store.address?.town }}
            {{ store.address?.region?.isocodeShort }}
            {{ store.address?.postalCode }}
          </p>
        </div>
      </div>
    </div>
    <div
      *ngIf="storesData.pagination?.totalResults > 0"
      class="py-2 d-flex justify-content-between align-items-center">
      <a
        data-cy="store-finder-pagination-previous"
        class="btn-link link-primary text-uppercase"
        [class.invisible]="storesData.pagination?.currentPage === 0"
        [routerLink]="[]"
        [queryParams]="{ page: storesData.pagination?.currentPage - 1 || null }"
        queryParamsHandling="merge">
        <rrs-icon [size]="12" [icon]="iconTypeList.CHEVRON_LEFT"></rrs-icon>
        {{ 'rrs.storeFinder.back' | cxTranslate }}</a
      >
      <span data-cy="store-finder-pagination-stats">
        {{
          storesData.pagination?.totalResults > 0
            ? ('rrs.storeFinder.pagination'
              | cxTranslate: (storesData.pagination | paginationStats))
            : ('rrs.storeFinder.noResults' | cxTranslate)
        }}
      </span>
      <a
        data-cy="store-finder-pagination-next"
        class="btn-link link-primary text-uppercase"
        [routerLink]="[]"
        [class.invisible]="
          storesData.pagination?.currentPage + 1 >=
          storesData.pagination?.totalPages
        "
        queryParamsHandling="merge"
        [queryParams]="{ page: storesData.pagination?.currentPage + 1 }"
        >{{ 'rrs.storeFinder.next' | cxTranslate }}
        <rrs-icon [size]="12" [icon]="iconTypeList.CHEVRON_RIGHT"></rrs-icon
      ></a>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="d-flex align-items-center justify-content-center p-5">
      <div class="loader spinner-border text-accent" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-template>
</ng-container>
