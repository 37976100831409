export const RrsCookiesGroups = {
  StrictlyNecessaryCookies: 'C0001',
  PerformanceCookies: 'C0002',
  FunctionalCookies: 'C0003',
  TargetingCookies: 'C0004',
  SocialMediaCookies: 'C0005',
  NonExemptThirdPartiesCookies: 'C0010',
};

export const scriptsCookieGroups = {
  bazaarvoice: ['StrictlyNecessaryCookies', 'NonExemptThirdPartiesCookies'],
  dynamicYield: ['FunctionalCookies', 'StrictlyNecessaryCookies'],
  dynatrace: ['StrictlyNecessaryCookies', 'NonExemptThirdPartiesCookies'],
  freshChat: ['StrictlyNecessaryCookies', 'NonExemptThirdPartiesCookies'],
  verint: ['StrictlyNecessaryCookies', 'NonExemptThirdPartiesCookies'],
  klarna: ['StrictlyNecessaryCookies'],
  powerreviews: ['StrictlyNecessaryCookies', 'NonExemptThirdPartiesCookies'],
  adobeLaunch: [
    'PerformanceCookies',
    'NonExemptThirdPartiesCookies',
    'TargetingCookies',
  ],
};
