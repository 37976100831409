import { Converter, ConverterService } from '@spartacus/core';
import { Injectable } from '@angular/core';
import { Brands } from '@app/custom/features/rrs-brands/models/brands.model';
import { BRAND_NAME_NORMALIZER } from '@app/custom/features/rrs-brands/configs/normalizer.config';

@Injectable()
export class RrsBrandsNormalizer implements Converter<Brands[], Brands[]> {
  constructor(private converter: ConverterService) {}
  convert(source: Brands[], target: Brands[] = []): Brands[] {
    target = {
      ...target,
      ...source,
    };
    if (source.length) {
      target = source.map((val) => {
        return {
          values: val?.values?.map((vl) => {
            return this.converter.convert(vl, BRAND_NAME_NORMALIZER);
          }),
          letters: val.letters,
        };
      });
    }
    return target;
  }
}
