import { Directive, HostListener } from '@angular/core';
import { CARD_MAX_LENGTH } from '@app/shared/configs';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[rrsCardNumberInput]',
})
export class CardNumberInputDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  ngModelChange(value: string): void {
    const formattedValue = (
      value
        .replace(/\D/g, '')
        .substring(0, CARD_MAX_LENGTH)
        .match(/.{1,4}/g) ?? []
    ).join('-');
    this.ngControl.valueAccessor?.writeValue(formattedValue);
  }
}
