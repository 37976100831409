import { CardNumberInputDirective } from '@app/shared/directives/card-number-input';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PhoneNumberInputDirective } from '@app/shared/directives/phone-number-input';
import { ReactiveFormsModule } from '@angular/forms';
import { RrsStrongPasswordDirective } from '@app/shared/directives/strong-password';
import { ZipcodeInputDirective } from '@app/shared/directives/zipcode-input/zipcode-input.directive';
import { RrsCmsHeadingDirective } from '@app/shared/directives/cms-heading/rrs-cms-heading.directive';
import { InternalLinkDirective } from './internal-link.directive';

@NgModule({
  declarations: [
    CardNumberInputDirective,
    PhoneNumberInputDirective,
    RrsStrongPasswordDirective,
    ZipcodeInputDirective,
    RrsCmsHeadingDirective,
    InternalLinkDirective,
  ],
  exports: [
    CardNumberInputDirective,
    PhoneNumberInputDirective,
    RrsStrongPasswordDirective,
    ZipcodeInputDirective,
    RrsCmsHeadingDirective,
    InternalLinkDirective,
  ],
  imports: [CommonModule, ReactiveFormsModule],
})
export class RrsSharedDirectivesModule {}
