import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { AppliedCouponsComponent } from '@spartacus/cart/base/components';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cart-coupon-applied',
  styleUrls: ['./rrs-cart-coupon-applied.component.scss'],
  templateUrl: './rrs-cart-coupon-applied.component.html',
})
export class RrsCartCouponAppliedComponent extends AppliedCouponsComponent {
  @Output() voucherRemoved = new EventEmitter<boolean>();
  removeVoucher(voucherId: string): void {
    this.voucherRemoved.emit(true);
    super.removeVoucher(voucherId);
  }
}
