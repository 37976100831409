export const MAX_BADGES_DISPLAYED = 3;

export const AVAILABLE_BADGES: Array<Badge> = [
  {
    label: 'Promotions Eligible',
    priority: 1,
    bgColor: '#EEFCF8',
    textColor: '#125945',
    categoryUrl: '/cs/promotionseligible',
    categoryId: 'promotionseligible',
    border: '1px solid #7EA89C',
  },
  {
    label: 'New Arrival',
    priority: 2,
    bgColor: '#E6DCEB',
    textColor: '#581479',
    categoryUrl: '/cs/newarrival',
    categoryId: 'newarrival',
    border: '1px solid #A37EB5',
  },
  {
    label: 'Bestseller',
    priority: 3,
    bgColor: '#F5DCE2',
    textColor: '#950E2F',
    categoryUrl: '/cs/bestseller',
    categoryId: 'bestseller',
    border: '1px solid #DB7D94',
  },
];

export interface Badge {
  label: string;
  priority: number;
  bgColor: string;
  categoryUrl: string;
  textColor: string;
  categoryId: string;
  excludeCategory?: boolean;
  border: string;
}
