import { Injectable, inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { RrsUserIdService } from '@app/custom/features/rrs-user-id/services/rrs-user-id.service';
import {
  OCC_PREFIX,
  ASSETS_PATH,
  ASM_PATH,
  SESSION_ID_HEADER_NAME,
} from '@app/custom/features/rrs-user-id/rrs-user-id.config';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { switchMap, take } from 'rxjs/operators';
import { WindowRef } from '@spartacus/core';

@Injectable()
export class RrsUserIdSessionInterceptor implements HttpInterceptor {
  protected rrsUserIdService = inject(RrsUserIdService);
  protected activeCartFacade = inject(ActiveCartFacade);
  protected winRef = inject(WindowRef);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      this.winRef.isBrowser() &&
      (request.url.includes(OCC_PREFIX) ||
        request.url.includes(ASSETS_PATH) ||
        request.url.includes(ASM_PATH))
    ) {
      return this.activeCartFacade.getActive().pipe(
        take(1),
        switchMap((activeCart) => {
          let headers = request.headers;

          if (activeCart.code) {
            headers = headers.set('x-rrs-cart-id', activeCart.code);
          }

          if (activeCart.guid) {
            headers = headers.set('x-rrs-cart-id-2', activeCart.guid);
          }

          const { userDeviceId } = this.rrsUserIdService;
          if (userDeviceId) {
            headers = headers.set(SESSION_ID_HEADER_NAME, userDeviceId);
          }

          const clonedRequest = request.clone({ headers });
          return next.handle(clonedRequest);
        })
      );
    }
    return next.handle(request);
  }
}
