import { RrsSharedDirectivesModule } from '@app/shared/directives';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RICH_TEXT_COMPONENT_ID } from '@app/custom/features/rrs-cms/configs/contentstack.config';
import { RrsCmsRichTextComponent } from '@app/custom/features/rrs-cms/components/rrs-cms-rich-text/components/rrs-cms-rich-text.component';
import { RrsSharedPipesModule } from '@app/shared/pipes';

@NgModule({
  declarations: [RrsCmsRichTextComponent],
  imports: [CommonModule, RrsSharedPipesModule, RrsSharedDirectivesModule],
  providers: [
    provideConfig({
      cmsComponents: {
        [RICH_TEXT_COMPONENT_ID]: {
          component: RrsCmsRichTextComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class RrsCmsRichTextModule {}
