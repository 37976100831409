import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { PHONE_MAX_LENGTH, PHONE_MIN_LENGTH } from '@app/shared/configs';

@Component({
  selector: 'rrs-phone-number-input',
  templateUrl: './rrs-phone-number-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsPhoneNumberInputComponent implements OnInit {
  @Input() control!: UntypedFormControl;
  @Input() label: string = 'Phone number';

  ngOnInit(): void {
    this.handleControl();
  }

  private handleControl(): void {
    if (this.control == null) this.control = new UntypedFormControl();

    this.control.addValidators([
      Validators.minLength(PHONE_MIN_LENGTH),
      Validators.maxLength(PHONE_MAX_LENGTH),
    ]);
  }
}
