import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { Product, ProductService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DONATION_PRODUCT_TYPE } from '@app/custom/features/rrs-donation/models/donation.constant';
import { FormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-donation',
  templateUrl: './rrs-donation.component.html',
})
export class RrsDonationComponent {
  iconTypeList = ICON_TYPE_LIST;

  form = new FormGroup({
    donation: new UntypedFormControl(null),
  });

  product$: Observable<Product | undefined> = this.productService
    .get(DONATION_PRODUCT_TYPE)
    .pipe(tap((product) => this.setDonationVariant(product)));

  constructor(protected productService: ProductService) {}

  get selectedVariant(): string {
    return this.form.get('donation')?.value;
  }

  setDonationVariant(product: Product | undefined): void {
    const donationVariant = (product?.variantOptions || [])[0]?.code;
    this.form.get('donation')?.setValue(donationVariant);
  }
}
