import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import {
  ExternalLinkModule,
  RrsSharedDirectivesModule,
} from '@app/shared/directives';
import { RrsAccordionModule, RrsIconModule } from '@app/shared/components';
import {
  RrsCmsFooterComponent,
  RrsCmsFooterNaviComponent,
  RrsCmsFooterSocialMediaComponent,
} from '@app/custom/features/rrs-cms/components/rrs-cms-footer/components';
import { CommonModule } from '@angular/common';
import { FOOTER_COMPONENT_ID } from '@app/custom/features/rrs-cms/configs/contentstack.config';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RrsFormErrorsModule } from '@app/shared/components/rrs-form-errors/rrs-form-errors.module';
import { RrsSharedPipesModule } from '@app/shared/pipes';

@NgModule({
  declarations: [
    RrsCmsFooterComponent,
    RrsCmsFooterNaviComponent,
    RrsCmsFooterSocialMediaComponent,
  ],
  imports: [
    CommonModule,
    ExternalLinkModule,
    I18nModule,
    ReactiveFormsModule,
    RouterModule,
    RrsAccordionModule,
    RrsFormErrorsModule,
    RrsIconModule,
    RrsSharedPipesModule,
    RrsSharedDirectivesModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        [FOOTER_COMPONENT_ID]: {
          component: RrsCmsFooterComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class RrsCmsFooterModule {}
