<ng-container *ngIf="user$ | async as user">
  <div class="position-relative">
    <h3 class="fs-6 mb-0 text-capitalize">
      {{ 'rrs.account.form.email' | cxTranslate }}
    </h3>
    <p class="fw-bold">{{ user?.uid }}</p>
  </div>
  <div class="position-relative">
    <h3 class="fs-6 mb-0 text-capitalize">
      {{ 'rrs.account.form.password' | cxTranslate }}
    </h3>
    <p class="fw-bold">************</p>

    <button
      (click)="editPersonalInformation()"
      class="position-absolute bottom-0 end-0 d-flex p-2"
      type="button"
      [attr.aria-label]="'rrs.common.edit' | cxTranslate">
      <rrs-icon [icon]="iconTypeList.EDIT" [size]="24"></rrs-icon>
    </button>
  </div>
</ng-container>
