import { normalizeHttpError, OccEndpointsService } from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserGroupList } from '@app/custom/features/rrs-account/models/customer-groups.model';

@Injectable({ providedIn: 'root' })
export class OccCustomerGroupsAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService
  ) {}

  getCustomerGroups(userId: string): Observable<UserGroupList> {
    const url = this.occEndpointsService.buildUrl('customerGroups', {
      urlParams: {
        userId,
      },
    });

    return this.http
      .get<UserGroupList>(url)
      .pipe(catchError((error) => throwError(normalizeHttpError(error))));
  }

  addToCustomerGroup({
    userId,
    groupId,
  }: {
    userId: string;
    groupId: string;
  }): Observable<any> {
    const url = this.occEndpointsService.buildUrl('addToCustomerGroup', {
      urlParams: {
        userId,
        groupId,
      },
    });

    return this.http
      .put(url, {})
      .pipe(catchError((error) => throwError(normalizeHttpError(error))));
  }

  removeFromCustomerGroup({
    userId,
    groupId,
  }: {
    userId: string;
    groupId: string;
  }): Observable<any> {
    const url = this.occEndpointsService.buildUrl('removeFromCustomerGroup', {
      urlParams: {
        userId,
        groupId,
      },
    });

    return this.http
      .delete(url)
      .pipe(catchError((error) => throwError(normalizeHttpError(error))));
  }
}
