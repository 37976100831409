<button class="d-flex align-items-center" (click)="toggleUi()">
  <rrs-icon
    [size]="16"
    [icon]="isCollapsed ? icon.CHEVRON_DOWN : icon.CHEVRON_UP"
    [attr.aria-label]="'asm.hideUi' | cxTranslate"
    class="m-2">
  </rrs-icon>
  <span *ngIf="!isCollapsed" class="label">
    {{ 'asm.toggleUi.collapse' | cxTranslate }}
  </span>
  <span *ngIf="isCollapsed" class="label">
    {{ 'asm.toggleUi.expand' | cxTranslate }}
  </span>
</button>
