<ng-container *ngIf="rewards$ | async as rewards">
  <div class="rewards">
    <header
      class="rewards__header d-flex align-items-center px-2 py-3 p-lg-0 mb-4">
      <h1
        class="fs-2 fw-normal d-inline-flex align-items-center mb-0"
        data-cy="rewards-header">
        {{ 'rrs.account.rewards.myRewards' | cxTranslate }}
      </h1>
    </header>

    <section class="mb-4 px-3 px-lg-0">
      <ng-container *ngIf="rewards?.rewards?.length; else noRewards">
        <ul class="list-none">
          <li
            *ngFor="let reward of rewards?.rewards; let i = index"
            class="mb-4 d-flex rewards__item"
            data-cy="rewards-item"
            [ngClass]="{
              'rewards__item--expired d-none d-lg-flex':
                reward?.isCancelled || reward?.isExpired || reward?.isRedeemed
            }">
            <div class="text-white d-flex flex-column me-3">
              <div
                class="d-flex align-items-center justify-content-center p-2 bg-opacity-50"
                [ngClass]="
                  !(
                    reward?.isCancelled ||
                    reward?.isExpired ||
                    reward?.isRedeemed
                  ) && willDateExpireSoon(reward.expireTimestamp)
                    ? 'bg-danger'
                    : 'bg-light'
                ">
                <rrs-icon [icon]="iconTypeList.DOLAR" [size]="64"></rrs-icon>
              </div>
              <div
                class="p-1 text-center"
                [ngClass]="
                  !(
                    reward?.isCancelled ||
                    reward?.isExpired ||
                    reward?.isRedeemed
                  ) && willDateExpireSoon(reward.expireTimestamp)
                    ? 'bg-danger'
                    : 'bg-light'
                "
                style="width: 6.25rem">
                <ng-container
                  *ngIf="
                    reward?.isCancelled ||
                      reward?.isExpired ||
                      reward?.isRedeemed;
                    else isActive
                  ">
                  <p
                    class="fs-7 mb-0 text-uppercase"
                    data-cy="rewards-item-state">
                    {{
                      (reward?.isCancelled
                        ? 'rrs.account.rewards.canceledOn'
                        : reward?.isRedeemed
                        ? 'rrs.account.rewards.redeemedOn'
                        : 'rrs.account.rewards.expiredOn'
                      ) | cxTranslate
                    }}:
                  </p>
                  <p class="fs-7 mb-0">
                    {{ reward.expireTimestamp | date: 'MM/dd/YYYY':'UTC' }}
                  </p>
                </ng-container>
                <ng-template #isActive>
                  <p
                    class="fs-7 mb-0 text-uppercase"
                    data-cy="rewards-item-state">
                    {{
                      (willDateExpireSoon(reward.expireTimestamp)
                        ? 'rrs.account.rewards.expiresSoon'
                        : 'rrs.account.rewards.expiresOn'
                      ) | cxTranslate
                    }}:
                  </p>
                  <p class="fs-7 mb-0">
                    {{ reward.expireTimestamp | date: 'MM/dd/YYYY':'UTC' }}
                  </p>
                </ng-template>
              </div>
            </div>

            <div>
              <p
                class="mb-0 fw-bold fs-5 text-uppercase"
                data-cy="rewards-item-name">
                {{ reward.rewardName }}
              </p>
              <p data-cy="rewards-item-desc">
                {{ reward.rewardName }}@{{ reward.locationName }}#{{
                  reward.rewardId
                }}
              </p>
            </div>
          </li>
        </ul>
      </ng-container>

      <ng-template #noRewards>
        <p>{{ 'rrs.account.rewards.noRewards' | cxTranslate }}</p>
      </ng-template>
    </section>
  </div>
</ng-container>

<rrs-activity></rrs-activity>
