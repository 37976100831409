<ng-conainer *ngIf="rrsLoyaltyService.getLoyalty() | async as loyalty">
  <section class="loyalty">
    <div class="row g-0">
      <div class="col-6 col-lg-4">
        <div class="p-2 h-100 d-flex align-items-center justify-content-center">
          <div class="chart position-relative">
            <svg viewBox="0 0 36 36" class="chart__wrap">
              <path
                class="chart__bg"
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831" />
              <path
                class="chart__circle"
                [attr.stroke-dasharray]="
                  (loyalty?.memberPointsAvailable /
                    loyalty?.pointsToNextReward) *
                    100 +
                  ', 100'
                "
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831" />
            </svg>

            <div
              class="chart__text position-absolute top-50 start-50 translate-middle text-center fw-bold">
              <span class="fs-3" data-cy="memberPointsAvailable"
                >{{ loyalty?.memberPointsAvailable }}
              </span>
              <br />
              <span> {{ 'rrs.account.loyalty.points' | cxTranslate }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4 d-none d-lg-block">
        <div
          class="p-2 h-100 d-flex flex-column align-items-center justify-content-center">
          <span class="fst-italic">
            {{ 'rrs.account.loyalty.need' | cxTranslate }}
          </span>
          <span class="fs-3 fw-bold" data-cy="pointsToNextReward">{{
            loyalty?.pointsToNextReward
          }}</span>
          <span class="fst-italic">
            {{ 'rrs.account.loyalty.points' | cxTranslate }}
          </span>
        </div>
      </div>

      <div class="col-6 col-lg-4">
        <div
          class="p-2 h-100 bg-stripe d-flex flex-column align-items-center justify-content-center">
          <span class="fst-italic">
            {{ 'rrs.account.loyalty.have' | cxTranslate }}
          </span>
          <span class="fs-3 fw-bold" data-cy="memberActiveRewards">{{
            loyalty?.totalActiveRewards
          }}</span>
          <span class="fst-italic">
            {{ 'rrs.account.loyalty.rewards' | cxTranslate }}
          </span>
        </div>
      </div>
    </div>
    <ng-container *ngIf="loyalty?.totalActiveRewards > 0">
      <div
        class="p-2 h-100 d-none flex-column align-items-center justify-content-center text-uppercase loyalty__banner">
        <p class="fs-3 fw-bold mb-1 text-accent">Lorem, ipsum dolor.</p>
        <p class="fw-bold">Lorem ipsum dolor sit amet.</p>
      </div>
    </ng-container>
  </section>
</ng-conainer>
