import {
  ChangeDetectionStrategy,
  Component,
  ViewContainerRef,
} from '@angular/core';
import { RrsAuthService } from '@app/custom/features/rrs-auth/services/rrs-auth.service';
import { ModalService } from '@app/custom/features/modal';
import { RrsSavedCartFormDialogComponent } from '@app/custom/features/rrs-saved-cart/components/rrs-saved-cart-form-dialog/rrs-saved-cart-form-dialog.component';
import { AddToSavedCartComponent } from '@spartacus/cart/saved-cart/components';
import { SavedCartFormType } from '@spartacus/cart/saved-cart/root';
import { AuthService, RoutingService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import { Cart } from '@spartacus/cart/base/root';

@Component({
  selector: 'rrs-add-to-saved-cart',
  templateUrl: './rrs-add-to-saved-cart.component.html',
  styleUrls: ['./rrs-add-to-saved-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsAddToSavedCartComponent extends AddToSavedCartComponent {
  constructor(
    protected activeCartFacade: RrsActiveCartService,
    protected authService: AuthService,
    protected routingService: RoutingService,
    protected vcr: ViewContainerRef,
    protected launchDialogService: LaunchDialogService,
    private modalService: ModalService,
    private rrsAuthService: RrsAuthService
  ) {
    super(
      activeCartFacade,
      authService,
      routingService,
      vcr,
      launchDialogService
    );
  }

  saveCart(cart: Cart): void {
    if (this.loggedIn) {
      const modalRef = this.modalService.open(RrsSavedCartFormDialogComponent, {
        windowClass: 'rrs-add-to-saved-cart-dialog',
      });
      modalRef.componentInstance.data = {
        cart,
        layoutOption: SavedCartFormType.SAVE,
      };
    } else {
      this.rrsAuthService.openLoginModal();
    }
  }
}
