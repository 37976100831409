<div class="row row-cols-4" *ngIf="isDesktop$ | async; else mobi">
  <nav
    class="footer-navi"
    data-cy="footer-navi"
    *ngFor="let navi of navigation?.sections">
    <h2 class="fs-6">{{ navi?.section?.section_title }}</h2>

    <ul class="list-none">
      <li *ngFor="let item of navi?.section?.links">
        <ng-container *ngIf="item?.custom_link; then customLink; else urlLink">
        </ng-container>
        <ng-template #customLink>
          <button
            (click)="customAction(item?.custom_link?.type)"
            [class.live-chat]="item?.custom_link?.type === 'liveChat'"
            type="button"
            class="text-start custom-link-button">
            {{ item?.custom_link?.text }}
          </button>
        </ng-template>
        <ng-template #urlLink>
          <a
            [routerLink]="item?.url_link?.url"
            [attr.title]="item?.url_link?.text">
            {{ item?.url_link?.text }}
          </a>
        </ng-template>
      </li>
    </ul>
  </nav>
</div>

<ng-template #mobi>
  <rrs-accordion [collapsing]="true">
    <accordion-item *ngFor="let navi of navigation?.sections">
      <ng-template accordionHeader let-toggle="toggle">
        <div
          (click)="toggle()"
          (keydown.enter)="toggle()"
          tabindex="0"
          class="footer-navi__accordion-header d-flex align-items-center justify-content-between p-3">
          <h6 class="m-0">{{ navi?.section?.section_title }}</h6>
          <rrs-icon
            class="accordion-icon"
            [icon]="iconTypeList.CHEVRON_DOWN"
            [size]="12"></rrs-icon>
        </div>
      </ng-template>

      <ng-template accordionContent>
        <div>
          <nav class="footer-navi footer-navi--mobile">
            <ul class="list-none">
              <li *ngFor="let item of navi?.section?.links">
                <ng-container
                  *ngIf="
                    item?.custom_link;
                    then customMobileLink;
                    else urlMobileLink
                  ">
                </ng-container>
                <ng-template #customMobileLink>
                  <button
                    (click)="customAction(item?.custom_link?.type)"
                    [class.live-chat]="item?.custom_link?.type === 'liveChat'"
                    class="d-flex align-items-center justify-content-between p-3 w-100"
                    type="button
                  ">
                    {{ item?.custom_link?.text }}
                  </button>
                </ng-template>
                <ng-template #urlMobileLink>
                  <a
                    [routerLink]="item?.url_link?.url"
                    [attr.title]="item?.url_link?.text"
                    class="d-flex align-items-center justify-content-between p-3">
                    {{ item?.url_link?.text }}

                    <rrs-icon
                      [icon]="iconTypeList.CHEVRON_RIGHT"
                      [size]="12"></rrs-icon>
                  </a>
                </ng-template>
              </li>
            </ul>
          </nav>
        </div>
      </ng-template>
    </accordion-item>
  </rrs-accordion>
</ng-template>
