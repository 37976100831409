import { CmsComponentConnector, CmsPageAdapter } from '@spartacus/core';
import { CONTENTSTACK_CMS_PAGE_NORMALIZER } from '@app/custom/features/rrs-cms/configs/contentstack.config';
import { NgModule } from '@angular/core';
import { RrsCmsComponentConnector } from '@app/custom/features/rrs-cms/services/rrs-cms-component.connector';
import { RrsCmsComponentsModule } from '@app/custom/features/rrs-cms/components/rrs-cms-components.module';
import { RrsCmsPageAdapter } from '@app/custom/features/rrs-cms/services/rrs-cms-page.adapter';
import { RrsCmsPageNormalizer } from '@app/custom/features/rrs-cms/services/rrs-cms-page.normalizer';

@NgModule({
  imports: [RrsCmsComponentsModule],
  providers: [
    { provide: CmsPageAdapter, useClass: RrsCmsPageAdapter },
    {
      provide: CONTENTSTACK_CMS_PAGE_NORMALIZER,
      useClass: RrsCmsPageNormalizer,
      multi: true,
    },
    {
      provide: CmsComponentConnector,
      useClass: RrsCmsComponentConnector,
    },
  ],
})
export class RrsCmsModule {}
