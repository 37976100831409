import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { rrsOccStoreFinderConfig } from '@app/custom/features/rrs-storefinder/configs/rrs-occ-store-finder.config';
import { RrsStoreFinderComponentsModule } from '@app/custom/features/rrs-storefinder/rrs-storefinder-components.module';
import { StoreFinderModule } from '@spartacus/storefinder';

@NgModule({
  imports: [CommonModule, RrsStoreFinderComponentsModule, StoreFinderModule],
  providers: [provideConfig(rrsOccStoreFinderConfig)],
})
export class RrsStoreFinderModule {}
