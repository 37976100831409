import { Injectable } from '@angular/core';
import { RrsOccCartEntryAdapter } from '@app/custom/features/rrs-cart/occ/rrs-occ-cart-entry.adapter';
import { CartEntryConnector } from '@spartacus/cart/base/core';
import { CartModification, OrderEntry } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RrsCartEntryConnector extends CartEntryConnector {
  constructor(protected adapter: RrsOccCartEntryAdapter) {
    super(adapter);
  }

  public addOrderEntry(
    userId: string,
    cartId: string,
    orderEntry: OrderEntry
  ): Observable<CartModification> {
    return this.adapter.addOrderEntry(userId, cartId, orderEntry);
  }
}
