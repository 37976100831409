import { RoutingConfig } from '@spartacus/core';

export const rrsOrdersRoutingConfig: RoutingConfig = {
  routing: {
    routes: {
      orderDetail: {
        paths: ['my-account/order-history/:orderCode'],
        paramsMapping: { orderCode: 'orderCode' },
      },
    },
  },
};
