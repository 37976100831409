<ng-container *ngIf="cart$ | async as cart">
  <button
    #element
    class="link cx-action-link link-light fw-normal text-uppercase"
    [disabled]="disableSaveCartForLater$ | async"
    (click)="saveCart(cart)"
    cxAutoFocus>
    {{ 'rrs.savedCart.saveAsWishlist' | cxTranslate }}
  </button>
</ng-container>
