import { RoutingConfig } from '@spartacus/core';

export const rrsStorefinderRoutingConfig: RoutingConfig = {
  routing: {
    routes: {
      storeFinder: {
        paths: ['store-finder'],
      },
      storeDetails: {
        paths: ['store/:storeId'],
        paramsMapping: { storeId: 'storeId' },
      },
    },
  },
};
