import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { PHONE_MAX_LENGTH } from '@app/shared/configs';

@Directive({
  selector: '[rrsPhoneNumberInput]',
})
export class PhoneNumberInputDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(value: string): void {
    this.onInputChange(value);
  }

  onInputChange(value: string): void {
    if (!value) {
      return;
    }

    const cleanedValue = value.replace(/\D/g, '').slice(0, PHONE_MAX_LENGTH);

    this.ngControl.valueAccessor?.writeValue(cleanedValue);
  }
}
