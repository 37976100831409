import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RrsCheckCardBalanceResponse } from '@app/custom/features/rrs-gift-cards-balance/model';
import {
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
  normalizeHttpError,
} from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsGiftCardsService {
  constructor(
    protected readonly globalMessage: GlobalMessageService,
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService
  ) {}

  public getCardBalance(
    giftCardNumber: string
  ): Observable<RrsCheckCardBalanceResponse> {
    const url = this.occEndpointsService.buildUrl('cardBalance', {
      urlParams: {
        giftCardNumber,
      },
    });

    return this.http.get(url).pipe(
      tap(({ errorMessage }: RrsCheckCardBalanceResponse) => {
        if (errorMessage) {
          this.globalMessage.add(
            errorMessage,
            GlobalMessageType.MSG_TYPE_ERROR
          );
        }
      }),
      catchError((error) => this.handleBadRequest(error))
    );
  }

  private handleBadRequest(error: HttpErrorResponse): Observable<never> {
    this.globalMessage.add(
      { key: 'httpHandlers.badRequestActivity' },
      GlobalMessageType.MSG_TYPE_ERROR
    );

    return throwError(normalizeHttpError(error));
  }
}
