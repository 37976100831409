<div class="modal-header align-items-center">
  <h4 class="mb-0">{{ 'rrs.storeLocator.selectStore' | cxTranslate }}</h4>
  <div class="modal-header__right-side d-flex align-items-center">
    <span class="fs-7 me-1">
      {{
        'rrs.storeLocator.closerThan'
          | cxTranslate: { distance: maxStoreDistance }
      }}
    </span>
    <button
      class="px-1 btn-close"
      [attr.aria-label]="'rrs.common.closeModal' | cxTranslate"
      type="button"
      cxModal="dismiss"
      cxModalReason="Cross click"
      (click)="onCancel()"></button>
  </div>
</div>
<div class="modal-body">
  <div class="search d-flex">
    <div class="search__container me-2 flex-grow-1 d-flex align-items-center">
      <input
        class="search__input flex-grow-1"
        type="text"
        data-cy="myStoreInput"
        [attr.aria-label]="'rrs.storeLocator.searchPlaceholder' | cxTranslate"
        [placeholder]="'rrs.storeLocator.searchPlaceholder' | cxTranslate"
        (keydown.enter)="searchStores()"
        [formControl]="searchControl" />
      <button
        (click)="searchStores(true)"
        class="search__location-btn btn btn-icon h-100">
        <rrs-icon [size]="17" [icon]="iconTypeList.LOCATION_CROSS"></rrs-icon>
      </button>
    </div>
    <button
      (click)="searchStores()"
      class="search__submit btn btn-primary btn-icon text-white btn-accent">
      <rrs-icon [size]="15" [icon]="iconTypeList.SEARCH_BOLD"></rrs-icon>
    </button>
  </div>
  <div *ngIf="isLoggedIn$ | async" class="py-3 underlined">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        [formControl]="setAsMyStoreControl"
        id="setMyStoreCheckbox" />
      <label class="form-check-label" for="setMyStoreCheckbox">
        <h6 class="store__checkbox-label mb-0">
          {{ 'rrs.storeLocator.setAsMyStore' | cxTranslate }}
        </h6>
      </label>
    </div>
  </div>
  <ng-container *ngIf="storesEntities$ | async as storesEntities">
    <ng-container *ngIf="error$ | async as error; else stores">
      <div
        *ngIf="error?.length"
        class="search__error alert alert-danger d-flex align-items-center p-3 mt-2">
        <rrs-icon
          class="me-3"
          [size]="24"
          [icon]="iconTypeList.ALERT_DANGER"></rrs-icon>
        <span>{{ error | cxTranslate }}</span>
      </div>
    </ng-container>
    <ng-template #stores>
      <ng-container *ngIf="isLoading$ | async; else storesList">
        <div
          class="loading-placeholder mt-2 d-flex flex-column gap-2 placeholder-glow">
          <span class="placeholder flex-grow-1 rounded-1 col-12"></span>
          <span class="placeholder flex-grow-1 rounded-1 col-12"></span>
          <span class="placeholder flex-grow-1 rounded-1 col-12"></span>
          <span class="placeholder flex-grow-1 rounded-1 col-12"></span>
        </div>
      </ng-container>

      <ng-template #storesList>
        <ul
          *ngIf="storesEntities.stores?.length; else noResultsMessage"
          class="mt-2 list-none">
          <ng-container
            *ngFor="let store of storesEntities.stores"
            [ngTemplateOutlet]="storeItem"
            [ngTemplateOutletContext]="{ $implicit: store }"></ng-container>
        </ul>
        <ng-template #noResultsMessage>
          <div class="mt-2 no-results-container">
            <h3 class="text-capitalize text-nowrap text-center">No results</h3>
          </div>
        </ng-template>
      </ng-template>
      <div
        class="stores-pagination py-2 d-flex justify-content-between align-items-center">
        <button
          [class.invisible]="
            !storesEntities?.pagination ||
            storesEntities.pagination?.currentPage === 0
          "
          (click)="previousPage()"
          class="btn btn-sm btn-secondary py-1 px-2 lh-sm">
          <ng-container *ngIf="isPrevPageLoading$ | async; else prevBtnText">
            <span
              class="mx-2 spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            <span class="visually-hidden">Loading...</span>
          </ng-container>
          <ng-template #prevBtnText>
            {{ 'rrs.storeLocator.previous' | cxTranslate }}
          </ng-template>
        </button>
        <span>
          {{
            storesEntities.pagination?.totalResults > 0
              ? ('rrs.storeFinder.pagination'
                | cxTranslate: (storesEntities.pagination | paginationStats))
              : ('rrs.storeFinder.noResultsWithin'
                | cxTranslate: { distance: 100 })
          }}
        </span>
        <button
          [class.invisible]="
            storesEntities.pagination?.currentPage + 1 >=
            storesEntities.pagination?.totalPages
          "
          (click)="nextPage()"
          class="btn btn-sm btn-secondary py-1 px-2 lh-sm">
          <ng-container *ngIf="isNextPageLoading$ | async; else nextBtnText">
            <span
              class="mx-2 spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            <span class="visually-hidden">Loading...</span>
          </ng-container>
          <ng-template #nextBtnText>
            {{ 'rrs.storeLocator.next' | cxTranslate }}
          </ng-template>
        </button>
      </div>
    </ng-template>
  </ng-container>
  <div class="d-flex gap-3 mt-3">
    <button
      cxModal="dismiss"
      cxModalReason="Cancel"
      (click)="onCancel()"
      class="btn btn-secondary modal-footer__cancel">
      {{ 'rrs.common.cancel' | cxTranslate }}
    </button>
    <button
      cxModal="dismiss"
      cxModalReason="Submit"
      [disabled]="!selectedStore"
      (click)="onSubmit()"
      data-cy="myStoreConfirm"
      class="modal__submit btn btn-primary">
      {{ 'rrs.common.select' | cxTranslate }}
    </button>
  </div>
</div>

<ng-template #storeItem let-store>
  <li
    *ngIf="store; else placeholder"
    class="form-check store py-2 d-flex align-items-center"
    role="radio"
    tabindex="0"
    data-cy="myStoreItem"
    (click)="selectStore(store)"
    (keydown.enter)="selectStore(store)"
    (keydown.space)="selectStore(store)"
    [class.checked]="isStoreSelected(store)"
    [attr.aria-checked]="isStoreSelected(store)">
    <input
      class="form-check-input me-3"
      type="radio"
      [checked]="isStoreSelected(store)" />
    <div class="me-3">
      <rrs-store-logo [store]="store" [size]="44"></rrs-store-logo>
    </div>
    <div class="store__info-container flex-grow-1">
      <div class="store__title mb-1 flex-grow-1 d-flex justify-content-between">
        <span class="fw-bold text-truncate me-2">
          {{ store.displayName | titlecase }}
        </span>
        <span class="store__title-distance white-space-nowrap">
          {{ store.formattedDistance }}
        </span>
      </div>
      <div
        class="store__details d-flex justify-content-between align-items-center">
        <ng-container
          *ngIf="scope === scopes.PICKUP_IN_STORE; else openingDetails">
          <span
            [ngClass]="
              store.productStock?.stockLevel > 0 ||
              store.productStock?.stockLevelStatus === stockLevelStatus.IN_STOCK
                ? 'text-success'
                : 'text-danger'
            "
            >{{
              (store.productStock?.stockLevel > 0
                ? 'rrs.storeFinder.numberInStock'
                : store.productStock?.stockLevelStatus ===
                  stockLevelStatus.IN_STOCK
                ? 'rrs.storeFinder.inStock'
                : 'rrs.storeFinder.outOfStock'
              ) | cxTranslate: { stockLevel: store.productStock?.stockLevel }
            }}</span
          >
        </ng-container>
        <ng-template #openingDetails>
          <span
            *ngIf="store | storeOpenState as openState"
            [ngClass]="openState.isOpen ? 'text-success' : 'text-danger'">
            {{ openState.key | cxTranslate: openState.params ?? {} }}
          </span>
        </ng-template>
        <a
          class="store__details-link text-decoration-underline"
          target="_blank"
          [routerLink]="
            { cxRoute: 'storeDetails', params: { storeId: store.name } } | cxUrl
          "
          [attr.aria-label]="
            'rrs.storeLocator.ariaLabelStoreDetails' | cxTranslate
          "
          >{{ 'rrs.storeLocator.details' | cxTranslate }}</a
        >
      </div>
    </div>
  </li>
</ng-template>
