import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { RrsAuthService } from '@app/custom/features/rrs-auth/services/rrs-auth.service';
import { AuthService } from '@spartacus/core';
import { ModalService } from '@app/custom/features/modal';
import { RrsCmsHeaderUiService } from '@app/custom/features/rrs-cms/components/rrs-cms-header/services/rrs-cms-header-ui.service';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { RrsDefaultStoreService } from '@app/custom/features/rrs-account/services/rrs-default-store.service';
import { RrsMiniCartService } from '@app/custom/features/rrs-mini-cart/services/rrs-mini-cart.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';

@Component({
  selector: 'rrs-cms-header-navi',
  styleUrls: ['./rrs-cms-header-navi.component.scss'],
  templateUrl: './rrs-cms-header-navi.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsCmsHeaderNaviComponent implements OnInit {
  @Input() navi: Contentstack.NaviFileLink[] = [];

  totalUnitCount$!: Observable<number | undefined>;
  defaultStoreId$!: Observable<string | null>;
  isDesktop$ = this.breakpointService.isUp(BREAKPOINT.xl);
  username$ = this.userAccount.get().pipe(map((user) => user?.firstName));

  constructor(
    protected breakpointService: BreakpointService,
    public headerUi: RrsCmsHeaderUiService,
    public miniCart: RrsMiniCartService,
    private activeCart: RrsActiveCartService,
    private modalService: ModalService,
    private authService: AuthService,
    private defaultStoreService: RrsDefaultStoreService,
    private userAccountFacade: UserAccountFacade,
    private rrsAuthService: RrsAuthService,
    protected userAccount: UserAccountFacade
  ) {}

  ngOnInit(): void {
    this.totalUnitCount$ = this.activeCart
      .getActive()
      .pipe(map((cart) => cart.totalUnitCount || cart.entries?.length));
    this.defaultStoreId$ = this.defaultStoreService.getDefaultStoreId();
  }

  doLogin(): void {
    this.rrsAuthService.openLoginModal();
  }

  isUserLogged(): Observable<boolean> {
    return this.authService.isUserLoggedIn();
  }

  toggleMenu(): void {
    this.headerUi.toggMenuMobi();
  }

  toggleSearch(): void {
    if (this.headerUi.isMenuMobiOpen.getValue()) {
      this.headerUi.toggMenuMobi();
    }
    this.headerUi.toggSearchBoxMobi();
  }
}
