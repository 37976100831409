import { LaunchDialogService } from '@spartacus/storefront';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentDetails, Region, UserAddressService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'rrs-saved-payments-form',
  templateUrl: './rrs-saved-payments-form.component.html',
  styleUrls: ['./rrs-saved-payments-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsSavedPaymentsFormComponent implements OnInit {
  payment!: Observable<PaymentDetails>;
  regions$!: Observable<Region[]>;
  paymentForm: UntypedFormGroup = this.fb.group({});

  constructor(
    public activeModal: NgbActiveModal,
    protected fb: UntypedFormBuilder,
    public launchDialogService: LaunchDialogService,
    protected userAddressService: UserAddressService
  ) {}

  ngOnInit(): void {
    this.regions$ = this.userAddressService.getRegions('US');
  }
}
