import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class RrsCmsHeaderUiService {
  isBannerShow = new BehaviorSubject(true);
  isMenuMobiOpen = new BehaviorSubject(false);
  isSearchBoxMobiOpen = new BehaviorSubject(false);

  constructor(@Inject(DOCUMENT) protected document: Document) {}

  toggBannerShow(): void {
    this.isBannerShow.next(!this.isBannerShow.value);
  }

  toggMenuMobi(): void {
    this.isMenuMobiOpen.next(!this.isMenuMobiOpen.value);
    this.document.body.classList.toggle('hidden');
  }

  toggSearchBoxMobi(): void {
    this.isSearchBoxMobiOpen.next(!this.isSearchBoxMobiOpen.value);
  }
}
