import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivitiesInterface } from '@app/custom/features/rrs-activity/models/activity.model';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { Observable } from 'rxjs';
import { RrsActivityService } from '@app/custom/features/rrs-activity/services/rrs-activity.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-activity',
  styleUrls: ['./rrs-activity.component.scss'],
  templateUrl: './rrs-activity.component.html',
})
export class RrsActivityComponent {
  activities$: Observable<ActivitiesInterface> =
    this.rrsActivityService.getUserActivity();

  iconTypeList = ICON_TYPE_LIST;
  page = 1;
  pageSize = 6;

  constructor(protected rrsActivityService: RrsActivityService) {}
}
