import {
  DIALOG_TYPE,
  LAUNCH_CALLER,
  LayoutConfig,
} from '@spartacus/storefront';
import { RrsSavedCartFormDialogComponent } from '../components/rrs-saved-cart-form-dialog/rrs-saved-cart-form-dialog.component';

export const rrsSavedCartFormLayoutConfig: LayoutConfig = {
  launch: {
    [LAUNCH_CALLER.SAVED_CART]: {
      inline: true,
      component: RrsSavedCartFormDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
