import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import {
  RrsPayment,
  RrsPaymentTypeNameMap,
  RrsPaymentTypes,
} from '@app/custom/features/rrs-checkout/model';
import {
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
  UserPaymentService,
  normalizeHttpError,
  UserIdService,
} from '@spartacus/core';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsSavedPaymentsService {
  GlobalMessageType = GlobalMessageType;

  constructor(
    private http: HttpClient,
    private occEndpointsService: OccEndpointsService,
    private globalMessageService: GlobalMessageService,
    private userPaymentService: UserPaymentService,
    protected activeCartService: RrsActiveCartService,
    protected userIdService: UserIdService
  ) {}

  setPaymentAsDefault(paymentId: string): void {
    const endpoint = this.occEndpointsService.buildUrl(
      `users/current/paymentdetails/${paymentId}`
    );
    this.http
      .patch(endpoint, { defaultPaymentInfo: true })
      .pipe(
        take(1),
        catchError((error) => throwError(normalizeHttpError(error)))
      )
      .subscribe(() => {
        this.userPaymentService.loadPaymentMethods();
        this.globalMessageService?.add(
          { key: 'rrs.savedPayments.setAsDefaultSuccessfully' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
      });
  }

  usePayment(paymentDetails: any): Observable<any> {
    return combineLatest([
      this.activeCartService.getActiveCartId(),
      this.userIdService.getUserId(),
    ]).pipe(
      take(1),
      switchMap(([cartId, userId]: [string, string]) => {
        const endpoint = this.occEndpointsService.buildUrl(
          `users/${userId}/carts/${cartId}/paymentdetails`,
          {
            queryParams: {
              paymentDetailsId: paymentDetails.paymentInfoId,
            },
          }
        );
        return this.http.put(endpoint, {});
      })
    );
  }

  generatePaymentItemLabel(payment: RrsPayment): string[] {
    let label1: string | undefined;
    let label2: string | undefined;
    let label3: string | undefined;
    switch (payment.tenderType) {
      case RrsPaymentTypes.GIFT_CARD:
      case RrsPaymentTypes.KLARNA:
        label1 = RrsPaymentTypeNameMap[payment.tenderType];
        label2 = payment.tenderId;
        break;

      case RrsPaymentTypes.PAYPAL:
        label1 = RrsPaymentTypeNameMap[payment.tenderType];
        label2 = payment?.nickName!;
        break;

      default:
        label1 = payment.tenderType;
        label2 = payment?.cardNumber ?? payment.nickName!;
        label3 = payment?.expiryMonthYear;
    }
    return [label1, label2, label3 || ''];
  }
}
