import {
  Address,
  GlobalMessageService,
  Region,
  TranslationService,
  UserAddressService,
  UserService,
} from '@spartacus/core';
import {
  AddressFormComponent,
  LaunchDialogService,
} from '@spartacus/storefront';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { RrsLoqateService } from '@app/custom/features/rrs-loqate/services/rrs-loqate.service';
import { POSTAL_CODE_REGEX } from '@app/shared/configs/zipcode-input.config';

@Component({
  selector: 'rrs-address-book-form',
  templateUrl: './rrs-address-book-form.component.html',
  styleUrls: ['./rrs-address-book-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsAddressBookFormComponent
  extends AddressFormComponent
  implements OnInit
{
  address!: Address;
  addressForm: UntypedFormGroup = this.fb.group({
    country: this.fb.group({
      isocode: ['US'],
    }),
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    line1: ['', Validators.required],
    line2: [''],
    nickname: [''],
    town: ['', Validators.required],
    region: this.fb.group({
      isocode: [null, Validators.required],
    }),
    postalCode: [
      '',
      [Validators.required, Validators.pattern(POSTAL_CODE_REGEX)],
    ],
    phone: ['', Validators.required],
    defaultAddress: [false],
  });
  private regionsSub: Subscription | undefined;
  autofillInputValue = '';

  constructor(
    public activeModal: NgbActiveModal,
    fb: UntypedFormBuilder,
    userService: UserService,
    userAddressService: UserAddressService,
    globalMessageService: GlobalMessageService,
    public launchDialogService: LaunchDialogService,
    translation: TranslationService,
    protected userAccountFacade: UserAccountFacade,
    protected locateService: RrsLoqateService
  ) {
    super(
      fb,
      userService,
      userAddressService,
      globalMessageService,
      translation,
      launchDialogService
    );
  }

  setStateAutofillValue(event: Event): void {
    this.regions$.pipe(take(1)).subscribe((regions) => {
      const autoFilledRegion = regions.find(
        (region: Region) =>
          region.name === (event.target as HTMLInputElement).value
      );
      autoFilledRegion
        ? this.addressForm.get('region')?.setValue({
            isocode: autoFilledRegion.isocode,
          })
        : this.addressForm.get('region')?.reset();
    });
  }

  print(region: Region): void {
    this.autofillInputValue = region.name || '';
  }

  ngOnInit(): void {
    this.addressData = this.address;
    this.selectedCountry$.next('US');
    this.subscription.add(
      this.userAccountFacade
        .get()
        .pipe(
          tap((user) => {
            if (!user) return;
            this.addressForm.get('firstName')?.setValue(user.firstName);
            this.addressForm.get('lastName')?.setValue(user.lastName);
          })
        )
        .subscribe()
    );
    super.ngOnInit();
    this.subscription.add(
      this.locateService.loqate$.subscribe(
        (verificationObject: { isValidated: boolean; address: Address }) => {
          if (verificationObject.isValidated) {
            this.addressForm.patchValue(verificationObject.address);
            this.activeModal.close();
            this.addressData
              ? this.editAddress(this.addressData.id!, this.addressForm.value)
              : this.addNewAddress(this.addressForm.value);
          }
        }
      )
    );
  }

  verifyAddress(): void {
    if (this.addressForm.valid) {
      if (this.addressForm.get('region')!.value.isocode) {
        this.regionsSub = this.regions$.pipe(take(1)).subscribe((regions) => {
          const obj = regions.find(
            (region) =>
              region.isocode ===
              this.addressForm.controls['region'].value.isocode
          );
          Object.assign(this.addressForm.value.region, {
            isocodeShort: obj?.isocodeShort,
          });
        });
      }

      if (this.addressForm.dirty) {
        this.locateService.validateAddress(this.addressForm.value);
      } else {
        // address form value not changed
        // ignore duplicate address
        this.activeModal.close();
        this.submitAddress.emit(undefined);
      }
    } else {
      this.addressForm.markAllAsTouched();
    }
  }

  addNewAddress(address: Address): void {
    this.userAddressService.addUserAddress(address);
  }

  editAddress(currentAddressId: string, newAddress: Address): void {
    this.userAddressService.updateUserAddress(currentAddressId, newAddress);
  }

  setHiddenAutofillValue(region: Region): void {
    this.autofillInputValue = region.name || '';
  }
}
