import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Config, RoutingService } from '@spartacus/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';

@Component({
  selector: 'rrs-store-finder-search',
  templateUrl: './rrs-store-finder-search.component.html',
  styleUrls: ['./rrs-store-finder-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsStoreFinderSearchComponent implements OnInit {
  form!: FormGroup;

  iconTypeList = ICON_TYPE_LIST;
  constructor(
    protected routingService: RoutingService,
    protected route: ActivatedRoute,
    protected config: Config
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      search: new FormControl(this.route.snapshot.queryParams?.query ?? ''),
    });
  }

  search(useMyLocation?: boolean): void {
    const query = this.form.get('search')?.value;

    this.routingService.go(
      {
        cxRoute: 'storeFinder',
      },
      {
        queryParams: {
          query: query?.length ? query : null,
          useMyLocation: useMyLocation ? true : null,
        },
      }
    );
  }
}
