import { from, Observable, of } from 'rxjs';
import { SearchClient, SearchIndex } from 'algoliasearch';
import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { RrsSearchConfig } from '@app/custom/features/rrs-search/models/rrs-search.model';
import { SearchResponse } from '@algolia/client-search';
import { map, switchMap } from 'rxjs/operators';
import {
  getFacetsUrlParams,
  parseFacetsUrlParams,
} from '@app/custom/features/rrs-product-listing/utils/rrs-facet.utils';
import { removeUndefinedFields } from '@app/shared/utils';
import { SingleSelectFacets } from '@app/custom/features/rrs-product-listing/model';

@Injectable({
  providedIn: 'root',
})
export class RrsAlgoliaService {
  private client?: SearchClient;
  private index?: SearchIndex;
  public currentReplica: string | undefined;

  search<T>(
    query: string,
    config?: RrsSearchConfig
  ): Observable<SearchResponse<T>> {
    return from(
      this.getIndex(environment.algolia.indexName, config?.sort)
    ).pipe(
      switchMap((index) =>
        from(
          index.search<T>(query, {
            hitsPerPage: config?.pageSize,
            facets: ['*'],
            facetFilters: config?.facetFilters,
            page: config?.currentPage,
          })
        ).pipe(
          switchMap((response) => {
            const searchParams = new URLSearchParams(response.params);
            const facetFilters = searchParams.get('facetFilters') ?? '[]';
            const selectedFacets = parseFacetsUrlParams(facetFilters);
            const activeFacetsArray = Object.entries(selectedFacets ?? {});
            const facetRequests = activeFacetsArray
              .filter(
                ([facetName]) =>
                  !SingleSelectFacets.some(
                    (f) => f.toLowerCase() === facetName.toLowerCase()
                  )
              )
              .map(([facetName]) => {
                return {
                  indexName: this.generateIndexString(
                    environment.algolia.indexName,
                    config?.sort
                  ),
                  query: response.query ?? '',
                  params: removeUndefinedFields({
                    hitsPerPage: 0,
                    page: 0,
                    facets: [facetName],
                    facetFilters: getFacetsUrlParams(
                      Object.fromEntries(
                        activeFacetsArray.filter(
                          (facet) => facet[0] !== facetName
                        )
                      )
                    ),
                  }),
                };
              });
            return facetRequests?.length
              ? from(this.client!.multipleQueries(facetRequests)).pipe(
                  map((facetsResponses) => {
                    facetsResponses.results.forEach((result) => {
                      response.facets = {
                        ...response.facets,
                        ...result.facets,
                      };
                    });
                    return response;
                  })
                )
              : of(response);
          })
        )
      )
    );
  }

  private async getIndex(
    indexName: string,
    sortingName?: string
  ): Promise<SearchIndex> {
    this.currentReplica = sortingName;
    if (!this.client) {
      const { default: algoliasearch } = await import('algoliasearch');
      this.client = algoliasearch(
        environment.algolia.apiKey,
        environment.algolia.appId
      );
    }
    return (this.index = this.client.initIndex(
      this.generateIndexString(indexName, sortingName)
    ));
  }

  generateIndexString(indexName: string, sortingName?: string): string {
    // for relevance option we use the main index
    if (sortingName === 'relevance') {
      sortingName = undefined;
    }
    return [indexName, sortingName].filter(Boolean).join('_');
  }
}
