import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RrsWriteReviewComponent } from './components/rrs-write-review/rrs-write-review.component';
import { RouterModule, Routes } from '@angular/router';
import { CmsPageGuard } from '@spartacus/storefront';

const routes: Routes = [
  {
    path: 'write-a-review',
    component: RrsWriteReviewComponent,
    canActivate: [CmsPageGuard],
    data: { pageLabel: 'write-a-review' },
  },
];

@NgModule({
  declarations: [RrsWriteReviewComponent],
  imports: [CommonModule, RouterModule.forChild(routes)],
})
export class RrsPowerReviewsModule {}
