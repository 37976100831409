import { isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { RrsCmsRichTextComponentService } from '@app/custom/features/rrs-cms/components/rrs-cms-rich-text/rrs-cms-rich-text-component.service';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { CmsComponentData } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'rrs-cms-rich-text',
  templateUrl: './rrs-cms-rich-text.component.html',
})
export class RrsCmsRichTextComponent implements OnDestroy, AfterViewInit {
  @ViewChild('cmsTarget') targetEl: ElementRef<HTMLDivElement> | undefined;
  data$ = this.componentData.data$;
  private dataSub: Subscription | undefined;

  constructor(
    protected componentData: CmsComponentData<Contentstack.RichText>,
    protected richTextComponentService: RrsCmsRichTextComponentService,
    @Inject(PLATFORM_ID) private platformId: string,
    protected renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    // Creates a html fragment on the client side or just render html on server side.
    this.dataSub = this.componentData.data$
      .pipe(take(1))
      .subscribe((data: Contentstack.RichText) => {
        if (isPlatformServer(this.platformId) && this.targetEl) {
          this.renderer.setProperty(
            this.targetEl.nativeElement,
            'innerHTML',
            data.html_content
          );
          return;
        }
        const fragment = document
          .createRange()
          .createContextualFragment(
            data.html_content?.length > 0 ? data.html_content : data.content
          );
        this.targetEl?.nativeElement.replaceWith(fragment);

        const shouldRunOnce = data.javascript?.should_run_once;
        const wasExecuted =
          this.richTextComponentService.checkIfJsExecutedAlready(data?.uid);
        const shouldBeExecuted =
          (shouldRunOnce && !wasExecuted) || !shouldRunOnce;

        if (shouldBeExecuted) {
          this.richTextComponentService.executeJavascript(data.javascript);
          if (shouldRunOnce) {
            this.richTextComponentService.setJSExecutedFlag(data?.uid);
          }
          return;
        }
      });
  }

  ngOnDestroy(): void {
    this.dataSub?.unsubscribe();
  }
}
