import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderDetail } from '@app/custom/features/rrs-account/components/rrs-orders/models/rrs-orders.model';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { Address } from '@spartacus/core';

@Component({
  selector: 'rrs-order-product-card',
  templateUrl: './rrs-order-product-card.component.html',
  styleUrls: ['./rrs-order-product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsOrderProductCardComponent {
  icon = ICON_TYPE_LIST;
  isCollapsed = true;

  @Input()
  item!: OrderDetail;

  @Input()
  storeAddress!: Address;
}
