import {
  animate,
  AUTO_STYLE,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeHeightAnimation = trigger('fadeHeightAnimation', [
  transition(':enter', [
    style({ height: 0 }),
    animate('300ms ease', style({ height: '*', visibility: 'visible' })),
  ]),
  transition(':leave', [
    animate('300ms ease', style({ height: 0, visibility: 'hidden' })),
  ]),
]);
