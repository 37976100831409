<ng-container *ngIf="savedCarts$ | async as savedCarts">
  <ng-container *ngIf="(isLoading$ | async) === false; else loading">
    <div class="container">
      <div class="row" *ngIf="savedCarts?.length > 0; else noSavedCarts">
        <div
          *ngFor="let savedCart of savedCarts"
          class="col-lg-6 mb-3 d-flex flex-column">
          <div class="cart__header ps-3 pt-4 pb-2 position-relative">
            <span class="truncate-text-two-lines">{{ savedCart?.name }}</span>
            <div
              class="cart__count-container d-flex justify-content-center align-items-center bg-white rounded-circle position-absolute">
              <img
                [attr.alt]="'cart.svg'"
                [attr.src]="'/assets/icons/cart.svg'"
                height="36"
                width="36" />
              <div
                class="cart__count rounded-circle position-absolute text-white text-center fw-bold fs-6">
                {{ savedCart?.totalItems }}
              </div>
            </div>
          </div>
          <div class="cart__details px-3 pt-0 pb-5 flex-grow-1">
            <div class="cart__description mt-2">
              <h2 class="fs-6 text-capitalize">
                {{ 'rrs.savedCart.description' | cxTranslate }}
              </h2>
              <p class="truncate-text-two-lines">
                {{ savedCart?.description }}
              </p>
            </div>
            <div class="cart__subtotal mt-2">
              <h2 class="fs-6 text-capitalize">
                {{ 'rrs.savedCart.subtotal' | cxTranslate }}
              </h2>
              <span>{{ savedCart?.totalPrice?.formattedValue }}</span>
            </div>
          </div>
          <div class="cart__foter d-flex flex-nowrap">
            <button
              class="btn btn-primary p-1 w-100"
              (click)="restoreSavedCart(savedCart)">
              <rrs-icon [icon]="iconTypeList.CART_RESTORE"></rrs-icon>
              {{ 'rrs.savedCart.restore' | cxTranslate }}
            </button>
            <a
              class="cart__details-btn btn p-1 w-100"
              [routerLink]="
                {
                  cxRoute: 'savedCartsDetails',
                  params: { savedCartId: savedCart?.code }
                } | cxUrl
              ">
              <rrs-icon [icon]="iconTypeList.CART_DETAILS"></rrs-icon>
              {{ 'rrs.savedCart.details' | cxTranslate }}</a
            >
          </div>
        </div>
      </div>
    </div>

    <ng-template #noSavedCarts>
      <span>{{ 'rrs.savedCartList.notFound' | cxTranslate }}</span>
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <div class="d-flex align-items-center justify-content-center p-5">
      <div class="loader spinner-border text-accent" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-template>
</ng-container>
