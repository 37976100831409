import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthRedirectService, AuthService } from '@spartacus/core';
import { map, take } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-rrs-auth-page',
  templateUrl: './rrs-auth-page.component.html',
})
export class RrsAuthPageComponent implements OnInit {
  constructor(
    protected authService: AuthService,
    protected authRedirectService: AuthRedirectService
  ) {}

  ngOnInit(): void {
    this.authService
      .isUserLoggedIn()
      .pipe(
        take(1),
        map((isUserLoggedin) => {
          if (isUserLoggedin) this.authRedirectService.redirect();
        })
      )
      .subscribe();
  }
}
