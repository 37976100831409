import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, of } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RrsImpervaService {
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  createGetToken(jsTimeout: number, tokenTimeout: number): Observable<any> {
    if (this.isBrowser && environment.production) {
      let protectionCache: any;

      function protectionObject(jsTimeout: number): Promise<any> {
        if (protectionCache !== undefined) {
          return Promise.resolve(protectionCache);
        }
        return new Promise((resolve) => {
          const timeout = setTimeout(() => {
            protectionCache = null;
            resolve(null);
          }, jsTimeout);
          window.protectionLoaded = function (protection: any): any {
            window.clearTimeout(timeout);
            protectionCache = protection;
            resolve(protection);
          };
        });
      }

      return new Observable((observer) => {
        protectionObject(jsTimeout)
          .then((protection) => {
            if (protection) {
              return protection.token(tokenTimeout).catch(() => null);
            } else {
              return null;
            }
          })
          .then((result) => {
            observer.next(result);
            observer.complete();
          })
          .catch((error) => {
            observer.error(error);
          });
      });
    } else return of(null);
  }

  getToken(): Observable<any> {
    return this.createGetToken(10 * 1000, 10 * 1000);
  }
}
