import { NgModule } from '@angular/core';
import { OccConfig, provideConfig } from '@spartacus/core';
import { rrsOrderOccConfig } from '@app/custom/features/rrs-order/occ.config';

@NgModule({
  declarations: [],
  imports: [],
  providers: [provideConfig(rrsOrderOccConfig as OccConfig)],
})
export class RrsOrderModule {}
