export interface GeolocationFromIp {
  city?: string;
  cityLatLong?: string;
  country?: string;
  region?: string;
  userIP?: string;
}

export enum MyStoreServiceScope {
  STORE_FACET,
  PICKUP_IN_STORE,
}
