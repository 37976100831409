import {
  ActivatedRouterStateSnapshot,
  ProductSearchPage,
  RoutingService,
} from '@spartacus/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ProductFacetService,
  ProductListComponentService,
} from '@spartacus/storefront';
import { RrsProductSearchPage } from '@app/custom/features/rrs-product-listing/model/rrs-product-listing.model';
import { RrsProductListComponentService } from './rrs-product-list/rrs-product-list-component.service';

@Injectable({
  providedIn: 'root',
})
export class RrsProductFacetService extends ProductFacetService {
  constructor(
    protected routing: RoutingService,
    protected rrsProductListComponentService: RrsProductListComponentService
  ) {
    super(
      routing,
      rrsProductListComponentService as unknown as ProductListComponentService
    );
  }

  protected filterForPage(
    state: ActivatedRouterStateSnapshot,
    page: ProductSearchPage
  ): boolean {
    return state.context.id === 'search';
  }

  get result$(): Observable<RrsProductSearchPage> {
    return this.searchResult$;
  }
}
