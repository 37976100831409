<ng-container *ngIf="product$ | async as product">
  <ng-container *ngIf="product.code !== donationProductType">
    <rrs-product-variants-colors></rrs-product-variants-colors>
    <section>
      <ng-container *ngIf="!shouldShowSizeSelector">
        <h6 class="mb-2">
          {{ 'rrs.product.variants.sizes' | cxTranslate }}:
          <span class="fw-normal">{{ form.get('sizes').value }}</span>
        </h6>

        <div class="row g-2 mb-4">
          <div
            *ngFor="let item of sizes | keyvalue: originalOrder"
            class="col-auto"
            [ngClass]="{
              'col-6 col-xl-4': item.value[0]?.sizeconversion
            }">
            <button
              (click)="setSizes(item.key)"
              [disabled]="
                form.get('width')?.value === '' ||
                (form.get('width')?.value && hasWidth(item.key))
              "
              [ngClass]="{
                'btn-size--active': item.key === form.get('sizes').value,
                'btn-size--wide': item.value[0]?.sizeconversion
              }"
              class="btn btn-secondary btn-size">
              {{ item.value[0]?.sizeconversion || item.key }}
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="width.size > 1">
        <h6 class="mb-2">
          {{ 'rrs.product.variants.width' | cxTranslate }}:
          <span class="fw-normal">{{ form.get('width').value }}</span>
        </h6>

        <div class="btn-group mb-4">
          <button
            (click)="setWidth(item.key)"
            *ngFor="let item of width | keyvalue: originalOrder"
            [disabled]="form.get('sizes')?.value && hasSizes(item.key)"
            [ngClass]="{
              'btn-size--active': item.key === form.get('width').value
            }"
            class="btn btn-secondary btn-size">
            {{ item.key }}
          </button>
        </div>
      </ng-container>
    </section>

    <div class="mb-3">
      <rrs-add-to-cart
        [addToCartStatus]="getAddToCartStatus()"
        [deliveryModeName]="fulfillmentConfig.selectedMethod"
        [productCode]="getProductCode"
        [product]="product"
        [showQuantity]="false">
      </rrs-add-to-cart>
    </div>

    <rrs-delivery-options
      (selectFulfillment)="selectFulfillment($event)"
      [fulfillment]="fulfillmentConfig"
      [productCode]="getProductCode ?? product.code"
      [productPrice]="product.price">
    </rrs-delivery-options>

    <div class="d-flex justify-content-center">
      <rrs-add-to-wishlist
        [disabled]="
          product.variantOptions?.length
            ? !(form.value.sizes && form.value.width)
            : false
        "
        [newDesign]="true"
        [productCode]="getProductCode"
        [product]="product">
      </rrs-add-to-wishlist>
    </div>
  </ng-container>
</ng-container>
