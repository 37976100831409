import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import * as fromNgrxRouter from '@ngrx/router-store';
import {
  ActivatedRouterStateSnapshot,
  CmsActivatedRouteSnapshot,
  HOME_PAGE_CONTEXT,
  PageContext,
  PageType,
  RoutingConfigService,
  SMART_EDIT_CONTEXT,
  WindowRef,
} from '@spartacus/core';

/* The serializer is there to parse the RouterStateSnapshot,
and to reduce the amount of properties to be passed to the reducer.
 */
@Injectable()
export class RrsCustomSerializer
  implements fromNgrxRouter.RouterStateSerializer<ActivatedRouterStateSnapshot>
{
  serialize(routerState: RouterStateSnapshot): ActivatedRouterStateSnapshot {
    let state: CmsActivatedRouteSnapshot =
      routerState.root as CmsActivatedRouteSnapshot;
    let cmsRequired = false;
    let context: PageContext | undefined;
    let semanticRoute: string | undefined;
    let urlString = '';

    while (state.firstChild) {
      state = state.firstChild as CmsActivatedRouteSnapshot;
      urlString +=
        '/' + state.url.map((urlSegment) => urlSegment.path).join('/');

      // we use semantic route information embedded from any parent route
      if (state.data?.cxRoute) {
        semanticRoute = state.data?.cxRoute;
      }

      // we use context information embedded in Cms driven routes from any parent route
      if (state.data?.cxCmsRouteContext) {
        context = state.data?.cxCmsRouteContext;
      }

      // we assume, that any route that has CmsPageGuard or it's child
      // is cmsRequired
      if (
        !cmsRequired &&
        (context ||
          state.routeConfig?.canActivate?.find(
            (x) => x && x.guardName === 'CmsPageGuard'
          ))
      ) {
        cmsRequired = true;
      }
    }

    // If `semanticRoute` couldn't be already recognized using `data.cxRoute` property
    // let's lookup the routing configuration to find the semantic route that has exactly the same configured path as the current URL.
    // This will work only for simple URLs without any dynamic routing parameters.
    semanticRoute = semanticRoute || this.lookupSemanticRoute(urlString);
    const { params } = state;
    context = this.getPageContext(context, state);

    return {
      url: routerState.url,
      queryParams: this.winRef.isBrowser() ? routerState.root.queryParams : {},
      params,
      context,
      cmsRequired,
      semanticRoute,
    };
  }

  private getPageContext(
    cmsRouteContext: PageContext | undefined,
    state: CmsActivatedRouteSnapshot
  ): PageContext {
    let context = cmsRouteContext;

    // we give smartedit preview page a PageContext
    if (
      state.url.length > 0 &&
      state.url[0].path === 'cx-preview' &&
      state.queryParams.cmsTicketId !== undefined
    ) {
      context = {
        id: SMART_EDIT_CONTEXT,
        type: PageType.CONTENT_PAGE,
      };
    } else {
      const { params } = state;
      if (params['productCode']) {
        context = { id: params['productCode'], type: PageType.PRODUCT_PAGE };
      } else if (params['categoryCode']) {
        context = { id: params['categoryCode'], type: PageType.CATEGORY_PAGE };
      } else if (params['brandCode']) {
        context = { id: params['brandCode'], type: PageType.CATEGORY_PAGE };
      } else if (state.data.pageLabel !== undefined) {
        context = { id: state.data.pageLabel, type: PageType.CONTENT_PAGE };
      }
    }

    if (!context) {
      if (state.url.length > 0) {
        const pageLabel =
          '/' + state.url.map((urlSegment) => urlSegment.path).join('/');
        context = {
          id: pageLabel,
          type: PageType.CONTENT_PAGE,
        };
      } else {
        context = {
          // We like URLs to be driven by the backend, the CMS actually returns the homepage
          // if no page label is given. Our logic however requires an id. undefined doesn't work.
          id: HOME_PAGE_CONTEXT,

          // We currently need to support a hardcoded page type, since the internal store uses the page
          // type to store the content.
          type: PageType.CONTENT_PAGE,
        };
      }
    }

    return context;
  }

  /**
   * Returns the semantic route name for given page label.
   *
   * *NOTE*: It works only for simple static urls that are equal to the page label
   * of cms-driven content page. For example: `/my-account/address-book`.
   *
   * It doesn't work for URLs with dynamic parameters. But such case can be handled
   * by reading the defined `data.cxRoute` from the Angular Routes.
   *
   * @param path path to be found in the routing config
   */
  private lookupSemanticRoute(path: string): string {
    // Page label is assumed to start with `/`, but Spartacus configured paths
    // don't start with slash. So we remove the leading slash:
    return this.routingConfig.getRouteName(path.substr(1));
  }

  constructor(
    private routingConfig: RoutingConfigService,
    private winRef: WindowRef
  ) {}
}
