import { Injectable } from '@angular/core';
import {
  Cart,
  ORDER_ENTRY_PROMOTIONS_NORMALIZER,
} from '@spartacus/cart/base/root';
import {
  Converter,
  ConverterService,
  POINT_OF_SERVICE_NORMALIZER,
  PRODUCT_NORMALIZER,
} from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class RrsCartNormalizer implements Converter<Cart, Cart> {
  constructor(private converter: ConverterService) {}

  convert(source: Cart, target?: Cart): Cart {
    if (target === undefined) {
      target = { ...source };
    }

    if (source.pickupOrderGroups?.length) {
      target.pickupOrderGroups = source.pickupOrderGroups.map((group) => ({
        ...group,
        entries: group.entries?.map((entry) => ({
          ...entry,
          product: this.converter.convert(entry.product, PRODUCT_NORMALIZER),
          promotions: this.converter.convert(
            { item: entry, promotions: target?.appliedProductPromotions },
            ORDER_ENTRY_PROMOTIONS_NORMALIZER
          ),
        })),
        deliveryPointOfService: this.converter.convert(
          group.deliveryPointOfService,
          POINT_OF_SERVICE_NORMALIZER
        ),
      }));
    }

    if (source.deliveryOrderGroups?.length) {
      target.deliveryOrderGroups = source.deliveryOrderGroups.map((group) => ({
        ...group,
        entries: group.entries?.map((entry) => ({
          ...entry,
          product: this.converter.convert(entry.product, PRODUCT_NORMALIZER),
          promotions: this.converter.convert(
            { item: entry, promotions: target?.appliedProductPromotions },
            ORDER_ENTRY_PROMOTIONS_NORMALIZER
          ),
        })),
      }));
    }

    if (source.entries?.length) {
      target.entries = source.entries.map((entry) => ({
        ...entry,
        ...(Boolean(entry.deliveryPointOfService) && {
          deliveryPointOfService: this.converter.convert(
            entry.deliveryPointOfService,
            POINT_OF_SERVICE_NORMALIZER
          ),
        }),
        product: this.converter.convert(entry.product, PRODUCT_NORMALIZER),
      }));
    }

    return target;
  }
}
