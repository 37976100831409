import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormErrorsComponent } from '@spartacus/storefront';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-form-errors',
  styleUrls: ['./rrs-form-errors.component.scss'],
  templateUrl: './rrs-form-errors.component.html',
})
export class RrsFormErrorsComponent extends FormErrorsComponent {
  filterRequiredError(
    errorDetails: Array<[string, string | boolean]>
  ): Array<[string, string | boolean]> {
    const requiredError = errorDetails.filter((error) => {
      return error.includes('required');
    });
    if (requiredError.length) return requiredError;
    return errorDetails;
  }
}
