import { Converter } from '@spartacus/core';
import { Injectable } from '@angular/core';
import { LoyaltyInterface } from '@app/custom/features/rrs-account/models/loyalty.model';

@Injectable()
export class RrsLoyaltyNormalizer implements Converter<any, LoyaltyInterface> {
  convert(source: any, target: LoyaltyInterface = {}): LoyaltyInterface {
    const activitySummaryResponse = source[0];
    const rewardsResponse = source[1];
    target = {
      memberActiveReward:
        activitySummaryResponse?.memberActiveRewards?.length >= 1
          ? activitySummaryResponse?.memberActiveRewards.slice(-1)
          : null,
      memberActiveRewards: activitySummaryResponse?.memberActiveRewards || [],
      memberPointsAvailable:
        activitySummaryResponse?.memberPointBalances?.memberPointsAvailable ||
        0,
      pointsToNextReward:
        activitySummaryResponse?.pointsToNextRecurringReward
          ?.pointsToNextReward || 0,
      totalActiveRewards: rewardsResponse?.totalActiveRewards || 0,
    };

    return target;
  }
}
