import { CommonModule } from '@angular/common';
import { I18nModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RrsAccountContentComponent } from '@app/custom/features/rrs-account/components/rrs-account-content/rrs-account-content.component';
import { RrsAccountLoyaltyComponent } from '@app/custom/features/rrs-account/components/rrs-account-loyalty/rrs-account-loyalty.component';
import { RrsAccountNavigationComponent } from '@app/custom/features/rrs-account/components/rrs-account-navigation/rrs-account-navigation.component';
import { RrsAddressBookModule } from '@app/custom/features/rrs-account/components/rrs-address-book/rrs-address-book.module';
import { RrsIconModule } from '@app/shared/components';
import { RrsOrdersModule } from '@app/custom/features/rrs-account/components/rrs-orders/rrs-orders.module';
import { RrsProfileModule } from '@app/custom/features/rrs-profile/rrs-profile.module';
import { RrsRewardsModule } from '@app/custom/features/rrs-rewards/rrs-rewards.module';
import { RrsSavedCartModule } from '@app/custom/features/rrs-saved-cart/rrs-saved-cart.module';
import { RrsSavedPaymentsModule } from '@app/custom/features/rrs-account/components/rrs-saved-payments/rrs-saved-payments.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    RrsAddressBookModule,
    RrsIconModule,
    RrsOrdersModule,
    RrsProfileModule,
    RrsRewardsModule,
    RrsSavedCartModule,
    RrsSavedPaymentsModule,
  ],
  declarations: [
    RrsAccountContentComponent,
    RrsAccountLoyaltyComponent,
    RrsAccountNavigationComponent,
  ],
  exports: [],
})
export class RrsAccountComponentsModule {}
