import { BaseStorefrontModule } from '@spartacus/storefront';
import { NgModule } from '@angular/core';
import { SpartacusConfigurationModule } from '@app/spartacus/spartacus-configuration.module';
import { SpartacusFeaturesModule } from '@app/spartacus/spartacus-features.module';

@NgModule({
  declarations: [],
  imports: [
    SpartacusFeaturesModule,
    SpartacusConfigurationModule,
    BaseStorefrontModule,
  ],
  exports: [BaseStorefrontModule],
})
export class SpartacusModule {}
