import { catchError, map } from 'rxjs/operators';
import {
  GeoPoint,
  Occ,
  SearchConfig,
  normalizeHttpError,
} from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { GeolocationFromIp } from '@app/custom/features/rrs-product-listing/model/rrs-search-stores.model';
import { Injectable } from '@angular/core';
import { OccStoreFinderAdapter } from '@spartacus/storefinder/occ';
import { UpdatedStores } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';

@Injectable({
  providedIn: 'root',
})
export class RrsStoreFinderAdapter extends OccStoreFinderAdapter {
  protected override callOccFindStores(
    query: string,
    searchConfig: SearchConfig,
    longitudeLatitude?: GeoPoint,
    radius?: number
  ): Observable<Occ.StoreFinderSearchPage> {
    const params: {
      longitude?: string;
      latitude?: string;
      radius?: string;
      query?: string;
      pageSize?: string;
      currentPage?: string;
      sort?: string;
    } = {};

    if (longitudeLatitude) {
      params.longitude = String(longitudeLatitude.longitude);
      params.latitude = String(longitudeLatitude.latitude);
      params.radius = String(radius);
    } else {
      params.query = query;
    }

    if (searchConfig.pageSize) {
      params.pageSize = String(searchConfig.pageSize);
    }
    if (searchConfig.currentPage) {
      params.currentPage = String(searchConfig.currentPage);
    }
    if (searchConfig.sort) {
      params.sort = searchConfig.sort;
    }

    return this.http.get<Occ.StoreFinderSearchPage>(
      this.occEndpointsService.buildUrl('stores', { queryParams: params })
    );
  }

  getGeolocationFromIp(): Observable<GeoPoint> {
    const endpoint = '/geolocation';
    const url = environment.production
      ? environment.geolocationApiBaseUrl + endpoint
      : endpoint;

    return this.http.get<GeolocationFromIp>(url).pipe(
      map((geoloc) => {
        const [latitude, longitude] = (
          geoloc?.cityLatLong?.split(',') ?? []
        ).map(Number);

        const geopoint: GeoPoint = {
          longitude,
          latitude,
        };

        return geopoint;
      })
    );
  }

  getUpdatedStores(): Observable<UpdatedStores> {
    const url = this.occEndpointsService.buildUrl('updatedStores');

    return this.http
      .get<UpdatedStores>(url)
      .pipe(catchError((error) => throwError(normalizeHttpError(error))));
  }
}
