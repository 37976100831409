<form
  (ngSubmit)="onSubmit()"
  [formGroup]="customerSelectionForm"
  class="d-flex flex-column flex-lg-row">
  <div class="form-group col-12 col-lg-3 me-3 mb-3 mb-lg-0 position-relative">
    <input
      class="form-control"
      required="true"
      #searchTerm
      type="text"
      formControlName="searchTerm"
      placeholder="{{ 'asm.customerSearch.searchTerm.label' | cxTranslate }}" />
    <rrs-form-errors
      [control]="customerSelectionForm.get('searchTerm')"></rrs-form-errors>
    <div
      *ngIf="searchResults | async as results"
      class="d-flex w-100 flex-column position-absolute border border-primary z-1000 bg-white"
      #resultList>
      <button
        class="px-3 py-2 w-100 text-start"
        *ngFor="let result of results.entries"
        (click)="selectCustomerFromList(result)">
        <span class="fw-semibold">{{ result.name }}:&nbsp;</span>
        <span>{{ result.uid }}</span>
      </button>
      <button
        class="px-3 py-2 w-100 text-start"
        (click)="closeResults()"
        *ngIf="
          (searchResultsLoading$ | async) === false &&
          searchTerm.value.length >= 3 &&
          !!results.entries &&
          results.entries.length <= 0
        ">
        {{ 'asm.customerSearch.noMatch' | cxTranslate }}
      </button>
    </div>
    <div
      class="d-flex w-100 flex-column position-absolute border border-primary z-1000 bg-white"
      *ngIf="searchResultsLoading$ | async">
      <div class="d-flex align-items-center justify-content-center p-3">
        <div
          class="spinner-border"
          role="status"
          style="width: 3rem; height: 3rem">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
  <div>
    <button type="submit" class="d-none d-lg-block btn btn-primary btn-sm">
      {{ 'asm.customerSearch.submit' | cxTranslate }}
    </button>
  </div>

  <button type="button" class="d-lg-none btn btn-primary">
    {{ 'asm.customerSearch.submit' | cxTranslate }}
  </button>
</form>
