<nav *ngIf="data$ | async as data" aria-label="breadcrumb" class="mt-2">
  <ol class="breadcrumb">
    <li
      *ngFor="let crumb of data; let last = last; let first = first"
      class="breadcrumb-item">
      <a
        [ngClass]="{ 'text-decoration-none pe-none': last && !first }"
        [routerLink]="crumb.link.href"
        class="link-primary">
        {{ crumb.link.title }}
      </a>
    </li>
  </ol>
</nav>
