import { Converter } from '@spartacus/core';
import { Injectable } from '@angular/core';
import { Brand } from '@app/custom/features/rrs-brands/models/brands.model';

@Injectable()
export class RrsBrandNameNormalizer implements Converter<Brand, Brand> {
  convert(source: Brand, target?: Brand): Brand {
    target = {
      ...target,
      ...source,
    };
    target.slug = source.name?.replace(/\&/g, 'and').replace(/\s/g, '');
    return target;
  }
}
