import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export class CustomValidators {
  static upperCase(control: AbstractControl): any | null {
    if (!control.value) return null;
    const value = control.value;

    const hasUpperCase = /[A-Z]+/.test(value);

    return !hasUpperCase ? { [ValidationError.UPPER_CASE]: true } : null;
  }

  static lowerCase(control: AbstractControl): any | null {
    if (!control.value) return null;
    const value = control.value;

    const hasLowerCase = value?.length && /[a-z]+/.test(value);

    return !hasLowerCase ? { [ValidationError.LOWER_CASE]: true } : null;
  }

  static numeric(control: AbstractControl): any | null {
    if (!control.value) return null;
    const value = control.value;

    const hasNumeric = /[0-9]+/.test(value);

    return !hasNumeric ? { [ValidationError.NUMERIC]: true } : null;
  }

  static specialChars(control: AbstractControl): any | null {
    if (!control.value) return null;
    const value = control.value;

    const hasSpecialCharacters = /[!"#$%&'()*+,-./:;=?@[\]^_`{|}~]+/.test(
      value
    );

    return !hasSpecialCharacters
      ? { [ValidationError.SPECIAL_CHARS]: true }
      : null;
  }

  static lengthRange(minLength: number, maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      const validatorMin = Validators.minLength(minLength)(control);
      const validatorMax = Validators.maxLength(maxLength)(control);
      return validatorMin || validatorMax
        ? {
            [ValidationError.LENGTH_RANGE]: {
              requiredMin: minLength,
              requiredMax: maxLength,
              actualValue: control.value?.length,
            },
          }
        : null;
    };
  }

  static isDateValid(control: AbstractControl): any | null {
    const value = control.value;

    const isDate = /^[0-9]{8}$/.test(value);

    return !isDate ? { [ValidationError.IS_DATE_VALID]: true } : null;
  }

  static telephone(control: AbstractControl): any | null {
    const value = control.value;

    //Using this pattern https://regex101.com/r/DsaRfI/1 for now. If Hybris/Spartacus has something else built in we can use that.
    const isTelephone =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
        value
      );

    return !isTelephone ? { [ValidationError.TELEPHONE]: true } : null;
  }
}

export enum ValidationError {
  IS_DATE_VALID = 'isDateValid',
  LENGTH_RANGE = 'lengthRange',
  LOWER_CASE = 'lowerCase',
  NUMERIC = 'numeric',
  REQUIRED = 'required',
  SPECIAL_CHARS = 'specialChars',
  UPPER_CASE = 'upperCase',
  TELEPHONE = 'telephone',
}
