import {
  FRESHDESK_WIDGET_ID,
  FRESHDESK_WIDGET_SRC,
} from '@app/custom/features/rrs-contact-us/configs/freshdesk.config';
import { Inject, Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class RrsContactUsService {
  nativeWindow = this.winRef.nativeWindow as Window & {
    FreshworksWidget?: any;
    fwSettings?: any;
  };

  constructor(
    protected winRef: WindowRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  initializeWidget(
    openOnLoad: boolean = false,
    showLauncher: boolean = true
  ): void {
    const applySettings = (): void => {
      if (openOnLoad) {
        this.nativeWindow.FreshworksWidget('open');
      }
      if (!showLauncher) {
        this.nativeWindow.FreshworksWidget('hide', 'launcher');
      }
    };
    if (this.nativeWindow.FreshworksWidget) {
      this.nativeWindow.FreshworksWidget('boot');
      applySettings();
      return;
    }
    this.nativeWindow.fwSettings = {
      widget_id: FRESHDESK_WIDGET_ID,
    };

    if ('function' != typeof this.nativeWindow.FreshworksWidget) {
      const n = function (): void {
        // @ts-ignore
        n.q.push(arguments);
      };
      // @ts-ignore
      (n.q = []), (this.nativeWindow.FreshworksWidget = n);
    }

    const freshdeskContactFormScript = this.document.createElement('script');
    freshdeskContactFormScript.async = true;
    freshdeskContactFormScript.src = FRESHDESK_WIDGET_SRC;
    freshdeskContactFormScript.addEventListener('load', applySettings);
    this.document.body.appendChild(freshdeskContactFormScript);
  }

  openWidget(): void {
    this.nativeWindow.FreshworksWidget('open');
  }

  closeWidget(): void {
    this.nativeWindow.FreshworksWidget('hide');
  }

  destroyWidget(): void {
    this.nativeWindow.FreshworksWidget('destroy');
  }
}
