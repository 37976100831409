import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { AsmToggleUiComponent } from '@spartacus/asm/components';
import { IconLoaderService } from '@spartacus/storefront';

@Component({
  selector: 'rrs-asm-toggle-ui',
  templateUrl: './rrs-asm-toggle-ui.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsAsmToggleUiComponent extends AsmToggleUiComponent {
  icon = ICON_TYPE_LIST;
}
