import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  DEFAULT_SCOPE,
  Product,
  ProductService,
  RoutingService,
} from '@spartacus/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { RrsPowerReviewsService } from '@app/custom/features/rrs-power-reviews/services/rrs-power-reviews.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-write-review',
  templateUrl: './rrs-write-review.component.html',
})
export class RrsWriteReviewComponent implements OnDestroy {
  protected subscription = new Subscription();

  isPowerRewiewsLoaded$ =
    this.rrsPowerReviewsService.isPowerReviewsInitialized$;
  product$: Observable<Product | undefined> =
    this.activatedRoute.queryParams.pipe(
      switchMap((params) => {
        return combineLatest([
          this.productService.get(params?.page_id),
          this.productService.hasError(params?.page_id, DEFAULT_SCOPE),
        ]);
      }),
      tap(([_, hasError]) => {
        if (hasError) this.routingService.go({ cxRoute: 'notFound' });
      }),
      map(([product]) => product)
    );

  @ViewChild('writereview')
  set displayReview(value: ElementRef) {
    if (value) {
      this.subscription.add(
        combineLatest([
          this.product$,
          this.activatedRoute.queryParams,
        ]).subscribe(([product, params]) => {
          const { path_name } = params;
          this.rrsPowerReviewsService.loadPowerReviewForm(product, path_name);
        })
      );
    }
  }

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected productService: ProductService,
    protected routingService: RoutingService,
    protected rrsPowerReviewsService: RrsPowerReviewsService
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
