import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLinkWithHref } from '@angular/router';
import { AvailableGiftCardsComponent } from '@app/custom/features/rrs-gift-cards-balance/components/available-gift-cards/available-gift-cards.component';
import { GiftCardBalanceComponent } from '@app/custom/features/rrs-gift-cards-balance/components/gift-card-balance/gift-card-balance.component';
import { RrsGiftCardsBalanceContainerComponent } from '@app/custom/features/rrs-gift-cards-balance/components/rrs-gift-cards-balance-container/rrs-gift-cards-balance-container.component';
import { rrsGiftCardsBalanceCmsConfig } from '@app/custom/features/rrs-gift-cards-balance/configs/cms.config';
import { giftCardsOccConfig } from '@app/custom/features/rrs-gift-cards-balance/configs/occ.config';
import { RrsFormErrorsModule } from '@app/shared/components/rrs-form-errors/rrs-form-errors.module';
import { RrsSharedPipesModule } from '@app/shared/pipes';
import { I18nModule, UrlModule } from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';

@NgModule({
  declarations: [
    RrsGiftCardsBalanceContainerComponent,
    GiftCardBalanceComponent,
    AvailableGiftCardsComponent,
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    I18nModule,
    ReactiveFormsModule,
    RouterLinkWithHref,
    UrlModule,
    RrsSharedPipesModule,
    RrsFormErrorsModule,
  ],
  providers: [...rrsGiftCardsBalanceCmsConfig, giftCardsOccConfig],
})
export class RrsGiftCardsBalanceModule {}
