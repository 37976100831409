import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@spartacus/storefront';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomModule } from '@app/custom/custom.module';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '@environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SpartacusModule } from '@app/spartacus/spartacus.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { OneTrustModule } from '@altack/ngx-onetrust';
import { RrsCookiesGroups } from '@app/custom/features/rrs-onetrust/rrs-onetrust.config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    EffectsModule.forRoot([]),
    HttpClientModule,
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    OneTrustModule.forRoot({
      cookiesGroups: RrsCookiesGroups,
      domainScript: environment.onetrustScript,
    }),
    // Spartacus & Custom Modules
    SpartacusModule,
    CustomModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
