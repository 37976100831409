import { GlobalMessageConfig, GlobalMessageType } from '@spartacus/core';

export const rrsGlobalMessageConfig: GlobalMessageConfig = {
  globalMessages: {
    [GlobalMessageType.MSG_TYPE_CONFIRMATION]: {
      timeout: 100000,
    },
    [GlobalMessageType.MSG_TYPE_INFO]: {
      timeout: 100000,
    },
    [GlobalMessageType.MSG_TYPE_ERROR]: {
      timeout: 100000,
    },
    [GlobalMessageType.MSG_TYPE_WARNING]: {
      timeout: 100000,
    },
  },
};

import { RrsLocalMessageConfig } from '@app/custom/features/rrs-global-message/model';

export const localMessagesConfig: RrsLocalMessageConfig = {
  scopes: [
    {
      name: 'login',
      globalMessagesKeys: [
        'httpHandlers.badRequestPleaseLoginAgain',
        'rrs.loginForm.httpHandlers.badRequest.bad_credentials',
        'rrs.forgottenPassword.passwordResetEmailSent',
      ],
      ignoreGlobally: true,
    },
    {
      name: 'customerPhone',
      globalMessagesKeys: [
        'httpHandlers.validationErrors.invalid.customerPhone',
      ],
      ignoreGlobally: true,
    },
    {
      name: 'signup',
      globalMessagesKeys: [
        'signup.incorrectPassword',
        'httpHandlers.validationErrors.missing.customerPhone',
        'Duplicate email', // this error comes only as a string from the BE so we used the message value here
      ],
      ignoreGlobally: true,
    },
  ],
};

export const localMessagesCheckoutConfig: RrsLocalMessageConfig = {
  scopes: [
    {
      name: 'login',
      globalMessagesKeys: [
        'httpHandlers.badRequestPleaseLoginAgain',
        'rrs.forgottenPassword.passwordResetEmailSent',
      ],
      ignoreGlobally: true,
    },
    {
      name: 'deliveryModes',
      globalMessagesKeys: ['rrs.deliveryModes.missingFields'],
      ignoreGlobally: true,
    },
    {
      name: 'customerPhone',
      globalMessagesKeys: [
        'httpHandlers.validationErrors.invalid.customerPhone',
      ],
      ignoreGlobally: true,
    },
    {
      name: 'giftCards',
      globalMessagesKeys: ['rrs.giftCards.giftCardsError'],
      ignoreGlobally: true,
    },
    {
      name: 'checkoutRewards',
      globalMessagesKeys: ['httpHandlers.invalidCodeProvided'],
      ignoreGlobally: true,
    },
    {
      name: 'signup',
      globalMessagesKeys: [
        'signup.incorrectPassword',
        'httpHandlers.validationErrors.missing.customerPhone',
        'Duplicate email',
      ],
      ignoreGlobally: true,
    },
    {
      name: 'paymentForm',
      globalMessagesKeys: ['rrs.checkout.paymentForm.cardValidationError'],
      ignoreGlobally: true,
    },
    // This scope does not exist on purpouse.
    // Sending messages here will hide them.
    {
      name: 'none',
      globalMessagesKeys: ['voucher.removeVoucherSuccess'],
      ignoreGlobally: true,
    },
  ],
};
