import { filter, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthService } from '@spartacus/core';
import { Injectable } from '@angular/core';
import { OccCustomerGroupsAdapter } from '@app/custom/features/rrs-account/occ/occ-customer-groups.adapter';
import { UserAccountService } from '@spartacus/user/account/core';
import { UserGroupList } from '@app/custom/features/rrs-account/models/customer-groups.model';

@Injectable({
  providedIn: 'root',
})
export class RrsCustomerGroupsService {
  constructor(
    protected userAccountService: UserAccountService,
    protected customerGroupsAdapter: OccCustomerGroupsAdapter,
    protected authService: AuthService
  ) {}

  getCustomerGroups(): Observable<UserGroupList | null> {
    return this.authService.isUserLoggedIn().pipe(
      switchMap((isLoggedIn) =>
        isLoggedIn
          ? this.userAccountService.get().pipe(
              filter((user) => !!user?.uid),
              switchMap((user) =>
                this.customerGroupsAdapter.getCustomerGroups(user?.uid!)
              )
            )
          : of(null)
      )
    );
  }

  addToCustomerGroup(groupId: string): Observable<any> {
    return this.userAccountService.get().pipe(
      filter((user) => !!user?.uid),
      switchMap((user) =>
        this.customerGroupsAdapter.addToCustomerGroup({
          userId: user?.uid!,
          groupId,
        })
      )
    );
  }

  removeFromCustomerGroup(groupId: string): Observable<any> {
    return this.userAccountService.get().pipe(
      filter((user) => !!user?.uid),
      switchMap((user) =>
        this.customerGroupsAdapter.removeFromCustomerGroup({
          userId: user?.uid!,
          groupId,
        })
      )
    );
  }
}
