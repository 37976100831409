<section
  class="searchbox-mobile"
  @fadeHeightAnimation
  *ngIf="headerUi.isSearchBoxMobiOpen | async">
  <div class="searchbox-mobile__container d-flex flex-column p-2">
    <input
      (keydown.enter)="launchSearchPage(searchInput.value)"
      [placeholder]="'rrs.searchbox.placeholder' | cxTranslate"
      #searchInput
      class="searchbox-mobile__input"
      type="text" />

    <button
      (click)="launchSearchPage(searchInput.value)"
      class="btn btn-primary btn-sm w-100">
      {{ 'rrs.searchbox.search' | cxTranslate }}
    </button>
  </div>
</section>
