import { Injectable } from '@angular/core';
import { SavedCartService } from '@spartacus/cart/saved-cart/core';

@Injectable({
  providedIn: 'root',
})
export class RrsSavedCartService extends SavedCartService {
  public resetSavedCartStates(): void {
    this.clearCloneSavedCart();
    this.clearSaveCart();
    this.clearRestoreSavedCart();
  }
}
