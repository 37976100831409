import { Injectable } from '@angular/core';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import { CONTENTSTACK_CMS_PAGE_LIST } from '@app/custom/features/rrs-cms/configs/contentstack.config';
import {
  RrsDynamicYieldContext,
  RrsDynamicYieldPageTypes,
} from '@app/custom/features/rrs-dynamic-yield/dynamic-yield.model';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { Cart } from '@spartacus/cart/base/root';
import {
  EventService,
  HOME_PAGE_CONTEXT,
  PageType,
  WindowRef,
} from '@spartacus/core';
import { NavigationEvent } from '@spartacus/storefront';
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@AutoUnsubscribe()
@Injectable({
  providedIn: 'root',
})
export class RrsDynamicYieldService {
  // Required by DynamicYield to fix issue with endcap display. ~Justin L, 2023/11/15
  private dataPropToggle = false;

  subscriptions = new Subscription();

  constructor(
    private eventService: EventService,
    private activeCartService: RrsActiveCartService,
    private winRef: WindowRef
  ) {}

  mapPageContextToDYContext(
    event: NavigationEvent
  ): Observable<RrsDynamicYieldContext> {
    const pageContext = event.context;
    if (pageContext.id === CONTENTSTACK_CMS_PAGE_LIST.CART) {
      return this.activeCartService.getActive().pipe(
        map((cart: Cart) => ({
          pageType: RrsDynamicYieldPageTypes.cart,
          data: (cart.entries ?? []).map((entry) => entry.product?.code),
        }))
      );
    }

    if (pageContext.id === HOME_PAGE_CONTEXT) {
      return of({
        pageType: RrsDynamicYieldPageTypes.homepage,
      });
    }

    if (pageContext.type === PageType.PRODUCT_PAGE) {
      return of({
        pageType: RrsDynamicYieldPageTypes.product,
        data: [pageContext.id],
      });
    }

    if (pageContext.id === 'search') {
      return of({
        pageType: RrsDynamicYieldPageTypes.category,
        data: event.params?.query?.split('-'),
      });
    }

    this.dataPropToggle = !this.dataPropToggle;
    if (!this.dataPropToggle) {
      return of({
        pageType: RrsDynamicYieldPageTypes.OTHER,
      });
    } else {
      return of({
        pageType: RrsDynamicYieldPageTypes.OTHER,
        data: [],
      });
    }
  }

  initialize(): void {
    if (this.winRef.isBrowser()) {
      this.subscriptions.add(
        this.eventService
          .get(NavigationEvent)
          .pipe(switchMap((event) => this.mapPageContextToDYContext(event)))
          .subscribe((context: RrsDynamicYieldContext) => {
            this.setContext(context);
          })
      );
    }
  }

  setContext(context: RrsDynamicYieldContext): void {
    const recommendationContext: any = {
      type: context.pageType,
    };

    if (context.data) {
      recommendationContext.data = context.data;
    }

    window.DY = window.DY || {};

    if (
      !window.DY.recommendationContext ||
      window.DY.recommendationContext.type != recommendationContext.type ||
      window.DY.recommendationContext.data != recommendationContext.data
    ) {
      window.DY.recommendationContext = recommendationContext;
    }
  }
}
