import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsStoreLocation } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'rrs-cms-store-location',
  templateUrl: './rrs-cms-store-location.component.html',
  styleUrls: ['./rrs-cms-store-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsCmsStoreLocationComponent {
  storeLocation$ = this.cmsComponentData.data$;

  constructor(protected cmsComponentData: CmsComponentData<CmsStoreLocation>) {}
}
