import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RrsTagComponent } from '../rrs-tag.directive';

@Component({
  selector: 'rrs-coupon-tag',
  templateUrl: './rrs-coupon-tag.component.html',
  styleUrls: ['./rrs-coupon-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsCouponTagComponent extends RrsTagComponent {
  @Input() label: string = '';
}
