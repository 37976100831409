import { CommonModule } from '@angular/common';
import { I18nModule, UrlModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RrsAccordionModule, RrsIconModule } from '@app/shared/components';
import { RrsAddToWishlistModule } from '@app/custom/features/rrs-add-to-wishlist/rrs-add-to-wishlist.module';
import { RrsCartItemComponent } from '@app/custom/features/rrs-cart/components/rrs-cart-item/rrs-cart-item.component';
import { RrsCartListComponent } from '@app/custom/features/rrs-cart/components/rrs-cart-list/rrs-cart-list.component';
import { RrsSharedPipesModule } from '@app/shared/pipes';
import { RrsStoreFinderComponentsModule } from '@app/custom/features/rrs-storefinder/rrs-storefinder-components.module';
import { RrsMediaModule } from '@app/custom/features/rrs-media/rrs-media.module';

@NgModule({
  declarations: [RrsCartItemComponent, RrsCartListComponent],
  exports: [RrsCartItemComponent, RrsCartListComponent],
  imports: [
    CommonModule,
    I18nModule,
    MediaModule,
    RouterModule,
    RrsAccordionModule,
    RrsAddToWishlistModule,
    RrsIconModule,
    RrsSharedPipesModule,
    RrsStoreFinderComponentsModule,
    UrlModule,
    RrsMediaModule,
  ],
})
export class RrsCartSharedModule {}
