import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { RrsBasePageMetaResolver } from '@app/custom/features/seo/resolvers/rrs-base-page-meta.resolver';
import { RrsContentPageMetaResolver } from '@app/custom/features/seo/resolvers/rrs-content-page-meta.resolver';
import { RoutingService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export const DEFAULT_TITLE = 'Shoes, Boots and Sandals | Rack Room Shoes';
@Injectable({
  providedIn: 'root',
})
export class RrsPlpPageTitleResolver extends RrsContentPageMetaResolver {
  constructor(
    protected basePageMetaResolver: RrsBasePageMetaResolver,
    protected routingService: RoutingService,
    private titlecasePipe: TitleCasePipe
  ) {
    super(basePageMetaResolver);
    this.pageTemplate = 'ProductListingPageTemplate';
  }

  resolveTitle(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveTitle().pipe(
      switchMap((pageTitle) => {
        if (pageTitle === DEFAULT_TITLE) {
          return this.resolveSlug().pipe(
            map((slug) => {
              if (!slug) {
                return DEFAULT_TITLE;
              }
              return slug + ' | Rack Room Shoes';
            })
          );
        }
        return of(pageTitle);
      })
    );
  }
  resolveDescription(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveDescription().pipe(
      switchMap((pageDescription) => {
        if (!pageDescription) {
          return this.resolveSlug().pipe(
            map((slug) => {
              if (!slug) {
                slug = 'Shoes, Boots and Sandals';
              }
              return `Shop ${slug} at Rack Room Shoes. Choose from the top brands, buy online or pick up in store today.`;
            })
          );
        }
        return of(pageDescription);
      })
    );
  }

  private resolveSlug(): Observable<string | null> {
    return this.routingService.getParams().pipe(
      map((query) => {
        if (query?.query) {
          return this.convertEndPointToTitle(query?.query);
        }
        return null;
      })
    );
  }
  private convertEndPointToTitle(slug: string): string {
    const replaceStringObj: any = {
      '-': ' ',
      womens: "women's",
      mens: "men's",
      girls: "girls'",
      boys: "boys'",
      kids: "kids'",
      and: ' and ',
      's and a': 'sanda',
    };
    const regex = new RegExp(Object.keys(replaceStringObj).join('|'), 'gi');
    slug = slug.replace(regex, (matched) => {
      return replaceStringObj[matched];
    });
    return this.titlecasePipe
      .transform(slug)
      .replace(/And/g, ' and ')
      .replace(/\s\s+/g, ' ');
  }
}
