<ng-container *ngIf="activeCart$ | async as activeCart">
  <div
    class="mini-cart"
    [class.mini-cart--collapsed]="isCollapsed || !activeCart.totalUnitCount">
    <div class="d-flex p-2 justify-content-between border-bottom">
      <h5 *ngIf="!miniCartTitle; else customTitle">
        {{ 'rrs.miniCart.myCart' | cxTranslate }}
      </h5>
      <span class="fs-7 fw-semibold"
        >{{ activeCart.totalUnitCount }}
        {{ 'rrs.miniCart.items' | cxTranslate }}</span
      >
    </div>
    <div class="mini-cart__cards d-flex flex-column-reverse">
      <rrs-mini-product-card
        *ngFor="let entry of activeCart.entries"
        [entry]="entry"></rrs-mini-product-card>
    </div>
    <div class="p-3 mt-2 bg-stripe">
      <a
        [routerLink]="'/cart'"
        (click)="close()"
        class="btn btn-sm btn-primary w-100"
        >{{ 'rrs.miniCart.viewCart' | cxTranslate }}</a
      >
    </div>
  </div>
</ng-container>

<ng-template #customTitle>
  <div class="d-flex">
    <rrs-icon [icon]="icon.CHECK" [size]="20" class="me-2"></rrs-icon>
    <h5>{{ miniCartTitle | cxTranslate }}</h5>
  </div>
</ng-template>
