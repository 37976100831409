import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalService } from '@app/custom/features/modal';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { map } from 'rxjs/operators';
import { RrsAccountInformationUpdateComponent } from '@app/custom/features/rrs-profile/components/rrs-account-information-update/rrs-account-information-update.component';
import { User } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { RrsEventsDispatcherService } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/services/rrs-events.dispatcher';

@Component({
  selector: 'rrs-account-information',
  templateUrl: './rrs-account-information.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsAccountInformationComponent {
  iconTypeList = ICON_TYPE_LIST;

  user$ = this.userAccountFacade
    .get()
    .pipe(map((user: User | undefined) => user));

  constructor(
    protected userAccountFacade: UserAccountFacade,
    private modalService: ModalService,
    protected eventsDispatcher: RrsEventsDispatcherService
  ) {}

  editPersonalInformation(): void {
    this.eventsDispatcher.dispatchEditAccountInfoEvent();
    this.modalService.open(RrsAccountInformationUpdateComponent);
  }
}
