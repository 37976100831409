<section>
  <h1 class="fs-4 fw-normal mb-3 mt-4">
    {{ 'rrs.giftCards.checkBalance' | cxTranslate }}
  </h1>
  <p>{{ 'rrs.giftCards.checkBalanceInfo' | cxTranslate }}</p>

  <form [formGroup]="form" #ngForm="ngForm" (submit)="onSubmit()">
    <input
      formControlName="cardNumber"
      class="form-control"
      [ngClass]="
        ngForm.submitted ? (form.get('cardNumber') | rrsIsControlValid) : ''
      "
      type="text"
      placeholder="{{ 'rrs.giftCards.cardNumberPlaceholder' | cxTranslate }}" />
    <rrs-form-errors
      *ngIf="ngForm.submitted"
      [control]="form.get('cardNumber')"
      aria-atomic="true"
      aria-live="assertive">
    </rrs-form-errors>
    <button type="submit" class="btn btn-primary text-uppercase mt-4 fs-5">
      {{ 'common.submit' | cxTranslate }}
    </button>
  </form>

  <div *ngIf="!isLoading; else loading">
    <div *ngIf="cardBalance" class="text-center">
      <p class="text-muted mt-3">
        {{ 'rrs.giftCards.cardNumber' | cxTranslate }}
        <span>{{ cardNumber }}</span>
      </p>
      <p class="text-muted mb-1">
        {{ 'rrs.giftCards.remainingBalance' | cxTranslate }}
      </p>
      <p class="text-accent fw-bold fs-2">{{ cardBalance }}</p>
    </div>
  </div>

  <ng-template #loading>
    <div class="d-flex align-items-center justify-content-center p-5">
      <div class="loader spinner-border text-accent" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-template>
</section>
