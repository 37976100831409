import { CmsConfig, provideConfig } from '@spartacus/core';
import { RrsContactUsComponent } from '@app/custom/features/rrs-contact-us/components/rrs-contact-us/rrs-contact-us.component';
import { CONTACT_US_COMPONENT_ID } from '@app/custom/features/rrs-cms/configs/contentstack.config';

export const rrsContactUsCmsConfig = [
  provideConfig({
    cmsComponents: {
      [CONTACT_US_COMPONENT_ID]: {
        component: RrsContactUsComponent,
      },
    },
  } as CmsConfig),
];
