import { CommonModule } from '@angular/common';
import { IconModule } from '@spartacus/storefront';
import { NgModule } from '@angular/core';
import { RrsStarRatingComponent } from './rrs-star-rating.component';

@NgModule({
  declarations: [RrsStarRatingComponent],
  imports: [CommonModule, IconModule],
  exports: [RrsStarRatingComponent],
})
export class RrsStarRatingModule {}
