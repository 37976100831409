<ng-container *ngIf="data$ | async as data">
  <ng-container
    *ngIf="data.typeCode === 'need_help_modal_component'; else dropdown">
    <button
      (click)="open(content)"
      type="button"
      class="title w-100 d-flex align-items-center justify-content-center">
      <span class="me-2 fw-bold text-decoration-underline">{{
        data.title
      }}</span>
      <rrs-icon [icon]="icon.QUESTION_MARK_CIRCLE" [size]="14"></rrs-icon>
    </button>

    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          {{ 'rrs.common.needHelp' | cxTranslate }}
        </h4>
        <button
          class="btn-close"
          [attr.aria-label]="'rrs.common.closeModal' | cxTranslate"
          type="button"
          cxModal="dismiss"
          (click)="modal.dismiss('Cross click')"
          cxModalReason="Cross click"></button>
      </div>
      <div class="modal-body">
        <ng-content *ngTemplateOutlet="tiles"></ng-content>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #dropdown>
    <button
      (click)="collapse.toggle()"
      [attr.aria-expanded]="!isCollapsed"
      [ngClass]="{ collapsed: isCollapsed }"
      type="button"
      class="title w-100 d-flex align-items-center justify-content-center py-3">
      <rrs-icon
        [icon]="icon.QUESTION_MARK_CIRCLE"
        [size]="14"
        fill="#757575"
        class="me-2">
      </rrs-icon>
      <span class="me-2 fw-bold text-decoration-underline fs-7">{{
        data.title
      }}</span>
      <rrs-icon class="chevron" [icon]="icon.CHEVRON_UP" [size]="14"></rrs-icon>
    </button>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <ng-content *ngTemplateOutlet="tiles"></ng-content>
    </div>
  </ng-template>

  <ng-template #tiles>
    <ng-container *ngFor="let tileData of data.tiles; let last = last">
      <ng-container
        *ngIf="tileData.tile_with_url_link as tile; else customLinkTemplate">
        <a
          [class.mb-3]="!last"
          [routerLink]="tile.url_link.url"
          (click)="closeModal()"
          [title]="tile.title"
          [target]="tile.url_link.new_tab ? '_blank' : '_self'"
          class="py-2 border border-primary rounded-1 d-flex align-items-center text-decoration-none">
          <ng-container
            *ngTemplateOutlet="tileContent; context: { tile }"></ng-container>
        </a>
      </ng-container>
      <ng-template #customLinkTemplate>
        <ng-container *ngIf="tileData.tile_with_custom_link as tile">
          <button
            [class.mb-3]="!last"
            [class.live-chat]="tile.custom_link.type === 'liveChat'"
            class="py-2 w-100 text-start border border-primary rounded-1 d-flex align-items-center"
            (click)="onCustomLinkClick(tile.custom_link.type)">
            <ng-container
              *ngTemplateOutlet="tileContent; context: { tile }"></ng-container>
          </button>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-template #tileContent let-tile="tile">
      <img
        [src]="tile?.icon_image?.file?.url"
        [alt]="tile?.icon_image?.alt_text"
        width="24"
        height="24"
        class="m-3" />
      <div>
        <span class="fw-bold d-block">{{ tile.title }}</span>
        <span>{{ tile.description }}</span>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
