export const MONTH_SELECT = [
  { value: 'birthMonth01UG', label: 'January' },
  { value: 'birthMonth02UG', label: 'February' },
  { value: 'birthMonth03UG', label: 'March' },
  { value: 'birthMonth04UG', label: 'April' },
  { value: 'birthMonth05UG', label: 'May' },
  { value: 'birthMonth06UG', label: 'June' },
  { value: 'birthMonth07UG', label: 'July' },
  { value: 'birthMonth08UG', label: 'August' },
  { value: 'birthMonth09UG', label: 'September' },
  { value: 'birthMonth10UG', label: 'October' },
  { value: 'birthMonth11UG', label: 'November' },
  { value: 'birthMonth12UG', label: 'December' },
];
