<div class="store-details mt-4 pt-4 pt-lg-0 mt-lg-0" *ngIf="store">
  <div class="mb-3" *ngIf="isLoggedIn$ | async">
    <div
      data-cy="store-finder-make-my-store"
      class="form-check form-switch ps-0">
      <label class="form-check-label" for="makeMyStoreSwitch">
        <h6 class="fs-7 m-0 fw-semibold">
          {{ 'rrs.storeFinder.makeMyStore' | cxTranslate }}
        </h6>
      </label>
      <input
        class="form-check-input m-0 mx-3"
        id="makeMyStoreSwitch"
        type="checkbox"
        role="switch"
        [formControl]="makeMyStoreControl"
        [checked]="(defaultStoreId$ | async) === store.name"
        (change)="setAsDefaultStore(store.name, $event)" />
    </div>
  </div>
  <rrs-store-finder-map [location]="store"></rrs-store-finder-map>
  <div class="d-flex justify-content-between flex-column flex-sm-row my-3">
    <div class="col-sm-6 me-sm-1">
      <h5 data-cy="store-finder-details-title">{{ store.displayName }}</h5>

      <p>
        {{ store.address?.line1 }} <br />
        {{ store.address?.town }}, {{ store.address?.region?.isocodeShort }}
        {{ store.address.postalCode }} <br />
        <span class="store-details__phone"
          >{{ store.phoneNumber | rrsPhoneNumber }} </span
        ><br />
      </p>
    </div>
    <div class="store-details__opening-hours ms-sm-1 col-sm-6">
      <h5>{{ 'rrs.storeFinder.todaysHours' | cxTranslate }}</h5>

      <span
        *ngIf="(store | storeOpeningSchedule)?.[0] as todaySchedule; else noSchedule"
        class="store__opening d-flex align-items-center mt-3">
        <ng-container
          [ngTemplateOutlet]="pulse"
          [ngTemplateOutletContext]="{
            isOpened: todaySchedule.isOpened
          }"></ng-container>
        <span class="text-uppercase">
          {{
            (todaySchedule.isOpened
              ? 'rrs.storeFinder.openToday'
              : 'rrs.storeFinder.closedToday'
            ) | cxTranslate
          }}
          {{
            todaySchedule.isOpened
              ? ': ' + todaySchedule.formattedOpeningHours
              : ''
          }}
        </span>
      </span>
      <ng-template #noSchedule>
        {{ 'rrs.storeFinder.noSchedule' | cxTranslate }}
      </ng-template>
    </div>
  </div>
  <a
    data-cy="store-finder-details-link"
    [routerLink]="
      { cxRoute: 'storeDetails', params: { storeId: store.name } } | cxUrl
    "
    class="btn btn-primary mt-2">
    {{ 'rrs.storeFinder.storeDetails' | cxTranslate }}
  </a>

  <ng-template #pulse let-isOpened="isOpened">
    <div
      class="pulse me-1"
      [ngClass]="isOpened ? 'pulse--green' : 'pulse--red'">
      <span>
        <span>
          <span></span>
        </span>
      </span>
    </div>
  </ng-template>
</div>
