import { PaginationConfig } from '@spartacus/storefront';

export const rrsPaginationConfig: PaginationConfig = {
  pagination: {
    addDots: false,
    addEnd: false,
    addNext: true,
    addPrevious: true,
    addStart: false,
    addFirst: false,
    addLast: false,
    rangeCount: 5,
  },
};
