import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';

@Component({
  selector: 'rrs-saved-carts',
  templateUrl: './rrs-saved-carts.component.html',
  styleUrls: ['./rrs-saved-carts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsSavedCartsComponent {
  iconTypeList = ICON_TYPE_LIST;
}
