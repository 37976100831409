import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalRef, ModalService } from '@app/custom/features/modal';
import { VALIDATE_USER_ENDPOINT } from '@app/custom/features/rrs-auth/rrs-auth.config';
import { RrsAuthComponent } from '@app/custom/features/rrs-auth/rrs-auth/rrs-auth.component';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  AuthRedirectService,
  AuthService,
  InterceptorUtil,
  OAuthLibWrapperService,
  OCC_USER_ID_CURRENT,
  OccEndpointsService,
  RoutingService,
  StateWithClientAuth,
  USE_CLIENT_TOKEN,
  UserIdService,
} from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsAuthService {
  public isAuthorizing$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private occEndpointsService: OccEndpointsService,
    private authService: AuthService,
    private modalService: ModalService,
    protected store: Store<StateWithClientAuth>,
    protected userIdService: UserIdService,
    protected oAuthLibWrapperService: OAuthLibWrapperService,
    protected authRedirectService: AuthRedirectService,
    protected routingService: RoutingService
  ) {}

  startAuthorizing(): void {
    this.isAuthorizing$.next(true);
    this.authService
      .isUserLoggedIn()
      .pipe(filter(Boolean), take(1))
      .subscribe(() => {
        this.isAuthorizing$.next(false);

        // @todo: temporary reload app after user logged in the checkout to avoid missing cart error issue.
        // We will change this after all other cart/payment endpoint issues will be fixed
        if (location.href.includes('checkout/')) {
          location.reload();
        }
      });
  }

  validateIfUserExists(emailOrPhone: string): Observable<Boolean> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // The line bellow add the token to the header
    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);

    return this.http
      .get(
        this.occEndpointsService.buildUrl(VALIDATE_USER_ENDPOINT, {
          queryParams: {
            email: emailOrPhone,
          },
        }),
        { headers }
      )
      .pipe(map((isUserExist) => isUserExist && isUserExist !== 'false'));
  }

  login(userId: string, password: string): Promise<void> {
    return this.loginWithCredentials(userId, password);
  }

  async loginWithoutRedirect(userId: string, password: string): Promise<void> {
    await this.beginLogin(userId, password);
    this.store.dispatch(new AuthActions.Login());
  }

  async loginWithCredentials(userId: string, password: string): Promise<void> {
    await this.beginLogin(userId, password);
    this.store.dispatch(new AuthActions.Login());
    this.authRedirectService.redirect();
  }

  async beginLogin(userId: string, password: string): Promise<void> {
    this.startAuthorizing();
    try {
      await this.oAuthLibWrapperService.authorizeWithPasswordFlow(
        userId,
        password
      );
      this.userIdService.setUserId(OCC_USER_ID_CURRENT);
    } catch (error) {
      console.log('login error', error);
      throw error;
    }
  }

  signOut(): void {
    this.routingService.go({ cxRoute: 'home' });
    this.authService.coreLogout();
  }

  openLoginModal(
    showGuestLogin = false,
    windowClass = 'login-modal'
  ): ModalRef {
    const signEl = document.querySelector('#header-navi-sign-link');
    if (signEl) {
      windowClass += ' sticked-to-nav';
    }
    return this.modalService.open(
      RrsAuthComponent,
      {
        windowClass,
        animation: false,
      },
      {
        showGuestLogin,
      }
    );
  }
}
