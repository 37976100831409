import { NgModule } from '@angular/core';

import { AsmFeatureModule } from '@app/spartacus/features/asm/asm-feature.module';
import { CartBaseFeatureModule } from '@app/spartacus/features/cart/cart-base-feature.module';
import { CartImportExportFeatureModule } from '@app/spartacus/features/cart/cart-import-export-feature.module';
import { CartQuickOrderFeatureModule } from '@app/spartacus/features/cart/cart-quick-order-feature.module';
import { CartSavedCartFeatureModule } from '@app/spartacus/features/cart/cart-saved-cart-feature.module';
import { CheckoutFeatureModule } from '@app/spartacus/features/checkout/checkout-feature.module';
import { OrderFeatureModule } from '@app/spartacus/features/order/order-feature.module';
import { ProductImageZoomFeatureModule } from '@app/spartacus/features/product/product-image-zoom-feature.module';
import { ProductVariantsFeatureModule } from '@app/spartacus/features/product/product-variants-feature.module';
import { StoreFinderFeatureModule } from '@app/spartacus/features/storefinder/store-finder-feature.module';
import { PersonalizationFeatureModule } from '@app/spartacus/features/tracking/personalization-feature.module';
import { UserFeatureModule } from '@app/spartacus/features/user/user-feature.module';
import { CartBaseModule } from '@spartacus/cart/base';

import {
  AnonymousConsentsModule,
  AuthModule,
  CostCenterOccModule,
  ExternalRoutesModule,
  ProductModule,
  ProductOccModule,
  UserModule,
  UserOccModule,
} from '@spartacus/core';
import {
  AddressBookModule,
  AnonymousConsentManagementBannerModule,
  AnonymousConsentsDialogModule,
  BannerCarouselModule,
  BannerModule,
  BreadcrumbModule,
  CategoryNavigationModule,
  CmsParagraphModule,
  ConsentManagementModule,
  FooterNavigationModule,
  HamburgerMenuModule,
  HomePageEventModule,
  LinkModule,
  LoginRouteModule,
  LogoutModule,
  MyCouponsModule,
  MyInterestsModule,
  NavigationEventModule,
  NavigationModule,
  NotificationPreferenceModule,
  PaymentMethodsModule,
  ProductCarouselModule,
  ProductDetailsPageModule,
  ProductFacetNavigationModule,
  ProductImagesModule,
  ProductIntroModule,
  ProductListingPageModule,
  ProductListModule,
  ProductPageEventModule,
  ProductReferencesModule,
  ProductSummaryModule,
  ProductTabsModule,
  SearchBoxModule,
  SiteContextSelectorModule,
  StockNotificationModule,
  TabParagraphContainerModule,
} from '@spartacus/storefront';

@NgModule({
  declarations: [],
  imports: [
    // Auth Core
    AuthModule.forRoot(),
    LogoutModule,
    LoginRouteModule,
    // Basic Cms Components
    HamburgerMenuModule,
    SiteContextSelectorModule,
    LinkModule,
    BannerModule,
    CmsParagraphModule,
    TabParagraphContainerModule,
    BannerCarouselModule,
    CategoryNavigationModule,
    NavigationModule,
    FooterNavigationModule,
    BreadcrumbModule,
    // User UI,
    AddressBookModule,
    PaymentMethodsModule,
    NotificationPreferenceModule,
    MyInterestsModule,
    StockNotificationModule,
    ConsentManagementModule,
    MyCouponsModule,
    // Anonymous Consents Core,
    AnonymousConsentsModule.forRoot(),
    // Anonymous Consents UI,
    AnonymousConsentsDialogModule,
    AnonymousConsentManagementBannerModule,
    // Product Core,
    ProductModule.forRoot(),
    ProductOccModule,
    // Product UI,
    ProductDetailsPageModule,
    ProductListingPageModule,
    ProductListModule,
    SearchBoxModule,
    ProductFacetNavigationModule,
    ProductTabsModule,
    ProductCarouselModule,
    ProductReferencesModule,
    ProductImagesModule,
    ProductSummaryModule,
    ProductIntroModule,
    CostCenterOccModule,
    // Page Events,
    NavigationEventModule,
    HomePageEventModule,
    ProductPageEventModule,
    // External routes,
    ExternalRoutesModule.forRoot(),
    UserFeatureModule,
    UserOccModule,
    PersonalizationFeatureModule,
    StoreFinderFeatureModule,
    ProductVariantsFeatureModule,
    ProductImageZoomFeatureModule,
    OrderFeatureModule,
    CheckoutFeatureModule,
    CartBaseModule,
    CartBaseFeatureModule,

    CartSavedCartFeatureModule,
    CartQuickOrderFeatureModule,
    CartImportExportFeatureModule,
    AsmFeatureModule,

    UserModule,
  ],
})
export class SpartacusFeaturesModule {}
