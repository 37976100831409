import { Injectable } from '@angular/core';
import { RrsStoreFinderService } from '@app/custom/features/rrs-storefinder/services/rrs-store-finder.service';
import { RrsBasePageMetaResolver } from '@app/custom/features/seo/resolvers/rrs-base-page-meta.resolver';
import { RrsContentPageMetaResolver } from '@app/custom/features/seo/resolvers/rrs-content-page-meta.resolver';
import { interpolate, titleCase } from '@app/shared/utils/string';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsStoreDetailsPageMetaResolver extends RrsContentPageMetaResolver {
  constructor(
    protected storeFinderService: RrsStoreFinderService,
    basePageMetaResolver: RrsBasePageMetaResolver
  ) {
    super(basePageMetaResolver);
    this.pageTemplate = 'StoreDetailsPageTemplate';
  }

  resolveTitle(): Observable<string | undefined> {
    return this.storeFinderService.getFindStoreById().pipe(
      withLatestFrom(this.basePageMetaResolver.resolveTitle()),
      map(([store, title]) => {
        const town = store?.address?.town ?? '';
        const location = `${town?.length ? titleCase(town) + ', ' : ''}${
          store?.address?.region?.isocodeShort
        }`;
        return interpolate(title, { location });
      })
    );
  }

  resolveDescription(): Observable<string | undefined> {
    return this.storeFinderService.getFindStoreById().pipe(
      withLatestFrom(this.basePageMetaResolver.resolveDescription()),
      map(([store, description]) => {
        const storeName = store?.displayName ?? '';
        return interpolate(description, { storeName });
      })
    );
  }
}
