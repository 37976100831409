<div *ngIf="order$ | async as order" class="container my-5">
  <div class="row">
    <section class="col-lg-3 mb-5">
      <div class="p-3 border border-light border-opacity-50 rounded-1">
        <button
          (click)="collapse.toggle()"
          [attr.aria-expanded]="!isCollapsed"
          [ngClass]="{ collapsed: isCollapsed }"
          type="button"
          class="w-100 d-flex align-items-center justify-content-between mb-4">
          <span class="me-3 fw-bold">{{
            'rrs.orders.orderOverview' | cxTranslate
          }}</span>
          <rrs-icon
            class="chevron"
            [icon]="icon.CHEVRON_UP"
            [size]="16"></rrs-icon>
        </button>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
          <h5 class="fs-6">{{ 'rrs.orders.orderNumber' | cxTranslate }}</h5>
          <p>{{ order.orderNumber }}</p>
          <h5 class="fs-6">{{ 'rrs.orders.orderDate' | cxTranslate }}</h5>
          <p>{{ order.saleDate }}</p>
          <h5 class="fs-6">{{ 'rrs.orders.purchasedAt' | cxTranslate }}</h5>
          <img
            *ngIf="order.companyNumber === 1; else offBroadWay"
            [attr.alt]="'RackRoomShoes logo'"
            [attr.src]="'/assets/images/rackRoomShoes.svg'"
            height="50"
            class="mb-3" />
          <ng-template #offBroadWay>
            <img
              [attr.alt]="'Off Broadway logo'"
              [attr.src]="'/assets/images/offBroadway.svg'"
              height="50"
              class="mb-3" />
          </ng-template>
        </div>
        <a
          [routerLink]="'/my-account/order-history'"
          class="btn btn-sm w-100 btn-light"
          >{{ 'rrs.orders.backToHistory' | cxTranslate }}</a
        >
      </div>
    </section>

    <section class="col-lg-9">
      <div class="d-none d-lg-flex justify-content-between">
        <h4 class="pb-2">{{ 'rrs.orders.itemsToBeShipped' | cxTranslate }}</h4>
        <cx-page-slot
          class="d-flex justify-content-center"
          position="need_help_modal"></cx-page-slot>
      </div>
      <div
        class="p-3 bg-stripe border-top border-2 border-accent d-none d-lg-flex">
        <h5 class="col-3">{{ 'rrs.orders.product' | cxTranslate }}</h5>
        <h5 class="col-3">{{ 'rrs.orders.productDetails' | cxTranslate }}</h5>
        <h5 class="col-4">{{ 'rrs.orders.orderStatus' | cxTranslate }}</h5>
        <h5 class="col-2 text-end">{{ 'rrs.orders.price' | cxTranslate }}</h5>
      </div>
      <rrs-order-product-card
        *ngFor="let item of order.orderDetails"
        [item]="item"
        [storeAddress]="order.storeAddress"
        class="d-block mb-5 pb-3 border-bottom border-light"></rrs-order-product-card>
      <cx-page-slot
        class="d-flex d-lg-none justify-content-center"
        position="need_help_modal"></cx-page-slot>
    </section>
  </div>
</div>
