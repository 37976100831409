import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RrsDefaultStoreService } from '@app/custom/features/rrs-account/services/rrs-default-store.service';
import { RrsPersonalGroupsService } from '@app/custom/features/rrs-profile/services/rrs-personal-groups.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-profile',
  styleUrls: ['./rrs-profile.component.scss'],
  templateUrl: './rrs-profile.component.html',
})
export class RrsProfileComponent implements OnInit {
  iconTypeList = ICON_TYPE_LIST;
  hasDefaultStore$!: Observable<boolean>;

  constructor(
    protected defaultStoreService: RrsDefaultStoreService,
    protected RrsPersonalGroupsService: RrsPersonalGroupsService
  ) {}

  ngOnInit(): void {
    this.RrsPersonalGroupsService.setPersonalGroups();

    this.hasDefaultStore$ = this.defaultStoreService
      .getDefaultStoreId()
      .pipe(map(Boolean));
  }
}
