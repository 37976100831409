import { Pipe, PipeTransform } from '@angular/core';
import { Scene7Presets } from '@app/shared/configs/scene7.config';
import { ImageGroup } from '@spartacus/core';

@Pipe({
  name: 'rrsProductImages',
})
export class RrsProductImagesPipe implements PipeTransform {
  transform(
    value: ImageGroup,
    preset: Scene7Presets = Scene7Presets.MAIN
  ): ImageGroup | null {
    const regex = new RegExp(/\$rrs_smallpdp\$(?!.*\$rrs_smallpdp\$)/);
    if (!value) {
      return null;
    }
    const transformedImage = {
      zoom: {
        ...value?.zoom,
        url: value?.zoom.url
          ?.replace(Scene7Presets.MAIN, preset)
          .replace(regex, ''),
      },
    };

    return transformedImage;
  }
}
