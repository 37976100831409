<div class="px-2 px-lg-0 mb-4 cart-coupon">
  <h2 class="fs-6 text-capitalize fw-normal text-light">
    {{ 'coupon.cartCoupon' | cxTranslate }}
  </h2>

  <ng-container *ngIf="cart$ | async as cart">
    <div class="form-group">
      <form (ngSubmit)="applyVoucher()" [formGroup]="couponForm">
        <div class="row g-0 gap-3">
          <div class="col">
            <input
              (input)="hideCouponState()"
              [class.is-invalid]="showCouponError"
              [class.is-valid]="showCouponSuccess"
              class="form-control text-uppercase cart-coupon__input"
              formControlName="couponCode"
              placeholder="{{ 'coupon.placeholder' | cxTranslate }}"
              required="true"
              type="text" />
          </div>
          <div class="col">
            <button
              [disabled]="(cartIsLoading$ | async) === true || !couponCodeValue"
              class="btn btn-primary btn-sm w-100 h-100"
              type="submit">
              <ng-container
                *ngIf="
                  (showCouponLoader$ | async) === true;
                  then load;
                  else text
                ">
              </ng-container>

              <ng-template #load>
                <div
                  class="spinner-border spinner-border-sm"
                  role="status"
                  style="width: 1.5rem; height: 1.5rem"></div>
              </ng-template>

              <ng-template #text>
                {{ 'coupon.apply' | cxTranslate }}
              </ng-template>
            </button>
          </div>

          <div *ngIf="showCouponError" class="invalid-feedback d-block">
            {{ showCouponError }}
          </div>

          <div *ngIf="showCouponSuccess" class="valid-feedback d-block">
            {{
              'coupon.couponSuccess'
                | cxTranslate
                  : {
                      code: showCouponSuccess | uppercase
                    }
            }}
          </div>
        </div>
      </form>
    </div>

    <rrs-cart-coupon-applied
      [cartIsLoading]="cartIsLoading$ | async"
      [isReadOnly]="false"
      [vouchers]="cart.appliedVouchers"
      (voucherRemoved)="resetVoucherApplyState()">
    </rrs-cart-coupon-applied>
  </ng-container>
</div>
