import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Address } from '@spartacus/core';
import { AddressBookComponentService } from '@spartacus/storefront';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rrs-address-book-confirmation',
  templateUrl: './rrs-address-book-confirmation.component.html',
  styleUrls: ['./rrs-address-book-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsAddressBookConfirmationComponent {
  address!: Address;

  constructor(
    public activeModal: NgbActiveModal,
    public addressBookService: AddressBookComponentService
  ) {}

  removeAddress(id?: string): void {
    if (id) {
      this.addressBookService.deleteUserAddress(id);
    }
    this.activeModal.dismiss('Cross click');
  }
}
