import { BehaviorSubject, Subscription } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import {
  FocusConfig,
  SearchBoxComponent,
  SearchBoxConfig,
} from '@spartacus/storefront';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { RRS_SEARCH_BOX_CONFIG } from '@app/custom/features/rrs-search/models/rrs-searchbox.model';

@AutoUnsubscribe()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-searchbox',
  styleUrls: ['./rrs-searchbox.component.scss'],
  templateUrl: './rrs-searchbox.component.html',
})
export class RrsSearchboxComponent
  extends SearchBoxComponent
  implements OnInit
{
  protected subscriptions = new Subscription();
  iconTypeList = ICON_TYPE_LIST;
  private _search$ = new BehaviorSubject<string>('');
  public isShowingResults: boolean = false;

  @Input() config: SearchBoxConfig = RRS_SEARCH_BOX_CONFIG;

  get focusConfig(): FocusConfig {
    return {
      autofocus: 'input',
      focusOnEscape: false,
    };
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.subscriptions.add(
      this._search$
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((searchQuery) => {
          this.search(searchQuery);
        })
    );
  }

  onSearchInput(query: string): void {
    this._search$.next(query);
  }

  closeSearchBox(event: UIEvent, force?: boolean | undefined): void {
    this.isShowingResults = false;
    this.close(event, force);
  }

  openSearchBox(): void {
    this.isShowingResults = true;
    this.open();
  }

  launchSearchPage(query: string): void {
    if (!query || query.trim().length < 3) return;

    this.routingService.go({
      cxRoute: 'search',
      params: {
        query,
      },
    });
  }
}
