import { BREADCRUMBS_COMPONENT_ID } from '@app/custom/features/rrs-cms/configs/contentstack.config';
import { CmsConfig, PageMetaModule, provideConfig } from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideCmsStructure } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { RrsCmsBreadcrumbsComponent } from '@app/custom/features/rrs-cms/components/rrs-cms-breadcrumbs/components/rrs-cms-breadcrumbs.component';

@NgModule({
  declarations: [RrsCmsBreadcrumbsComponent],
  imports: [CommonModule, RouterModule, PageMetaModule],
  providers: [
    // To add breadcrumbs you need to add BreadcrumbSlot to the page template
    provideCmsStructure({
      componentId: 'breadcrumbs',
      pageSlotPosition: 'BreadcrumbsSlot',
    }),
    provideConfig({
      cmsComponents: {
        [BREADCRUMBS_COMPONENT_ID]: {
          component: RrsCmsBreadcrumbsComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [RrsCmsBreadcrumbsComponent],
})
export class RrsCmsBreadcrumbsModule {}
