import { ViewportScroller } from '@angular/common';
import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Directive to catch link click from richtext component html fragment and execute the route with Angular router.
 */
@Directive({
  selector: '[rrsInternalLink]',
})
export class InternalLinkDirective {
  constructor(
    private router: Router,
    public viewportScroller: ViewportScroller
  ) {}

  @HostListener('click', ['$event'])
  onClick(e: any): void {
    const linkHref = e.target?.closest('a[href]')?.getAttribute('href');
    if (linkHref) {
      if (
        this.routingExceptions.some((exception) => linkHref.includes(exception))
      )
        return;

      if (linkHref.includes('#')) {
        e.preventDefault();
        this.viewportScroller.scrollToAnchor(linkHref.replace('#', ''));
        return;
      }
      e.preventDefault();
      this.router.navigateByUrl(linkHref);
    }
  }

  routingExceptions: string[] = [
    '.pdf',
    'mailto:',
    'tel:',
    'sms:',
    'javascript:',
    'http://',
    'https://',
  ];
}
