import { NgModule } from '@angular/core';
import { RrsCartEventsBuilder } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/builders/rrs-cart-events.builder';
import { RrsPageEventsBuilder } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/builders/rrs-page-events.builder';

@NgModule({})
export class RrsAepEventsModule {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    _rrsSearchPageEventBuilder: RrsPageEventsBuilder,
    _rrsCartEventsBuilder: RrsCartEventsBuilder
  ) {}
}
