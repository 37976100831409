import { CommonModule } from '@angular/common';
import { I18nModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { RrsFormErrorsComponent } from './components/rrs-form-errors/rrs-form-errors.component';

@NgModule({
  declarations: [RrsFormErrorsComponent],
  exports: [RrsFormErrorsComponent],
  imports: [CommonModule, I18nModule],
})
export class RrsFormErrorsModule {}
