import { CommonModule } from '@angular/common';
import { ExternalLinkDirective } from '@app/shared/directives/external-link/external-link.directive';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [ExternalLinkDirective],
  exports: [ExternalLinkDirective],
  imports: [CommonModule],
})
export class ExternalLinkModule {}
