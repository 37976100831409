import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RewardsInterface } from '@app/custom/features/rrs-rewards/models/rewards.model';
import { RrsRewardsService } from '@app/custom/features/rrs-rewards/serwices/rrs-rewards.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-rewards',
  styleUrls: ['./rrs-rewards.component.scss'],
  templateUrl: './rrs-rewards.component.html',
})
export class RrsRewardsComponent {
  iconTypeList = ICON_TYPE_LIST;

  rewards$: Observable<RewardsInterface> = this.rrsRewardsService
    .getUserRewards()
    .pipe(
      map((rewards: RewardsInterface) => {
        const rewardsSorted = (rewards.rewards || [])
          .sort(
            (a, b) =>
              +new Date(b.expireTimestamp as any) -
              +new Date(a.expireTimestamp as any)
          )
          .sort((a, b) => {
            if (a.isCancelled || a.isExpired || a.isRedeemed) return 1;
            else return -1;
          });

        return {
          rewards: rewardsSorted,
        };
      })
    );

  constructor(protected rrsRewardsService: RrsRewardsService) {}

  willDateExpireSoon(date: string): boolean {
    const dateToExpire = Date.now() + 1000 * 60 * 60 * 24 * 30;
    const expireTimestamp = new Date(date).getTime();
    return expireTimestamp < dateToExpire;
  }
}
