import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';
import { RrsPlpPageTitleResolver } from '@app/custom/features/rrs-product-listing/services/rrs-plp-page-title.resolver';
import { RrsProductFacetService } from '@app/custom/features/rrs-product-listing/services/rrs-product-facet-service.service';
import {
  CmsConfig,
  PageMetaResolver,
  provideConfig,
  RoutingConfig,
} from '@spartacus/core';
import { ProductFacetService } from '@spartacus/storefront';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    provideConfig({
      slots: {
        ProductList: ['ProductListComponent'],
      },
    } as CmsComponentsConfig),
    provideConfig({
      routing: {
        routes: {
          search: {
            paths: ['search/:query', 'search', 'cs/:query', 'brands/:query'],
          },
        },
      },
    } as RoutingConfig),
    provideConfig({
      featureModules: {
        ['RRS_PLP_FEATURE']: {
          module: () =>
            import('./rrs-product-listing.module').then(
              (m) => m.RrsProductListingModule
            ),
          cmsComponents: ['ProductListComponent'],
        },
      },
    } as CmsConfig),
    {
      provide: ProductFacetService,
      useClass: RrsProductFacetService,
    },
    {
      provide: PageMetaResolver,
      useExisting: RrsPlpPageTitleResolver,
      multi: true,
    },
    TitleCasePipe,
  ],
})
export class RrsProductListingFeatureModule {}
