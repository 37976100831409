import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';
import { GIFT_CARDS_COMPONENT_ID } from '@app/custom/features/rrs-cms/configs/contentstack.config';
import { RrsGiftCardsBalanceContainerComponent } from '@app/custom/features/rrs-gift-cards-balance/components/rrs-gift-cards-balance-container/rrs-gift-cards-balance-container.component';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { provideCmsStructure } from '@spartacus/storefront';

export const rrsGiftCardsBalanceCmsConfig = [
  provideConfig({
    slots: {
      GiftCardsSlot: ['gift_cards'],
    },
  } as CmsComponentsConfig),
  provideConfig({
    cmsComponents: {
      [GIFT_CARDS_COMPONENT_ID]: {
        component: RrsGiftCardsBalanceContainerComponent,
      },
    },
  } as CmsConfig),
];
