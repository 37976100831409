<section class="cart-list">
  <header
    [ngClass]="{ 'd-none d-lg-flex': options.isSavedCart }"
    class="cart-list__header row fw-bold py-3 text-uppercase">
    <div class="col-6 fs-4 d-none d-lg-block">
      {{ 'cart.product' | cxTranslate }}
    </div>
    <div class="col-3 fs-4 d-none d-lg-block">
      {{ 'cart.fulfillment' | cxTranslate }}
    </div>
    <div class="col-2 fs-4 d-none d-lg-block text-center">
      {{ 'cart.price' | cxTranslate }}
    </div>
    <div class="col-1 fs-4 d-none d-lg-block"></div>
    <div class="col-12 fs-4 d-lg-none">
      <span class="link link-light fw-normal ms-1 text-capitalize">
        {{ 'cart.orderSummary.orderSummary' | cxTranslate }}
      </span>
    </div>
  </header>

  <section class="cart-list__list">
    <ng-container *ngFor="let item of items; let i = index">
      <ng-container
        *ngIf="getControl(item) | async as control"
        [class.is-changed]="control.get('quantity').disabled">
        <rrs-cart-item
          [cartListLength]="items.length"
          [item]="item"
          [options]="options"
          [deliveryAddress]="deliveryAddress"
          [promotionLocation]="promotionLocation"
          [quantityControl]="control.get('quantity')"
          [readonly]="readonly"
          [productPromotions]="
            getPromotions(
              item.entryNumber,
              appliedPromotions,
              item.basePrice?.value
            )
          "></rrs-cart-item>
      </ng-container>
    </ng-container>
  </section>
</section>

<div
  *ngIf="removeInProgress$ | async"
  class="d-flex align-items-center justify-content-center position-fixed top-0 start-0 vh-100 w-100 bg-white z-2000 opacity-75">
  <div class="spinner-border spinner-size" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
