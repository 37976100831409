import { Injectable } from '@angular/core';
import { RrsStoreEntities } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import {
  Converter,
  ConverterService,
  POINT_OF_SERVICE_NORMALIZER,
} from '@spartacus/core';

@Injectable({ providedIn: 'root' })
export class RrsSearchStoresNormalizer
  implements Converter<RrsStoreEntities, RrsStoreEntities>
{
  constructor(private converter: ConverterService) {}

  convert(
    source: RrsStoreEntities,
    target?: RrsStoreEntities
  ): RrsStoreEntities {
    if (target === undefined) {
      target = { ...source };
    }

    if (source.stores) {
      target.stores = source.stores.map((store) =>
        this.converter.convert(store, POINT_OF_SERVICE_NORMALIZER)
      );
    }

    return target;
  }
}
