import { ModalRef, ModalService } from '@app/custom/features/modal';
import { AddToCartComponent } from '@spartacus/cart/base/components/add-to-cart';
import {
  CmsAddToCartComponent,
  EventService,
  isNotUndefined,
  isNotNullable,
} from '@spartacus/core';
import {
  BREAKPOINT,
  BreakpointService,
  CmsComponentData,
  CurrentProductService,
} from '@spartacus/storefront';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { RrsMiniCartModalComponent } from '@app/custom/features/rrs-mini-cart/components/rrs-mini-cart-modal/rrs-mini-cart-modal.component';
import { RrsMiniCartService } from '@app/custom/features/rrs-mini-cart/services/rrs-mini-cart.service';
import { EMPTY, of, race, Subscription } from 'rxjs';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import { RrsStoreFacetService } from '@app/custom/features/rrs-product-listing/services/rrs-store-facet/rrs-store-facet.service';
import { FulfillmentOptions } from '@app/custom/features/rrs-product-details/models/rrs-delivery-options.model';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { AddToCartStatus } from '@app/custom/features/rrs-add-to-cart/models/add-to-cart.model';
import { RrsEventsDispatcherService } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/services/rrs-events.dispatcher';
import {
  CartAddEntrySuccessEvent,
  CartAddEntryFailEvent,
} from '@spartacus/cart/base/root';

// TODO: Replace with a proper `add to cart` implementation
@Component({
  selector: 'rrs-add-to-cart',
  templateUrl: './rrs-add-to-cart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsAddToCartComponent
  extends AddToCartComponent
  implements OnInit, OnDestroy
{
  @Input() addToCartStatus!: AddToCartStatus;
  @Input() deliveryModeName!: FulfillmentOptions;
  @Input() isDonation = false;
  @Input() productDonationCode!: string;

  iconTypeList = ICON_TYPE_LIST;
  isDesktop: boolean = false;
  subscription = new Subscription();

  isCartProcessing$ = this.activeCartService.isCartProcessing$;

  private modalRef: ModalRef | undefined;

  constructor(
    protected currentProductService: CurrentProductService,
    protected cd: ChangeDetectorRef,
    protected activeCartService: RrsActiveCartService,
    protected miniCartService: RrsMiniCartService,
    protected breakpointService: BreakpointService,
    @Optional() protected component: CmsComponentData<CmsAddToCartComponent>,
    eventService: EventService,
    private modalService: ModalService,
    protected storeFacetService: RrsStoreFacetService,
    protected eventsDispatcher: RrsEventsDispatcherService
  ) {
    super(
      currentProductService,
      cd,
      activeCartService,
      component,
      eventService
    );
  }

  addToCart(): void {
    const quantity = 1;

    if (!this.productCode) {
      return;
    }

    this.eventsDispatcher.dispatchDYAddToCartEvent(
      this.product.code ?? this.productCode,
      this.product.price?.value ?? 0
    );

    this.activeCartService
      .getEntries()
      .pipe(
        withLatestFrom(
          this.deliveryModeName === FulfillmentOptions.PICKUP
            ? this.storeFacetService.getSelectedStore('pickupInStore')
            : of(null)
        ),
        take(1),
        tap(([_, store]) => {
          const deliveryOptions = this.isDonation
            ? {}
            : {
                ...(this.deliveryModeName === FulfillmentOptions.PICKUP &&
                store !== null
                  ? {
                      deliveryMode: { code: 'pickup' },
                      deliveryPointOfService: { name: store.name },
                    }
                  : {
                      deliveryMode: { code: 'economy' },
                    }),
              };

          this.activeCartService.addEntries([
            {
              product: {
                code: this.isDonation
                  ? this.productDonationCode
                  : this.productCode,
                baseProduct: this.product?.code,
                price: this.product?.price,
              },
              quantity,
              ...deliveryOptions,
            },
          ]);
        }),
        switchMap(() => {
          return race(
            this.eventService.get(CartAddEntrySuccessEvent),
            this.eventService.get(CartAddEntryFailEvent)
          ).pipe(take(1));
        }),
        switchMap((event) => {
          if (event instanceof CartAddEntrySuccessEvent) {
            return this.activeCartService.getLastEntry(this.productCode).pipe(
              filter(isNotUndefined),
              take(1),
              tap(() => {
                if (this.isDesktop) {
                  this.miniCartService.triggerNotification(
                    'rrs.miniCart.addedToCart'
                  );
                } else {
                  this.openModal();
                }
              })
            );
          }
          return EMPTY;
        })
      )
      .subscribe();
  }

  openModal(): void {
    let modalInstance: any;
    this.modalRef = this.modalService.open(RrsMiniCartModalComponent);
    modalInstance = this.modalRef.componentInstance;
    modalInstance.entry$ = this.activeCartService.getLastEntry(
      this.productCode
    );
  }

  ngOnInit(): void {
    if (this.product) {
      if (!this.productCode) {
        this.productCode = this.product.code ?? '';
      }
      this.setStockInfo(this.product);
      this.cd.markForCheck();
    } else if (this.productCode) {
      // force hasStock and quantity for the time being, as we do not have more info:
      this.quantity = 1;
      this.hasStock = true;
      this.cd.markForCheck();
    } else {
      this.subscription.add(
        (this.productListItemContext
          ? this.productListItemContext.product$
          : this.currentProductService.getProduct()
        )
          .pipe(filter(isNotNullable))
          .subscribe((product) => {
            if (!this.productCode) {
              this.productCode = this.product.code ?? '';
            }
            this.setStockInfo(product);
            this.cd.markForCheck();
          })
      );
    }

    this.subscription.add(
      this.breakpointService
        .isUp(BREAKPOINT.lg)
        .pipe(
          tap((bool) => {
            this.isDesktop = bool;
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }
}
