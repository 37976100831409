import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterModule } from '@angular/router';
import { RrsAuthGuard } from '@app/custom/features/rrs-auth/services/rrs-auth.guard';
import { RrsGlobalMessageModule } from '@app/custom/features/rrs-global-message';
import { RrsIconModule } from '@app/shared/components';
import { RrsFormErrorsModule } from '@app/shared/components/rrs-form-errors/rrs-form-errors.module';
import { RrsInputModule } from '@app/shared/components/rrs-input';
import { RrsLoaderModule } from '@app/shared/components/rrs-loader/rrs-loader.module';
import { RrsSharedDirectivesModule } from '@app/shared/directives';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  AuthGuard,
  AuthHttpHeaderService,
  AuthModule,
  CmsConfig,
  I18nModule,
  provideConfig,
  provideDefaultConfig,
  UrlModule,
} from '@spartacus/core';
import {
  DIALOG_TYPE,
  FormErrorsModule,
  KeyboardFocusModule,
  LogoutModule,
  PageSlotModule,
} from '@spartacus/storefront';
import '@spartacus/storefront';
import { RrsAuthComponent } from './rrs-auth/rrs-auth.component';
import { RrsAuthPageComponent } from './rrs-auth-page/rrs-auth-page.component';
import { RrsSharedPipesModule } from '@app/shared/pipes';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { RrsUserPasswordService } from '@app/custom/features/rrs-auth/services/rrs-user-password.service';
import { rrsOccUserProfileConfig } from '@app/custom/features/rrs-auth/rrs-auth-occ.config';
import { RrsAuthHttpHeaderService } from '@app/custom/features/rrs-auth/services/rrs-auth-http-header.service';

@NgModule({
  declarations: [RrsAuthComponent, RrsAuthPageComponent],
  imports: [
    AuthModule.forRoot(),
    CommonModule,
    FormErrorsModule,
    FormsModule,
    I18nModule,
    LogoutModule,
    NgSelectModule,
    PageSlotModule,
    ReactiveFormsModule,
    RouterLink,
    RouterModule,
    RrsFormErrorsModule,
    RrsGlobalMessageModule,
    RrsIconModule,
    RrsInputModule,
    RrsLoaderModule,
    RrsSharedDirectivesModule,
    PageSlotModule,
    RrsSharedPipesModule,
    UrlModule,
    KeyboardFocusModule,
  ],
  providers: [
    {
      provide: AuthGuard,
      useClass: RrsAuthGuard,
    },
    {
      provide: UserPasswordFacade,
      useExisting: RrsUserPasswordService,
    },
    {
      provide: AuthHttpHeaderService,
      useExisting: RrsAuthHttpHeaderService,
    },
    provideConfig(rrsOccUserProfileConfig),
    provideDefaultConfig({
      launch: {
        LOGIN: {
          inlineRoot: true,
          component: RrsAuthComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
      },
    }),
    provideConfig({
      slots: {
        LoginSlotI: ['AuthPageComponent'],
      },
    }),
    provideConfig({
      cmsComponents: {
        AuthPageComponent: {
          component: RrsAuthPageComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class RrsAuthModule {}

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    LOGIN = 'LOGIN',
  }
}
