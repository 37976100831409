import { PdpAvailability } from '@app/custom/features/rrs-product-details/models/rrs-product.model';

export function isInStock<T extends { pdpAvailability?: PdpAvailability }>(
  entity?: T
): boolean {
  if (!entity || !entity.pdpAvailability) {
    return false;
  }

  return (
    entity.pdpAvailability.hasShippingAvailability ||
    entity.pdpAvailability.hasPickupAvailability
  );
}
