import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthService, CmsService, RoutingService } from '@spartacus/core';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { milesToMeters } from '@app/custom/features/rrs-product-listing/utils/rrs-facet.utils';
import { RrsDefaultStoreService } from '@app/custom/features/rrs-account/services/rrs-default-store.service';
import { RrsPointOfService } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { RrsStoreFinderService } from '@app/custom/features/rrs-storefinder/services/rrs-store-finder.service';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';

@AutoUnsubscribe()
@Component({
  selector: 'rrs-store-details',
  templateUrl: './rrs-store-details.component.html',
  styleUrls: ['./rrs-store-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsStoreDetailsComponent implements OnInit {
  protected subscriptions = new Subscription();

  store$!: Observable<RrsPointOfService>;
  nearbyStores$!: Observable<RrsPointOfService[]>;
  isLoading$!: Observable<boolean>;
  isLoggedIn$!: Observable<boolean>;
  defaultStoreId$!: Observable<string | null>;
  displayOBBigLogo$!: Observable<boolean>;
  isDesktop$ = this.breakpointService.isUp(BREAKPOINT.md);
  seoHeading$ = this.cmsService
    .getCurrentPage()
    .pipe(map((page) => page?.heading));

  iconTypeList = ICON_TYPE_LIST;

  constructor(
    private storeFinderService: RrsStoreFinderService,
    private route: ActivatedRoute,
    protected authService: AuthService,
    protected defaultStoreService: RrsDefaultStoreService,
    protected breakpointService: BreakpointService,
    protected cmsService: CmsService,
    protected routingService: RoutingService
  ) {}

  ngOnInit(): void {
    this.storeFinderService.clearStoresData();
    this.requestStoresData();
    this.initStore();
    this.isLoggedIn$ = this.authService.isUserLoggedIn();
    this.isLoading$ = this.storeFinderService.getStoresLoading();
    this.defaultStoreId$ = this.defaultStoreService.getDefaultStoreId();
    this.subscriptions.add(
      this.storeFinderService
        .getStoresError()
        .pipe(filter(Boolean))
        .subscribe(() => {
          this.routingService.go(
            { cxRoute: 'notFound' },
            { skipLocationChange: true, state: { preserveGlobalMessage: true } }
          );
        })
    );
  }

  requestStoresData(): void {
    this.subscriptions.add(
      this.route.params.subscribe((params) => {
        this.storeFinderService.viewStoreById(params.storeId);
      })
    );
  }

  private initStore(): void {
    this.store$ = this.storeFinderService.getFindStoreById().pipe(
      tap((store) => {
        this.storeFinderService.findStoresAction(
          '',
          {
            pageSize: 5,
            currentPage: 0,
          },
          store.geoPoint,
          undefined,
          undefined,
          milesToMeters(100)
        );
      })
    );
    this.nearbyStores$ = this.storeFinderService.getFindStoresEntities().pipe(
      withLatestFrom(this.storeFinderService.getFindStoreById()),
      map(
        ([storeEntities, currentStore]) =>
          storeEntities.stores?.filter(
            (store) => store.name !== currentStore.name
          ) ?? []
      )
    );
    this.displayOBBigLogo$ = combineLatest([
      this.store$,
      this.breakpointService.isUp(BREAKPOINT.lg),
    ]).pipe(map(([store, isUpLg]) => isUpLg && !!store.isOffBroadway));
  }

  setAsDefaultStore(storeId: string, e: Event): void {
    if ((e.target as HTMLInputElement).checked) {
      this.defaultStoreService.setDefaultStoreId(storeId);
    } else {
      this.defaultStoreService.removeDefaultStore(storeId);
    }
  }
}
