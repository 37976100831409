<div class="modal-header py-3">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'rrs.savedPayments.deletePayment' | cxTranslate }}
  </h4>
  <button
    (click)="activeModal.dismiss('Cross click')"
    [attr.aria-label]="'rrs.common.closeModal' | cxTranslate"
    class="btn-close"
    cxModal="dismiss"
    cxModalReason="Cross click"
    type="button"></button>
</div>
<div class="modal-body">
  <div *ngIf="payment">
    <h3 *ngIf="payment.tenderType !== paymentTypes.PAYPAL" class="mb-3">
      {{ payment?.tenderType }}, {{ payment?.cardNumber }},
      {{ payment?.expiryMonth }}/{{ payment?.expiryYear }}
    </h3>
    <h3 *ngIf="payment.tenderType === paymentTypes.PAYPAL" class="mb-3">
      {{ paymentTranslations[payment.tenderType] | cxTranslate }},
      <span class="text-lowercase">{{ payment.email }}</span>
    </h3>
  </div>
  <p class="mb-5">
    {{ 'rrs.savedPayments.deletePaymentConfirmation' | cxTranslate }}
  </p>
  <div class="d-flex gap-3">
    <button
      (click)="activeModal.dismiss('Cross click')"
      class="btn w-100 btn-secondary">
      {{ 'rrs.common.no' | cxTranslate }}
    </button>

    <button
      class="btn btn-primary"
      data-cy="confirm"
      (click)="removePayment(payment)">
      {{ 'rrs.common.yes' | cxTranslate }}
    </button>
  </div>
</div>
