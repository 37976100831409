import { RrsPointOfService } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { SearchState } from '@spartacus/core';

export interface RrsPDPFacets {
  sizes?: string;
  width?: string;
}

export enum RrsWidthFacetCodes {
  Medium = 'M',
}

export interface RrsFacetValue {
  name?: string;
  count?: number;
  selected?: boolean;
  query?: SearchState;
}

export interface RrsSizeFacetGroup {
  name?: string;
  values?: RrsFacetValue[];
  subgroups?: RrsSizeFacetGroup[];
}

export interface RrsFacet {
  name?: string;
  values?: Array<RrsFacetValue | RrsSizeFacetGroup>;
  multiSelect?: boolean;
  order?: number;
}

export interface RrsStoreFacetList {
  page: number;
  pageSize: number;
  to: number;
  from: number;
  total: number;
  stores: RrsPointOfService[];
}

export type RrsActiveFacets = Record<string, string[]>;

export enum FacetCodes {
  SIZE = 'size',
  SIZE_BOY_BABY = 'sizeBoyBaby',
  SIZE_BOY_BIG_KID = 'sizeBoyBigKid',
  SIZE_BOY_LITTLE_KID = 'sizeBoyLittleKid',
  SIZE_BOY_TODDLER = 'sizeBoyToddler',
  SIZE_GIRL_BABY = 'sizeGirlBaby',
  SIZE_GIRL_BIG_KID = 'sizeGirlBigKid',
  SIZE_GIRL_LITTLE_KID = 'sizeGirlLittleKid',
  SIZE_GIRL_TODDLER = 'sizeGirlToddler',
  SIZE_MENS = 'sizeMens',
  SIZE_WOMENS = 'sizeWomens',
  RATING = 'rating',
  STYLE = 'style',
  BRAND = 'brand',
  MATERIAL = 'material',
  FEATURE = 'feature',
  CATEGORY = 'category',
  CATEGORY_ID = 'categoryId',
  COLOR_FAMILIES = 'colorFamilies',
  DELIVERABLE = 'deliverable',
  EXPEDITABLE = 'expeditable',
  FEATURE_ID = 'featureId',
  GENDER = 'gender',
  GENDER_ID = 'genderId',
  HEEL_HEIGHT_RANGE = 'heelHeightRange',
  MATERIALS = 'materials',
  PRICE = 'price',
  PRICE_RANGE = 'priceRange',
  STYLE_ID = 'styleId',
  WIDE_CALF = 'wideCalf',
  WIDTH = 'width',
  STORE_ID = '_geoloc.storeId',
  BRAND_ID = 'brandId',
  CATEGORY_PAGE_ID = 'categoryPageId',
}

export const FacetsToDisplay = [
  FacetCodes.SIZE_BOY_BABY,
  FacetCodes.SIZE_BOY_BIG_KID,
  FacetCodes.SIZE_BOY_LITTLE_KID,
  FacetCodes.SIZE_BOY_TODDLER,
  FacetCodes.SIZE_GIRL_BABY,
  FacetCodes.SIZE_GIRL_BIG_KID,
  FacetCodes.SIZE_GIRL_LITTLE_KID,
  FacetCodes.SIZE_GIRL_TODDLER,
  FacetCodes.SIZE_MENS,
  FacetCodes.SIZE_WOMENS,
  FacetCodes.RATING,
  FacetCodes.STYLE,
  FacetCodes.BRAND,
  FacetCodes.MATERIAL,
  FacetCodes.FEATURE,
  FacetCodes.CATEGORY,
  FacetCodes.COLOR_FAMILIES,
  FacetCodes.GENDER,
  FacetCodes.HEEL_HEIGHT_RANGE,
  FacetCodes.MATERIALS,
  FacetCodes.PRICE_RANGE,
  FacetCodes.WIDTH,
];

export const ScrollableFacets = [
  FacetCodes.STYLE,
  FacetCodes.BRAND,
  FacetCodes.MATERIAL,
  FacetCodes.FEATURE,
];

export const SizeFacets = [
  FacetCodes.SIZE,
  FacetCodes.SIZE_BOY_BABY,
  FacetCodes.SIZE_BOY_BIG_KID,
  FacetCodes.SIZE_BOY_LITTLE_KID,
  FacetCodes.SIZE_BOY_TODDLER,
  FacetCodes.SIZE_GIRL_BABY,
  FacetCodes.SIZE_GIRL_BIG_KID,
  FacetCodes.SIZE_GIRL_LITTLE_KID,
  FacetCodes.SIZE_GIRL_TODDLER,
  FacetCodes.SIZE_MENS,
  FacetCodes.SIZE_WOMENS,
];

export const LittleKidSizeOrder = [
  '10.5',
  '11',
  '11.5',
  '12',
  '12.5',
  '13',
  '13.5',
  '1',
  '1.5',
  '2',
  '2.5',
  '3',
];

export const GirlsSizeFacets = [
  FacetCodes.SIZE_GIRL_BABY,
  FacetCodes.SIZE_GIRL_TODDLER,
  FacetCodes.SIZE_GIRL_LITTLE_KID,
  FacetCodes.SIZE_GIRL_BIG_KID,
];

export const BoysSizeFacets = [
  FacetCodes.SIZE_BOY_BABY,
  FacetCodes.SIZE_BOY_TODDLER,
  FacetCodes.SIZE_BOY_LITTLE_KID,
  FacetCodes.SIZE_BOY_BIG_KID,
];

export const NecessarySearchFacets = [
  FacetCodes.BRAND,
  FacetCodes.COLOR_FAMILIES,
  FacetCodes.STYLE,
  FacetCodes.FEATURE,
  FacetCodes.MATERIAL,
  FacetCodes.MATERIALS,
];

export const SingleSelectFacets = [
  FacetCodes.GENDER,
  FacetCodes.GENDER_ID,
  FacetCodes.CATEGORY,
  FacetCodes.CATEGORY_ID,
  FacetCodes.STYLE,
  FacetCodes.STYLE_ID,
  FacetCodes.FEATURE,
  FacetCodes.FEATURE_ID,
];

export const HiddenSearchBreadcrumbFacets = [FacetCodes.STORE_ID];

export const HiddenCategoryBreadcrumbFacets = [FacetCodes.STORE_ID];

export const STORE_FACET_SESSION_STORAGE_KEY = 'storeFacet.selectedStore';

export const SEARCHABLE_FACET_LENGTH = 10;

// Miles
export const MAX_STORE_DISTANCE = 100;
export const STORES_PAGE_SIZE = 4;
