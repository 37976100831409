import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CmsService } from '@spartacus/core';
import { AutoUnsubscribe } from '@app/shared/decorators';

@AutoUnsubscribe()
@Directive({
  selector: '[rrsCmsHeading]',
})
export class RrsCmsHeadingDirective implements OnInit {
  protected subscriptions = new Subscription();

  constructor(private cmsService: CmsService, private el: ElementRef) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.cmsService
        .getCurrentPage()
        .pipe(
          map((page) => page?.heading),
          distinctUntilChanged()
        )
        .subscribe((heading) => {
          if (heading) {
            this.el.nativeElement.innerHTML = heading;
          }
        })
    );
  }
}
