import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { RrsPointOfService } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';

interface StoreOpening {
  formattedDate: string;
  formattedOpeningHours: string;
  isSpecialHours: boolean;
  isOpened: boolean;
}

@Pipe({
  name: 'storeOpeningSchedule',
})
export class StoreOpeningSchedulePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(store: RrsPointOfService): StoreOpening[] {
    const openingDays: StoreOpening[] = [];

    const openingSchedule = store.openingHours;
    if (!openingSchedule) {
      return openingDays;
    }

    const specialOpeningDays = openingSchedule.specialDayOpeningList ?? [];
    const weekDayOpeningDays = openingSchedule.weekDayOpeningList ?? [];

    for (let i = 0; i < 7; i++) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      const currentWeekDay = this.datePipe.transform(date, 'EEE');
      const formattedDate = this.datePipe.transform(date, 'EEE - MM/dd') ?? '';

      let isOpened = false;
      let isSpecialHours = false;
      let formattedOpeningHours = '';
      const specialOpeningDay = specialOpeningDays.find((d) => {
        return d.formattedDate === this.datePipe.transform(date, 'M/d/yy');
      });

      const daySchedule =
        specialOpeningDay ??
        weekDayOpeningDays.find((d) => d.weekDay === currentWeekDay);
      isSpecialHours = !!specialOpeningDay;
      isOpened = !daySchedule?.closed;
      formattedOpeningHours = `${daySchedule?.openingTime?.formattedHour} - ${daySchedule?.closingTime?.formattedHour}`;

      openingDays.push({
        formattedDate,
        formattedOpeningHours,
        isSpecialHours,
        isOpened,
      });
    }

    return openingDays;
  }
}
