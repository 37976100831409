import { PageMetaConfig } from '@spartacus/core';

export const rrsPageMetaConfig: PageMetaConfig = {
  pageMeta: {
    resolvers: [
      {
        property: 'title',
        method: 'resolveTitle',
      },
      {
        property: 'heading',
        method: 'resolveHeading',
      },
      {
        property: 'description',
        method: 'resolveDescription',
        disabledInCsr: false,
      },
      {
        property: 'robots',
        method: 'resolveRobots',
        disabledInCsr: false,
      },
      {
        property: 'canonicalUrl',
        method: 'resolveCanonicalUrl',
        disabledInCsr: false,
      },
      {
        property: 'image',
        method: 'resolveImage',
        disabledInCsr: false,
      },
    ],
    canonicalUrl: {
      forceHttps: true,
      forceWww: true,
      removeQueryParams: true,
      forceTrailingSlash: false,
    },
    enableInDevMode: true,
  },
};
