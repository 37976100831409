import {
  CART_QUICK_ORDER_FEATURE,
  QuickOrderRootModule,
} from '@spartacus/cart/quick-order/root';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import {
  quickOrderTranslationChunksConfig,
  quickOrderTranslations,
} from '@spartacus/cart/quick-order/assets';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [QuickOrderRootModule],
  providers: [
    provideConfig({
      featureModules: {
        [CART_QUICK_ORDER_FEATURE]: {
          module: () =>
            import('@spartacus/cart/quick-order').then(
              (m) => m.QuickOrderModule
            ),
        },
      },
    } as CmsConfig),
    provideConfig({
      i18n: {
        resources: quickOrderTranslations,
        chunks: quickOrderTranslationChunksConfig,
      },
    } as I18nConfig),
  ],
})
export class CartQuickOrderFeatureModule {}
