import {
  BREAKPOINT,
  BreakpointService,
  CmsComponentData,
} from '@spartacus/storefront';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { RrsCmsHeaderUiService } from '@app/custom/features/rrs-cms/components/rrs-cms-header/services/rrs-cms-header-ui.service';
import { RrsMiniCartService } from '@app/custom/features/rrs-mini-cart/services/rrs-mini-cart.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cms-header',
  styleUrls: ['./rrs-cms-header.component.scss'],
  templateUrl: './rrs-cms-header.component.html',
})
export class RrsCmsHeaderComponent {
  data$ = this.componentData.data$;
  isDesktop$ = this.breakpointService.isUp(BREAKPOINT.xl);

  constructor(
    protected breakpointService: BreakpointService,
    protected componentData: CmsComponentData<Contentstack.Header>,
    public headerUi: RrsCmsHeaderUiService,
    public miniCart: RrsMiniCartService
  ) {}

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (window.scrollY < 1) {
      this.headerUi.isBannerShow.next(true);
    } else {
      this.headerUi.isBannerShow.next(false);
    }
  }
}
