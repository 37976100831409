import { StateUtils } from '@spartacus/core';
import { STORE_FINDER_DATA } from '@spartacus/storefinder/core';

export const FIND_STORES_RESET = '[StoreFinder] Find Stores Reset';

export class FindStoresReset extends StateUtils.LoaderResetAction {
  readonly type = FIND_STORES_RESET;

  constructor() {
    super(STORE_FINDER_DATA);
  }
}
