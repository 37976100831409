<ng-container *ngIf="productGallery$ | async as productGallery">
  <section class="product-details-zoom container-fluid g-0">
    <div class="row">
      <div class="col">
        <div class="modal-header d-flex justify-content-end py-0">
          <button
            (click)="closeModal()"
            aria-label="Close"
            class="btn-close m-4"
            type="button"></button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <swiper [config]="config" [initialSlide]="activeSlide">
          <ng-template swiperSlide *ngFor="let item of productGallery">
            <rrs-media
              [container]="item.container | rrsProductImages: scene7Preset"
              width="1296"
              height="787"
              format="product"
              tabindex="0">
            </rrs-media>
          </ng-template>
        </swiper>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <header class="product-details-zoom__header bg-stripe p-4">
          <div class="container">
            <ng-container *ngIf="product$ | async as product">
              <h1 *ngIf="product && product.name" class="fw-normal fs-2">
                {{ product.name }}
              </h1>

              <p class="text-success fw-bold fs-3 mb-0">
                {{ product.price?.formattedValue }}

                <span
                  *ngIf="product.price?.comparePrice"
                  class="fs-6 fw-normal text-light text-decoration-line-through ms-4">
                  ${{ product.price?.comparePrice }}</span
                >
              </p>
            </ng-container>
          </div>
        </header>
      </div>
    </div>
  </section>
</ng-container>
