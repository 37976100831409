<ng-container *ngIf="storeLocation$ | async as store">
  <div *ngIf="store.name?.length" class="store-details-container py-3">
    <h6 class="my-0">{{ store.name }}</h6>
    <div>
      <pre class="my-0 store-address">{{ store.address }}</pre>
      <span class="store-details__phone"
        >{{ store.phone | rrsPhoneNumber }}
      </span>
    </div>
    <a
      *ngIf="store.store_id"
      class="link-primary"
      [routerLink]="
        { cxRoute: 'storeDetails', params: { storeId: store.store_id } } | cxUrl
      "
      >{{ 'rrs.storeFinder.seeStoreDetails' | cxTranslate }}</a
    >
  </div>
</ng-container>
