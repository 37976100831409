import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';

@Component({
  selector: 'rrs-cms-header-mega-menu',
  templateUrl: './rrs-cms-header-mega-menu.component.html',
  styleUrls: ['./rrs-cms-header-mega-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsCmsHeaderMegaMenuComponent {
  @Input() banner?: Contentstack.HeaderMegaMenuBanner;
  @Input() menu!: Contentstack.SingleLevelNav[];

  titleViewAll = 'VIEW ALL';
}
