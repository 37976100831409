import { Action, select, Store } from '@ngrx/store';
import { catchError, take } from 'rxjs/operators';
import {
  GeoPoint,
  GlobalMessageService,
  RoutingService,
  SearchConfig,
  WindowRef,
} from '@spartacus/core';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  RrsPointOfService,
  RrsStoreEntities,
} from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import {
  StateWithStoreFinder,
  StoreFinderActions,
  StoreFinderService,
} from '@spartacus/storefinder/core';
import { RrsStoreFinderConnector } from '@app/custom/features/rrs-storefinder/occ/rrs-store-finder.connector';
import {
  RrsStoreFinderActions,
  RrsStoreFinderSelectors,
} from '@app/custom/features/rrs-storefinder/store';

@Injectable({
  providedIn: 'root',
})
export class RrsStoreFinderService extends StoreFinderService {
  geoPointFromBrowserAPI: GeoPoint | null = null;
  private geolocationSub$ = new BehaviorSubject<GeoPoint | null>(null);
  private geolocationNotAvailable$ = new BehaviorSubject<boolean>(false);

  constructor(
    store: Store<StateWithStoreFinder>,
    winRef: WindowRef,
    globalMessageService: GlobalMessageService,
    routingService: RoutingService,
    @Inject(PLATFORM_ID) platformId: any,
    protected storeFinderConnector: RrsStoreFinderConnector
  ) {
    super(store, winRef, globalMessageService, routingService, platformId);
  }

  protected geolocWatchId: number | null = null;

  override getFindStoresEntities(): Observable<RrsStoreEntities> {
    return super.getFindStoresEntities() as Observable<RrsStoreEntities>;
  }

  override viewStoreById(storeId: string): void {
    this.clearWatchGeoloc(
      new StoreFinderActions.FindStoreById({
        storeId,
      })
    );
  }

  override findStoresAction(
    queryText: string,
    searchConfig?: SearchConfig,
    longitudeLatitude?: GeoPoint,
    countryIsoCode?: string,
    useMyLocation?: boolean,
    radius?: number,
    onGeolocationError?: () => void
  ): void {
    if (useMyLocation && this.winRef.nativeWindow) {
      if (this.geoPointFromBrowserAPI !== null) {
        this.clearWatchGeoloc(
          this.findStoresActionFactory(
            queryText,
            searchConfig,
            this.geoPointFromBrowserAPI,
            countryIsoCode,
            radius
          )
        );
      } else {
        this.geolocWatchId =
          this.winRef.nativeWindow.navigator.geolocation.watchPosition(
            (pos: GeolocationPosition) => {
              const position: GeoPoint = {
                longitude: pos.coords.longitude,
                latitude: pos.coords.latitude,
              };
              this.geoPointFromBrowserAPI = position;
              this.geolocationNotAvailable$.next(false);
              this.clearWatchGeoloc(
                this.findStoresActionFactory(
                  queryText,
                  searchConfig,
                  position,
                  countryIsoCode,
                  radius
                )
              );
            },
            () => {
              this.storeFinderConnector
                .getGeolocationFromIp()
                .pipe(
                  take(1),
                  catchError(() => {
                    onGeolocationError?.();
                    return of(null);
                  })
                )
                .subscribe((geopoint) => {
                  this.geolocationNotAvailable$.next(!geopoint);
                  if (geopoint === null) {
                    return;
                  }
                  this.clearWatchGeoloc(
                    this.findStoresActionFactory(
                      queryText,
                      searchConfig,
                      geopoint,
                      countryIsoCode,
                      radius
                    )
                  );
                });
            }
          );
      }
    } else {
      this.clearWatchGeoloc(
        this.findStoresActionFactory(
          queryText,
          searchConfig,
          longitudeLatitude,
          countryIsoCode,
          radius
        )
      );
    }
  }

  protected findStoresActionFactory(
    queryText: string,
    searchConfig?: SearchConfig,
    longitudeLatitude?: GeoPoint,
    countryIsoCode?: string,
    radius?: number
  ): StoreFinderActions.FindStores {
    return new StoreFinderActions.FindStores({
      queryText,
      searchConfig,
      longitudeLatitude,
      countryIsoCode,
      radius,
    });
  }

  override viewAllStores(): void {
    this.clearWatchGeoloc(new StoreFinderActions.ViewAllStores());
  }

  protected clearWatchGeoloc(callbackAction: Action): void {
    if (this.geolocWatchId !== null) {
      this.winRef.nativeWindow?.navigator.geolocation.clearWatch(
        this.geolocWatchId
      );
      this.geolocWatchId = null;
    }
    this.store.dispatch(callbackAction);
  }

  get geolocationNotAvailable(): Observable<boolean> {
    return this.geolocationNotAvailable$.asObservable();
  }

  clearStoresData(): void {
    this.store.dispatch(new RrsStoreFinderActions.FindStoresReset());
  }

  getFindStoreById(): Observable<RrsPointOfService> {
    return this.store.pipe(
      select(RrsStoreFinderSelectors.getFindStoreByIdEntity)
    );
  }

  getStoresError(): Observable<boolean> {
    return this.store.pipe(select(RrsStoreFinderSelectors.getStoresError));
  }

  getGeolocation(): Observable<GeoPoint | null> {
    if (this.geolocationSub$.getValue() === null) {
      this.winRef.nativeWindow?.navigator.geolocation.getCurrentPosition(
        (pos: GeolocationPosition) => {
          this.geoPointFromBrowserAPI = {
            longitude: pos.coords.longitude,
            latitude: pos.coords.latitude,
          };
          this.geolocationSub$.next(this.geoPointFromBrowserAPI);
        },
        () => {
          this.storeFinderConnector
            .getGeolocationFromIp()
            .pipe(
              take(1),
              catchError(() => {
                return of(null);
              })
            )
            .subscribe((geo) => {
              this.geolocationSub$.next(geo);
            });
        }
      );
    }

    return this.geolocationSub$.asObservable();
  }
}
