import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Cart } from '@spartacus/cart/base/root';
import { Observable, Subscription } from 'rxjs';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { RrsCmsHeaderUiService } from '@app/custom/features/rrs-cms/components/rrs-cms-header/services/rrs-cms-header-ui.service';
import { RrsMiniCartService } from '@app/custom/features/rrs-mini-cart/services/rrs-mini-cart.service';
import { tap } from 'rxjs/operators';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';

@Component({
  selector: 'rrs-mini-cart',
  templateUrl: './rrs-mini-cart.component.html',
  styleUrls: ['./rrs-mini-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsMiniCartComponent implements OnInit, OnDestroy {
  subscription!: Subscription;
  isCollapsed: boolean = true;
  miniCartTitle: string = '';
  icon = ICON_TYPE_LIST;

  constructor(
    public miniCartService: RrsMiniCartService,
    private activeCartService: RrsActiveCartService,
    public headerUi: RrsCmsHeaderUiService,
    private ref: ChangeDetectorRef
  ) {}

  activeCart$: Observable<Cart> = this.activeCartService.getActive();

  ngOnInit(): void {
    this.subscription = this.miniCartService.miniCart$
      .pipe(
        tap((miniCart) => {
          this.isCollapsed = miniCart.collapsed;
          this.miniCartTitle = miniCart.miniCartTitle;
          this.ref.detectChanges();
        })
      )
      .subscribe();
  }

  close(): void {
    this.miniCartService.close();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
