import { APP_INITIALIZER } from '@angular/core';
import { RrsDynatraceService } from '@app/custom/features/rrs-dynatrace/rrs-dynatrace.service';
import { scriptsCookieGroups } from '@app/custom/features/rrs-onetrust/rrs-onetrust.config';
import { RrsOnetrustService } from '@app/custom/features/rrs-onetrust/rrs-onetrust.service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';

export const RrsDynatraceInitializer = {
  provide: APP_INITIALIZER,
  useFactory: appInitializer,
  deps: [RrsDynatraceService, RrsOnetrustService],
  multi: true,
};

export function appInitializer(
  rrsDynatraceService: RrsDynatraceService,
  rrsOnetrustService: RrsOnetrustService
): Function {
  return (): Subscription =>
    rrsOnetrustService
      .checkConsents(scriptsCookieGroups.dynatrace)
      .subscribe((hasConsent) => {
        if (!hasConsent || !environment.dynatrace?.oneAgentScriptUrl) return;
        rrsDynatraceService.initDynatrace();
      });
}
