<ng-container *ngIf="brands$ | async as brands">
  <section class="brands">
    <header class="p-4">
      <h1 class="fs-6 fw-normal text-capitalize text-center">
        {{ 'rrs.brands.header' | cxTranslate }}
      </h1>

      <ul
        class="list-none d-flex align-items-center justify-content-center flex-wrap">
        <li *ngFor="let b1 of brands">
          <ul
            class="list-none d-flex align-items-center justify-content-center">
            <li *ngFor="let letter of b1.letters" class="mx-2">
              <button
                (click)="scroller.scrollToAnchor(letter)"
                class="fs-4 text-uppercase">
                {{ letter }}
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </header>

    <ul class="list-none">
      <li
        *ngFor="let b1 of brands"
        class="row g-0 brands__l1-item px-2 px-lg-4 py-4">
        <div class="col-2 fs-3">
          <ul class="list-none row">
            <li
              *ngFor="let letter of b1.letters"
              [id]="letter"
              class="col-12 col-lg-2 text-uppercase">
              {{ letter }}
            </li>
          </ul>
        </div>
        <div class="col-10">
          <ul class="list-none row brands__l2 text-uppercase">
            <li
              *ngFor="let brand of b1.values"
              class="col-12 col-md-6 col-lg-3">
              <a
                class="cart-item__imgs mb-2"
                [routerLink]="['/cs', brand.slug | rrsStartWithHash]">
                {{ brand.name | rrsStartWithHash }} ({{ brand.counts }})
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </section>
</ng-container>
