import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RrsAddToWishlistComponent } from './components/rrs-add-to-wishlist/rrs-add-to-wishlist.component';
import { RrsAddToWishlistModalComponent } from './components/rrs-add-to-wishlist-modal/rrs-add-to-wishlist-modal.component';
import { I18nModule } from '@spartacus/core';
import { RrsIconModule } from '@app/shared/components';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@spartacus/storefront';
import { RrsFormErrorsModule } from '@app/shared/components/rrs-form-errors/rrs-form-errors.module';
import { ModalModule } from '@app/custom/features/modal';
import { RrsSharedPipesModule } from '@app/shared/pipes';

@NgModule({
  declarations: [RrsAddToWishlistComponent, RrsAddToWishlistModalComponent],
  imports: [
    CommonModule,
    I18nModule,
    RrsIconModule,
    NgSelectModule,
    ReactiveFormsModule,
    IconModule,
    RrsFormErrorsModule,
    ModalModule,
    RrsSharedPipesModule,
  ],
  exports: [RrsAddToWishlistComponent, RrsAddToWishlistModalComponent],
})
export class RrsAddToWishlistModule {}
