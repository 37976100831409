import { SortModel } from '@spartacus/core/src/model/misc.model';

export const sortOptions: SortModel[] = [
  {
    name: 'Relevance',
    code: 'relevance',
  },
  {
    name: 'New Arrivals',
    code: 'new_arrivals',
  },
  {
    name: 'Top Rated',
    code: 'top_rated',
  },
  {
    name: 'Brand A-Z',
    code: 'brand_asc',
  },
  {
    name: 'Brand Z-A',
    code: 'brand_desc',
  },
  {
    name: 'Price Low to High',
    code: 'price_asc',
  },
  {
    name: 'Price High to Low',
    code: 'price_desc',
  },
];
