import { CmsConfig, provideConfig } from '@spartacus/core';
import { BRANDS_TABS_COMPONENT_ID } from '@app/custom/features/rrs-cms/configs/contentstack.config';
import { RrsBrandsListComponent } from '@app/custom/features/rrs-brands/components/rrs-brands-list/rrs-brands-list.component';
import { RrsBrandsTabsComponent } from '@app/custom/features/rrs-brands/components/rrs-brands-tabs/rrs-brands-tabs.component';
import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';

export const rrsBrandsCmsConfig = [
  provideConfig({
    slots: {
      BrandsTabs: ['brands_tabs'],
      BrandsList: ['BrandsListComponent'],
    },
  } as CmsComponentsConfig),
  provideConfig({
    cmsComponents: {
      [BRANDS_TABS_COMPONENT_ID]: {
        component: RrsBrandsTabsComponent,
      },
      BrandsListComponent: {
        component: RrsBrandsListComponent,
      },
    },
  } as CmsConfig),
];
