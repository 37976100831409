import { isPlatformServer } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response as Response_2 } from 'express';

@Component({
  selector: 'rrs-cms-page-not-found-component',
  templateUrl: './rrs-cms-page-not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsCmsPageNotFoundComponent implements OnInit {
  response?: Response_2;
  constructor(@Inject(PLATFORM_ID) private platformId: string) {
    if (isPlatformServer(this.platformId)) {
      this.response = inject(RESPONSE);
    }
  }

  ngOnInit(): void {
    this.response?.status(404);
  }
}
