import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalService } from '@app/custom/features/modal';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { map } from 'rxjs/operators';
import { RrsPersonalInformationUpdateComponent } from '@app/custom/features/rrs-profile/components/rrs-personal-information-update/rrs-personal-information-update.component';
import { User } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { RrsPersonalGroupsService } from '@app/custom/features/rrs-profile/services/rrs-personal-groups.service';
import { RrsEventsDispatcherService } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/services/rrs-events.dispatcher';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-personal-information',
  templateUrl: './rrs-personal-information.component.html',
})
export class RrsPersonalInformationComponent {
  iconTypeList = ICON_TYPE_LIST;

  user$ = this.userAccountFacade
    .get()
    .pipe(map((user: User | undefined) => user));

  userGroups$ = this.rrsPersonalGroupsService.personalGroups$;

  constructor(
    protected modalService: ModalService,
    protected rrsPersonalGroupsService: RrsPersonalGroupsService,
    protected userAccountFacade: UserAccountFacade,
    protected eventsDispatcher: RrsEventsDispatcherService
  ) {}

  editPersonalInformation(): void {
    this.eventsDispatcher.dispatchEditAccountInfoEvent();
    this.modalService.open(RrsPersonalInformationUpdateComponent);
  }
}
