import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { RrsIconModule } from '@app/shared/components';
import { RrsPaginationComponent } from '@app/custom/features/rrs-pagination/rrs-pagination.component';
import { rrsPaginationConfig } from '@app/custom/features/rrs-pagination/configs/rrs-pagination.config';

@NgModule({
  declarations: [RrsPaginationComponent],
  exports: [RrsPaginationComponent],
  imports: [CommonModule, RouterModule, RrsIconModule],
  providers: [provideConfig(rrsPaginationConfig)],
})
export class RrsPaginationModule {}
