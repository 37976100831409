import { AuthService } from '@spartacus/core';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  MAX_STORE_DISTANCE,
  RrsStoreFacetList,
} from '@app/custom/features/rrs-product-listing/model';
import {
  RrsPointOfService,
  RrsStoreEntities,
  SelectedStoreDialogData,
} from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { UntypedFormControl } from '@angular/forms';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { milesToMeters } from '@app/custom/features/rrs-product-listing/utils/rrs-facet.utils';
import { RrsDefaultStoreService } from '@app/custom/features/rrs-account/services/rrs-default-store.service';
import { RrsStoreFinderService } from '@app/custom/features/rrs-storefinder/services/rrs-store-finder.service';
import { map, pairwise, switchMap, tap } from 'rxjs/operators';
import { RrsOccProductStoreStockAdapter } from '../../../rrs-product-details/occ/rrs-occ-product-stock.adapter';
import { MyStoreServiceScope } from '@app/custom/features/rrs-product-listing/model/rrs-search-stores.model';
import { StockLevelStatus } from '@app/custom/features/rrs-product-details/models/rrs-product.model';
@AutoUnsubscribe()
@Component({
  selector: 'rrs-find-store-dialog',
  templateUrl: './rrs-find-store-dialog.component.html',
  styleUrls: ['./rrs-find-store-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RrsFindStoreDialogComponent implements OnInit {
  private subscriptions = new Subscription();
  private useMyLocation!: boolean;
  public scopes = MyStoreServiceScope;
  @Input() scope?: MyStoreServiceScope = MyStoreServiceScope.STORE_FACET;
  @Input() initialSearchValue!: string;
  @Input() selectedStore!: RrsPointOfService;
  @Input() productCode!: string;
  @Input() initialSetAsMyStore!: boolean;
  @Input() currentStore!: string;

  @Output() storeSelected = new EventEmitter<SelectedStoreDialogData>();
  @Output() cancel = new EventEmitter<string>();

  geolocationError$ = new BehaviorSubject<boolean>(false);
  isLoading$ = new BehaviorSubject<boolean>(false);
  error$!: Observable<string>;
  searchControl!: UntypedFormControl;
  maxStoreDistance = MAX_STORE_DISTANCE;
  currentPage$ = new BehaviorSubject<number>(0);
  storesList$!: Observable<RrsStoreFacetList>;
  isLoggedIn$!: Observable<boolean>;
  setAsMyStoreControl!: UntypedFormControl;
  storesEntities$!: Observable<RrsStoreEntities>;
  stockLevelStatus = StockLevelStatus;
  iconTypeList = ICON_TYPE_LIST;
  useMyLocationClicked = false;
  isNextPageLoading$!: Observable<boolean>;
  isPrevPageLoading$!: Observable<boolean>;

  constructor(
    protected authService: AuthService,
    protected defaultStoreService: RrsDefaultStoreService,
    protected storeFinderService: RrsStoreFinderService,
    protected productStockAdapter: RrsOccProductStoreStockAdapter
  ) {}

  ngOnInit(): void {
    this.storeFinderService.clearStoresData();
    if (!this.initialSearchValue) {
      this.useMyLocation = true;
    }
    this.error$ = combineLatest([
      this.storeFinderService.getStoresError(),
      this.geolocationError$,
    ]).pipe(
      tap(([error, geolocationError]) => {
        if (error || geolocationError) {
          this.isLoading$.next(false);
        }
      }),
      map(([error, geolocationError]) =>
        geolocationError
          ? 'rrs.storeFinder.geolocationNotEnabled'
          : error
          ? 'rrs.storeLocator.searchStoresError'
          : ''
      )
    );
    this.isNextPageLoading$ = this.pageLoadingFactory(
      (prevPage, currPage) => currPage > prevPage
    );
    this.isPrevPageLoading$ = this.pageLoadingFactory(
      (prevPage, currPage) => currPage < prevPage
    );
    this.searchControl = new UntypedFormControl(this.initialSearchValue ?? '');
    this.setAsMyStoreControl = new UntypedFormControl(
      !!this.initialSetAsMyStore
    );
    this.isLoggedIn$ = this.authService.isUserLoggedIn();
    this.initStoresList();
    this.storesEntities$ = this.storeFinderService.getFindStoresEntities().pipe(
      tap(() => this.geolocationError$.next(false)),
      switchMap((entities) => {
        return this.scope === MyStoreServiceScope.PICKUP_IN_STORE &&
          this.productCode &&
          entities?.stores?.length
          ? forkJoin(
              entities.stores?.map((store) => {
                return this.productStockAdapter
                  .getStoreStockLevel(this.productCode!, store.name!)
                  .pipe(map((productStock) => ({ ...store, productStock })));
              })
            ).pipe(map((stores) => ({ ...entities, stores })))
          : of(entities);
      }),
      tap(() => {
        this.isLoading$.next(false);
      })
    );
  }

  searchStores(useMyLocation?: boolean): void {
    this.useMyLocationClicked = !!useMyLocation;
    if (!this.useMyLocationClicked) {
      this.geolocationError$.next(false);
    }
    this.useMyLocation = useMyLocation ?? this.searchControl.value === '';
    if (useMyLocation) {
      this.searchControl.reset();
    }
    this.currentPage$.next(0);
  }

  nextPage(): void {
    this.currentPage$.next(this.currentPage$.getValue() + 1);
  }

  previousPage(): void {
    this.currentPage$.next(this.currentPage$.getValue() - 1);
  }

  isStoreSelected(store: RrsPointOfService): boolean {
    return (
      store.name === this.selectedStore?.name ||
      store.name === this.currentStore
    );
  }

  selectStore(store: RrsPointOfService): void {
    if (store.name !== undefined) {
      this.currentStore = store.name;
    }
    this.selectedStore = store;
  }

  onSubmit(): void {
    if (this.selectedStore) {
      this.storeSelected.emit({
        store: this.selectedStore,
        setAsDefault: this.setAsMyStoreControl.value,
        currentSearchQuery: this.searchControl.value,
      });
      if (this.setAsMyStoreControl.value) {
        this.defaultStoreService.setDefaultStoreId(this.selectedStore.name!);
      }
    } else {
      this.onCancel();
    }
  }

  onCancel(): void {
    this.cancel.emit(this.searchControl.value);
  }

  private findStores(): void {
    this.isLoading$.next(true);
    if (this.searchControl.value || this.useMyLocation) {
      this.storeFinderService.findStoresAction(
        this.searchControl.value,
        {
          pageSize: 4,
          currentPage: this.currentPage$.getValue(),
        },
        undefined,
        undefined,
        this.useMyLocation,
        milesToMeters(100),
        () => {
          this.geolocationError$.next(this.useMyLocationClicked);
        }
      );
    }
  }

  private initStoresList(): void {
    this.subscriptions.add(
      this.currentPage$.subscribe(() => {
        this.findStores();
      })
    );
  }

  private pageLoadingFactory(
    conditionFn: (prevPage: number, currPage: number) => boolean
  ): Observable<boolean> {
    return combineLatest([
      this.currentPage$.pipe(pairwise()),
      this.isLoading$,
    ]).pipe(
      map(([[prevPage, currPage], isLoading]) => {
        return conditionFn(prevPage, currPage) && isLoading;
      })
    );
  }
}
