import { Injectable } from '@angular/core';
import { powerReviewConfig } from '@app/custom/features/rrs-power-reviews/configs/rrs-power-review.config';
import {
  Category,
  ImageGroup,
  Product,
  ScriptLoader,
  WindowRef,
  VariantOption,
} from '@spartacus/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { environment } from '@environments/environment';

@AutoUnsubscribe()
@Injectable({
  providedIn: 'root',
})
export class RrsPowerReviewsService {
  constructor(
    protected winRef: WindowRef,
    protected scriptLoader: ScriptLoader
  ) {
    this.initializePowerReviews();
  }

  subscriptions = new Subscription();

  isPowerReviewsInitialized$ = new BehaviorSubject(false);

  loadPowerReviewForm(product: Product | undefined, path: string): void {
    if (this.winRef.isBrowser() && product) {
      this.subscriptions.add(
        this.isPowerReviewsInitialized$.subscribe((isInitialized) => {
          if (!isInitialized) return;
          window.pwr =
            window.pwr ||
            function (): void {
              (window.pwr.q = window.pwr.q || []).push(arguments);
            };

          window.pwr('render', {
            api_key: environment.powerReviews?.apiKey,
            components: {
              Write: powerReviewConfig.reviewWrite,
            },
            locale: powerReviewConfig.locale,
            merchant_group_id: environment.powerReviews?.merchantGroupId,
            merchant_id: environment.powerReviews?.merchantId,
            page_id: product.code,
            return_url: path,
          });
        })
      );
    }
  }

  loadPowerReviews(product: Product | null, path: string): void {
    if (this.winRef.isBrowser() && product) {
      this.subscriptions.add(
        this.isPowerReviewsInitialized$.subscribe((isInitialized) => {
          if (!isInitialized) return;
          window.pwr =
            window.pwr ||
            function (): void {
              (window.pwr.q = window.pwr.q || []).push(arguments);
            };

          const getReviewUrl = `${window.location.origin}/${powerReviewConfig.writeReviewUrl}?page_id=${product.code}&path_name=${path}`;
          window.pwr('render', {
            api_key: environment.powerReviews?.apiKey,
            components: {
              ReviewDisplay: powerReviewConfig.reviewDisplay,
              ReviewImageSnippet: powerReviewConfig.reviewImageSnippet,
              ReviewSnippet: powerReviewConfig.reviewSnippet,
            },
            locale: powerReviewConfig.locale,
            merchant_group_id: environment.powerReviews?.merchantGroupId,
            merchant_id: environment.powerReviews?.merchantId,
            page_id: product.code,
            review_wrapper_url: getReviewUrl,
            product: {
              name: product.name,
              url: `${this.winRef.document.location.origin}${product?.url}`,
              image_url:
                (product?.images?.GALLERY as Array<ImageGroup>)?.[1]?.zoom
                  ?.url ?? null,
              description: product.description,
              category_name: product.categories
                ? product.categories[0]?.name
                : null,
              brand_name: (product.categories as Category[]).find((category) =>
                category.code?.includes('brand')
              )?.name,
              price: product.price ? product.price.formattedValue : null,
              variants: product.variantOptions?.map((option) => ({
                page_id_variant: option.code,
                upc: option.vendorUPC,
              })),
            },
            on_render: function (config: any, data: any) {},
          });
        })
      );
    }
  }

  initializePowerReviews(): void {
    if (this.winRef.isBrowser()) {
      this.scriptLoader.embedScript({
        src: powerReviewConfig.scriptUrl,
        callback: () => {
          this.isPowerReviewsInitialized$.next(true);
        },
        errorCallback: () => {
          this.isPowerReviewsInitialized$.next(false);
          console.error('Error loading PowerReviews script');
        },
      });
    }
  }
}
