import {
  CmsComponent,
  CmsComponentConnector,
  PageContext,
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RrsCmsComponentConnector extends CmsComponentConnector {
  getList(ids: string[], pageContext: PageContext): Observable<CmsComponent[]> {
    return of(); //@todo: check why this is executing
  }
}
