import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInFadeOutAnimation = trigger('fadeInFadeOutAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(100%)' }),
    animate('200ms ease', style({ opacity: 1, transform: 'translateX(0)' })),
  ]),
  transition(':leave', [
    animate('200ms ease', style({ opacity: 0, transform: 'translateX(100%)' })),
  ]),
]);
