import {
  generateAlgoliaFacetConfig,
  parseFacetsUrlParams,
} from '@app/custom/features/rrs-product-listing/utils/rrs-facet.utils';
import {
  ConverterService,
  ProductSearchAdapter,
  ProductSearchPage,
  Suggestion,
  SearchConfig,
} from '@spartacus/core';
import { ALGOLIA_SEARCH_NORMALIZER } from '@app/custom/features/rrs-search/adapter/rrs-search.normalizer';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  RrsAlgolia,
  RrsSearchConfig,
} from '@app/custom/features/rrs-search/models/rrs-search.model';
import { RrsAlgoliaService } from '@app/custom/features/rrs-search/services/algolia.service';

@Injectable()
export class RrsSearchAdapter implements ProductSearchAdapter {
  constructor(
    private algoliaService: RrsAlgoliaService,
    private converter: ConverterService
  ) {}

  search(
    query: string,
    searchConfig?: RrsSearchConfig
  ): Observable<ProductSearchPage> {
    const facetFiltersState = parseFacetsUrlParams(searchConfig?.facetFilters);
    const facetFilters = generateAlgoliaFacetConfig(facetFiltersState);

    const config = { ...searchConfig };
    if (facetFilters != null) {
      config.facetFilters = facetFilters;
    }

    const result = this.algoliaService.search<RrsAlgolia.Hit>(query, config);
    return result.pipe(this.converter.pipeable(ALGOLIA_SEARCH_NORMALIZER));
  }

  loadSuggestions(term: string, pageSize?: number): Observable<Suggestion[]> {
    throw new Error('Method not implemented.');
  }
}
