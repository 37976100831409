import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { BadRequestHandler } from '@spartacus/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RrsCartHttpErrorHandlerService extends BadRequestHandler {
  protected handleVoucherOperationError(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response).filter(
      (e) =>
        e.message === 'coupon.invalid.code.provided' &&
        e.type === 'VoucherOperationError'
    );
  }
}
