import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateProfileComponentService } from '@spartacus/user/profile/components';
import { RrsUserProfileService } from '@app/custom/features/rrs-profile/services/rrs-user-profile.service';
import { RrsPersonalGroupsService } from '@app/custom/features/rrs-profile/services/rrs-personal-groups.service';
import { RrsCustomerGroupsService } from '@app/custom/features/rrs-account/services/rrs-customer-groups.service';
import { combineLatest, forkJoin } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class PersonalInformationService extends UpdateProfileComponentService {
  form: UntypedFormGroup = new UntypedFormGroup({
    customerId: new UntypedFormControl(''),
    firstName: new UntypedFormControl('', Validators.required),
    gender: new UntypedFormControl(null),
    lastName: new UntypedFormControl('', Validators.required),
    monthOfBirth: new UntypedFormControl(null),
    customerPhone: new UntypedFormControl(''),
  });

  constructor(
    protected globalMessageService: GlobalMessageService,
    protected modalService: NgbModal,
    protected userProfile: RrsUserProfileService,
    protected rrsPersonalGroupsService: RrsPersonalGroupsService,
    protected rrsCustomerGroupsService: RrsCustomerGroupsService
  ) {
    super(userProfile, globalMessageService);

    rrsPersonalGroupsService.personalGroups$
      .pipe(take(1))
      .subscribe((groups) => {
        this.form.controls['gender'].setValue(groups?.gender?.uid);
        this.form.controls['monthOfBirth'].setValue(groups?.monthOfBirth?.uid);
      });
  }

  updateProfile(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.busy$.next(true);

    this.rrsPersonalGroupsService.personalGroups$
      .pipe(
        take(1),
        switchMap((groups) => {
          const observablesArray = [];

          if (
            groups?.gender?.uid !== this.form.controls['gender'].value &&
            this.form.controls['gender'].value
          )
            observablesArray.push(
              this.rrsCustomerGroupsService.addToCustomerGroup(
                this.form.controls['gender']?.value
              )
            );

          if (
            groups?.monthOfBirth?.uid !==
              this.form.controls['monthOfBirth'].value &&
            this.form.controls['monthOfBirth'].value
          )
            observablesArray.push(
              this.rrsCustomerGroupsService.addToCustomerGroup(
                this.form.controls['monthOfBirth']?.value
              )
            );

          return combineLatest([
            this.userProfile.update(this.form.value),
            ...observablesArray,
          ]);
        })
      )
      .subscribe({
        next: () => this.onSuccess(),
        error: (error: Error) => this.onError(error),
      });
  }

  protected onSuccess(): void {
    this.rrsPersonalGroupsService.setPersonalGroups();
    this.modalService.dismissAll();
    this.busy$.next(false);
    this.form.reset();

    this.globalMessageService.add(
      {
        key: 'rrs.account.form.personalUpdateSuccess',
      },
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );
  }
}
