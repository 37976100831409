import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GENDER_SELECT, MONTH_SELECT } from '@app/shared/models';
import { GlobalMessageService } from '@spartacus/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PersonalInformationService } from '@app/custom/features/rrs-profile/services/rrs-personal-information.service';
import { UpdateProfileComponent } from '@spartacus/user/profile/components';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { RrsPersonalGroupsService } from '@app/custom/features/rrs-profile/services/rrs-personal-groups.service';
import { RrsCustomerGroupsService } from '@app/custom/features/rrs-account/services/rrs-customer-groups.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-personal-information-update',
  templateUrl: './rrs-personal-information-update.component.html',
  providers: [
    {
      provide: PersonalInformationService,
      useClass: PersonalInformationService,
      deps: [
        GlobalMessageService,
        NgbModal,
        UserProfileFacade,
        RrsPersonalGroupsService,
        RrsCustomerGroupsService,
      ],
    },
  ],
})
export class RrsPersonalInformationUpdateComponent extends UpdateProfileComponent {
  genderOptions = GENDER_SELECT;
  monthOfBirthOptions = MONTH_SELECT;

  constructor(
    protected modalService: NgbModal,
    protected service: PersonalInformationService
  ) {
    super(service);
  }

  close(): void {
    this.modalService.dismissAll();
  }
}
