import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { RrsCmsHeaderUiService } from '@app/custom/features/rrs-cms/components/rrs-cms-header/services/rrs-cms-header-ui.service';

@Component({
  selector: 'rrs-cms-header-banner-top',
  templateUrl: './rrs-cms-header-banner-top.component.html',
  styleUrls: ['./rrs-cms-header-banner-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsCmsHeaderBannerTopComponent {
  @Input() banner!: Contentstack.HeaderTopBanner;

  constructor(public headerUi: RrsCmsHeaderUiService) {}
}
