import { provideConfig, RoutingConfig } from '@spartacus/core';

export const rrsProductDetailsRoutingConfig = provideConfig({
  routing: {
    routes: {
      product: {
        paramsMapping: { productCode: 'code' },
        paths: ['p/:slug/:productCode', '/p/:productCode'],
      },
    },
  },
} as RoutingConfig);
