import {
  Breadcrumb,
  Category,
  Converter,
  ImageType,
  Product,
  ProductSearchPage,
} from '@spartacus/core';
import {
  createProductUrl,
  createSlugFromProductName,
} from '@app/shared/utils/slug';
import {
  BoysSizeFacets,
  FacetCodes,
  FacetsToDisplay,
  GirlsSizeFacets,
  LittleKidSizeOrder,
  RrsActiveFacets,
  RrsFacet,
  RrsFacetValue,
  RrsSizeFacetGroup,
  SingleSelectFacets,
  SizeFacets,
} from '@app/custom/features/rrs-product-listing/model';
import {
  getFacetsUrlParams,
  parseFacetsUrlParams,
} from '@app/custom/features/rrs-product-listing/utils/rrs-facet.utils';
import { Injectable, InjectionToken } from '@angular/core';
import { RrsAlgolia } from '../models/rrs-search.model';
import { RrsAlgoliaService } from '@app/custom/features/rrs-search/services/algolia.service';
import { RrsProductSearchPage } from '@app/custom/features/rrs-product-listing/model/rrs-product-listing.model';
import { SearchResponse } from '@algolia/client-search';
import { sortOptions } from '@app/custom/features/rrs-search/adapter/rrs-search-options';

export const ALGOLIA_SEARCH_NORMALIZER = new InjectionToken<
  Converter<SearchResponse<RrsAlgolia.Hit>, ProductSearchPage>
>('AlgoliaSearchNormalizer');

@Injectable()
export class RrsSearchNormalizer
  implements Converter<SearchResponse<RrsAlgolia.Hit>, RrsProductSearchPage>
{
  constructor(private rrsAlgoliaService: RrsAlgoliaService) {}

  convert(
    source: SearchResponse<RrsAlgolia.Hit>,
    target?: RrsProductSearchPage
  ): RrsProductSearchPage {
    const searchParams = new URLSearchParams(source.params);
    const facetFilters = searchParams.get('facetFilters') ?? '[]';
    const selectedFacets = parseFacetsUrlParams(facetFilters);

    const products = source.hits.map((hit) => {
      const product: Product = {
        averageRating: hit.rating || 0,
        code: hit.sku,
        images: {
          PRIMARY: {
            thumbnail: {
              format: 'thumbnail',
              imageType: ImageType.PRIMARY,
              url: hit.thumbnail,
            },
          },
        },
        manufacturer: hit.brand,
        name: hit.name,
        nameHtml: hit.name,
        price: {
          value: hit.price,
          formattedValue: `$${hit.price?.toLocaleString()}`,
          isVisible: hit.priceVisible,
        },
        slug: `${createSlugFromProductName(
          hit.brand
        )}-${createSlugFromProductName(hit.name)}`,
        url: createProductUrl(hit),
      };

      if (hit.priceOriginal) {
        product.oldPrice = {
          value: hit.priceOriginal,
          formattedValue: `$${hit.priceOriginal?.toLocaleString()}`,
        };
      }

      product.categories = hit.category?.map(
        (category: string, index: number): Category => {
          // this property name was changed on the Algolia few times already so we handle both names.
          const categoryId = hit?.categoriesId
            ? hit.categoriesId[index]
            : hit.categoryId[index];
          return {
            code: categoryId,
            name: category,
          };
        }
      );

      return product;
    });

    return {
      breadcrumbs: this.getBreadcrumbs(selectedFacets),
      currentQuery: {
        query: {
          value: source.query?.replace(/[-]/g, '') ?? '',
        },
        facetFilters: {
          value: facetFilters,
        },
      },
      selectedFacets,
      facets: this.getFacets(
        Object.entries(source.facets ?? {}),
        selectedFacets,
        source.renderingContent?.facetOrdering?.facets?.order
      ),
      pagination: {
        currentPage: source.page,
        pageSize: source.hitsPerPage,
        sort: this.rrsAlgoliaService.currentReplica,
        totalPages: source.nbPages,
        totalResults: source.nbHits,
        exhaustiveNbHits: source.exhaustiveNbHits,
        exhaustiveFacetsCount: source.exhaustiveFacetsCount,
      },
      products,
      sorts: sortOptions,
    };
  }

  private getFacetValues(
    facetName: string,
    values: Record<string, number>,
    selectedFacets: RrsActiveFacets
  ): RrsFacetValue[] {
    let facetValues = Object.entries(values).map(([name, count]) => ({
      count: count,
      name: name,
      selected: !!selectedFacets[facetName]?.find(
        (value) => value.toLowerCase() === name.toLowerCase()
      ),
      query: {
        query: {
          value: this.getFacetValueQueryLink(name, facetName, selectedFacets),
        },
      },
    }));

    if (facetName === FacetCodes.RATING) {
      facetValues = facetValues.sort((prev, next) => +next.name - +prev.name);
    }

    return facetValues;
  }

  private getFacets(
    facets: [string, Record<string, number>][],
    selectedFacets: RrsActiveFacets,
    facetOrderList: string[] | any
  ): RrsFacet[] {
    const facetsList: RrsFacet[] = [];
    let sizeFacetIndex = -1;
    let girlsGroupIndex = -1;
    let boysGroupIndex = -1;
    for (const [facetName, values] of facets) {
      if (!FacetsToDisplay.includes(facetName as FacetCodes)) continue;

      // Handle size facets grouping
      if (SizeFacets.includes(facetName as FacetCodes)) {
        if (sizeFacetIndex === -1) {
          facetsList.push({
            multiSelect: true,
            name: FacetCodes.SIZE,
            values: [] as RrsSizeFacetGroup[],
          });
          sizeFacetIndex = facetsList.length - 1;
        }
        if (
          [FacetCodes.SIZE_MENS, FacetCodes.SIZE_WOMENS].includes(
            facetName as FacetCodes
          )
        ) {
          facetsList[sizeFacetIndex].values!.push({
            name: facetName,
            values: this.getFacetValues(facetName, values, selectedFacets),
          });
        }

        if (GirlsSizeFacets.includes(facetName as FacetCodes)) {
          if (girlsGroupIndex === -1) {
            facetsList[sizeFacetIndex].values!.push({
              name: 'sizeGirl',
              subgroups: [] as RrsSizeFacetGroup[],
            });
            girlsGroupIndex = facetsList[sizeFacetIndex].values!.length - 1;
          }

          const newFacet = {
            name: facetName,
            values: this.getFacetValues(facetName, values, selectedFacets),
          };

          const currentSubgroups = (
            facetsList[sizeFacetIndex].values![
              girlsGroupIndex
            ] as RrsSizeFacetGroup
          ).subgroups!;
          currentSubgroups.push(newFacet);
          currentSubgroups.forEach((subgroup: RrsSizeFacetGroup) => {
            if (
              subgroup.name === FacetCodes.SIZE_GIRL_LITTLE_KID &&
              subgroup.values
            ) {
              subgroup.values = this.sortByOrderOfNameValues(
                subgroup.values,
                LittleKidSizeOrder
              );
            }
          });
        }

        if (BoysSizeFacets.includes(facetName as FacetCodes)) {
          if (boysGroupIndex === -1) {
            facetsList[sizeFacetIndex].values!.push({
              name: 'sizeBoy',
              subgroups: [] as RrsSizeFacetGroup[],
            });
            boysGroupIndex = facetsList[sizeFacetIndex].values!.length - 1;
          }

          (
            facetsList[sizeFacetIndex].values![
              boysGroupIndex
            ] as RrsSizeFacetGroup
          ).subgroups!.push({
            name: facetName,
            values:
              facetName === FacetCodes.SIZE_BOY_LITTLE_KID
                ? this.sortByOrderOfNameValues(
                    this.getFacetValues(facetName, values, selectedFacets),
                    LittleKidSizeOrder
                  )
                : this.getFacetValues(facetName, values, selectedFacets),
          });
        }
      } else {
        // Handle the rest of facets
        facetsList.push({
          multiSelect: !SingleSelectFacets.some(
            (f) => f.toLowerCase() === facetName.toLowerCase()
          ),
          name: facetName,
          values: this.getFacetValues(facetName, values, selectedFacets),
        });
      }
    }

    // Sort size facets
    if (sizeFacetIndex !== -1) {
      const customSortOrder: Record<string, number> = {
        sizeWomens: 1,
        sizeMens: 2,
        sizeGirl: 3,
        sizeBoy: 4,
      };
      const subgroupSortOrder: Record<string, number> = {
        sizeBoyBaby: 1,
        sizeGirlBaby: 1,
        sizeBoyToddler: 2,
        sizeGirlToddler: 2,
        sizeBoyLittleKid: 3,
        sizeGirlLittleKid: 3,
        sizeBoyBigKid: 4,
        sizeGirlBigKid: 4,
      };
      if (girlsGroupIndex !== -1) {
        (
          facetsList[sizeFacetIndex].values![
            girlsGroupIndex
          ] as RrsSizeFacetGroup
        ).subgroups!.sort((a, b) => {
          if (
            a.name &&
            a.name in subgroupSortOrder &&
            b.name &&
            b.name in subgroupSortOrder
          ) {
            return subgroupSortOrder[a.name] - subgroupSortOrder[b.name];
          }
          return 0;
        });
      }

      if (boysGroupIndex !== -1) {
        (
          facetsList[sizeFacetIndex].values![
            boysGroupIndex
          ] as RrsSizeFacetGroup
        ).subgroups!.sort((a, b) => {
          if (
            a.name &&
            a.name in subgroupSortOrder &&
            b.name &&
            b.name in subgroupSortOrder
          ) {
            return subgroupSortOrder[a.name] - subgroupSortOrder[b.name];
          }
          return 0;
        });
      }
      facetsList[sizeFacetIndex].values!.sort((a, b) => {
        if (
          a.name &&
          a.name in customSortOrder &&
          b.name &&
          b.name in customSortOrder
        ) {
          return customSortOrder[a.name] - customSortOrder[b.name];
        }
        return 0;
      });
    }

    return facetOrderList
      ? this.orderFacets(facetsList, facetOrderList)
      : facetsList;
  }

  /**
   * Orders the facets by a list of name values
   * @param data RrsFacet[]
   * @param orderList string[]
   * @returns RrsFacet[]
   */
  private orderFacets(data: RrsFacet[], orderList: string[]): RrsFacet[] {
    // need to use any because RrsFacet name property is nullable and cannot use possible null properties to find index
    let combinedList = data.map((value: any) => ({
      ...value,
      order: orderList.findIndex((order: string) => order.includes(value.name)),
    }));

    let sortedList: RrsFacet[] = combinedList
      .filter((value: any) => {
        return value?.order > -1;
      })
      .sort((a, b) => a.order - b.order);
    return sortedList;
  }

  private getFacetValueQueryLink(
    value: string,
    facetName: string,
    currentFacets?: RrsActiveFacets
  ): string {
    if (!value || !facetName) return '';
    const current = { ...currentFacets } ?? {};

    const filteredCurrent = current[facetName]?.filter(
      (val) => val.toLowerCase() !== value.toLowerCase()
    );
    const isFilterApplied =
      filteredCurrent?.length !== current[facetName]?.length;

    current[facetName] = isFilterApplied
      ? filteredCurrent
      : [...(current[facetName] ?? []), value];
    const facetFiltersQueryParams = getFacetsUrlParams(current);

    return facetFiltersQueryParams;
  }

  private getBreadcrumbs(currentFacets?: RrsActiveFacets): Breadcrumb[] {
    return Object.entries(currentFacets ?? {}).reduce<Breadcrumb[]>(
      (acc, [attribute, values]) => {
        acc = [
          ...acc,
          ...values.map((value) => ({
            facetName: attribute,
            facetValueName: value,
            removeQuery: {
              query: {
                value: this.getFacetValueQueryLink(
                  value,
                  attribute,
                  currentFacets
                ),
              },
            },
          })),
        ];
        return acc;
      },
      []
    );
  }
  /**
   * Sorts values by the order list of the name values
   * @param facetValues RrsFacetValue[]
   * @param orderList RrsFacetValue[]
   * @returns RrsFacetValue[]
   */
  private sortByOrderOfNameValues(
    facetValues: RrsFacetValue[],
    orderList: Array<string>
  ): RrsFacetValue[] {
    const orderMap = new Map(orderList.map((item, index) => [item, index]));

    facetValues.sort((a, b) => {
      return (orderMap.get(a.name!) || 0) - (orderMap.get(b.name!) || 0);
    });
    return facetValues;
  }
}
