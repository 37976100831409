import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { RrsCheckoutService } from '@app/custom/features/rrs-checkout/rrs-checkout.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RrsCheckoutGuard implements CanActivate {
  constructor(
    protected checkoutService: RrsCheckoutService,
    protected router: Router
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.checkoutService.checkoutFirstStart) {
      this.checkoutService.checkoutFirstStart = false;
      this.router.navigate(['/checkout']);
      return false;
    } else {
      return true;
    }
  }
}
