<a
  [attr.title]="logo?.desktop?.site_logo?.alt_text"
  [routerLink]="logo?.desktop?.site_logo?.url"
  class="logo__desktop my-1"
  data-cy="header-logo">
  <img
    [attr.alt]="logo?.desktop?.site_logo?.image_file?.title"
    [attr.src]="logo?.desktop?.site_logo?.image_file?.url"
    height="56"
    width="204" />
</a>

<a
  [attr.title]="logo?.mobile?.site_logo?.alt_text"
  [routerLink]="logo?.mobile?.site_logo?.url"
  class="logo__mobile my-1">
  <img
    [attr.alt]="logo?.mobile?.site_logo?.image_file?.title"
    [attr.src]="logo?.mobile?.site_logo?.image_file?.url"
    height="38"
    width="250" />
</a>
