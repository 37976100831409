import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { PersonalGroupsInterface } from '@app/custom/features/rrs-profile/models/rrs-personal-groups.model';
import { RrsCustomerGroupsService } from '@app/custom/features/rrs-account/services/rrs-customer-groups.service';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsPersonalGroupsService {
  personalGroups$ = new BehaviorSubject<PersonalGroupsInterface | null>(null);

  constructor(protected rrsCustomerGroupsService: RrsCustomerGroupsService) {}

  setPersonalGroups(): void {
    this.rrsCustomerGroupsService
      .getCustomerGroups()
      .pipe(
        take(1),
        tap((groups) => {
          const userGroups: PersonalGroupsInterface = {};

          const genderGroup = groups?.userGroups?.find(
            (item) => item.name === 'Female' || item.name === 'Male'
          );

          const monthOfBirthGroup = groups?.userGroups
            ?.filter((item) => (item?.name || '').includes('Birth Month'))
            ?.find((item) => item?.uid !== 'birthMonthUG');

          userGroups.gender = genderGroup;
          userGroups.monthOfBirth = monthOfBirthGroup;

          this.personalGroups$.next(userGroups);
        })
      )
      .subscribe();
  }
}
