import { CommonModule } from '@angular/common';
import { I18nModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RrsProductCardComponent } from './rrs-product-card.component';
import { RrsStarRatingModule } from '@app/custom/features/rrs-star-rating/rrs-star-rating.module';
import { RrsMediaModule } from '@app/custom/features/rrs-media/rrs-media.module';
import { RrsSharedPipesModule } from '@app/shared/pipes';

@NgModule({
  declarations: [RrsProductCardComponent],
  imports: [
    CommonModule,
    RrsStarRatingModule,
    I18nModule,
    MediaModule,
    RouterModule,
    RrsMediaModule,
    RrsSharedPipesModule,
  ],
  exports: [RrsProductCardComponent],
})
export class RrsProductCardModule {}
