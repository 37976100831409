import { CommonModule } from '@angular/common';
import { I18nModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RrsCardNumberInputComponent } from './rrs-card-number-input';
import { RrsIconModule } from '@app/shared/components/rrs-icon/rrs-icon.module';
import { RrsPasswordErrorsComponent } from './rrs-password-input';
import { RrsPhoneNumberInputComponent } from './rrs-phone-number-input';
import { RrsSharedDirectivesModule } from '@app/shared/directives';
import { RrsSharedPipesModule } from '@app/shared/pipes';

@NgModule({
  declarations: [
    RrsPhoneNumberInputComponent,
    RrsPasswordErrorsComponent,
    RrsCardNumberInputComponent,
  ],
  exports: [
    RrsPhoneNumberInputComponent,
    RrsPasswordErrorsComponent,
    RrsCardNumberInputComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RrsSharedDirectivesModule,
    RrsSharedPipesModule,
    RrsIconModule,
    I18nModule,
  ],
})
export class RrsInputModule {}
