<div
  class="delivery px-4 py-3"
  [attr.aria-label]="'rrs.product.deliveryOptions.inStorePickup' | cxTranslate">
  <div class="form-check d-flex align-items-center flex-grow-1">
    <input
      class="form-check-input me-3"
      type="radio"
      (change)="selectDeliveryOption()"
      (keydown.enter)="selectDeliveryOption()"
      [disabled]="fulfillment.deliveryOutOfStock"
      [checked]="
        fulfillment.selectedMethod === deliveryOptions.DELIVERY &&
        !fulfillment.deliveryOutOfStock
      "
      id="deliveryOptionRadio" />
    <label
      class="form-check-label cursor-pointer d-flex align-items-center flex-grow-1"
      [class.opacity-50]="fulfillment.deliveryOutOfStock"
      for="deliveryOptionRadio">
      <div
        class="d-flex flex-column justify-content-between align-items-start flex-grow-1">
        <p class="delivery__title mb-1 fw-bold">
          {{ getTitleAndDescription().title | cxTranslate }}
        </p>
        <p
          class="delivery__title mb-0"
          [innerHTML]="
            getTitleAndDescription().description
              | cxTranslate: { price: freeShippingThreshold.formattedValue }
          "></p>
      </div>
    </label>
  </div>
</div>
