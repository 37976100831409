import { Injectable } from '@angular/core';
import {
  ProductActions,
  ProductSearchService,
  SearchConfig,
} from '@spartacus/core';

@Injectable({ providedIn: 'root' })
export class RrsProductSearchService extends ProductSearchService {
  search(query: string, searchConfig?: SearchConfig): void {
    this.store.dispatch(
      new ProductActions.SearchProducts({
        queryText: query,
        searchConfig: searchConfig,
      })
    );
  }
}
