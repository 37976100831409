<form class="search__form row" [formGroup]="form" (ngSubmit)="search()">
  <div class="col-md-8">
    <input
      data-cy="store-finder-search-input"
      type="text"
      class="form-control"
      formControlName="search"
      [placeholder]="'rrs.storeFinder.searchPlaceholder' | cxTranslate" />
  </div>
  <div class="col-md-4 mt-3 mt-md-0">
    <button
      data-cy="store-finder-search-submit"
      type="submit"
      class="btn btn-primary">
      {{ 'rrs.storeFinder.search' | cxTranslate }}
    </button>
  </div>
</form>
<button
  data-cy="store-finder-search-by-location"
  class="search__location-btn d-flex align-items-center mt-1"
  (click)="search(true)">
  <rrs-icon
    class="me-1"
    size="12"
    [icon]="iconTypeList.LOCATION_CROSS"></rrs-icon>
  {{ 'rrs.storeFinder.useMyLocation' | cxTranslate }}
</button>
