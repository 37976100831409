import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { RrsEventsDispatcherService } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/services/rrs-events.dispatcher';

@Component({
  selector: 'rrs-cms-footer-social-media',
  templateUrl: './rrs-cms-footer-social-media.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsCmsFooterSocialMediaComponent {
  constructor(protected eventsDispatcher: RrsEventsDispatcherService) {}

  @Input() socialMedia!: Contentstack.FooterSocialLinks;

  dispatchClickEvent(
    link: Contentstack.FooterSocialLinks['links'][number]
  ): void {
    this.eventsDispatcher.dispatchSocialLinkClick(
      link.image_link.alt_text?.toLowerCase()
    );
  }
}
