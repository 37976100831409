<h2 class="fs-6 fw-normal pb-2 border-bottom border-accent border-2">
  {{ 'rrs.account.navigationHeader' | cxTranslate }}
</h2>

<ul class="list-none">
  <li *ngFor="let item of accountNavigation.link_list">
    <button
      *ngIf="item.link.action; else anchorLink"
      (click)="customAction(item.link.action)"
      class="d-flex align-items-center justify-content-between w-100 py-2 text-uppercase nolink fs-6">
      {{ 'rrs.account.navigation.' + item.link.title | cxTranslate }}
      <rrs-icon [icon]="iconTypeList.CHEVRON_RIGHT" [size]="8"></rrs-icon>
    </button>
    <ng-template #anchorLink>
      <a
        [routerLink]="item.link.href"
        class="d-flex align-items-center justify-content-between w-100 py-2 text-uppercase nolink"
        routerLinkActive="fw-bold">
        {{ 'rrs.account.navigation.' + item.link.title | cxTranslate }}

        <rrs-icon [icon]="iconTypeList.CHEVRON_RIGHT" [size]="8"></rrs-icon>
      </a>
    </ng-template>
  </li>
</ul>
