import { CmsConfig, provideConfig } from '@spartacus/core';
import { RrsProductDetailsContainerComponent } from '@app/custom/features/rrs-product-details/components';
import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';

export const rrsProductDetailsCmsConfig = [
  provideConfig({
    slots: {
      MainProductDetails: ['ProductDetailsContainer'],
    },
  } as CmsComponentsConfig),
  provideConfig({
    cmsComponents: {
      ProductDetailsContainer: {
        component: RrsProductDetailsContainerComponent,
      },
    },
  } as CmsConfig),
];
