import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RrsAsmUiComponent } from './components/rrs-asm-ui/rrs-asm-ui.component';
import { rrsAsmLayoutConfig } from '@app/custom/features/rrs-asm/configs/rrs-asm.config';
import { I18nModule, provideConfig } from '@spartacus/core';
import { RrsAsmToggleUiComponent } from './components/rrs-asm-toggle-ui/rrs-asm-toggle-ui.component';
import { RrsIconModule } from '@app/shared/components';
import { RrsCsagentLoginFormComponent } from './components/rrs-csagent-login-form/rrs-csagent-login-form.component';
import { RrsFormErrorsModule } from '@app/shared/components/rrs-form-errors/rrs-form-errors.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RrsCustomerSelectionComponent } from './components/rrs-customer-selection/rrs-customer-selection.component';
import { RrsAsmSessionTimerComponent } from './components/rrs-asm-session-timer/rrs-asm-session-timer.component';
import { FormatTimerPipe } from '@app/custom/features/rrs-asm/pipes/format-timer.pipe';
import { RrsCustomerEmulationComponent } from './components/rrs-customer-emulation/rrs-customer-emulation.component';

@NgModule({
  declarations: [
    RrsAsmUiComponent,
    RrsAsmToggleUiComponent,
    RrsCsagentLoginFormComponent,
    RrsCustomerSelectionComponent,
    RrsAsmSessionTimerComponent,
    FormatTimerPipe,
    RrsCustomerEmulationComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    RrsIconModule,
    RrsFormErrorsModule,
    ReactiveFormsModule,
  ],
  providers: [provideConfig(rrsAsmLayoutConfig)],
})
export class RrsAsmModule {}
