import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CARD_MIN_LENGTH } from '@app/shared/configs';
import { Image } from '@spartacus/core';

@Component({
  selector: 'rrs-card-number-input',
  templateUrl: './rrs-card-number-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsCardNumberInputComponent implements OnInit {
  @Input()
  label: string = '';
  @Input()
  valid: 'is-valid' | 'is-invalid' | '' = '';
  @Input()
  disabled: boolean = false;
  @Input()
  control!: UntypedFormControl;
  @Input()
  placeholder: string = '';

  cardIcons: Image[] = [
    {
      altText: 'Visa',
      url: 'assets/images/visa.svg',
    },
    {
      altText: 'MasterCard',
      url: 'assets/images/masterCard.svg',
    },
    {
      altText: 'Discover',
      url: 'assets/images/discover.svg',
    },
    {
      altText: 'American Express',
      url: 'assets/images/americanExpress.svg',
    },
  ];

  ngOnInit(): void {
    if (!this.control) this.control = new UntypedFormControl();
    this.control.addValidators([Validators.minLength(CARD_MIN_LENGTH)]);
  }
}
