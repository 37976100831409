export const rrsOrderOccConfig = {
  backend: {
    occ: {
      endpoints: {
        placeOrder:
          '/users/${userId}/orders?fields=FULL,paymentTenderDataList,rewards',
      },
    },
  },
};
