<ng-container *ngIf="stores">
  <div class="p-5 mt-4 mb-3 bg-stripe"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <ng-container *ngIf="stores.openedRecently.length">
          <h3 class="fw-normal">
            {{ 'rrs.storeFinder.nowOpen' | cxTranslate }}
          </h3>
          <ng-container
            [ngTemplateOutlet]="storesList"
            [ngTemplateOutletContext]="{
              stores: stores.openedRecently
            }"></ng-container>
        </ng-container>
      </div>
      <div class="col-lg-4">
        <ng-container *ngIf="stores.comingSoon.length">
          <h3 class="fw-normal">
            {{ 'rrs.storeFinder.comingSoon' | cxTranslate }}
          </h3>
          <ng-container
            [ngTemplateOutlet]="storesList"
            [ngTemplateOutletContext]="{
              stores: stores.comingSoon
            }"></ng-container>
        </ng-container>
      </div>
      <div class="col-lg-4">
        <h3 class="fw-normal" *ngIf="hasRemodels">
          {{ 'rrs.storeFinder.remodelsRelocations' | cxTranslate }}
        </h3>
        <cx-page-slot #remodels position="remodels"></cx-page-slot>
      </div>
    </div>
  </div>

  <ng-template #storesList let-stores="stores">
    <ng-container *ngFor="let store of stores">
      <div
        *ngIf="store.displayName?.length"
        class="store-details-container py-3">
        <h6 class="my-0">{{ store.displayName }}</h6>
        <p class="my-0">
          {{ store.address?.line1 }} <br />
          {{ store.address?.town }},
          {{ store.address?.region?.isocodeShort }}
          {{ store.address.postalCode }} <br />
          <span class="store-details__phone"
            >{{ store.phoneNumber | rrsPhoneNumber }}
          </span>
        </p>
        <a
          *ngIf="store.name"
          class="link-primary"
          [routerLink]="
            { cxRoute: 'storeDetails', params: { storeId: store.name } } | cxUrl
          "
          >{{ 'rrs.storeFinder.seeStoreDetails' | cxTranslate }}</a
        >
      </div>
    </ng-container>
  </ng-template>
</ng-container>
