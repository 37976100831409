import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { RrsAccordionModule, RrsIconModule } from '@app/shared/components';
import { CommonModule } from '@angular/common';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RrsCmsNeedHelpComponent } from './rrs-cms-need-help.component';
import {
  NEED_HELP_DROPDOWN_COMPONENT_ID,
  NEED_HELP_MODAL_COMPONENT_ID,
} from '@app/custom/features/rrs-cms/configs/contentstack.config';

@NgModule({
  declarations: [RrsCmsNeedHelpComponent],
  exports: [RrsCmsNeedHelpComponent],
  imports: [
    CommonModule,
    NgbCollapseModule,
    RouterModule,
    RrsAccordionModule,
    RrsIconModule,
    I18nModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        [NEED_HELP_MODAL_COMPONENT_ID]: {
          component: RrsCmsNeedHelpComponent,
        },
        [NEED_HELP_DROPDOWN_COMPONENT_ID]: {
          component: RrsCmsNeedHelpComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class RrsCmsNeedHelpModule {}
