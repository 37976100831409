export enum FulfillmentOptions {
  PICKUP,
  DELIVERY,
}

export interface FulfillmentConfig {
  selectedMethod?: FulfillmentOptions;
  deliveryOutOfStock?: boolean;
  pickupOutOfStock?: boolean;
  isPickupStoreSelected?: boolean;
}

export const FREE_DELIVERY_THRESHOLD = {
  value: 65,
  formattedValue: '$65',
};
