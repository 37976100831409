import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';

import { RrsMediaComponent } from '@app/custom/features/rrs-media/components/rrs-media/rrs-media.component';

@NgModule({
  declarations: [RrsMediaComponent],
  exports: [RrsMediaComponent],
  imports: [CommonModule, I18nModule],
})
export class RrsMediaModule {}
