import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Brands } from '@app/custom/features/rrs-brands/models/brands.model';
import { Observable } from 'rxjs';
import { RrsBrandsService } from '@app/custom/features/rrs-brands/services/rrs-brands.service';
import { ViewportScroller } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-brands-list',
  templateUrl: './rrs-brands-list.component.html',
  styleUrls: ['./rrs-brands-list.component.scss'],
})
export class RrsBrandsListComponent {
  brands$: Observable<Brands[]> = this.rrsBrandsService.getBrands();

  constructor(
    protected rrsBrandsService: RrsBrandsService,
    public scroller: ViewportScroller
  ) {}
}
