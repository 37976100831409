<div class="container py-4">
  <h1
    rrsCmsHeading
    data-cy="store-finder-title"
    class="fw-normal text-capitalize fs-2">
    {{ 'rrs.storeFinder.findStore' | cxTranslate }}
  </h1>
  <cx-page-slot position="above_search_content"></cx-page-slot>
  <div
    class="row my-3"
    *ngIf="{ selectedStore: selectedStore$ | async } as data">
    <div class="col-lg-6">
      <rrs-store-finder-search></rrs-store-finder-search>
      <rrs-store-finder-list
        (selectStore)="selectStore($event)"
        [selected]="data.selectedStore"></rrs-store-finder-list>
    </div>
    <div class="col-lg-6">
      <rrs-store-finder-list-item-details
        [store]="data.selectedStore"></rrs-store-finder-list-item-details>
    </div>
  </div>
  <div class="my-4">
    <cx-page-slot position="below_search_content"></cx-page-slot>
  </div>
</div>
<rrs-specific-stores-list
  [stores]="specificStoresList$ | async"></rrs-specific-stores-list>
