import { CmsConfig, provideConfig } from '@spartacus/core';
import { RrsResetPasswordComponent } from '@app/custom/features/rrs-reset-password/components/rrs-reset-password/rrs-reset-password.component';
import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';

export const rrsResetPasswordCmsConfig = [
  provideConfig({
    slots: {
      ResetPasswordSlot: ['RrsResetPasswordComponent'],
    },
  } as CmsComponentsConfig),
  provideConfig({
    cmsComponents: {
      RrsResetPasswordComponent: {
        component: RrsResetPasswordComponent,
      },
    },
  } as CmsConfig),
];
