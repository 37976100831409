import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@app/shared/utils';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { RrsClaimRewardsService } from '@app/custom/features/rrs-rewards/serwices/rrs-claim-rewards.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-claim-rewards',
  styleUrls: ['./rrs-claim-rewards.component.scss'],
  templateUrl: './rrs-claim-rewards.component.html',
})
export class RrsClaimRewardsComponent {
  iconTypeList = ICON_TYPE_LIST;

  form: FormGroup = this.fb.group({
    registerNo: [
      '',
      {
        validators: [Validators.required, Validators.minLength(1)],
      },
    ],
    salesDate: [
      '',
      {
        validators: [Validators.required, CustomValidators.isDateValid],
      },
    ],
    storeNo: [
      '',
      {
        validators: [Validators.required, Validators.minLength(4)],
      },
    ],
    transactionNo: [
      '',
      {
        validators: [Validators.required, Validators.minLength(6)],
      },
    ],
  });

  constructor(
    protected fb: FormBuilder,
    protected rrsClaimRewardsService: RrsClaimRewardsService
  ) {}

  sendForm(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
    } else {
      const body = this.form.getRawValue();
      const dateMM = [...body.salesDate].slice(0, 2).join('');
      const dateDD = [...body.salesDate].slice(2, 4).join('');
      const dateYYYY = [...body.salesDate].slice(-4).join('');

      const orderNumber =
        dateYYYY +
        dateMM +
        dateDD +
        body.storeNo +
        body.registerNo +
        body.transactionNo;

      this.rrsClaimRewardsService.putClaimRewards(orderNumber);

      this.form.reset();
    }
  }
}
