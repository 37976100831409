<section>
  <h2 class="fs-4 fw-normal mb-3 mt-4">
    {{ 'rrs.giftCards.availableGiftCards' | cxTranslate }}
  </h2>
  <img
    [attr.alt]="'rrs.giftCards.cardsImage' | cxTranslate"
    [attr.src]="'/assets/images/giftcards.jpg'"
    width="100%" />
  <p>{{ 'rrs.giftCards.availableGiftCardsInfo' | cxTranslate }}</p>
  <a
    [routerLink]="{ cxRoute: 'storeFinder' } | cxUrl"
    class="btn btn-primary text-uppercase fs-5">
    {{ 'rrs.giftCards.findStore' | cxTranslate }}
  </a>
</section>
