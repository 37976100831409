<nav
  class="header-menu-mobi-item"
  [ngClass]="'header-menu-mobi-item--l' + level">
  <ul class="list-none">
    <li>
      <ng-container
        *ngIf="
          isAccountNavigation ||
            item?.section?.second_level_sections?.length ||
            item?.section?.links?.length;
          else link
        ">
        <button
          class="d-flex align-items-center justify-content-between px-3 py-4 fs-5 header-menu-mobi-item__link"
          [class.bg-stripe]="isAccountNavigation"
          (click)="toggIsItemOpen()"
          type="button">
          <ng-container *ngIf="!isAccountNavigation; else accountNavigation">
            {{ item?.section?.section_title }}
          </ng-container>

          <ng-template #accountNavigation>
            <div class="d-flex align-items-center">
              <rrs-icon
                [icon]="iconTypeList.USER"
                [size]="24"
                class="me-2"></rrs-icon>
              {{ 'rrs.account.navigation.myAccount' | cxTranslate }}
            </div>
          </ng-template>

          <rrs-icon
            [icon]="iconTypeList.CHEVRON_RIGHT"
            [size]="12"
            *ngIf="
              isAccountNavigation ||
              item?.section?.second_level_sections?.length ||
              item?.section?.links?.length
            ">
          </rrs-icon>
        </button>
      </ng-container>

      <ng-template #link>
        <button
          *ngIf="item?.link?.action; else anchorLink"
          (click)="customAction(item.link.action)"
          class="d-flex align-items-center justify-content-between px-3 py-4 fs-5 header-menu-mobi-item__link header-menu-mobi-item__link--capitalize nolink">
          {{ 'rrs.account.navigation.' + item.link.title | cxTranslate }}
        </button>
        <ng-template #anchorLink>
          <a
            [attr.title]="
              item?.section?.section_title ||
              item?.url_link?.text ||
              ('rrs.account.navigation.' + item.link.title | cxTranslate)
            "
            [routerLink]="
              item?.section?.url || item?.url_link?.url || item?.link?.href
            "
            target="{{
              item?.section?.new_tab || item?.url_link?.new_tab
                ? '_blank'
                : '_self'
            }}"
            class="d-flex align-items-center justify-content-between px-3 py-4 fs-5 header-menu-mobi-item__link header-menu-mobi-item__link--capitalize nolink"
            (click)="headerUi.toggMenuMobi()">
            {{
              item?.section?.section_title ||
                item?.url_link?.text ||
                ('rrs.account.navigation.' + item.link.title | cxTranslate)
            }}

            <rrs-icon
              [icon]="iconTypeList.CHEVRON_RIGHT"
              [size]="12"
              *ngIf="
                item?.section?.second_level_sections?.length ||
                item?.section?.links?.length
              ">
            </rrs-icon>
          </a>
        </ng-template>
      </ng-template>

      <ng-container
        *ngIf="
          (isAccountNavigation ||
            item?.section?.second_level_sections?.length ||
            item?.section?.links?.length) &&
          isItemOpen
        ">
        <div class="header-menu-mobi-item__link-list">
          <button
            class="d-flex align-items-center justify-content-between px-3 py-4 fs-5 header-menu-mobi-item__link"
            [class.bg-stripe]="isAccountNavigation"
            (click)="toggIsItemOpen()"
            type="button">
            <rrs-icon [icon]="iconTypeList.CHEVRON_LEFT" [size]="12"></rrs-icon>

            <ng-container *ngIf="!isAccountNavigation; else isAccount">
              {{ item?.section?.section_title }}
            </ng-container>

            <ng-template #isAccount>
              <div class="d-flex align-items-center">
                <rrs-icon
                  [icon]="iconTypeList.USER"
                  [size]="24"
                  class="me-2"></rrs-icon>
                {{ 'rrs.account.navigation.myAccount' | cxTranslate }}
              </div>
            </ng-template>

            <rrs-icon
              class="invisible"
              [icon]="iconTypeList.CHEVRON_LEFT"
              [size]="12"></rrs-icon>
          </button>

          <ng-container *ngIf="!isAccountNavigation">
            <a
              [attr.title]="item?.section?.section_title"
              [routerLink]="item?.section?.url || item?.section?.links?.url"
              *ngIf="level === 1"
              target="{{
                item?.section?.new_tab || item?.url_link?.new_tab
                  ? '_blank'
                  : '_self'
              }}"
              class="d-flex align-items-center justify-content-between px-3 py-4 fs-5 header-menu-mobi-item__link header-menu-mobi-item__link--capitalize nolink"
              (click)="headerUi.toggMenuMobi()">
              View All
            </a>
          </ng-container>

          <rrs-cms-header-menu-mobi-item
            [item]="iteml2"
            [level]="level + 1"
            *ngFor="
              let iteml2 of item?.section?.second_level_sections?.[0].sections ||
                item?.section?.links || item?.link_list
            ">
          </rrs-cms-header-menu-mobi-item>
        </div>
      </ng-container>
    </li>
  </ul>
</nav>
