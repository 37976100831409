<div
  class="px-0 px-md-3 py-2 d-flex justify-content-between align-items-center border-bottom">
  <div class="d-none d-md-flex align-items-center w-100">
    <img
      [attr.alt]="'logo'"
      [attr.src]="'/assets/images/rackRoomShoes.svg'"
      height="32"
      class="me-2" />
    <h4 class="m-0">
      {{ 'asm.mainTitle' | cxTranslate }}
    </h4>
  </div>
  <div
    class="d-flex justify-content-between justify-content-md-end w-100 align-items-center gap-4">
    <rrs-asm-toggle-ui></rrs-asm-toggle-ui>

    <rrs-asm-session-timer
      *ngIf="customerSupportAgentLoggedIn$ | async"></rrs-asm-session-timer>

    <button
      *ngIf="
        (customerSupportAgentLoggedIn$ | async) === false &&
        (csAgentTokenLoading$ | async) === false
      "
      (click)="hideUi()"
      type="button">
      <rrs-icon
        [size]="16"
        [icon]="icon.CLOSE"
        [attr.aria-label]="'asm.hideUi' | cxTranslate"></rrs-icon>
    </button>

    <button
      type="button"
      *ngIf="customerSupportAgentLoggedIn$ | async"
      (click)="logout()">
      <rrs-icon
        [size]="16"
        [icon]="icon.POWER_OFF"
        [attr.aria-label]="'asm.hideUi' | cxTranslate"></rrs-icon>
    </button>
  </div>
</div>

<section class="p-3" *ngIf="(isCollapsed$ | async) === false as notCollapsed">
  <ng-container
    *ngIf="customerSupportAgentLoggedIn$ | async; else showLoginForm">
    <ng-container *ngIf="customer$ | async; else showCustomerSelection">
      <rrs-customer-emulation *ngIf="notCollapsed"></rrs-customer-emulation>
    </ng-container>
    <ng-template #showCustomerSelection>
      <rrs-customer-selection
        *ngIf="notCollapsed"
        (submitEvent)="
          startCustomerEmulationSession($event)
        "></rrs-customer-selection>
    </ng-template>
  </ng-container>

  <ng-template #showLoginForm>
    <rrs-csagent-login-form
      *ngIf="notCollapsed"
      (submitEvent)="loginCustomerSupportAgent($event)"
      [csAgentTokenLoading]="
        csAgentTokenLoading$ | async
      "></rrs-csagent-login-form>
  </ng-template>
</section>
