import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RrsCouponTagComponent } from './rrs-coupon-tag/rrs-coupon-tag.component';
import { RrsFilterTagComponent } from './rrs-filter-tag/rrs-filter-tag.component';
import { RrsIconModule } from '@app/shared/components/rrs-icon/rrs-icon.module';
import { RrsPaymentTagComponent } from './rrs-payment-tag/rrs-payment-tag.component';

@NgModule({
  declarations: [
    RrsFilterTagComponent,
    RrsCouponTagComponent,
    RrsPaymentTagComponent,
  ],
  exports: [
    RrsFilterTagComponent,
    RrsCouponTagComponent,
    RrsPaymentTagComponent,
  ],
  imports: [CommonModule, RrsIconModule],
})
export class RrsTagsModule {}
