<div class="saved-payments__title d-flex p-2 mb-lg-3 align-items-center">
  <h2 class="me-3 mb-0 fw-normal">
    {{ 'rrs.savedPayments.title' | cxTranslate }}
  </h2>
  <rrs-icon
    [size]="36"
    [icon]="icon.CARD_DOLLAR"
    class="d-none d-lg-block pb-1"></rrs-icon>
</div>

<div class="container-fluid p-0">
  <div *ngIf="paymentMethods$ | async as payments" class="row g-0 g-lg-4">
    <div
      *ngFor="let payment of payments"
      class="col-lg-6 payment mb-4"
      data-cy="addressCard">
      <h3
        *ngIf="payment.tenderType !== paymentTypes.PAYPAL"
        class="payment__title fw-semibold p-2 mb-3 text-capitalize">
        {{ payment?.tenderType }}, {{ payment?.cardNumber }},
        {{ payment?.expiryMonth }}/{{ shortenYear(payment?.expiryYear) }}
      </h3>
      <h3
        *ngIf="payment.tenderType === paymentTypes.PAYPAL"
        class="payment__title fw-semibold p-2 mb-3 text-capitalize">
        {{ paymentTranslations[payment.tenderType] | cxTranslate }},
        <span class="text-lowercase">{{ payment.email }}</span>
      </h3>
      <div class="mx-3">
        <span class="fw-bold">{{ 'rrs.addressBook.name' | cxTranslate }}</span>
        <p class="fw-bold">{{ payment?.accountHolderName }}</p>
        <span class="fw-bold">{{
          'rrs.savedPayments.cardInformation' | cxTranslate
        }}</span>
        <p *ngIf="payment.tenderType !== paymentTypes.PAYPAL" class="fw-bold">
          {{ payment?.tenderType }}, {{ payment?.cardNumber }},
          {{ payment?.expiryMonth }}/{{ shortenYear(payment?.expiryYear) }}
        </p>
        <p *ngIf="payment.tenderType === paymentTypes.PAYPAL" class="fw-bold">
          {{ paymentTranslations[payment.tenderType] | cxTranslate }},
          <span class="text-lowercase">{{ payment.email }}</span>
        </p>
        <div *ngIf="payment.billingAddress as address" class="payment__address">
          <span class="fw-bold">{{
            'rrs.addressBook.address' | cxTranslate
          }}</span>
          <div>{{ address?.line1 }}</div>
          <div>{{ address?.line2 }}</div>
          <div>
            <span>{{ address?.town }}&nbsp;</span>
            <span>{{ address?.region?.isocodeShort }}&nbsp;</span>
            <span>{{ address?.postalCode }}</span>
          </div>
        </div>
        <div class="d-flex my-3 w-100 justify-content-end">
          <div class="form-check form-switch">
            <label class="form-check-label" for="defaultAddress">
              <h4
                *ngIf="!payment.defaultPaymentInfo"
                class="fs-7 m-0 fw-semibold">
                {{ 'rrs.addressBook.makeDefault' | cxTranslate }}
              </h4>
              <h4
                *ngIf="payment.defaultPaymentInfo"
                class="fs-7 m-0 fw-semibold">
                {{ 'rrs.addressBook.default' | cxTranslate }}
              </h4>
            </label>
            <input
              class="form-check-input m-0 mx-3"
              type="checkbox"
              role="switch"
              id="defaultAddress"
              data-cy="default"
              (click)="setDefaultPaymentMethod(payment)"
              [class.pe-none]="payment.defaultPaymentInfo"
              [checked]="payment.defaultPaymentInfo" />
          </div>
          <button
            type="button"
            class="me-3"
            data-cy="delete"
            (click)="openConfirmationModal(payment)">
            <rrs-icon
              [size]="24"
              [icon]="icon.CLOSE_BIG"
              [attr.aria-label]="'rrs.common.remove' | cxTranslate"></rrs-icon>
          </button>
          <!-- TODO: Once endpoints are made implement editing and adding new payments
          <button type="button" data-cy="edit" (click)="openFormModal('')">
            <rrs-icon
              [size]="24"
              [icon]="icon.EDIT"
              [attr.aria-label]="'rrs.common.edit' | cxTranslate"></rrs-icon>
          </button> -->
        </div>
      </div>
      <footer class="p-2 bg-stripe"></footer>
    </div>
    <p *ngIf="!payments.length" class="mt-3 ms-2 mb-5">
      {{ 'rrs.savedPayments.description' | cxTranslate }}
    </p>

    <!-- <div class="col-lg-6 payment">
      <div class="d-flex m-3 flex-column justify-content-center text-center">
        <p class="mb-5">{{ 'rrs.savedPayments.newCard' | cxTranslate }}</p>
        <button
          type="button"
          (click)="openFormModal('')"
          [attr.aria-label]="'rrs.common.add' | cxTranslate">
          <rrs-icon [size]="28" [icon]="icon.ADD_CIRCLE"></rrs-icon>
        </button>
      </div>
    </div> -->
  </div>
</div>
