import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RrsIconComponent } from '@app/shared/components/rrs-icon/rrs-icon.component';

@NgModule({
  declarations: [RrsIconComponent],
  exports: [RrsIconComponent],
  imports: [CommonModule],
})
export class RrsIconModule {}
