import { Directive, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hideMegaMenu]',
})
export class HideMegaMenuDirective {
  constructor(@Inject(DOCUMENT) protected document: Document) {}

  @HostListener('click') onMenuItemClick(): void {
    const header = this.document.querySelector('.mega-menu');

    header?.classList.add('d-none');

    setTimeout(() => {
      header?.classList.remove('d-none');
    }, 0);
  }
}
