<section class="container-fluid featured-brands" *ngIf="data$ | async as data">
  <div class="row mb-4" *ngFor="let item of data">
    <div
      class="col-12 col-lg-3 d-flex align-items-center justify-content-center">
      <a
        [routerLink]="item.url"
        class="fs-3 lh-sm text-light text-center text-uppercase py-4 text-decoration-none">
        {{ item.title }}
      </a>
    </div>
    <div class="col-12 col-lg-9">
      <div class="py-lg-4 featured-brands__scroll-container">
        <ul class="list-none featured-brands__scroll">
          <li
            *ngFor="let brand of item.brands"
            class="d-flex align-items-center justify-content-center">
            <a
              [routerLink]="brand.brand.url"
              [attr.title]="brand.brand.alt_text">
              <img
                [attr.src]="brand.brand.image_file.url"
                [attr.alt]="brand.brand.alt_text"
                height="140"
                width="140" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
