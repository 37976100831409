import { Cart } from '@spartacus/cart/base/root';
import { CartEventInfo } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/model/events';

export function getCartInfo(cart: Cart): CartEventInfo {
  return {
    email: cart.user?.uid != 'anonymous' ? cart.user?.uid : undefined,
    cart_id: cart.code ?? '',
    promoCode: cart.appliedVouchers?.[0]?.code || '',
    items: (cart.entries || []).map((entry) => ({
      productInfo: {
        colorVariant: entry.product?.code ?? '',
        sku: entry.product?.baseProduct ?? '',
        categories: entry.product?.categories,
        name: entry.product?.name,
        productBrand:
          entry.product?.categories?.find((category) =>
            category.code?.includes('brand')
          )?.name || '',
        price: entry.totalPrice?.value?.toString() ?? '0.00',
        quantity: entry.quantity?.toString() ?? '1',
      },
    })),
  };
}
