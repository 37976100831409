<div class="px-2 px-lg-0 mb-4 order-summary">
  <h2 class="fs-5 pb-2 text-capitalize order-summary__header">
    {{ 'cart.orderSummary.orderSummary' | cxTranslate }}
  </h2>

  <ul *ngIf="cart" class="list-none">
    <li class="d-flex align-items-center justify-content-between fs-5 mb-4">
      <span>{{ 'cart.orderSummary.subtotal' | cxTranslate }}</span>
      <span>{{ cart.subTotal?.formattedValue }}</span>
    </li>

    <ng-container *ngIf="cart.totalDiscounts?.value || getRewardsSummary">
      <li>
        <span class="d-inline-flex mb-2">
          {{ 'cart.orderSummary.discounts' | cxTranslate }}
        </span>

        <div class="hr"></div>
      </li>

      <li
        *ngIf="getRewardsSummary"
        class="d-flex align-items-center justify-content-between my-1">
        <span>{{ 'cart.orderSummary.rewards' | cxTranslate }}</span>
        <span>{{ getRewardsSummary | currency: 'USD' }}</span>
      </li>

      <li
        *ngIf="cart.orderDiscounts?.value"
        class="d-flex align-items-center justify-content-between my-1">
        <span>{{ 'cart.orderSummary.orderPromotions' | cxTranslate }}</span>
        <span>-{{ cart.orderDiscounts?.formattedValue }}</span>
      </li>

      <li
        *ngIf="cart.productDiscounts?.value"
        class="d-flex align-items-center justify-content-between my-1">
        <span>{{ 'cart.orderSummary.productPromotions' | cxTranslate }}</span>
        <span>-{{ cart.productDiscounts?.formattedValue }}</span>
      </li>

      <li class="d-flex align-items-center justify-content-between my-1">
        <span>{{ 'cart.orderSummary.totalSavings' | cxTranslate }}</span>
        <span>{{ cart.totalDiscounts?.formattedValue }}</span>
      </li>

      <li>
        <div class="hr"></div>
      </li>
    </ng-container>

    <li class="d-flex align-items-center justify-content-between mt-2">
      <span>{{ 'cart.orderSummary.tax' | cxTranslate }}</span>
      <span>{{ cart.totalTax?.formattedValue }}</span>
    </li>

    <li class="d-flex align-items-center justify-content-between mb-2">
      <span>{{ 'cart.orderSummary.taxExplanation' | cxTranslate }}</span>
    </li>

    <li
      *ngIf="!isBOPUIS(cart.entries)"
      class="d-flex align-items-center justify-content-between">
      <span>{{ 'cart.orderSummary.shipping' | cxTranslate }}</span>
      <span>
        {{
          cart.deliveryCost?.formattedValue
            ? cart.deliveryCost.formattedValue
            : ('cart.orderSummary.freeDeliveryCost' | cxTranslate)
        }}
      </span>
    </li>

    <li
      class="d-flex align-items-center justify-content-between fw-bold fs-5 mt-4">
      <span>{{ 'cart.orderSummary.totalEstimated' | cxTranslate }}</span>
      <span class="order-revenue">{{
        cart.totalPriceWithTax?.formattedValue
      }}</span>
    </li>
  </ul>
</div>
