import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';

export const rrsAccountNavigation: Contentstack.HeaderMenu = {
  link: {
    href: '/',
    title: 'My Profile',
  },
  link_list: [
    {
      link: {
        href: '/my-account/rewards',
        title: 'rewards',
      },
      link_list: [],
    },
    {
      link: {
        href: '/my-account/profile',
        title: 'profile',
      },
      link_list: [],
    },
    {
      link: {
        href: '/my-account/address-book',
        title: 'shippingAddress',
      },
      link_list: [],
    },
    {
      link: {
        href: '/my-account/payment-details',
        title: 'savedPayments',
      },
      link_list: [],
    },
    {
      link: {
        href: '/my-account/wishlists',
        title: 'wishLists',
      },
      link_list: [],
    },
    {
      link: {
        href: '/my-account/order-history',
        title: 'purchaseHistory',
      },
      link_list: [],
    },
    {
      link: {
        href: '/my-account/claim-rewards',
        title: 'claimRewards',
      },
      link_list: [],
    },
    {
      link: {
        action: 'signOut',
        title: 'signOut',
      },
      link_list: [],
    },
  ],
};
