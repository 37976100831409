import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { AccordionItemDirective } from '@app/shared/components/rrs-accordion/directives/rrs-accordion-item.directive';
import { expandCollapse } from '@app/shared/animations';

@Component({
  selector: 'rrs-accordion',
  templateUrl: './rrs-accordion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandCollapse],
})
export class RrsAccordionComponent implements OnChanges, AfterContentInit {
  @Input() defaultExpanded: boolean = false;
  expanded = new Set<number>();

  @Input() collapsing = true;

  @ContentChildren(AccordionItemDirective)
  items!: QueryList<AccordionItemDirective>;

  ngAfterContentInit(): void {
    if (this.defaultExpanded) this.expandAll();
  }

  getToggleState = (index: number): any => {
    return this.toggleState.bind(this, index);
  };

  toggleState = (index: number): void => {
    if (this.expanded.has(index)) {
      this.expanded.delete(index);
    } else {
      if (this.collapsing) {
        this.expanded.clear();
      }
      this.expanded.add(index);
    }
  };

  private expandAll(): void {
    this.items?.forEach((_, index) => this.expanded.add(index));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.defaultExpanded?.previousValue !==
        changes.defaultExpanded?.currentValue &&
      this.items
    ) {
      if (changes.defaultExpanded.currentValue) {
        this.expandAll();
      } else {
        this.expanded.clear();
      }
    }
  }
}
