<div class="modal-header">
  <rrs-icon [icon]="icon.CHECK" [size]="20" class="me-2"></rrs-icon>
  <h5 class="modal-title" id="modal-basic-title">
    {{ 'rrs.miniCart.addedToCart' | cxTranslate }}
  </h5>
  <button
    (click)="close()"
    [attr.aria-label]="'rrs.common.closeModal' | cxTranslate"
    class="btn-close"
    type="button"
    cxModal="dismiss"
    cxModalReason="Cross click"></button>
</div>
<div *ngIf="entry$ | async as entry" class="pb-3 px-0 modal-body">
  <rrs-mini-product-card [entry]="entry"></rrs-mini-product-card>
</div>
<div class="p-3 mt-2">
  <a
    [routerLink]="'/cart'"
    (click)="close()"
    class="btn btn-sm btn-primary w-100 mb-3"
    >{{ 'rrs.miniCart.viewCart' | cxTranslate }}</a
  >
</div>
