<ng-container *ngIf="savedCart$ | async as cart">
  <div
    class="saved-cart__action-btn-container d-flex py-3 justify-content-lg-end justify-content-center border-bottom border-accent">
    <button
      class="saved-cart__action-btn btn btn-sm btn-primary text-uppercase"
      (click)="restoreSavedCart(cart)">
      {{ 'rrs.savedCart.restoreWishlist' | cxTranslate }}
    </button>
  </div>
  <div class="d-flex py-3 justify-content-end">
    <a
      class="saved-cart__action-btn saved-cart__action-btn--back btn btn-link btn-sm me-2 text-uppercase text-white"
      [routerLink]="{ cxRoute: 'savedCarts' } | cxUrl">
      {{ 'rrs.savedCart.backToWishlists' | cxTranslate }}
    </a>
    <button
      class="saved-cart__action-btn btn btn-primary btn-sm text-uppercase"
      (click)="openDialog(cart, savedCartFormType.DELETE)">
      {{ 'rrs.savedCart.deleteWishlist' | cxTranslate }}
    </button>
  </div>
</ng-container>
