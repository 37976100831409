<img
  [attr.alt]="
    (store.isOffBroadway
      ? 'common.images.offBroadwayLogoAlt'
      : 'common.images.rrsLogoAlt'
    ) | cxTranslate
  "
  [attr.src]="
    store.isOffBroadway
      ? '/assets/images/offBroadway.svg'
      : '/assets/images/rackRoomShoes.svg'
  "
  [height]="size"
  [width]="size" />
