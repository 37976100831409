import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CmsComponent, PointOfService } from '@spartacus/core';
import { RrsStoreFinderAdapter } from '@app/custom/features/rrs-storefinder/occ/rrs-store-finder.adapter';
import {
  RrsPointOfService,
  SpecificStoresList,
} from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { map } from 'rxjs/operators';
import { RrsStoreFinderService } from '@app/custom/features/rrs-storefinder/services/rrs-store-finder.service';

export interface CmsStoreFinderComponent extends CmsComponent {}

@Component({
  selector: 'rrs-store-finder',
  templateUrl: './rrs-store-finder.component.html',
  styleUrls: ['./rrs-store-finder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsStoreFinderComponent implements OnInit {
  selectedStore$ = new BehaviorSubject<PointOfService | null>(null);
  specificStoresList$!: Observable<SpecificStoresList>;

  constructor(
    protected storeFinderAdapter: RrsStoreFinderAdapter,
    protected storeFinderService: RrsStoreFinderService
  ) {}

  ngOnInit(): void {
    this.storeFinderService.clearStoresData();
    this.initUpdatedStores();
  }

  selectStore(store: PointOfService): void {
    this.selectedStore$.next(store);
  }

  private initUpdatedStores(): void {
    this.specificStoresList$ = this.storeFinderAdapter.getUpdatedStores().pipe(
      map((updatedStores) => {
        return updatedStores?.pointOfServices?.reduce<SpecificStoresList>(
          (acc: SpecificStoresList, store: RrsPointOfService) => {
            if (store?.nowOpen) {
              acc.openedRecently.push(store);
            } else if (store.comingSoon) {
              acc.comingSoon.push(store);
            }
            return acc;
          },
          { openedRecently: [], comingSoon: [] }
        );
      })
    );
  }
}
