import { Component, OnInit } from '@angular/core';
import { OrderSummaryComponent } from '@spartacus/cart/base/components';
import { isBOPUIS } from '@app/custom/features/rrs-cart/utils/cart';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';

@Component({
  selector: 'rrs-order-summary',
  styleUrls: ['./rrs-order-summary.component.scss'],
  templateUrl: './rrs-order-summary.component.html',
})
export class RrsOrderSummaryComponent
  extends OrderSummaryComponent
  implements OnInit
{
  isBOPUIS = isBOPUIS;

  constructor(protected activeCartService: RrsActiveCartService) {
    super();
  }

  ngOnInit(): void {
    this.activeCartService.reloadActiveCart();
  }
  get getRewardsSummary(): number {
    const rewardsSummary = (this.cart?.rewards || [])
      .filter((item) => item?.active)
      .reduce((prev, next) => prev + next.value, 0);

    return rewardsSummary;
  }
}
