import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { RrsOnetrustService } from '@app/custom/features/rrs-onetrust/rrs-onetrust.service';
import { RrsFreshChatService } from '@app/custom/features/rrs-freshchat/rrs-freshchat.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cms-footer-navi',
  styleUrls: ['./rrs-cms-footer-navi.component.scss'],
  templateUrl: './rrs-cms-footer-navi.component.html',
})
export class RrsCmsFooterNaviComponent {
  iconTypeList = ICON_TYPE_LIST;
  isDesktop$ = this.breakpointService.isUp(BREAKPOINT.lg);

  @Input() navigation!: Contentstack.FooterPrimaryNavigation[];

  constructor(
    protected breakpointService: BreakpointService,
    protected oneTrustService: RrsOnetrustService,
    protected freshChatService: RrsFreshChatService
  ) {}

  // TODO: We should discuss how to trigger such actions from the CMS
  customAction(type: string): void {
    switch (type) {
      case 'liveChat':
        this.freshChatService.open();
        break;
      case 'cookieConsent':
        this.oneTrustService.showModal();
        break;
    }
  }
}
