import { BehaviorSubject, Observable, combineLatest, using } from 'rxjs';
import {
  ProductListRouteParams,
  SearchCriteria,
  ViewConfig,
  ViewModes,
} from '@spartacus/storefront';
import { Injectable } from '@angular/core';
import { RrsSearchCriteria } from '@app/custom/features/rrs-search/models/rrs-search.model';
import { ActivatedRoute, Router } from '@angular/router';
import {
  RoutingService,
  CurrencyService,
  LanguageService,
  ProductSearchPage,
  ActivatedRouterStateSnapshot,
  RouterState,
} from '@spartacus/core';
import { RrsProductSearchService } from '@app/custom/features/rrs-product-listing/services/rrs-product-search/rrs-product-search.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  tap,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsProductListComponentService {
  constructor(
    protected productSearchService: RrsProductSearchService,
    protected routing: RoutingService,
    protected activatedRoute: ActivatedRoute,
    protected currencyService: CurrencyService,
    protected languageService: LanguageService,
    protected router: Router,
    protected config: ViewConfig
  ) {}

  /**
   * Emits the search results for the current search query.
   *
   * The `searchResults$` is _not_ concerned with querying, it only observes the
   * `productSearchService.getResults()`
   */
  protected searchResults$: Observable<ProductSearchPage> =
    this.productSearchService
      .getResults()
      .pipe(filter((searchResult) => Object.keys(searchResult).length > 0));

  /**
   * Observes the route and performs a search on each route change.
   *
   * Context changes, such as language and currencies are also taken
   * into account, so that the search is performed again.
   */
  protected searchByRouting$: Observable<ActivatedRouterStateSnapshot> =
    combineLatest([
      this.routing.getRouterState().pipe(
        distinctUntilChanged((x, y) => {
          // router emits new value also when the anticipated `nextState` changes
          // but we want to perform search only when current url changes
          return x.state.url === y.state.url;
        })
      ),
      ...this.siteContext,
    ]).pipe(
      debounceTime(0),
      map(([routerState, ..._context]) => (routerState as RouterState).state),
      tap((state: ActivatedRouterStateSnapshot) => {
        const criteria = this.getCriteriaFromRoute(
          state.params,
          state.queryParams,
          state.url
        );
        this.search(criteria);
      })
    );

  /**
   * This stream is used for the Product Listing and Product Facets.
   *
   * It not only emits search results, but also performs a search on every change
   * of the route (i.e. route params or query params).
   *
   * When a user leaves the PLP route, the PLP component unsubscribes from this stream
   * so no longer the search is performed on route change.
   */
  readonly model$: Observable<ProductSearchPage> = using(
    () => this.searchByRouting$.subscribe(),
    () => this.searchResults$
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

  private _viewMode$ = new BehaviorSubject<ViewModes>(ViewModes.Grid);

  get viewMode$(): Observable<ViewModes> {
    return this._viewMode$.asObservable();
  }

  protected getCriteriaFromRoute(
    routeParams: ProductListRouteParams,
    queryParams: RrsSearchCriteria,
    url: string
  ): RrsSearchCriteria {
    const criteria: RrsSearchCriteria = {
      pageSize: queryParams.pageSize || this.config.view?.defaultPageSize,
      currentPage: queryParams.currentPage,
      sortCode: queryParams.sortCode,
    };

    if (url.includes('/search')) {
      /* Search PLP */
      criteria.query =
        queryParams.query || this.getQueryFromRouteParams(routeParams);

      criteria.facetFilters = queryParams.facetFilters;
    } else {
      /* Category PLP */
      criteria.query = '';
      criteria.facetFilters = this.getFiltersFromRouteParams(
        routeParams,
        queryParams
      );
    }

    return criteria;
  }

  /**
   * Resolves the search query from the given `ProductListRouteParams`.
   */
  protected getQueryFromRouteParams({ query }: ProductListRouteParams): string {
    if (query) {
      return query;
    }
    return '';
  }

  protected getFiltersFromRouteParams(
    { query }: ProductListRouteParams,
    { facetFilters }: RrsSearchCriteria
  ): string {
    const parsedFacetFilters = facetFilters ? JSON.parse(facetFilters) : [];
    if (query && query !== 'all') {
      parsedFacetFilters.push(`categoryPageId:${query}`);
    }
    return JSON.stringify(parsedFacetFilters);
  }

  protected search(criteria: RrsSearchCriteria): void {
    const currentPage = criteria.currentPage;
    const pageSize = criteria.pageSize;
    const sort = criteria.sortCode;
    const facetFilters = criteria.facetFilters;

    this.productSearchService.search(
      criteria.query as string,
      Object.assign(
        {},
        facetFilters && { facetFilters },
        currentPage && { currentPage },
        pageSize && { pageSize },
        sort && { sort }
      )
    );
  }

  setViewMode(mode: ViewModes): void {
    this._viewMode$.next(mode);
  }

  /**
   * The site context is used to update the search query in case of a
   * changing context. The context will typically influence the search data.
   *
   * We keep this private for now, as we're likely refactoring this in the next
   * major version.
   */
  private get siteContext(): Observable<string>[] {
    // TODO: we should refactor this so that custom context will be taken
    // into account automatically. Ideally, we drop the specific context
    // from the constructor, and query a ContextService for all contexts.

    return [this.languageService.getActive(), this.currencyService.getActive()];
  }

  /**
   * Get items from a given page without using navigation
   */
  getPageItems(pageNumber: number): void {
    this.routing
      .getRouterState()
      .subscribe((route) => {
        const routeCriteria = this.getCriteriaFromRoute(
          route.state.params,
          route.state.queryParams,
          route.state.url
        );
        const criteria = {
          ...routeCriteria,
          currentPage: pageNumber,
        };
        this.search(criteria);
      })
      .unsubscribe();
  }

  /**
   * Sort the search results by the given sort code.
   */
  sort(sortCode: string): void {
    this.route({ sortCode });
  }

  /**
   * Routes to the next product listing page, using the given `queryParams`. The
   * `queryParams` support sorting, pagination and querying.
   *
   * The `queryParams` are delegated to the Angular router `NavigationExtras`.
   */
  protected route(queryParams: SearchCriteria): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
    });
  }
}
