import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RrsFreshChatService } from '@app/custom/features/rrs-freshchat/rrs-freshchat.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cms-need-help',
  styleUrls: ['./rrs-cms-need-help.component.scss'],
  templateUrl: './rrs-cms-need-help.component.html',
})
export class RrsCmsNeedHelpComponent {
  data$ = this.componentData.data$;
  icon = ICON_TYPE_LIST;
  isCollapsed = true;

  constructor(
    protected componentData: CmsComponentData<Contentstack.NeedHelp>,
    protected modalService: NgbModal,
    protected freshChatService: RrsFreshChatService
  ) {}

  onCustomLinkClick(type: string): void {
    if (type === 'liveChat') {
      this.freshChatService.open();
    }
    this.closeModal();
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  open(content: any): void {
    this.modalService.open(content);
  }
}
