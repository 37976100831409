import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  RrsPaymentTranslations,
  RrsPaymentTypes,
} from '@app/custom/features/rrs-checkout/model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentDetails, UserPaymentService } from '@spartacus/core';

@Component({
  selector: 'rrs-saved-payments-confirmation-confirmation',
  templateUrl: './rrs-saved-payments-confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsSavedPaymentsConfirmationComponent {
  paymentTypes = RrsPaymentTypes;
  payment!: PaymentDetails;
  paymentTranslations = RrsPaymentTranslations;

  constructor(
    public activeModal: NgbActiveModal,
    protected userPaymentService: UserPaymentService
  ) {}

  removePayment(payment: PaymentDetails): void {
    this.userPaymentService.deletePaymentMethod(payment.id || '');
    this.activeModal.dismiss();
  }
}
