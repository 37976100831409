import {
  miniCart,
  NOTIFICATION_DURATION,
} from '@app/custom/features/rrs-mini-cart/configs/rrs-mini-cart.config';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RrsMiniCartService {
  private isHoveredOver: boolean = false;
  private miniCartSubject = new Subject<miniCart>();
  miniCart$ = this.miniCartSubject.asObservable();

  open(miniCartTitle: string = '', isHovered: boolean = true): void {
    this.miniCartSubject.next({ miniCartTitle, collapsed: false });
    this.isHoveredOver = isHovered;
  }

  close(miniCartTitle: string = ''): void {
    this.miniCartSubject.next({ miniCartTitle, collapsed: true });
    this.isHoveredOver = false;
  }

  triggerNotification(message: string = ''): void {
    this.open(message, false);
    setTimeout(() => {
      if (this.isHoveredOver) return;
      this.close(message);
    }, NOTIFICATION_DURATION);
  }
}
