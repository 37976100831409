import { Injectable } from '@angular/core';
import { RrsUserProfileConnector } from '@app/custom/features/rrs-profile/services/rrs-user-profile.connector';
import {
  AuthService,
  EventService,
  UserIdService,
  QueryService,
  CommandService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { UserProfileService } from '@spartacus/user/profile/core';

@Injectable({ providedIn: 'root' })
export class RrsUserProfileService extends UserProfileService {
  constructor(
    userAccountService: UserAccountFacade,
    authService: AuthService,
    protected userProfileConnector: RrsUserProfileConnector,
    eventService: EventService,
    userIdService: UserIdService,
    query: QueryService,
    command: CommandService
  ) {
    super(
      userAccountService,
      authService,
      userProfileConnector,
      eventService,
      userIdService,
      query,
      command
    );
  }
}
