<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{
      true
        ? ('rrs.common.edit' | cxTranslate)
        : ('rrs.common.add' | cxTranslate)
    }}
    {{ 'rrs.savedPayments.payment' | cxTranslate }}
  </h4>
  <button
    (click)="activeModal.dismiss('Cross click')"
    [attr.aria-label]="'rrs.common.closeModal' | cxTranslate"
    class="btn-close"
    cxModal="dismiss"
    cxModalReason="Cross click"
    type="button"></button>
</div>

<div class="modal-body">
  <form [formGroup]="paymentForm">
    <h5 class="fw-semibold mt-4 mb-3">
      {{ 'rrs.savedPayments.creditDebitCard' | cxTranslate }}
    </h5>

    <div class="form-group mb-3 w-100">
      <rrs-card-number-input
        [label]="('rrs.savedPayments.cardNumber' | cxTranslate) + '*'"
        [placeholder]="
          'rrs.savedPayments.cardNumber' | cxTranslate
        "></rrs-card-number-input>
      <rrs-form-errors aria-atomic="true" aria-live="assertive">
      </rrs-form-errors>
    </div>

    <div class="d-flex gap-3 mb-3 flex-column flex-sm-row">
      <div class="form-group w-100">
        <label class="form-label" for="payment-exp"
          >{{ 'rrs.checkout.paymentForm.exp' | cxTranslate }}*</label
        >
        <input
          aria-required="true"
          class="form-control"
          id="payment-exp"
          placeholder="{{
            'rrs.checkout.paymentForm.expPlaceholder' | cxTranslate
          }}"
          type="text" />
        <rrs-form-errors aria-atomic="true" aria-live="assertive">
        </rrs-form-errors>
      </div>

      <div class="form-group w-100">
        <label class="form-label" for="payment-cvc">
          {{ 'rrs.checkout.paymentForm.cvn' | cxTranslate }}*
        </label>
        <input
          aria-required="true"
          class="form-control"
          id="payment-cvc"
          placeholder="{{ 'rrs.checkout.paymentForm.cvn' | cxTranslate }}"
          type="text" />
        <rrs-form-errors aria-atomic="true" aria-live="assertive">
        </rrs-form-errors>
      </div>
    </div>

    <div class="d-flex gap-3 mb-3 flex-column flex-sm-row">
      <div class="form-group w-100">
        <label class="form-label" for="address-firstName">
          {{ 'rrs.addressBook.form.firstName' | cxTranslate }}*
        </label>
        <input
          aria-required="true"
          class="form-control"
          id="address-firstName"
          placeholder="{{ 'rrs.addressBook.form.firstName' | cxTranslate }}"
          type="text" />
        <rrs-form-errors aria-atomic="true" aria-live="assertive">
        </rrs-form-errors>
      </div>

      <div class="form-group w-100">
        <label class="form-label" for="address-lastName">
          {{ 'rrs.addressBook.form.lastName' | cxTranslate }}*
        </label>
        <input
          aria-required="true"
          class="form-control"
          id="address-lastName"
          placeholder="{{ 'rrs.addressBook.form.lastName' | cxTranslate }}"
          type="text" />
        <rrs-form-errors aria-atomic="true" aria-live="assertive">
        </rrs-form-errors>
      </div>
    </div>

    <div class="d-flex gap-3 mb-3 flex-column flex-sm-row">
      <div class="form-group w-100">
        <label class="form-label" for="addres-line1">
          {{ 'rrs.addressBook.form.addressOne' | cxTranslate }}*
        </label>
        <input
          aria-required="true"
          class="form-control"
          id="addres-line1"
          placeholder="{{ 'rrs.addressBook.form.addressOne' | cxTranslate }}"
          type="text" />
        <rrs-form-errors aria-atomic="true" aria-live="assertive">
        </rrs-form-errors>
      </div>

      <div class="form-group w-100">
        <label class="form-label" for="addres-line2">
          {{ 'rrs.addressBook.form.addressTwo' | cxTranslate }}
        </label>
        <input
          aria-required="true"
          class="form-control"
          id="addres-line2"
          placeholder="{{ 'rrs.addressBook.form.addressTwo' | cxTranslate }}"
          type="text" />
        <rrs-form-errors aria-atomic="true" aria-live="assertive">
        </rrs-form-errors>
      </div>
    </div>

    <div class="d-flex gap-3 mb-3 flex-column flex-sm-row">
      <div class="form-group w-100">
        <label class="form-label" for="addres-city">
          {{ 'rrs.addressBook.form.city' | cxTranslate }}*
        </label>
        <input
          aria-required="true"
          class="form-control"
          id="addres-city"
          placeholder="{{ 'rrs.addressBook.form.city' | cxTranslate }}"
          type="text" />
        <rrs-form-errors aria-atomic="true" aria-live="assertive">
        </rrs-form-errors>
      </div>

      <ng-container *ngIf="regions$ | async as regions">
        <div *ngIf="regions.length !== 0" class="form-group w-100">
          <label class="form-label" for="addres-city">
            {{ 'rrs.addressBook.form.state' | cxTranslate }}*
          </label>
          <ng-select
            [clearable]="false"
            [items]="regions"
            [searchable]="false"
            aria-required="true"
            bindLabel="{{ regions[0].name ? 'name' : 'isocode' }}"
            bindValue="{{ regions[0].name ? 'isocode' : 'region' }}"
            class="region-select"
            id="region"
            placeholder="{{ 'rrs.addressBook.form.state' | cxTranslate }}">
          </ng-select>
          <div class="text-danger fst-italic">
            {{ 'formErrors.required' | cxTranslate }}
          </div>
        </div>
      </ng-container>
    </div>

    <div class="d-flex gap-3 mb-3 flex-column flex-sm-row">
      <div class="form-group w-100">
        <label class="form-label" for="addres-zip">
          {{ 'rrs.addressBook.form.zip' | cxTranslate }}*
        </label>
        <input
          aria-required="true"
          class="form-control"
          id="addres-zip"
          maxlength="5"
          placeholder="{{ 'rrs.addressBook.form.zip' | cxTranslate }}"
          rrsZipcodeInput
          type="text" />
        <rrs-form-errors aria-atomic="true" aria-live="assertive">
        </rrs-form-errors>
      </div>

      <div class="form-group w-100">
        <label class="form-label" for="address-phone">
          {{ 'rrs.addressBook.form.phone' | cxTranslate }}*
        </label>

        <input
          aria-required="true"
          class="form-control"
          id="address-phone"
          placeholder="{{ 'rrs.addressBook.form.phone' | cxTranslate }}"
          rrsPhoneNumberInput
          type="text" />
        <rrs-form-errors aria-atomic="true" aria-live="assertive">
        </rrs-form-errors>
      </div>
    </div>

    <div class="d-flex gap-3">
      <button
        (click)="activeModal.dismiss('Cross click')"
        class="btn btn-secondary"
        type="button">
        {{ 'rrs.common.cancel' | cxTranslate }}
      </button>

      <button class="btn btn-primary" type="submit">
        {{ 'rrs.common.save' | cxTranslate }}
      </button>
    </div>
  </form>
</div>
