import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '@app/shared/configs';
import { CustomFormValidators } from '@spartacus/storefront';
import { CustomValidators } from '@app/shared/utils';
import { Injectable } from '@angular/core';
import { ResetPasswordComponentService } from '@spartacus/user/profile/components';

@Injectable({
  providedIn: 'root',
})
export class RrsSetPasswordService extends ResetPasswordComponentService {
  form: UntypedFormGroup = new UntypedFormGroup(
    {
      password: new UntypedFormControl('', [
        Validators.required,
        CustomValidators.lengthRange(PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH),
        CustomValidators.lowerCase,
        CustomValidators.numeric,
        CustomValidators.specialChars,
        CustomValidators.upperCase,
      ]),
      passwordConfirm: new UntypedFormControl('', Validators.required),
    },
    {
      validators: CustomFormValidators.passwordsMustMatch(
        'password',
        'passwordConfirm'
      ),
    }
  );

  protected redirect(): void {
    this.routingService.go({ cxRoute: 'home' });
  }
}
