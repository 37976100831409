import { Injectable } from '@angular/core';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import {
  CONTENTSTACK_CMS_PAGE_LIST,
  CONTENTSTACK_CMS_PAGE_NORMALIZER,
  CONTENTSTACK_CMS_PAGE_TYPE,
  GLOBAL_CONTENT_TYPE,
  RrsCheckoutStepsParams,
  RrsCoreContentstackIncludes,
  RrsGlobalContentstackIncludes,
  RrsPageContentToTemplateMap,
} from '@app/custom/features/rrs-cms/configs/contentstack.config';
import { RrsContentstackService } from '@app/custom/features/rrs-cms/services/rrs-contentstack.service';
import { Cart } from '@spartacus/cart/base/root';
import {
  CmsPageAdapter,
  CmsStructureModel,
  Config,
  ConverterService,
  PageContext,
  PageType,
  RoutingService,
} from '@spartacus/core';
import { LayoutSlotConfig, SlotConfig, SlotGroup } from '@spartacus/storefront';
import { Observable, forkJoin, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';

@Injectable()
export class RrsCmsPageAdapter implements CmsPageAdapter {
  constructor(
    protected activeCartService: RrsActiveCartService,
    protected converter: ConverterService,
    protected routingService: RoutingService,
    protected rrsContentstackService: RrsContentstackService,
    protected config: Config
  ) {}

  /**
   * Get correct observable based on the page context.
   * Because we have to choose between loading a `cart` or `emptyCart` content type from Contenstack,
   * for the cart page we will use loaded active cart observable.
   * For other pages, we don't need cart data, so we will use empty object to not block adapter from loading cms.
   * @param pageContext
   */
  getCartObservableObject(pageContext: PageContext): Observable<Cart> {
    if (pageContext.id.includes('cart')) {
      return this.activeCartService.requireLoadedCart();
    } else {
      return of({});
    }
  }

  load(pageContext: PageContext): Observable<CmsStructureModel> {
    return this.getCartObservableObject(pageContext)
      .pipe(withLatestFrom(this.routingService.getRouterState()))
      .pipe(
        take(1),
        switchMap(([cart, routerState]) => {
          const pageContentType = this.getPageContentType(pageContext, cart);
          const template = this.generateTemplateName(pageContentType);
          const nextState = routerState?.nextState || routerState.state;
          const urlPath = this.getUrlPath(nextState!.url);

          return forkJoin([
            this.rrsContentstackService.loadContent(GLOBAL_CONTENT_TYPE, {
              include: RrsGlobalContentstackIncludes,
            }),
            this.rrsContentstackService.loadContent(pageContentType, {
              include: RrsCoreContentstackIncludes,
              query: `{ "$or": [{ "url_path": "*" }, { "url_path": "${urlPath}" }] }`,
            }),
          ]).pipe(
            this.converter.pipeable(CONTENTSTACK_CMS_PAGE_NORMALIZER),
            map((structure: CmsStructureModel): CmsStructureModel => {
              return this.addCmsComponents(structure, template);
            }),
            catchError((error) => {
              console.error(error);
              return of({});
            })
          );
        })
      );
  }

  getUrlPath(currentUrl: string): string {
    if (currentUrl?.includes(CONTENTSTACK_CMS_PAGE_LIST.CHECKOUT)) {
      return CONTENTSTACK_CMS_PAGE_LIST.CHECKOUT;
    }

    if (currentUrl?.includes(CONTENTSTACK_CMS_PAGE_LIST.MY_ACCOUNT)) {
      return CONTENTSTACK_CMS_PAGE_LIST.MY_ACCOUNT;
    }

    // get the pathname from the url (e.g. /coupoun?abc=1 -> /coupoun)
    const pathname = currentUrl.match(/^([^?#]+)/)?.[0] ?? currentUrl;
    return pathname;
  }

  generateTemplateName(pageContentType: string): string {
    return RrsPageContentToTemplateMap[pageContentType];
  }

  protected getPageContentType(pageContext: PageContext, cart: Cart): string {
    if (['my-account', 'order-details'].includes(pageContext.id)) {
      return CONTENTSTACK_CMS_PAGE_TYPE.MY_ACCOUNT;
    }

    if (pageContext.id === CONTENTSTACK_CMS_PAGE_LIST.BRANDS) {
      return CONTENTSTACK_CMS_PAGE_TYPE.BRANDS;
    }

    if (pageContext.id === CONTENTSTACK_CMS_PAGE_LIST.LOGIN) {
      return CONTENTSTACK_CMS_PAGE_TYPE.LOGIN;
    }

    if (pageContext.id === CONTENTSTACK_CMS_PAGE_LIST.GIFT_CARDS) {
      return CONTENTSTACK_CMS_PAGE_TYPE.GIFT_CARDS;
    }

    if (pageContext.id === CONTENTSTACK_CMS_PAGE_LIST.CART) {
      if ((cart?.totalItems || 0) > 0) return CONTENTSTACK_CMS_PAGE_TYPE.CART;
      return CONTENTSTACK_CMS_PAGE_TYPE.CART_EMPTY;
    }

    if (pageContext.id === '/not-found') {
      return CONTENTSTACK_CMS_PAGE_TYPE.NOT_FOUND;
    }

    if (pageContext.id.includes('/info')) {
      return CONTENTSTACK_CMS_PAGE_TYPE.CORE_CONTENT;
    }

    if (pageContext.type === PageType.PRODUCT_PAGE) {
      return CONTENTSTACK_CMS_PAGE_TYPE.PRODUCT_DETAILS;
    }

    if (pageContext.id === 'search') {
      return CONTENTSTACK_CMS_PAGE_TYPE.PRODUCT_LISTING;
    }
    if (
      pageContext.id === CONTENTSTACK_CMS_PAGE_LIST.CHECKOUT ||
      RrsCheckoutStepsParams.includes(`${pageContext.id}`)
    ) {
      return CONTENTSTACK_CMS_PAGE_TYPE.CHECKOUT;
    }

    if (pageContext.id === CONTENTSTACK_CMS_PAGE_LIST.STORE_DETAILS) {
      return CONTENTSTACK_CMS_PAGE_TYPE.STORE_DETAILS;
    }

    if (pageContext.id === CONTENTSTACK_CMS_PAGE_LIST.STORE_FINDER) {
      return CONTENTSTACK_CMS_PAGE_TYPE.STORE_FINDER;
    }

    if (pageContext.id.includes(CONTENTSTACK_CMS_PAGE_LIST.BRANDS_LISTING)) {
      return CONTENTSTACK_CMS_PAGE_TYPE.CATEGORY_PAGE;
    }

    if (pageContext.id.includes(CONTENTSTACK_CMS_PAGE_LIST.WRITE_REVIEW)) {
      return CONTENTSTACK_CMS_PAGE_TYPE.WRITE_REVIEW;
    }

    if (pageContext.id === CONTENTSTACK_CMS_PAGE_LIST.CONTACT_US) {
      return CONTENTSTACK_CMS_PAGE_TYPE.CONTACT_US;
    }

    if (pageContext.id.includes(CONTENTSTACK_CMS_PAGE_LIST.ORDER_STATUS)) {
      return CONTENTSTACK_CMS_PAGE_TYPE.ORDER_STATUS;
    }

    if (pageContext.id.includes(CONTENTSTACK_CMS_PAGE_LIST.ORDER_SUMMARY)) {
      return CONTENTSTACK_CMS_PAGE_TYPE.ORDER_SUMMARY;
    }

    if (pageContext.type === PageType.CATEGORY_PAGE) {
      return CONTENTSTACK_CMS_PAGE_TYPE.CATEGORY_PAGE;
    }

    return CONTENTSTACK_CMS_PAGE_TYPE.CONTENT;
  }

  private addCmsComponents(
    structure: CmsStructureModel,
    template: string
  ): CmsStructureModel {
    const targetPage = { ...(structure.page ?? {}), template };
    let targetComponents = [...(structure.components ?? [])];
    const templateConfig = this.config?.layoutSlots?.[template];
    const templateSlots: string[] =
      this.getSlotsFromLayoutConfig(templateConfig);

    templateSlots?.forEach((slot) => {
      const components = this.config.slots?.[slot]?.map((componentId) => ({
        flexType: componentId,
        typeCode: componentId,
        uid: componentId,
      }));
      if (slot && components) {
        targetPage.slots = {
          ...(targetPage.slots || {}),
          [slot]: { components },
        };
      }
      targetComponents = [...(targetComponents || []), ...(components ?? [])];
    });
    return { page: targetPage, components: targetComponents };
  }

  private getSlotsFromLayoutConfig(
    layoutConfig?: LayoutSlotConfig | SlotConfig | SlotGroup
  ): string[] {
    const slots: string[] = [];
    if (layoutConfig) {
      Object.entries(layoutConfig).forEach(([key, value]) => {
        if (key === 'slots' && Array.isArray(value)) {
          slots.push(...value);
        } else if (typeof value === 'object') {
          slots.push(...this.getSlotsFromLayoutConfig(value));
        }
      });
    }
    return slots;
  }
}
