import { Injectable } from '@angular/core';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { RrsScriptLoadService } from '@app/shared/services/rrs-script-load.service';
import { Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { take, tap } from 'rxjs/operators';
import RichTextJavascript = Contentstack.RichTextJavascript;

@Injectable({
  providedIn: 'root',
})
export class RrsCmsRichTextComponentService {
  jsExecutionMap = new Map<string, boolean>();
  jQueryLoaded = false;

  constructor(protected scriptLoadService: RrsScriptLoadService) {}

  setJSExecutedFlag(componentUid: string | undefined): void {
    if (!componentUid) {
      return;
    }
    this.jsExecutionMap.set(componentUid, true);
  }

  checkIfJsExecutedAlready(componentUid?: string): boolean | undefined {
    if (!componentUid) {
      return false;
    }
    return this.jsExecutionMap.get(componentUid);
  }

  injectJQueryScript(): Observable<boolean> {
    if (this.jQueryLoaded) {
      return of(true);
    }
    return fromPromise(
      this.scriptLoadService.loadScript(
        'https://code.jquery.com/jquery-3.7.0.min.js'
      )
    ).pipe(tap(() => (this.jQueryLoaded = true)));
  }

  executeJavascript(richTextJavascriptData: RichTextJavascript): void {
    if (!richTextJavascriptData?.inject_jquery_script) {
      this.evaluateJavascript(richTextJavascriptData?.javascript_content);
    } else {
      this.injectJQueryScript()
        .pipe(take(1))
        .subscribe((a) => {
          this.evaluateJavascript(richTextJavascriptData?.javascript_content);
        });
    }
  }

  evaluateJavascript(script: string): void {
    eval(script);
  }
}
