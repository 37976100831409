import {
  AccordionContentDirective,
  AccordionHeaderDirective,
  AccordionItemDirective,
  AccordionTitleDirective,
} from '@app/shared/components/rrs-accordion/directives';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RrsAccordionComponent } from '@app/shared/components/rrs-accordion/components';

@NgModule({
  declarations: [
    RrsAccordionComponent,
    AccordionItemDirective,
    AccordionContentDirective,
    AccordionTitleDirective,
    AccordionHeaderDirective,
  ],
  imports: [CommonModule],
  exports: [
    RrsAccordionComponent,
    AccordionItemDirective,
    AccordionContentDirective,
    AccordionTitleDirective,
    AccordionHeaderDirective,
  ],
})
export class RrsAccordionModule {}
