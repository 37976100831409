export function interpolate(
  str: string | undefined,
  params: Record<string, string>
): string | undefined {
  return str?.length
    ? str.replace(/\{\{(\w+)\}\}/g, (match, key) => {
        return params.hasOwnProperty(key) ? params[key] : match;
      })
    : str;
}
/**
 *
 * Capitalizes string
 * @param string
 * @returns transformed string
 */
export function titleCase(str?: string): string {
  if (!str) {
    return '';
  }

  return str.toLowerCase().replace(/(^|\s)\S/g, function (match) {
    return match.toUpperCase();
  });
}
