import { CartCouponComponent } from '@spartacus/cart/base/components';
import { CustomerCouponService } from '@spartacus/core';
import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { RrsCartCouponService } from '@app/custom/features/rrs-cart-coupon/services/rrs-cart-coupon.service';
import { BehaviorSubject } from 'rxjs';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'rrs-cart-coupon',
  styleUrls: ['./rrs-cart-coupon.component.scss'],
  templateUrl: './rrs-cart-coupon.component.html',
})
export class RrsCartCouponComponent extends CartCouponComponent {
  showCouponError: string | null = null;
  showCouponSuccess: string | null = null;
  showCouponLoader$ = new BehaviorSubject(false);

  constructor(
    protected activeCartService: RrsActiveCartService,
    protected cartVoucherService: RrsCartCouponService,
    protected customerCouponService: CustomerCouponService,
    protected formBuilder: UntypedFormBuilder
  ) {
    super(
      cartVoucherService,
      formBuilder,
      customerCouponService,
      activeCartService
    );
  }

  get couponCodeValue(): string {
    return this.couponForm.get('couponCode')?.value;
  }

  applyVoucher(): void {
    if (this.couponForm.valid) {
      this.showCouponLoader$.next(true);
      this.hideCouponState();
      this.cartVoucherService
        .addVoucher(this.couponForm.value.couponCode, this.cartId)
        .pipe(take(1))
        .subscribe(
          () => this.onSuccess(true),
          (error) => this.onError(true, error?.error[0]?.message)
        );
    } else {
      this.couponForm.markAllAsTouched();
    }
  }

  hideCouponState(): void {
    this.showCouponError = null;
    this.showCouponSuccess = null;
  }

  onSuccess(success: boolean): void {
    if (success) {
      this.showCouponLoader$.next(false);
      this.showCouponSuccess = this.couponCodeValue;
      this.couponForm.reset();
      this.cartVoucherService.resetAddVoucherProcessingState();
    }
  }

  protected onError(error: boolean, message: string = ''): void {
    if (error) {
      this.showCouponLoader$.next(false);
      this.showCouponError = message;
      this.customerCouponService.loadCustomerCoupons(
        this.MAX_CUSTOMER_COUPON_PAGE
      );
      this.cartVoucherService.resetAddVoucherProcessingState();
    }
  }

  resetVoucherApplyState(): void {
    this.showCouponError = '';
    this.showCouponSuccess = '';
  }
}
