import { RrsProductScope } from '@app/spartacus/configurations/types.config';
import { OccConfig, OccEndpoint, ProductScope } from '@spartacus/core';

declare module '@spartacus/core' {
  interface OccEndpoints {
    getStoreProductStock?: string | OccEndpoint;
  }
}

export const rrsOccProductConfig: OccConfig = {
  backend: {
    loadingScopes: {
      product: {
        [RrsProductScope.ALL]: {
          include: [
            ProductScope.DETAILS,
            ProductScope.VARIANTS,
            RrsProductScope.SIBLINGS,
            RrsProductScope.PDP_AVAILABILITY,
          ],
        },
      },
    },
    occ: {
      endpoints: {
        getStoreProductStock: '/products/${productCode}/stock/${storeName}',
        product: {
          details:
            'products/${productCode}?fields=code,name,summary,price(formattedValue,DEFAULT),images(galleryIndex,FULL),baseProduct,averageRating,stock(DEFAULT),description,availableForPickup,url,numberOfReviews,manufacturer,categories(FULL),tags,purchasable,baseOptions(DEFAULT)',
          price:
            'products/${productCode}?fields=price(formattedValue,isVisible)',
          siblings: 'products/${productCode}/siblings?fields=FULL',
          pdpAvailability: 'products/${productCode}/pdpAvailability',
        },
      },
    },
  },
};
