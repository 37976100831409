<ng-container *ngIf="activities$ | async as activities">
  <section>
    <header class="px-3 px-lg-0 mb-4">
      <h2 class="fs-3 fw-normal text-capitalize mb-0" data-cy="activity-header">
        {{ 'rrs.account.activity.pointsHistory' | cxTranslate }}
      </h2>
    </header>

    <ng-container
      *ngIf="activities?.memberTransactionItems?.length; else noActivity">
      <table class="table table-striped mb-4">
        <thead>
          <tr>
            <th scope="col">
              {{ 'rrs.account.activity.amount' | cxTranslate }}
            </th>
            <th scope="col">
              {{ 'rrs.account.activity.reason' | cxTranslate }}
            </th>
            <th scope="col">
              {{ 'rrs.account.activity.date' | cxTranslate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let activity of activities?.memberTransactionItems
                | slice: (page - 1) * pageSize:page * pageSize
            "
            data-cy="activity-item">
            <td>+ ${{ activity?.transactionPointValue }}</td>
            <td data-cy="activity-item-name">
              {{ activity?.transactionTypePublicName }} at
              {{ activity?.locationExternalReference }}
            </td>
            <td>
              {{ activity?.transactionTimestampUTC | date: 'MM/dd/YYYY' }}
            </td>
          </tr>
        </tbody>
      </table>

      <ng-container
        *ngIf="activities?.memberTransactionItems?.length > pageSize">
        <div class="d-none d-lg-block">
          <ngb-pagination
            [collectionSize]="activities?.memberTransactionItems?.length"
            [(page)]="page"
            [pageSize]="pageSize">
            <ng-template ngbPaginationPrevious>
              <rrs-icon
                [icon]="iconTypeList.CHEVRON_LEFT"
                [size]="16"></rrs-icon>
            </ng-template>
            <ng-template ngbPaginationNext>
              <rrs-icon
                [icon]="iconTypeList.CHEVRON_RIGHT"
                [size]="16"></rrs-icon>
            </ng-template>
          </ngb-pagination>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #noActivity>
      <p class="px-3">{{ 'rrs.account.activity.noActivity' | cxTranslate }}</p>
    </ng-template>
  </section>
</ng-container>
