import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { RrsPointOfService } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { StoreFinderConfig } from '@spartacus/storefinder/core';

@Component({
  selector: 'rrs-store-finder-map',
  templateUrl: './rrs-store-finder-map.component.html',
  styleUrls: ['./rrs-store-finder-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsStoreFinderMapComponent {
  @Input() location!: RrsPointOfService;

  constructor(protected config: StoreFinderConfig) {}

  getUrl(): string | null {
    if (!this.location.name) {
      return null;
    }
    const storeName = this.location.isOffBroadway
      ? 'Off Broadway Shoe Warehouse '
      : 'Rack Room Shoes ';
    const storeQuery =
      storeName +
      this.location.address?.line1 +
      ' ' +
      this.location.address?.town +
      ', ' +
      this.location.address?.region?.isocodeShort +
      ' ' +
      this.location.address?.postalCode;
    return `${environment.googleMaps.baseUrl}/place?key=${
      this.config.googleMaps?.apiKey
    }&q=${encodeURIComponent(storeQuery)}`;
  }
}
