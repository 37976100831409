import { RrsCmsPageNotFoundComponent } from '@app/custom/features/rrs-cms/components/rrs-cms-page-not-found/component/rrs-cms-page-not-found.component';
import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';
import { CmsConfig, provideConfig } from '@spartacus/core';

export const rrsNotFoundCmsConfig = [
  provideConfig({
    slots: {
      NotFoundMain: ['PageNotFoundComponent'],
    },
  } as CmsComponentsConfig),
  provideConfig({
    cmsComponents: {
      PageNotFoundComponent: {
        component: RrsCmsPageNotFoundComponent,
      },
    },
  } as CmsConfig),
];
