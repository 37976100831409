<ng-container *ngIf="getUrl() as url">
  <iframe
    data-cy="store-finder-map"
    width="100%"
    height="400"
    frameborder="0"
    style="border: 0"
    loading="lazy"
    referrerpolicy="no-referrer"
    allowfullscreen
    [src]="url | safeUrl"></iframe>
</ng-container>
