import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConverterService,
  InterceptorUtil,
  OccEndpointsService,
  USE_CLIENT_TOKEN,
  User,
  WindowRef,
  normalizeHttpError,
} from '@spartacus/core';
import { USER_PROFILE_SERIALIZER } from '@spartacus/user/profile/core';
import { OccUserProfileAdapter } from '@spartacus/user/profile/occ';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RrsOccUserProfileAdapter extends OccUserProfileAdapter {
  constructor(
    http: HttpClient,
    occEndpoints: OccEndpointsService,
    converter: ConverterService,
    protected winRef: WindowRef
  ) {
    super(http, occEndpoints, converter);
  }
  update(userId: string, user: User): Observable<unknown> {
    const endpoint = this.occEndpoints.isConfigured('userUpdateProfile')
      ? 'userUpdateProfile'
      : 'user';
    const url = this.occEndpoints.buildUrl(endpoint, { urlParams: { userId } });
    user = this.converter.convert(user, USER_PROFILE_SERIALIZER);
    return this.http
      .put(url, user)
      .pipe(catchError((error) => throwError(normalizeHttpError(error))));
  }

  requestForgotPasswordEmail(userEmailAddress: string): Observable<unknown> {
    const url = this.occEndpoints.buildUrl('userForgotPassword');
    const resetPasswordUrl =
      this.winRef.nativeWindow?.location.origin + '/login/reset-password';
    const httpParams: HttpParams = new HttpParams()
      .set('userId', userEmailAddress)
      .set('newPasswordPageUrl', resetPasswordUrl);
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    return this.http
      .post(url, httpParams, { headers })
      .pipe(catchError((error) => throwError(normalizeHttpError(error))));
  }
}
