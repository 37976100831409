import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class RrsBazaarvoiceService {
  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected winRef: WindowRef
  ) {}

  initBazaarvoice(): void {
    const bazaarvoiceScript = this.document.createElement('script');
    bazaarvoiceScript.async = true;
    bazaarvoiceScript.src = '/assets/vendors/bazaarvoice/bazaarvoice.js';
    this.document.body.appendChild(bazaarvoiceScript);
  }
}
