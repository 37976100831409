import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { OrderHistoryComponent } from '@spartacus/order/components';
import { OrderHistoryList } from '@spartacus/order/root';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'rrs-orders',
  templateUrl: './rrs-orders.component.html',
  styleUrls: ['./rrs-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsOrdersComponent extends OrderHistoryComponent {
  icon = ICON_TYPE_LIST;
  private CUSTOM_PAGE_SIZE = 25;

  orders$: Observable<OrderHistoryList | undefined> = this.orderHistoryFacade
    .getOrderHistoryList(this.CUSTOM_PAGE_SIZE)
    .pipe(
      tap((orders: OrderHistoryList | undefined) => {
        if (orders?.pagination?.sort) {
          this.sortType = orders.pagination.sort;
        }
      })
    );
}
