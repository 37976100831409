/**
 * This module allows You to define global message scopes and subscribe to them to render messages in desired places.
 * It also allows You to set ignoring messages scope in all other places.
 * To use it just add new scope in this module config.ts file:
 *
 *    scopes: [
 *     {
 *       name: 'login',
 *       globalMessagesKeys: ['httpHandlers.badRequestPleaseLoginAgain'],
 *       ignoreGlobally: true,
 *     },
 *    ]
 *
 * To subscribe for specific scope, use rrs-global-message component and provide a scope name in component input.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  RrsGlobalMessageComponent,
  rrsGlobalMessageConfig,
} from '@app/custom/features/rrs-global-message';
import { RrsGlobalMessageService } from '@app/custom/features/rrs-global-message/rrs-global-message.service';
import { RrsGatewayTimeoutHandler } from '@app/custom/features/rrs-global-message/services/rrs-gateway-timeout.handler';
import { RrsIconModule } from '@app/shared/components';
import {
  GatewayTimeoutHandler,
  GlobalMessageService,
  I18nModule,
  provideDefaultConfig,
} from '@spartacus/core';

@NgModule({
  declarations: [RrsGlobalMessageComponent],
  exports: [RrsGlobalMessageComponent],
  imports: [CommonModule, I18nModule, RrsIconModule],
  providers: [
    provideDefaultConfig(rrsGlobalMessageConfig),
    {
      provide: GlobalMessageService,
      useExisting: RrsGlobalMessageService,
    },
    {
      provide: GatewayTimeoutHandler,
      useExisting: RrsGatewayTimeoutHandler,
    },
  ],
})
export class RrsGlobalMessageModule {}
