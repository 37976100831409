import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { CmsService } from '@spartacus/core';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cms-featured-brands',
  styleUrls: ['./rrs-cms-featured-brands.component.scss'],
  templateUrl: './rrs-cms-featured-brands.component.html',
})
export class RrsCmsFeaturedBrandsComponent {
  data$ = this.cmsService.getContentSlot('featured_brands').pipe(
    switchMap((slots) => {
      const list = (slots.components || []).map((slot) => {
        return slot.uid as string;
      });

      return combineLatest([
        ...list?.map((item) =>
          this.cmsService.getComponentData<Contentstack.BrandsList>(item)
        ),
      ]);
    })
  );

  constructor(protected cmsService: CmsService) {}
}
