import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderEntry } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';

@Component({
  selector: 'rrs-mini-cart-modal',
  templateUrl: './rrs-mini-cart-modal.component.html',
  styleUrls: ['./rrs-mini-cart-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsMiniCartModalComponent {
  icon = ICON_TYPE_LIST;
  entry$!: Observable<OrderEntry>;

  constructor(private modalService: NgbModal) {}

  close(): void {
    this.modalService.dismissAll();
  }
}
