import { I18nModule } from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RrsCmsPageNotFoundComponent } from './component/rrs-cms-page-not-found.component';
import { rrsNotFoundCmsConfig } from '@app/custom/features/rrs-cms/components/rrs-cms-page-not-found/cms.config';
import { PageSlotModule } from '@spartacus/storefront';

@NgModule({
  declarations: [RrsCmsPageNotFoundComponent],
  imports: [CommonModule, RouterModule, I18nModule, PageSlotModule],
  providers: [...rrsNotFoundCmsConfig],
  exports: [RrsCmsPageNotFoundComponent],
})
export class RrsCmsPageNotFoundModule {}
