import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RssOnetrustInitializer } from '@app/custom/features/rrs-onetrust/rss-one-trust.initializer';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [RssOnetrustInitializer],
})
export class RrsOnetrustModule {}
