export const accountOccConfig = {
  backend: {
    occ: {
      endpoints: {
        activitySummary: '/users/${userId}/activitySummary',
        customerGroups: '/users/${userId}/customergroups',
        addToCustomerGroup: '/users/${userId}/customergroup/${groupId}',
        removeFromCustomerGroup: '/users/${userId}/customergroup/${groupId}',
      },
    },
  },
};
