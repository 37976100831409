import { InjectionToken } from '@angular/core';
import {
  Brand,
  Brands,
} from '@app/custom/features/rrs-brands/models/brands.model';
import { Converter } from '@spartacus/core';

export const BRANDS_NORMALIZER = new InjectionToken<
  Converter<Brands[], Brands[]>
>('RrsBrandsNormalizer');
export const BRAND_NAME_NORMALIZER = new InjectionToken<
  Converter<Brand, Brand>
>('RrsBrandNameNormalizer');
