import { ModalRef, ModalService } from '@app/custom/features/modal';
import {
  Address,
  GlobalMessageService,
  GlobalMessageType,
} from '@spartacus/core';
import { AddressBookComponentService } from '@spartacus/storefront';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { Observable } from 'rxjs';
import { RrsAddressBookConfirmationComponent } from '@app/custom/features/rrs-account/components/rrs-address-book/components/rrs-address-book-confirmation/rrs-address-book-confirmation.component';
import { RrsAddressBookFormComponent } from '@app/custom/features/rrs-account/components/rrs-address-book/components/rrs-address-book-form/rrs-address-book-form.component';
import { tap } from 'rxjs/operators';
import { RrsEventsDispatcherService } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/services/rrs-events.dispatcher';

@Component({
  selector: 'rrs-address-book',
  templateUrl: './rrs-address-book.component.html',
  styleUrls: ['./rrs-address-book.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsAddressBookComponent implements OnInit {
  icon = ICON_TYPE_LIST;
  addresses$!: Observable<Address[]>;
  modalRef!: ModalRef;

  constructor(
    public addressBookService: AddressBookComponentService,
    protected modalService: ModalService,
    protected globalMessageService: GlobalMessageService,
    protected eventDispatcher: RrsEventsDispatcherService
  ) {}

  ngOnInit(): void {
    this.addressBookService.loadAddresses();
    this.addresses$ = this.addressBookService.getAddresses().pipe(
      tap((addresses) => {
        if (addresses.every((address) => address.defaultAddress === false)) {
          this.setFirstAddressAsDefault(addresses);
        }
      })
    );
  }

  openConfirmationModal(address: Address): void {
    this.modalRef = this.modalService.open(RrsAddressBookConfirmationComponent);
    this.modalRef.componentInstance.address = address;
  }

  openFormModal(address?: Address): void {
    if (address) {
      this.eventDispatcher.dispatchEditShippingAddressEvent();
    } else {
      this.eventDispatcher.dispatchAddNewShippingAddressEvent();
    }
    this.modalRef = this.modalService.open(RrsAddressBookFormComponent);
    this.modalRef.componentInstance.address = address;
  }

  setAddressAsDefault(addressId: string, address: Address): void {
    this.addressBookService.updateUserAddress(addressId, {
      ...address,
      defaultAddress: true,
    });
  }

  setFirstAddressAsDefault(addresses: Address[]): void {
    if (addresses[0]?.id) {
      this.setAddressAsDefault(addresses[0].id, addresses[0]);
    }
  }
}
