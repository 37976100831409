import { catchError, first, switchMap } from 'rxjs/operators';
import {
  GlobalMessageService,
  GlobalMessageType,
  normalizeHttpError,
  OccEndpointsService,
  UserIdService,
} from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RewardsInterface } from '@app/custom/features/rrs-rewards/models/rewards.model';

@Injectable({
  providedIn: 'root',
})
export class RrsRewardsService {
  constructor(
    protected readonly globalMessage: GlobalMessageService,
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected userIdService: UserIdService
  ) {}

  getUserRewards(): Observable<RewardsInterface> {
    return this.userIdService.getUserId().pipe(
      first(),
      switchMap((userId) => {
        const url = this.occEndpointsService.buildUrl('rewards', {
          urlParams: {
            userId,
          },
        });

        return this.http.get(url).pipe(
          catchError((error) => {
            this.globalMessage.add(
              { key: 'httpHandlers.badRequestReports' },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            return throwError(normalizeHttpError(error));
          })
        );
      })
    );
  }
}
