import { HttpHeaders } from '@angular/common/http';
import { Cart } from '@spartacus/cart/base/root';
import {
  InterceptorUtil,
  OCC_USER_ID_CURRENT,
  USE_CLIENT_TOKEN,
  User,
} from '@spartacus/core';

/**
 * Get user email assigned for the cart.
 * @param cartUser
 */
export function getCartUserEmail(cartUser?: User | null | undefined): string {
  const userIdSplit = cartUser?.uid?.split('|') || [];
  return userIdSplit.length > 1
    ? userIdSplit[1]
    : userIdSplit[0]?.replace('anonymous', '');
}

export function isAnonymousCart(cartUser: User | undefined): boolean {
  return cartUser?.uid === 'anonymous';
}

/**
 * Because userId for payments endpoints work little different from ootb endpoints custom logic is applied here.
 * For logged user use `current` id and cart code.
 * If ASM is enabled use userId instead of `current` string.
 * For guest user we use anonymous id and cart guid and we need to pre-authorize
 * @param cart
 * @param isLogged
 * @param asmEnabled
 */
export function preparePaymentEndpointData(
  cart: Cart,
  isLogged: boolean,
  asmEnabled: boolean
): RrsPaymentEndpointData {
  let userId = OCC_USER_ID_CURRENT;
  let cartId = cart.code!;

  let headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  if (!isLogged) {
    userId = 'anonymous';
    cartId = cart.guid!;
    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
  }

  if (userId === OCC_USER_ID_CURRENT && asmEnabled) {
    userId = cart.user!.uid!;
  }

  return {
    userId,
    cartId,
    headers,
  };
}

export interface RrsPaymentEndpointData {
  userId: string;
  cartId: string;
  headers: HttpHeaders;
}
