<ng-container *ngIf="isPowerRewiewsLoaded$ | async; else pageLoader">
  <ng-container *ngIf="product$ | async as product">
    <div class="container" #writereview>
      <div class="row">
        <div class="col-12">
          <div id="pr-write"></div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #pageLoader>
  <div class="d-flex align-items-center justify-content-center p-5">
    <div class="spinner-border" role="status" style="width: 6rem; height: 6rem">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
