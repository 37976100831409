import { InjectionToken } from '@angular/core';
import { CmsStructureModel, Converter } from '@spartacus/core';

export const CONTENTSTACK_CMS_PAGE_NORMALIZER = new InjectionToken<
  Converter<any, CmsStructureModel>
>('ContentstackCmsPageNormalizer');

export const GLOBAL_CONTENT_TYPE = 'global_content_v1';

export const BRANDS_TABS_COMPONENT_ID = 'brands_tabs';
export const BREADCRUMBS_COMPONENT_ID = 'breadcrumbs';
export const CONTACT_US_COMPONENT_ID = 'contact_us_component';
export const CONTENT_NAVIGATION_COMPONENT_ID = 'content_navigation';
export const GIFT_CARDS_COMPONENT_ID = 'gift_cards';
export const FOOTER_COMPONENT_ID = 'footer_component';
export const FOOTER_SECTION_ID = 'FooterSection';
export const HEADER_COMPONENT_ID = 'header_component';
export const HEADER_BANNER_ID = 'header_bottom_banner';
export const HEADER_BANNER_SLOT = 'header_banner_slot';
export const HEADER_SECTION_ID = 'HeaderSection';
export const NEED_HELP_MODAL_SLOT = 'need_help_modal';
export const NEED_HELP_DROPDOWN_SLOT = 'need_help_dropdown';
export const NEED_HELP_MODAL_COMPONENT_ID = 'need_help_modal_component';
export const NEED_HELP_DROPDOWN_COMPONENT_ID = 'need_help_dropdown_component';
export const PAGE_NOT_FOUND_COMPONENT_ID = 'page_not_found_component';
export const RICH_TEXT_COMPONENT_ID = 'rich_text_html';

export enum CONTENTSTACK_CMS_PAGE_LIST {
  BRANDS = '/brands',
  BRANDS_LISTING = '/brands/',
  CART = '/cart',
  CHECKOUT = '/checkout',
  CONTACT_US = '/contact-us',
  GIFT_CARDS = '/gift-cards',
  LOGIN = '/login',
  MY_ACCOUNT = '/my-account',
  ORDER_STATUS = 'order-status',
  ORDER_SUMMARY = 'order-summary',
  RESET_PASSWORD = '/login/reset-password',
  STORE_DETAILS = 'store-details',
  STORE_FINDER = 'store-finder',
  WRITE_REVIEW = '/write-a-review',
}

export enum CONTENTSTACK_CMS_PAGE_TYPE {
  BRANDS = 'brands',
  CART = 'cart',
  CART_EMPTY = 'cart_empty',
  CATEGORY_PAGE = 'cs',
  CHECKOUT = 'checkout',
  CONTACT_US = 'content',
  CONTENT = 'content',
  CORE_CONTENT = 'core_content',
  GIFT_CARDS = 'gift_cards',
  HOME_PAGE = 'homepage',
  LOGIN = 'sign_in',
  MY_ACCOUNT = 'my_account',
  NOT_FOUND = 'not_found',
  ORDER_STATUS = 'order_status',
  ORDER_SUMMARY = 'order_summary',
  PRODUCT_DETAILS = 'product_details',
  PRODUCT_LISTING = 'product_listing',
  RESET_PASSWORD = 'reset_password',
  STORE_DETAILS = 'store_details',
  STORE_FINDER = 'store_finder',
  WRITE_REVIEW = 'write-a-review',
}

// This has to be re-declared here, to not import checkout module statically. @todo: rethink!
export const RrsCheckoutStepsParams = [
  '/delivery-address',
  '/delivery-mode',
  '/payment-details',
  '/review-order',
];

export const RrsContentstackSlotNamesList = [
  // All
  'breadcrumbs',

  // Brands
  'brands_tabs',

  // Cart
  'bottom_content',
  'campaign_message_slot_1',
  'campaign_message_slot_2',
  'campaign_message_slot_3',
  'legal_content',

  // Content
  'content_navigation',
  'hero_banner',
  'main_content',

  // Gift Cards
  'gift_cards',

  // Home
  'home_page_section_1',
  'featured_brands',

  // Login Page
  'below_form_content',

  // PDP
  'pre_description_content',
  'post_description_content',

  // PLP
  'top_content',
  'product_grid_content',
  'no_results_slot',

  // store details
  'news_store_events_content',

  // Not Found
  'not_found_main_slot',

  // Store Finder
  'above_search_content',
  'below_search_content',
  'remodels',

  // Order Status
  'form_bottom_content',
  'form_top_content',
  'ad_slot_1',
];

export enum RrsContentTypes {
  brands = 'brands',
  cart = 'cart',
  cart_empty = 'cart_empty',
  checkout = 'checkout',
  content = 'content',
  core_content = 'core_content',
  gift_cards = 'gift_cards',
  sign_in = 'sign_in',
  my_account = 'my_account',
  not_found = 'not_found',
  order_status = 'order_status',
  order_summary = 'order_summary',
  product_details = 'product_details',
  product_listing = 'product_listing',
  store_details = 'store_details',
  store_finder = 'store_finder',
}

export const RrsPageContentToTemplateMap: { [contentType: string]: string } = {
  [RrsContentTypes.brands]: 'BrandsPageTemplate',
  [RrsContentTypes.cart]: 'CartPageTemplate',
  [RrsContentTypes.cart_empty]: 'CartEmptyPageTemplate',
  [RrsContentTypes.checkout]: 'CheckoutPageTemplate',
  [RrsContentTypes.content]: 'ContentPageTemplate',
  [RrsContentTypes.core_content]: 'CoreContentPageTemplate',
  [RrsContentTypes.gift_cards]: 'GiftCardsPageTemplate',
  [RrsContentTypes.sign_in]: 'LoginPageTemplate',
  [RrsContentTypes.my_account]: 'AccountPageTemplate',
  [RrsContentTypes.not_found]: 'NotFoundTemplate',
  [RrsContentTypes.order_status]: 'OrderStatusPageTemplate',
  [RrsContentTypes.order_summary]: 'OrderSummaryPageTemplate',
  [RrsContentTypes.product_details]: 'ProductDetailsPageTemplate',
  [RrsContentTypes.product_listing]: 'ProductListingPageTemplate',
  [RrsContentTypes.store_details]: 'StoreDetailsPageTemplate',
  [RrsContentTypes.store_finder]: 'StoreFinderPageTemplate',
};

export const RrsGlobalContentstackIncludes = [
  'footer.copyright',
  'footer.loyalty',
  'footer.primary_nav',
  'footer.social_links',
  'footer',
  'header.bottom_banner',
  'header.branding',
  'header.category_nav.top_level_sections.section.second_level_sections',
  'header.top_banner',
  'customer_care.need_help',
];

export const RrsCoreContentstackIncludes = [
  'content_navigation.content_navigation',
  'content_navigation',
];
