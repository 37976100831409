import { ModalService } from '@app/custom/features/modal';
import { Cart } from '@spartacus/cart/base/root';
import { RoutingService } from '@spartacus/core';
import {
  ChangeDetectionStrategy,
  Component,
  ViewContainerRef,
} from '@angular/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { RrsSavedCartFormDialogComponent } from '@app/custom/features/rrs-saved-cart/components/rrs-saved-cart-form-dialog/rrs-saved-cart-form-dialog.component';
import { RrsSavedCartService } from '@app/custom/features/rrs-saved-cart/services/rrs-saved-cart.service';
import { SavedCartFormType } from '@spartacus/cart/saved-cart/root';
import { SavedCartListComponent } from '@spartacus/cart/saved-cart/components';
import { Subscription } from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'rrs-saved-carts-list',
  templateUrl: './rrs-saved-carts-list.component.html',
  styleUrls: ['./rrs-saved-carts-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsSavedCartsListComponent extends SavedCartListComponent {
  protected subscriptions = new Subscription();
  iconTypeList = ICON_TYPE_LIST;

  constructor(
    routing: RoutingService,
    vcr: ViewContainerRef,
    launchDialogService: LaunchDialogService,
    protected savedCartService: RrsSavedCartService,
    protected routingService: RoutingService,
    private modalService: ModalService
  ) {
    super(routing, savedCartService, vcr, launchDialogService);
    this.subscriptions.add(
      this.savedCartService
        .getRestoreSavedCartProcessSuccess()
        .subscribe((success) => this.onRestoreComplete(success))
    );
  }

  openDialog(event: Event, cart: Cart): void {
    const modalRef = this.modalService.open(RrsSavedCartFormDialogComponent, {
      windowClass: 'rrs-add-to-saved-cart-dialog',
    });
    modalRef.componentInstance.data = {
      cart,
      layoutOption: SavedCartFormType.RESTORE,
    };
    event.stopPropagation();
  }

  restoreSavedCart(cart: Cart): void {
    if (!cart.code) return;

    this.savedCartService.restoreSavedCart(cart?.code);
  }

  onRestoreComplete(success: boolean): void {
    if (success) {
      this.routingService.go({ cxRoute: 'cart' });

      this.savedCartService.resetSavedCartStates();
    }
  }
}
