import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cms-content-navigation',
  templateUrl: './rrs-cms-content-navigation.component.html',
})
export class RrsCmsContentNavigationComponent {
  data$ = this.componentData.data$;

  constructor(protected componentData: CmsComponentData<any>) {}

  customAction(type: string): void {
    switch (type) {
    }
  }
}
