import { provideConfig, RoutingConfig } from '@spartacus/core';

export const rrsCheckoutRoutingConfig = provideConfig({
  routing: {
    routes: {
      checkout: {
        paths: ['checkout'],
        checkoutDeliveryAddress: { paths: ['checkout/delivery-address'] },
        checkoutDeliveryMode: { paths: ['checkout/delivery-mode'] },
        checkoutPaymentDetails: { paths: ['checkout/payment-details'] },
        checkoutReviewOrder: { paths: ['checkout/review-order'] },
      },
      orderSummary: {
        paths: ['order-summary'],
      },
    },
  },
} as RoutingConfig);
