import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { User } from '@spartacus/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'rrs-rrs-order-details',
  templateUrl: './rrs-order-details.component.html',
  styleUrls: ['./rrs-order-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsOrderDetailsComponent implements OnInit {
  constructor(
    protected breakpointService: BreakpointService,
    protected orderDetailsService: OrderDetailsService,
    protected userAccountFacade: UserAccountFacade
  ) {}
  icon = ICON_TYPE_LIST;
  isCollapsed = false;
  order$: Observable<any> = this.orderDetailsService.getOrderDetails();

  ngOnInit(): void {
    this.breakpointService
      .isUp(BREAKPOINT.lg)
      .pipe(first())
      .subscribe((isDesktop) => {
        this.isCollapsed = !isDesktop;
      });
  }
}
