import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { RrsCmsHeaderUiService } from '@app/custom/features/rrs-cms/components/rrs-cms-header/services/rrs-cms-header-ui.service';
import SwiperCore, { SwiperOptions, EffectFade, Autoplay } from 'swiper';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

SwiperCore.use([Autoplay, EffectFade]);

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cms-header-banner-bottom',
  styleUrls: ['./rrs-cms-header-banner-bottom.component.scss'],
  templateUrl: './rrs-cms-header-banner-bottom.component.html',
})
export class RrsCmsHeaderBannerBottomComponent {
  banner$: Observable<Contentstack.HeaderBottomBanner> =
    this.componentData.data$;

  swiperConfig: SwiperOptions = {
    autoplay: {
      delay: 5000,
    },
    effect: 'fade',
    pagination: false,
    slidesPerGroup: 1,
    slidesPerView: 1,
    speed: 10,
  };

  constructor(
    public headerUi: RrsCmsHeaderUiService,
    protected componentData: CmsComponentData<Contentstack.HeaderBottomBanner>
  ) {}
}
