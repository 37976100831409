import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RrsOrderDetailsComponent } from '@app/custom/features/rrs-account/components/rrs-orders/components/rrs-order-details/rrs-order-details.component';
import { RrsOrderStatusService } from '@app/custom/features/rrs-order-status/services/rrs-order-status.service';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { OrderDetailsService } from '@spartacus/order/components';
import { BreakpointService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'rrs-order-status-details',
  templateUrl: './rrs-order-status-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsOrderStatusDetailsComponent
  extends RrsOrderDetailsComponent
  implements OnInit
{
  private subscriptions = new Subscription();

  constructor(
    breakpointService: BreakpointService,
    orderDetailsService: OrderDetailsService,
    userAccountFacade: UserAccountFacade,
    protected orderStatusService: RrsOrderStatusService,
    protected route: ActivatedRoute
  ) {
    super(breakpointService, orderDetailsService, userAccountFacade);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.params.subscribe((params) => {
        this.order$ = this.orderStatusService.getOrderDetails(
          params.orderCode,
          params.email
        );
      })
    );
  }
}
