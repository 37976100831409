import { Injectable } from '@angular/core';
import {
  RrsPointOfService,
  StoreFeatures,
} from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { Converter, ConverterService, PointOfService } from '@spartacus/core';

@Injectable({ providedIn: 'root' })
export class RrsStoreNormalizer
  implements Converter<PointOfService, RrsPointOfService>
{
  convert(
    source: PointOfService,
    target?: RrsPointOfService
  ): RrsPointOfService {
    if (target === undefined) {
      target = { ...(source as RrsPointOfService) };
    }

    if (source?.address) {
      if (source.address.postalCode) {
        target.address = {
          ...source.address,
          postalCode: source.address.postalCode.padStart(5, '0'),
        };
      }
      const region = source.address.region;
      if (!region?.isocodeShort && region?.isocode?.length) {
        target.address = {
          ...target.address,
          region: {
            ...region,
            // If isocodeShort isn't populated we can get it from isocode e.g. US-NC -> NC
            isocodeShort: region.isocode.replace(/^[A-Z]{2}-/, ''),
          },
        };
      }
    }

    const features = source.features?.entry as any as {
      key: string;
      value: string;
    }[];

    const getFeatureValue = (featureType: StoreFeatures): string | undefined =>
      features?.find((feature) => feature.key.split('-')[0] === featureType)
        ?.value;

    target.isOffBroadway =
      getFeatureValue(StoreFeatures.IS_OB_WAREHOUSE)?.toLowerCase() === 'true';
    target.curbsidePickupEnabled =
      getFeatureValue(StoreFeatures.CURBSIDE_PICKUP_ENABLED)?.toLowerCase() ===
      'true';
    target.phoneNumber = getFeatureValue(StoreFeatures.PHONE);
    target.pickupEnabled =
      getFeatureValue(StoreFeatures.IS_PICKUP_ENABLED)?.toLowerCase() ===
      'true';
    target.statusDate = getFeatureValue(StoreFeatures.STATUS_DATE);

    target.storeImages = [
      {
        ...(source?.storeImages?.[0] ?? {}),
        url: `https://deichmann.scene7.com/asset/deichmann/US_01_${
          source.name ?? ''
        }?&defaultImage=US_01_default_storelocator`,
      },
    ];

    return target;
  }
}
