import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RrsImpervaBotProtectionInterceptor } from './rrs-imperva-bot-protection.interceptor';
import { RrsSharedPipesModule } from '@app/shared/pipes';
import {
  RrsImpervaCredentialsService,
  RrsImpervaCredentialsServiceBrowser,
} from './services/rrs-imperva-credentials.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, RrsSharedPipesModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RrsImpervaBotProtectionInterceptor,
      multi: true,
    },
    {
      provide: RrsImpervaCredentialsService,
      useClass: RrsImpervaCredentialsServiceBrowser,
    },
  ],
})
export class RrsImpervaModule {}
