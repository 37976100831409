import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'rrs-end-of-content',
  templateUrl: './rrs-end-of-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsEndOfContentComponent {
  seoEndOfContent$ = this.cmsService
    .getCurrentPage()
    .pipe(map((page) => page?.endOfContent));

  constructor(protected cmsService: CmsService) {}
}
