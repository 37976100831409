import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Pipe({
  name: 'rrsIsControlValid',
  pure: false,
})
export class RrsIsControlValidPipe implements PipeTransform {
  transform(control: UntypedFormControl | undefined): string {
    if (control?.invalid && (control?.touched || control?.dirty)) {
      return 'is-invalid';
    }
    if (control?.valid && (control?.touched || control?.dirty)) {
      return 'is-valid';
    }
    return '';
  }
}
