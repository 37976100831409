<div *ngIf="headerUi.isMenuMobiOpen | async" class="header-menu-mobi">
  <ng-container *ngIf="isUserLogged() | async; else noUser">
    <rrs-cms-header-menu-mobi-item
      [isAccountNavigation]="true"
      [item]="accountNavigation"></rrs-cms-header-menu-mobi-item>
  </ng-container>

  <ng-template #noUser>
    <button
      (click)="headerUi.toggMenuMobi(); doLogin()"
      (keydown.enter)="headerUi.toggMenuMobi(); doLogin()"
      class="d-flex align-items-center justify-content-between px-3 py-4 text-uppercase bg-stripe nolink header-menu-mobi-item__link"
      tabindex="0">
      <div class="d-flex align-items-center">
        <rrs-icon
          [icon]="iconTypeList.USER"
          [size]="24"
          class="me-2"></rrs-icon>
        <span class="fs-5">
          {{ 'rrs.account.signIn' | cxTranslate }}
        </span>
      </div>
    </button>
  </ng-template>

  <!-- TODO: Feature Resolved in Other Task -->
  <a
    *ngIf="{ defaultStoreId: defaultStoreId$ | async } as defaulStoreData"
    class="d-flex align-items-center justify-content-between px-3 py-4 fs-5 text-uppercase nolink header-menu-mobi-item__link"
    tabindex="0"
    (click)="headerUi.toggMenuMobi()"
    (keydown.enter)="headerUi.toggMenuMobi()"
    [routerLink]="
      (defaulStoreData.defaultStoreId?.length
        ? {
            cxRoute: 'storeDetails',
            params: { storeId: defaulStoreData.defaultStoreId }
          }
        : { cxRoute: 'storeFinder' }
      ) | cxUrl
    ">
    {{ 'rrs.navigation.myStore' | cxTranslate }}
  </a>
  <ng-container *ngFor="let item of menu[0].top_level_sections">
    <rrs-cms-header-menu-mobi-item
      *ngIf="item.section.visible"
      [item]="item"></rrs-cms-header-menu-mobi-item>
  </ng-container>
</div>
