import { Injectable } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { SchemaBuilder } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsOtherPageTypeSchemaBuilder implements SchemaBuilder {
  constructor(protected cmsService: CmsService) {}

  build(): Observable<any> {
    return this.cmsService.getCurrentPage().pipe(
      map((page) => {
        return page?.schema?.length ? page.schema : {};
      })
    );
  }
}
