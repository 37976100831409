import {
  ASM_FEATURE,
  AsmRootModule,
  CsAgentAuthService,
} from '@spartacus/asm/root';
import {
  asmTranslationChunksConfig,
  asmTranslations,
} from '@spartacus/asm/assets';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { RrsCsAgentAuthService } from '@app/custom/features/rrs-auth/services/rrs-csagent-auth.service';

@NgModule({
  declarations: [],
  imports: [AsmRootModule],
  providers: [
    provideConfig({
      featureModules: {
        [ASM_FEATURE]: {
          module: () => import('@spartacus/asm').then((m) => m.AsmModule),
        },
      },
    } as CmsConfig),
    provideConfig({
      i18n: {
        resources: asmTranslations,
        chunks: asmTranslationChunksConfig,
      },
    } as I18nConfig),
    {
      provide: CsAgentAuthService,
      useClass: RrsCsAgentAuthService,
    },
  ],
})
export class AsmFeatureModule {}
