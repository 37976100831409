<div class="d-flex align-items-center">
  <span>{{ 'asm.agentSessionTimer.label' | cxTranslate }}:&nbsp;</span>
  <span class="fw-bold"
    >{{ timeLeft | formatTimer }}
    {{ 'asm.agentSessionTimer.minutes' | cxTranslate }}</span
  >
  <button
    class="d-flex align-items-center"
    title="{{ 'asm.agentSessionTimer.reset' | cxTranslate }}"
    (click)="resetTimer()">
    <rrs-icon
      class="ms-1"
      [size]="16"
      [icon]="icon.ADD_CIRCLE"
      [attr.aria-label]="'asm.hideUi' | cxTranslate"></rrs-icon>
  </button>
</div>
