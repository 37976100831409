import { CommonModule } from '@angular/common';
import { I18nModule, UrlModule } from '@spartacus/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { rrsBrandsCmsConfig } from '@app/custom/features/rrs-brands/configs/cms.config';
import { RrsBrandsListComponent } from '@app/custom/features/rrs-brands/components/rrs-brands-list/rrs-brands-list.component';
import { rrsBrandsRoutingConfig } from '@app/custom/features/rrs-brands/configs/router.config';
import { RrsBrandsTabsComponent } from './components/rrs-brands-tabs/rrs-brands-tabs.component';
import { RrsStartWithHashPipe } from './pipes/rrs-start-with-hash.pipe';
import { SwiperModule } from 'swiper/angular';
import {
  BRAND_NAME_NORMALIZER,
  BRANDS_NORMALIZER,
} from '@app/custom/features/rrs-brands/configs/normalizer.config';
import { RrsBrandsNormalizer } from '@app/custom/features/rrs-brands/services/rrs-brands.normalizer';
import { RrsBrandNameNormalizer } from '@app/custom/features/rrs-brands/services/rrs-brand-name.normalizer';

@NgModule({
  declarations: [
    RrsBrandsListComponent,
    RrsStartWithHashPipe,
    RrsBrandsTabsComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    NgbModule,
    RouterModule.forRoot([], {
      anchorScrolling: 'enabled',
      scrollOffset: [0, 128],
    }),
    SwiperModule,
    UrlModule,
  ],
  providers: [
    ...rrsBrandsCmsConfig,
    rrsBrandsRoutingConfig,
    {
      provide: BRANDS_NORMALIZER,
      useClass: RrsBrandsNormalizer,
      multi: true,
    },
    {
      provide: BRAND_NAME_NORMALIZER,
      useClass: RrsBrandNameNormalizer,
      multi: true,
    },
  ],
})
export class RrsBrandsModule {}
