import { CommonModule } from '@angular/common';
import { I18nModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { RrsOrderSummaryComponent } from './components/rrs-order-summary/rrs-order-summary.component';

@NgModule({
  declarations: [RrsOrderSummaryComponent],
  exports: [RrsOrderSummaryComponent],
  imports: [CommonModule, I18nModule],
})
export class RrsOrderSummaryModule {}
