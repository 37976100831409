import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule, UrlModule } from '@spartacus/core';
import { RrsAccordionModule, RrsIconModule } from '@app/shared/components';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { RrsAddToCartModule } from '@app/custom/features/rrs-add-to-cart/rrs-add-to-cart.module';
import { RrsDonationComponent } from '@app/custom/features/rrs-donation/components/rrs-donation/rrs-donation.component';
import { RrsDonationVariantComponent } from './components/rrs-donation-variant/rrs-donation-variant.component';

@NgModule({
  declarations: [RrsDonationComponent, RrsDonationVariantComponent],
  exports: [RrsDonationComponent, RrsDonationVariantComponent],
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    RrsAccordionModule,
    RrsAddToCartModule,
    RrsIconModule,
    UrlModule,
  ],
})
export class RrsDonationModule {}
