<nav>
  <ul class="d-flex justify-content-around list-none">
    <li *ngFor="let item of socialMedia.links" data-cy="footer-social">
      <a
        (click)="dispatchClickEvent(item)"
        [href]="item.image_link.url"
        [attr.title]="item.image_link.alt_text">
        <img
          [attr.src]="item.image_link.image_file?.url"
          [attr.alt]="item.image_link.image_file?.title"
          height="32"
          loading="lazy"
          width="32" />
      </a>
    </li>
  </ul>
</nav>
