import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalRef, ModalService } from '@app/custom/features/modal';
import { RrsSavedPaymentsConfirmationComponent } from '@app/custom/features/rrs-account/components/rrs-saved-payments/components/rrs-saved-payments-confirmation/rrs-saved-payments-confirmation.component';
import { RrsSavedPaymentsFormComponent } from '@app/custom/features/rrs-account/components/rrs-saved-payments/components/rrs-saved-payments-form/rrs-saved-payments-form.component';
import { RrsSavedPaymentsService } from '@app/custom/features/rrs-account/components/rrs-saved-payments/services/rrs-saved-payments.service';
import {
  RrsPaymentTranslations,
  RrsPaymentTypes,
} from '@app/custom/features/rrs-checkout/model';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import {
  GlobalMessageService,
  PaymentDetails,
  UserPaymentService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'rrs-saved-payments',
  templateUrl: './rrs-saved-payments.component.html',
  styleUrls: ['./rrs-saved-payments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsSavedPaymentsComponent implements OnInit {
  icon = ICON_TYPE_LIST;
  modalRef!: ModalRef;
  paymentMethods$!: Observable<PaymentDetails[]>;
  loading$!: Observable<boolean>;
  paymentTypes = RrsPaymentTypes;
  paymentTranslations = RrsPaymentTranslations;

  constructor(
    protected modalService: ModalService,
    protected userPaymentService: UserPaymentService,
    protected globalMessageService: GlobalMessageService,
    protected rrsSavedPaymentsService: RrsSavedPaymentsService
  ) {}

  openConfirmationModal(payment: any): void {
    this.modalRef = this.modalService.open(
      RrsSavedPaymentsConfirmationComponent
    );
    this.modalRef.componentInstance.payment = payment;
  }

  openFormModal(payment?: any): void {
    this.modalRef = this.modalService.open(RrsSavedPaymentsFormComponent);
    this.modalRef.componentInstance.address = payment;
  }

  ngOnInit(): void {
    this.paymentMethods$ = this.userPaymentService.getPaymentMethods().pipe(
      tap((paymentDetails) => {
        // Set first payment method to DEFAULT if none is set
        if (
          paymentDetails.length > 0 &&
          !paymentDetails.find(
            (paymentDetail) => paymentDetail.defaultPaymentInfo
          )
        ) {
          this.setDefaultPaymentMethod(paymentDetails[0]);
        }
      })
    );

    this.loading$ = this.userPaymentService.getPaymentMethodsLoading();
    this.userPaymentService.loadPaymentMethods();
  }

  setDefaultPaymentMethod(paymentMethod: PaymentDetails): void {
    if (!paymentMethod.id) return;
    this.rrsSavedPaymentsService.setPaymentAsDefault(paymentMethod.id);
  }

  deletePaymentMethod(paymentMethod: PaymentDetails): void {
    if (paymentMethod.id) {
      this.userPaymentService.deletePaymentMethod(paymentMethod.id);
    }
  }

  shortenYear(year: string): string {
    return year.slice(-2);
  }
}
