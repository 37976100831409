import { CommonModule } from '@angular/common';
import { I18nModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RrsIconModule } from '@app/shared/components';
import { RrsMiniCartComponent } from './components/rrs-mini-cart/rrs-mini-cart.component';
import { RrsMiniCartModalComponent } from './components/rrs-mini-cart-modal/rrs-mini-cart-modal.component';
import { RrsMiniProductCardComponent } from './components/rrs-mini-product-card/rrs-mini-product-card.component';
import { RrsSharedPipesModule } from '@app/shared/pipes';

@NgModule({
  declarations: [
    RrsMiniCartComponent,
    RrsMiniProductCardComponent,
    RrsMiniCartModalComponent,
  ],
  imports: [
    CommonModule,
    NgbCollapseModule,
    MediaModule,
    RrsIconModule,
    RouterModule,
    I18nModule,
    RrsSharedPipesModule,
  ],
  exports: [RrsMiniCartComponent, RrsMiniProductCardComponent],
})
export class RrsMiniCartModule {}
