import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';
import { RrsCmsFeaturedBrandsComponent } from '@app/custom/features/rrs-homepage/components/rrs-cms-featured-brands/rrs-cms-featured-brands.component';
import { provideConfig, CmsConfig } from '@spartacus/core';

export const rrsHomePageCmsConfig = [
  provideConfig({
    slots: {
      FeaturedBrandsSlot: ['featured_brands'],
    },
  } as CmsComponentsConfig),
  provideConfig({
    cmsComponents: {
      featured_brands: {
        component: RrsCmsFeaturedBrandsComponent,
      },
    },
  } as CmsConfig),
];
