import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-scroll-to-top',
  styleUrls: ['./rrs-scroll-to-top.component.scss'],
  templateUrl: './rrs-scroll-to-top.component.html',
})
export class RrsScrollToTopComponent {
  iconTypeList = ICON_TYPE_LIST;
  windowScrolled: boolean = false;

  constructor(@Inject(DOCUMENT) protected document: Document) {}

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (
      this.document.body.scrollTop > window.innerHeight * 0.25 ||
      this.document.documentElement.scrollTop > window.innerHeight * 0.25
    ) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
  }

  scrollToTop(): void {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  }
}
