import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
  GlobalMessageService,
  GlobalMessageType,
  PaginationModel,
  PointOfService,
  RoutingService,
} from '@spartacus/core';
import { ActivatedRoute } from '@angular/router';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { RrsStoreEntities } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { RrsStoreFinderService } from '@app/custom/features/rrs-storefinder/services/rrs-store-finder.service';
import { tap } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'rrs-store-finder-list',
  templateUrl: './rrs-store-finder-list.component.html',
  styleUrls: ['./rrs-store-finder-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsStoreFinderListComponent implements OnInit {
  protected subscriptions = new Subscription();
  iconTypeList = ICON_TYPE_LIST;

  @Input() selected!: PointOfService | null;
  @Output() selectStore = new EventEmitter<PointOfService | null>();

  storeEntities$!: Observable<RrsStoreEntities>;
  pagination$!: Observable<PaginationModel>;
  isLoading$!: Observable<boolean>;

  constructor(
    protected storeFinderService: RrsStoreFinderService,
    protected route: ActivatedRoute,
    protected routingService: RoutingService,
    protected globalMessageService: GlobalMessageService
  ) {}

  ngOnInit(): void {
    this.storeEntities$ = this.storeFinderService.getFindStoresEntities().pipe(
      tap((storesEntities) => {
        this.onSelectStore(storesEntities?.stores?.[0] || null);
      })
    );
    this.isLoading$ = this.storeFinderService.getStoresLoading();
    const getLocationsSub = this.route.queryParams.subscribe((params) => {
      // By default stores should be searched by user's location
      const noQuerySpecified = !params?.query;
      // @TODO: According to the requirements the params specified here
      // should always return the list of all stores. Backend team should help
      // with finding out what the parametres of the request should be
      this.storeFinderService.findStoresAction(
        params?.query ?? '',
        {
          pageSize: 5,
          currentPage: params?.page ?? 0,
        },
        undefined,
        undefined,
        noQuerySpecified ? true : Boolean(params?.useMyLocation),
        // @TODO: Find out what value should it be and use this value from config
        10_000_000,
        () => {
          // User specifically clicked on "use my location" button
          if (params?.useMyLocation) {
            this.routingService.go(
              {
                cxRoute: 'storeFinder',
              },
              {
                queryParams: {
                  useMyLocation: null,
                },
                queryParamsHandling: 'merge',
              }
            );
            this.globalMessageService.add(
              { key: 'rrs.storeFinder.geolocationNotEnabled' },
              GlobalMessageType.MSG_TYPE_ERROR,
              5000
            );
          }
        }
      );
    });
    this.subscriptions.add(getLocationsSub);
  }

  onSelectStore(store: PointOfService | null): void {
    this.selectStore.emit(store);
  }
}
