import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalService } from '@app/custom/features/modal';
import { RrsDefaultStoreService } from '@app/custom/features/rrs-account/services/rrs-default-store.service';
import { RrsAuthComponent } from '@app/custom/features/rrs-auth/rrs-auth/rrs-auth.component';
import { RrsCmsHeaderUiService } from '@app/custom/features/rrs-cms/components/rrs-cms-header/services/rrs-cms-header-ui.service';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { THEME_COLORS_TEXT } from '@app/shared/models';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { AuthService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cms-header-menu',
  styleUrls: ['./rrs-cms-header-menu.component.scss'],
  templateUrl: './rrs-cms-header-menu.component.html',
})
export class RrsCmsHeaderMenuComponent {
  iconTypeList = ICON_TYPE_LIST;
  themeColorsText = THEME_COLORS_TEXT;

  defaultStoreId$: Observable<string | null> =
    this.defaultStoreService.getDefaultStoreId();

  @Input() menu!: Contentstack.HeaderCategoryNav[];

  constructor(
    protected authService: AuthService,
    protected modalService: ModalService,
    public headerUi: RrsCmsHeaderUiService,
    private defaultStoreService: RrsDefaultStoreService
  ) {}

  doLogin(): void {
    this.modalService.open(RrsAuthComponent);
  }

  isUserLogged(): Observable<boolean> {
    return this.authService.isUserLoggedIn();
  }
}
