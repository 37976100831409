import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalService } from '@app/custom/features/modal';
import { RrsLoqateService } from '@app/custom/features/rrs-loqate/services/rrs-loqate.service';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { Address } from '@spartacus/core';

@Component({
  selector: 'rrs-locate-modal',
  templateUrl: './rrs-locate-modal.component.html',
  styleUrls: ['./rrs-locate-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsLocateModalComponent {
  icon = ICON_TYPE_LIST;

  @Input() address!: Address;
  @Input() cartId!: string;
  @Input() recommendedAddress!: Address;
  isRecommendedAddress = true;

  constructor(
    protected modal: ModalService,
    protected loqateService: RrsLoqateService
  ) {}

  saveAddress(): void {
    this.loqateService.loqateSubject.next({
      isValidated: true,
      address: this.isRecommendedAddress
        ? this.recommendedAddress
        : this.address,
    });
    this.modal.dismissActiveModal();
  }
}
