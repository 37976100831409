import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const expandCollapse = trigger('contentExpansion', [
  state('expanded', style({ height: '*', visibility: 'visible' })),
  state('collapsed', style({ height: '0px', visibility: 'hidden' })),
  transition(
    'expanded <=> collapsed',
    animate('300ms cubic-bezier(.37,1.04,.68,.98)')
  ),
]);
