import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RrsUserIdSessionInterceptor } from '@app/custom/features/rrs-user-id/rrs-user-id-session.interceptor';
import { RrsUserIdInitializer } from '@app/custom/features/rrs-user-id/rrs-user-id.initializer';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RrsUserIdSessionInterceptor,
      multi: true,
    },
    RrsUserIdInitializer,
  ],
})
export class RrsUserIdModule {}
