import { LayoutConfig } from '@spartacus/storefront';
import { RrsAsmUiComponent } from '@app/custom/features/rrs-asm/components/rrs-asm-ui/rrs-asm-ui.component';

export const rrsAsmLayoutConfig: LayoutConfig = {
  launch: {
    ASM: {
      outlet: 'header',
      component: RrsAsmUiComponent,
    },
  },
};
