import { Category } from '@spartacus/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrentProductService } from '@spartacus/storefront';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-product-category',
  templateUrl: './rrs-product-category.component.html',
})
export class RrsProductCategoryComponent {
  brandCategory$: Observable<Category | undefined> = this.currentProductService
    .getProduct()
    .pipe(
      map((product) =>
        product?.categories?.find((category) =>
          category.code?.includes('brand')
        )
      )
    );

  constructor(protected currentProductService: CurrentProductService) {}
}
