import { Injectable } from '@angular/core';
import { RrsCartEntryConnector } from '@app/custom/features/rrs-cart/occ/rrs-cart-entry.connector';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { Store } from '@ngrx/store';
import {
  CartActions,
  MultiCartService,
  StateWithMultiCart,
} from '@spartacus/cart/base/core';
import {
  CartAddEntryEvent,
  CartAddEntryFailEvent,
  CartAddEntrySuccessEvent,
  CartModification,
} from '@spartacus/cart/base/root';
import {
  EventService,
  UserIdService,
  normalizeHttpError,
} from '@spartacus/core';
import { Subscription, forkJoin, from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

@AutoUnsubscribe()
@Injectable({
  providedIn: 'root',
})
export class RrsMultiCartService extends MultiCartService {
  constructor(
    store: Store<StateWithMultiCart>,
    userIdService: UserIdService,
    protected cartEntryConnector: RrsCartEntryConnector,
    protected eventService: EventService
  ) {
    super(store, userIdService);
  }

  subscriptions = new Subscription();

  override addEntries(
    userId: string,
    cartId: string,
    products: { productCode: string; quantity: number }[]
  ): void {
    this.subscriptions.add(
      forkJoin(
        products.map((product) =>
          this.cartEntryConnector
            .addOrderEntry(userId, cartId, {
              product: { code: product.productCode },
              quantity: product.quantity,
            })
            .pipe(
              mergeMap((cartModification: CartModification) => {
                this.eventService.dispatch(
                  this.cartEventFactory(
                    new CartAddEntrySuccessEvent(),
                    product?.productCode ?? '',
                    product.quantity,
                    cartId
                  ),
                  CartAddEntrySuccessEvent
                );
                return [
                  new CartActions.CartAddEntrySuccess({
                    userId,
                    cartId,
                    ...product,
                    ...(cartModification as Required<CartModification>),
                  }),
                  new CartActions.LoadCart({
                    cartId,
                    userId,
                  }),
                ];
              }),
              catchError((error) => {
                this.eventService.dispatch(
                  this.cartEventFactory(
                    new CartAddEntryFailEvent(),
                    product?.productCode ?? '',
                    product.quantity,
                    cartId,
                    error
                  ),
                  CartAddEntryFailEvent
                );
                return from([
                  new CartActions.CartAddEntryFail({
                    userId,
                    cartId,
                    ...product,
                    error: normalizeHttpError(error),
                  }),
                  new CartActions.LoadCart({
                    cartId,
                    userId,
                  }),
                ]);
              })
            )
        )
      ).subscribe((actions) => {
        if (actions) actions.forEach((action) => this.store.dispatch(action));
      })
    );
  }

  cartEventFactory(
    event: CartAddEntryEvent | CartAddEntrySuccessEvent | CartAddEntryFailEvent,
    productCode: string,
    quantity: number,
    cartId: string,
    error?: any
  ): CartAddEntryEvent | CartAddEntrySuccessEvent | CartAddEntryFailEvent {
    const newEvent = { ...event };
    newEvent.productCode = productCode;
    newEvent.quantity = quantity;
    newEvent.cartId = cartId;
    if (error) {
      (newEvent as CartAddEntryFailEvent).error = error;
    }
    return newEvent;
  }
}
