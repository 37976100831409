import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RrsOrdersModule } from '@app/custom/features/rrs-account/components/rrs-orders/rrs-orders.module';
import { RrsFormErrorsModule } from '@app/shared/components/rrs-form-errors/rrs-form-errors.module';
import { RrsSharedPipesModule } from '@app/shared/pipes';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { OccOrderHistoryAdapter } from '@spartacus/order/occ';
import { CmsPageGuard, PageSlotModule } from '@spartacus/storefront';
import { RrsOrderStatusComponent } from './components/rrs-order-status/rrs-order-status.component';
import { orderStatusRoutingConfig } from '@app/custom/features/rrs-order-status/config/rrs-order-status-routing.config';
import { orderStatusOccConfig } from '@app/custom/features/rrs-order-status/config/rrs-order-status-occ.config';
import { RrsOrderStatusDetailsComponent } from '@app/custom/features/rrs-order-status/components/rrs-order-status-details/rrs-order-status-details.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { RrsSharedDirectivesModule } from '@app/shared/directives';
import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';

@NgModule({
  imports: [
    CommonModule,
    PageSlotModule,
    I18nModule,
    ReactiveFormsModule,
    RrsSharedPipesModule,
    RrsSharedDirectivesModule,
    RrsFormErrorsModule,
    RrsOrdersModule,
    NgbCollapseModule,
    UrlModule,
    RouterModule.forChild([
      {
        path: 'order-details/:email/:orderCode',
        component: RrsOrderStatusDetailsComponent,
        canActivate: [CmsPageGuard],
        data: { pageLabel: 'order-details' },
      },
    ]),
  ],
  declarations: [RrsOrderStatusComponent, RrsOrderStatusDetailsComponent],
  providers: [
    provideConfig({
      slots: {
        OrderStatusSlot: ['OrderStatusComponent'],
      },
    } as CmsComponentsConfig),
    provideConfig({
      cmsComponents: {
        OrderStatusComponent: {
          component: RrsOrderStatusComponent,
        },
      },
    } as CmsConfig),
    provideConfig(orderStatusRoutingConfig),
    provideConfig(orderStatusOccConfig),
    OccOrderHistoryAdapter,
  ],
})
export class RrsOrderStatusModule {}
