import { ModalModule } from '@app/custom/features/modal';
import { I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { PageSlotModule, SpinnerModule } from '@spartacus/storefront';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RrsAddToSavedCartComponent } from './components/rrs-add-to-saved-cart/rrs-add-to-saved-cart.component';
import { RrsCartSharedModule } from '@app/custom/features/rrs-cart/components/rrs-cart-shared.module';
import { RrsCmsNeedHelpModule } from '@app/custom/features/rrs-cms/components';
import { RrsFormErrorsModule } from '@app/shared/components/rrs-form-errors/rrs-form-errors.module';
import { RrsIconModule } from '@app/shared/components';
import { RrsSavedCartDetailsActionComponent } from './components/rrs-saved-cart-details/rrs-saved-cart-details-action/rrs-saved-cart-details-action.component';
import { RrsSavedCartDetailsComponent } from './components/rrs-saved-cart-details/rrs-saved-cart-details.component';
import { RrsSavedCartDetailsItemsComponent } from '@app/custom/features/rrs-saved-cart/components/rrs-saved-cart-details/rrs-saved-cart-details-items/rrs-saved-cart-details-items.component';
import { RrsSavedCartDetailsOverviewComponent } from './components/rrs-saved-cart-details/rrs-saved-cart-details-overview/rrs-saved-cart-details-overview.component';
import { RrsSavedCartFormDialogComponent } from './components/rrs-saved-cart-form-dialog/rrs-saved-cart-form-dialog.component';
import { rrsSavedCartFormLayoutConfig } from '@app/custom/features/rrs-saved-cart/configs/rrs-saved-cart-layout.config';
import { RrsSavedCartsComponent } from '@app/custom/features/rrs-saved-cart/components/rrs-saved-carts/rrs-saved-carts.component';
import { RrsSavedCartService } from '@app/custom/features/rrs-saved-cart/services/rrs-saved-cart.service';
import { RrsSavedCartsListComponent } from './components/rrs-saved-carts-list/rrs-saved-carts-list.component';
import { rrsSavedCartsRoutingConfig } from '@app/custom/features/rrs-saved-cart/configs/rrs-saved-cart-routing.config';
import { SavedCartDetailsModule } from '@spartacus/cart/saved-cart/components';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { SavedCartModule } from '@spartacus/cart/saved-cart';
import { RrsSharedPipesModule } from '@app/shared/pipes';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    ReactiveFormsModule,
    SavedCartModule,
    RrsIconModule,
    RrsFormErrorsModule,
    UrlModule,
    SpinnerModule,
    RouterModule,
    SavedCartDetailsModule,
    RrsCartSharedModule,
    RrsCmsNeedHelpModule,
    PageSlotModule,
    ModalModule,
    RrsSharedPipesModule,
  ],
  declarations: [
    RrsSavedCartsComponent,
    RrsAddToSavedCartComponent,
    RrsSavedCartFormDialogComponent,
    RrsSavedCartsListComponent,
    RrsSavedCartDetailsComponent,
    RrsSavedCartDetailsActionComponent,
    RrsSavedCartDetailsOverviewComponent,
    RrsSavedCartDetailsItemsComponent,
  ],
  exports: [RrsSavedCartsComponent, RrsAddToSavedCartComponent],
  providers: [
    provideConfig(rrsSavedCartFormLayoutConfig),
    provideConfig(rrsSavedCartsRoutingConfig),
    {
      provide: SavedCartFacade,
      useExisting: RrsSavedCartService,
    },
  ],
})
export class RrsSavedCartModule {}
