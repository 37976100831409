<ng-container *ngIf="errorsDetails$ | async as errorDetails">
  <ng-container *ngIf="!hidden">
    <div class="invalid-feedback">
      <p *ngFor="let error of filterRequiredError(errorDetails)" class="mb-1">
        {{
          prefix + '.' + error[0] | cxTranslate: getTranslationParams(error[1])
        }}
      </p>
    </div>
  </ng-container>
</ng-container>
