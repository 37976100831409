import { Injectable } from '@angular/core';
import { IMPERVA_BYPASS_HEADER_VALUE } from '@environments/secrets';

@Injectable({
  providedIn: 'root',
})
export abstract class RrsImpervaCredentialsService {
  abstract getToken(): string | null;
}

@Injectable()
export class RrsImpervaCredentialsServiceServer extends RrsImpervaCredentialsService {
  getToken(): string {
    return IMPERVA_BYPASS_HEADER_VALUE;
  }
}

@Injectable()
export class RrsImpervaCredentialsServiceBrowser extends RrsImpervaCredentialsService {
  getToken(): null {
    return null;
  }
}
