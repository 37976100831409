import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WindowRef } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RrsUserIdService {
  private http = inject(HttpClient);
  private winRef = inject(WindowRef);

  loadUserDeviceId(): Observable<string> {
    return this.http.get<string>('/user/id').pipe();
  }

  get userDeviceId(): string | undefined {
    return this.winRef.document.cookie
      ?.split('; ')
      ?.find((s) => s.includes('rrs_user_device_id'))
      ?.split('=')[1];
  }
}
