import { Injectable } from '@angular/core';
import { RrsOnetrustService } from '@app/custom/features/rrs-onetrust/rrs-onetrust.service';
import { scriptsCookieGroups } from '@app/custom/features/rrs-onetrust/rrs-onetrust.config';
import {
  RrsPageEvent,
  RrsLoginSuccessEvent,
} from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/model/events';
import { removeUndefinedFields } from '@app/shared/utils';
import { CxEvent, EventService, ScriptLoader } from '@spartacus/core';
import { AepCollectorService } from '@spartacus/tracking/tms/aep';
import { AepCollectorConfig } from '@spartacus/tracking/tms/aep/config/default-aep.config';
import {
  TmsCollector,
  TmsCollectorConfig,
  WindowObject,
} from '@spartacus/tracking/tms/core';

@Injectable({
  providedIn: 'root',
})
export class RrsAepCollectorService
  extends AepCollectorService
  implements TmsCollector
{
  constructor(
    scriptLoader: ScriptLoader,
    protected eventsService: EventService,
    protected rrsOnetrustService: RrsOnetrustService
  ) {
    super(scriptLoader);
  }

  override async init(
    config: AepCollectorConfig,
    windowObject: WindowObject
  ): Promise<void> {
    try {
      const dataLayerProperty = config.dataLayerProperty ?? 'digitalData';
      windowObject[dataLayerProperty] = windowObject[dataLayerProperty] ?? {};

      const consentGiven = await this.rrsOnetrustService
        .checkConsents(scriptsCookieGroups.adobeLaunch)
        .toPromise();

      if (!consentGiven) {
        return Promise.reject('No consent to load Adobe Launch');
      }

      const scriptLoad = new Promise<void>((resolve, reject) => {
        if (config.scriptUrl) {
          this.scriptLoader.embedScript({
            src: config.scriptUrl,
            callback: () => {
              resolve();
              if (windowObject._satellite) {
                windowObject._satellite.pageBottom();
              }
            },
            errorCallback: () => {
              reject(new Error('Error loading Adobe Launch'));
            },
            attributes: {
              async: true,
              defer: false,
            },
          });
        }
      });

      const timeout = new Promise<void>((_, reject) => {
        setTimeout(() => {
          reject(new Error('Adobe Launch timed out'));
        }, 6000);
      });

      return Promise.race([scriptLoad, timeout]);
    } catch (error) {
      console.error('Error loading Adobe Launch:', error);
      return Promise.reject(error);
    }
  }

  override pushEvent<T extends CxEvent>(
    config: TmsCollectorConfig,
    windowObject: WindowObject,
    event: T | any
  ): void {
    const dataLayerProperty = config.dataLayerProperty ?? 'digitalData';
    let satelliteTrackEvent = event.satelliteEvent;
    let customEvent = event.customEvent;
    delete event.satelliteEvent;
    delete event.customEvent;
    windowObject[dataLayerProperty] = removeUndefinedFields({
      ...windowObject[dataLayerProperty],
      ...event,
    });
    if (event instanceof RrsPageEvent) {
      windowObject.document.dispatchEvent(
        new CustomEvent('rrsstorefront.pageViewEvent')
      );
    }
    if (satelliteTrackEvent && windowObject._satellite) {
      windowObject._satellite.track(satelliteTrackEvent);
    }
    if (customEvent) {
      windowObject.document.dispatchEvent(customEvent);
    }
    if (event instanceof RrsLoginSuccessEvent) {
      windowObject.document.dispatchEvent(
        new CustomEvent('rrsstorefront.loginSuccessEvent')
      );
    }
  }
}
