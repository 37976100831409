import { I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { MediaModule, PageSlotModule } from '@spartacus/storefront';
import { CommonModule } from '@angular/common';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RrsIconModule } from '@app/shared/components';
import { RrsOrderDetailsComponent } from './components/rrs-order-details/rrs-order-details.component';
import { RrsOrderProductCardComponent } from './components/rrs-order-product-card/rrs-order-product-card.component';
import { RrsOrdersComponent } from '@app/custom/features/rrs-account/components/rrs-orders/components/rrs-orders/rrs-orders.component';
import { rrsOrdersRoutingConfig } from '@app/custom/features/rrs-account/components/rrs-orders/configs/routing.config';
import { OrderHistoryOccConfig } from '@app/custom/features/rrs-account/components/rrs-orders/configs/occ.config';

@NgModule({
  declarations: [
    RrsOrdersComponent,
    RrsOrderDetailsComponent,
    RrsOrderProductCardComponent,
  ],
  imports: [
    CommonModule,
    RrsIconModule,
    I18nModule,
    RouterModule,
    UrlModule,
    NgbCollapseModule,
    PageSlotModule,
    MediaModule,
  ],
  providers: [
    provideConfig(OrderHistoryOccConfig),
    provideConfig(rrsOrdersRoutingConfig),
  ],
  exports: [
    RrsOrdersComponent,
    RrsOrderDetailsComponent,
    RrsOrderProductCardComponent,
  ],
})
export class RrsOrdersModule {}
