import { I18nModule, OccConfig, provideConfig } from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { rrActivityOccConfig } from '@app/custom/features/rrs-activity/configs/occ.config';
import { RrsActivityComponent } from '@app/custom/features/rrs-activity/components/rrs-activity/rrs-activity.component';
import { RrsIconModule } from '@app/shared/components';

@NgModule({
  declarations: [RrsActivityComponent],
  exports: [RrsActivityComponent],
  imports: [CommonModule, RrsIconModule, NgbPaginationModule, I18nModule],
  providers: [provideConfig(rrActivityOccConfig as OccConfig)],
})
export class RrsActivityModule {}
