import { APP_INITIALIZER } from '@angular/core';
import { RrsBazaarvoiceService } from '@app/custom/features/rrs-bazaarvoice/rrs-bazaarvoice.service';
import { RrsOnetrustService } from '@app/custom/features/rrs-onetrust/rrs-onetrust.service';
import { scriptsCookieGroups } from '@app/custom/features/rrs-onetrust/rrs-onetrust.config';
import { Subscription } from 'rxjs';

export const RssBazaarvoiceInitializer = {
  deps: [RrsBazaarvoiceService, RrsOnetrustService],
  multi: true,
  provide: APP_INITIALIZER,
  useFactory: initBazaarvoice,
};

export function initBazaarvoice(
  RrsBazaarvoiceService: RrsBazaarvoiceService,
  OnetrustService: RrsOnetrustService
): Function {
  return (): Subscription =>
    OnetrustService.checkConsents(scriptsCookieGroups.bazaarvoice).subscribe(
      (hasConsent) => {
        if (!hasConsent) return;
        RrsBazaarvoiceService.initBazaarvoice();
      }
    );
}
