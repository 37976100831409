<ng-container *ngIf="user$ | async as user">
  <div class="position-relative">
    <h3 class="fs-6 mb-0 text-capitalize">
      {{ 'rrs.account.form.name' | cxTranslate }}
    </h3>
    <p class="fw-bold">{{ user.name }}</p>

    <h3 class="fs-6 mb-0 text-capitalize">
      {{ 'rrs.account.form.customerPhone' | cxTranslate }}
    </h3>
    <p>{{ user?.customerPhone || '' }}</p>

    <ng-container *ngIf="userGroups$ | async as userGroups">
      <h3 class="fs-6 mb-0 text-capitalize">
        {{ 'rrs.account.form.monthOfBirth' | cxTranslate }}
      </h3>
      <p>{{ userGroups?.monthOfBirth | rrsMonthOfBirth | cxTranslate }}</p>

      <h3 class="fs-6 mb-0 text-capitalize">
        {{ 'rrs.account.form.gender' | cxTranslate }}
      </h3>
      <p>
        {{
          userGroups?.gender?.name ||
            ('rrs.monthOfBirth.notSelected' | cxTranslate)
        }}
      </p>
    </ng-container>

    <button
      (click)="editPersonalInformation()"
      class="position-absolute bottom-0 end-0 d-flex p-2"
      type="button"
      [attr.aria-label]="'rrs.common.edit' | cxTranslate">
      <rrs-icon [icon]="iconTypeList.EDIT" [size]="24"></rrs-icon>
    </button>
  </div>
</ng-container>
