<button
  (click)="onClick()"
  [class.link-disabled]="disabled"
  [class]="
    newDesign
      ? 'link link-primary d-flex'
      : 'link link-accent d-flex text-uppercase fw-normal'
  "
  [attr.aria-label]="'cart.addToWishlist' | cxTranslate">
  <rrs-icon
    *ngIf="newDesign; else oldIcon"
    [icon]="icons.HEART_SMALL"
    [size]="14"
    class="me-1 align-self-center"></rrs-icon>
  <ng-template #oldIcon>
    <rrs-icon [icon]="icons.HEART" class="me-2"></rrs-icon>
  </ng-template>
  {{ 'cart.addToWishlist' | cxTranslate }}
</button>
