import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-icon',
  styleUrls: ['./rrs-icon.component.scss'],
  templateUrl: './rrs-icon.component.html',
})
export class RrsIconComponent {
  @Input() classList: string = '';
  @Input() fill: string = 'currentColor';
  @Input() icon: ICON_TYPE_LIST = ICON_TYPE_LIST.BOOTSTRAP;
  @Input() size = 24;
}
