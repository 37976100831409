import { catchError, first, switchMap } from 'rxjs/operators';
import {
  ConverterService,
  EventService,
  GlobalMessageService,
  GlobalMessageType,
  normalizeHttpError,
  OccEndpointsService,
  QueryService,
  UserIdService,
} from '@spartacus/core';
import { Observable, throwError, zip } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LOYALTY_NORMALIZER } from '@app/custom/features/rrs-account/configs';
import { LoyaltyInterface } from '@app/custom/features/rrs-account/models/loyalty.model';
import { RrsLoyaltyEvent } from '@app/custom/features/rrs-account/events/rrs-loyalty.event';

@Injectable({
  providedIn: 'root',
})
export class RrsLoyaltyService {
  loyaltyQuery = this.query.create(() => this.getLoyaltyData(), {
    reloadOn: [RrsLoyaltyEvent],
  });

  constructor(
    protected converterService: ConverterService,
    protected eventService: EventService,
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected query: QueryService,
    protected readonly globalMessage: GlobalMessageService,
    protected userIdService: UserIdService
  ) {}

  getLoyalty(): Observable<LoyaltyInterface | undefined> {
    return this.loyaltyQuery.get();
  }

  updateLoyalty(): void {
    this.eventService.dispatch({}, RrsLoyaltyEvent);
  }

  protected getLoyaltyData(): Observable<LoyaltyInterface> {
    return this.userIdService.getUserId().pipe(
      first(),
      switchMap((userId) => {
        const activitySummaryUrl = this.occEndpointsService.buildUrl(
          'activitySummary',
          {
            urlParams: {
              userId,
            },
          }
        );

        const totalActiveRewardsUrl = this.occEndpointsService.buildUrl(
          'rewards',
          {
            urlParams: {
              userId,
            },
          }
        );

        return zip(
          this.http.get(activitySummaryUrl),
          this.http.get(totalActiveRewardsUrl)
        ).pipe(
          this.converterService.pipeable(LOYALTY_NORMALIZER),
          catchError((error) => {
            this.globalMessage.add(
              { key: 'httpHandlers.badRequestAccountLoyalty' },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            return throwError(normalizeHttpError(error));
          })
        );
      })
    );
  }
}
