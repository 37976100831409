<section
  #header
  *ngIf="data$ | async as data"
  [class.header--with-banner]="headerUi.isBannerShow | async"
  class="header container-fluid">
  <!-- Header Banner Top -->
  <rrs-cms-header-banner-top
    [banner]="data?.top_banner[0]"></rrs-cms-header-banner-top>

  <!-- Header Menu & Searchbox -->
  <div class="header__main d-flex" [class.row]="(isDesktop$ | async) === false">
    <div
      class="d-flex w-100 flex-nowrap justify-content-center align-items-center justify-content-xl-start">
      <!-- Header Header Logo -->
      <rrs-cms-header-logo [logo]="data?.branding[0]"></rrs-cms-header-logo>

      <div
        class="d-none d-xl-flex flex-column justify-content-between h-100 w-100">
        <rrs-searchbox></rrs-searchbox>
        <!-- Header Menu Desktop -->
        <rrs-cms-header-menu
          *ngIf="isDesktop$ | async"
          [menu]="data?.category_nav"></rrs-cms-header-menu>
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-center align-items-xl-end justify-content-xl-end px-0 px-xl-2">
      <!-- Header Navigation -->
      <rrs-cms-header-navi [navi]="data?.navi"></rrs-cms-header-navi>
    </div>

    <div *ngIf="(isDesktop$ | async) === false" class="col-12 p-0">
      <rrs-searchbox-mobile></rrs-searchbox-mobile>
    </div>
  </div>

  <!-- Header Menu Mobile -->
  <rrs-cms-header-menu-mobi
    *ngIf="(isDesktop$ | async) === false"
    [menu]="data?.category_nav"></rrs-cms-header-menu-mobi>
</section>
