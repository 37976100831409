import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RrsBadRequestErrorHandler } from '@app/shared/errorHandlers/handlers/rrs-bad-request-error.handler';
import { errorHandlers, HttpErrorHandler } from '@spartacus/core';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    ...errorHandlers,
    {
      provide: HttpErrorHandler,
      useClass: RrsBadRequestErrorHandler,
      multi: true,
    },
  ],
})
export class RrsErrorHandlersModule {}
