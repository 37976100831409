<section class="accordion">
  <div
    *ngFor="let item of items; index as i"
    class="accordion-item"
    [class.disabled]="item.disabled"
    [class.active]="expanded.has(i)">
    <ng-container
      [ngTemplateOutlet]="item?.customHeader?.templateRef || defaultHeader"
      [ngTemplateOutletContext]="{
        $implicit: item,
        index: i,
        toggle: getToggleState(i)
      }"></ng-container>
    <div
      [class.expanded]="expanded.has(i)"
      [@contentExpansion]="expanded.has(i) ? 'expanded' : 'collapsed'">
      <div
        class="accordion-body"
        *ngTemplateOutlet="item?.content?.templateRef || null"></div>
    </div>
  </div>
</section>

<ng-template #defaultHeader let-item let-index="index">
  <button
    class="accordion-button"
    (click)="item.disabled ? {} : toggleState(index)"
    [disabled]="item.disabled">
    <ng-container
      *ngTemplateOutlet="item?.customTitle?.templateRef || defaultTitle">
    </ng-container>
  </button>
  <ng-template #defaultTitle>
    <span class="fs-5">{{ item?.title }}</span>
  </ng-template>
</ng-template>
