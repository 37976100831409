import { ApplicationRef, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from './modal-options';
import { ModalRef } from './modal-ref';

/**
 * A service to handle modal
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modals: ModalRef[] = [];

  constructor(
    private ngbModalService: NgbModal,
    protected applicationRef: ApplicationRef
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  protected get rootComponent() {
    return this.applicationRef?.components?.[0]?.location?.nativeElement;
  }

  open(content: any, options?: ModalOptions, data?: any): ModalRef {
    let activeModal: ModalRef;

    options = { container: this.rootComponent, ...options };

    const existingModal = this.modals.find(
      (modal) => modal.componentInstance instanceof content
    );

    if (existingModal) {
      // If a modal of the same type is open, close it
      existingModal.close();
    }

    activeModal = this.ngbModalService.open(content, options);
    if (data) {
      activeModal.componentInstance.data = data;
    }
    this.modals.push(activeModal);
    this.handleModalRemoveEvents(activeModal);

    return activeModal;
  }

  protected handleModalRemoveEvents(modal: ModalRef): void {
    modal.result.finally(() => {
      this.modals = this.modals.filter((m) => m !== modal);
    });
  }

  getActiveModal(): ModalRef {
    const modal = this.modals[this.modals.length - 1];
    // @ts-ignore
    return modal ? modal : null;
  }

  dismissActiveModal(reason?: any): void {
    const modal: ModalRef = this.getActiveModal();

    if (modal) {
      modal.dismiss(reason);
    }
  }

  closeActiveModal(reason?: any): void {
    const modal: ModalRef = this.getActiveModal();

    if (modal) {
      modal.close(reason);
    }
  }
}
