import {
  Brand,
  Brands,
} from '@app/custom/features/rrs-brands/models/brands.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RrsAlgoliaService } from '@app/custom/features/rrs-search/services/algolia.service';
import { SearchResponse } from '@algolia/client-search';
import { BRANDS_NORMALIZER } from '@app/custom/features/rrs-brands/configs/normalizer.config';
import { ConverterService } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class RrsBrandsService {
  constructor(
    protected rrsAlgoliaService: RrsAlgoliaService,
    protected converterService: ConverterService
  ) {}

  getBrands(): Observable<Brands[]> {
    return this.rrsAlgoliaService.search('').pipe(
      map((data: SearchResponse) => {
        const algoliaBrands = data?.facets?.brand;
        const sortedBrands = this.getSortedBrandsArray(algoliaBrands);
        const brandsReduced = this.getReducedBrandsArray(sortedBrands);

        return brandsReduced;
      }),
      this.converterService.pipeable(BRANDS_NORMALIZER)
    );
  }

  getSortedBrandsArray(algoliaBrands: Record<string, number> = {}): Brand[] {
    return Object.entries(algoliaBrands)
      .map((item) => ({
        counts: item[1],
        name: this.isFirstLetterNumber(item[0])
          ? `#${item[0].toLowerCase()}`
          : item[0].toLowerCase(),
      }))
      .sort((a, b) => {
        if (a.name.startsWith('#') || b.name.startsWith('#')) return -1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
  }

  getReducedBrandsArray(sortedBrands: Brand[] = []): Brands[] {
    return sortedBrands.reduce((reducedBrands: Brands[], brand) => {
      const { counts, name } = brand;
      const firstLetter = name.charAt(0) || '';

      if (reducedBrands.length) {
        if (
          reducedBrands[reducedBrands.length - 1].letters.includes(firstLetter)
        ) {
          reducedBrands[reducedBrands.length - 1] = {
            letters: reducedBrands[reducedBrands.length - 1].letters,
            values: [...reducedBrands[reducedBrands.length - 1].values, brand],
          };
        } else {
          if (reducedBrands[reducedBrands.length - 1].values.length < 16) {
            reducedBrands[reducedBrands.length - 1] = {
              letters: [
                ...reducedBrands[reducedBrands.length - 1].letters,
                firstLetter,
              ],
              values: [
                ...reducedBrands[reducedBrands.length - 1].values,
                brand,
              ],
            };
          } else {
            reducedBrands.push({
              letters: [firstLetter],
              values: [{ counts, name }],
            });
          }
        }
      } else {
        reducedBrands.push({
          letters: [firstLetter],
          values: [{ counts, name }],
        });
      }

      return reducedBrands;
    }, []);
  }

  isFirstLetterNumber(_string: string): boolean {
    return /^\d/.test(_string);
  }
}
