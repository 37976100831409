import { RoutingConfig } from '@spartacus/core';

export const rrsSavedCartsRoutingConfig: RoutingConfig = {
  routing: {
    routes: {
      savedCarts: {
        paths: ['my-account/wishlists'],
      },
      savedCartsDetails: {
        paths: ['my-account/wishlists/:savedCartId'],
        paramsMapping: { savedCartId: 'savedCartId' },
      },
    },
  },
};
