enum ICON_TYPE_LIST {
  ADD_CIRCLE = 'ADD_CIRCLE',
  ALERT_DANGER = 'ALERT_DANGER',
  ALERT_INFO = 'ALERT_INFO',
  ALERT_SUCCESS = 'ALERT_SUCCESS',
  ALERT_WARN = 'ALERT_WARN',
  BOOTSTRAP = 'BOOTSTRAP',
  CARD_DOLLAR = 'CARD_DOLLAR',
  CART = 'CART',
  CART_DETAILS = 'CART_DETAILS',
  CART_FILLED = 'CART_FILLED',
  CART_RESTORE = 'CART_RESTORE',
  CHAT = 'CHAT',
  CHECK = 'CHECK',
  CHECK_CIRCLE = 'CHECK_CIRCLE',
  CHEVRON_DOWN = 'CHEVRON_DOWN',
  CHEVRON_LEFT = 'CHEVRON_LEFT',
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  CHEVRON_UP = 'CHEVRON_UP',
  CLOSE = 'CLOSE',
  CLOSE_BIG = 'CLOSE_BIG',
  CLOSE_CIRCLE = 'CLOSE_CIRCLE',
  DASH = 'DASH',
  DASH_CIRCLE = 'DASH_CIRCLE',
  DOLAR = 'DOLAR',
  EDIT = 'EDIT',
  EMAIL = 'EMAIL',
  GRID_UNCHECKED = 'GRID_UNCHECKED',
  HEART = 'HEART',
  HEART_SMALL = 'HEART_SMALL',
  LIST_UNCHECKED = 'LIST_UNCHECKED',
  LOCATION_CROSS = 'LOCATION_CROSS',
  MAP_PIN = 'MAP_PIN',
  POST = 'POST',
  POWER_OFF = 'POWER_OFF',
  QUESTION_MARK_CIRCLE = 'QUESTION_MARK_CIRCLE',
  RECEIPT = 'RECEIPT',
  SEARCH = 'SEARCH',
  SEARCH_BOLD = 'SEARCH_BOLD',
  SEARCH_PLUS = 'SEARCH_PLUS',
  SHIPPING_STANDARD = 'SHIPPING_STANDARD',
  USER = 'USER',
  INFO = 'INFO',
}

export { ICON_TYPE_LIST };
