<div class="modal-header py-3">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'rrs.addressBook.deleteAddress' | cxTranslate }}
  </h4>
  <button
    (click)="activeModal.dismiss('Cross click')"
    [attr.aria-label]="'rrs.common.closeModal' | cxTranslate"
    class="btn-close"
    cxModal="dismiss"
    cxModalReason="Cross click"
    type="button"></button>
</div>
<div class="modal-body">
  <h3 class="mb-3">
    {{ address.nickname ? address.nickname : address.line1 }}
  </h3>
  <p class="mb-5">{{ 'rrs.addressBook.confirmDeleteAddress' | cxTranslate }}</p>
  <div class="d-flex gap-3">
    <button
      (click)="activeModal.dismiss('Cross click')"
      class="btn w-100 btn-secondary">
      {{ 'rrs.common.no' | cxTranslate }}
    </button>

    <button
      (click)="removeAddress(address.id)"
      class="btn btn-primary"
      data-cy="confirm">
      {{ 'rrs.common.yes' | cxTranslate }}
    </button>
  </div>
</div>
