<ng-container *ngIf="{ store: store$ | async } as data">
  <ng-container *ngIf="isLoading$ | async; else loaded">
    <div class="d-flex justify-content-center">
      <div class="spinner-border text-accent" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-container>
  <ng-template #loaded>
    <ng-container *ngIf="data.store; else noStore">
      <div class="row">
        <div class="col-4">
          <cx-media
            *ngIf="data.store.storeImages?.[0]; else logoFallback"
            [container]="data.store.storeImages[0]"></cx-media>
          <ng-template #logoFallback>
            <rrs-store-logo
              class="d-flex justify-content-center"
              [store]="data.store"
              [size]="100"></rrs-store-logo>
          </ng-template>
        </div>
        <div class="col-8 d-flex flex-column">
          <h6>{{ data.store.displayName }}</h6>
          <span>
            {{ data.store.address?.line1 }}
            <br />{{ data.store.address?.town }},
            {{ data.store.address?.region?.isocodeShort }}
            {{ data.store.address?.postalCode }}
          </span>
          <span class="mt-2">
            {{ data.store.phoneNumber | rrsPhoneNumber }}
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button
          (click)="openStoresDialog()"
          class="d-flex p-2"
          type="button"
          [attr.aria-label]="'rrs.common.edit' | cxTranslate">
          <rrs-icon [icon]="iconTypeList.EDIT" [size]="24"></rrs-icon>
        </button>
      </div>
    </ng-container>
    <ng-template #noStore>
      <p class="pb-4 pt-2">
        {{ 'rrs.account.noStoreChosen' | cxTranslate }}
      </p>
      <button (click)="openStoresDialog()" class="btn btn-primary py-1">
        {{ 'rrs.account.chooseMyStore' | cxTranslate }}
      </button>
    </ng-template>
  </ng-template>
</ng-container>
