<ng-container *ngIf="data$ | async as data">
  <nav
    *ngFor="let navi of data?.sections"
    class="mb-4"
    data-cy="component-navi">
    <h6>{{ navi?.section?.section_title }}</h6>

    <ul class="list-none">
      <li *ngFor="let item of navi?.section?.links">
        <ng-container *ngIf="item?.custom_link; then customLink; else urlLink">
        </ng-container>
        <ng-template #customLink>
          <button
            (click)="customAction(item?.custom_link?.type)"
            type="button"
            class="text-start">
            {{ item?.custom_link?.text }}
          </button>
        </ng-template>
        <ng-template #urlLink>
          <a
            [routerLink]="item?.url_link?.url"
            [attr.title]="item?.url_link?.text">
            {{ item?.url_link?.text }}
          </a>
        </ng-template>
      </li>
    </ul>
  </nav>
</ng-container>
