import { DeferLoadingStrategy } from '@spartacus/core';
import { LayoutConfig } from '@spartacus/storefront';

const rrsLayoutConfig: LayoutConfig = {
  deferredLoading: {
    strategy: DeferLoadingStrategy.DEFER,
    intersectionMargin: '10px',
  },
  layoutSlots: {
    header: {
      slots: ['HeaderSection'],
    },
    footer: {
      slots: ['FooterSection'],
    },

    AccountPageTemplate: { slots: [] },
    BrandsPageTemplate: { slots: ['BrandsTabs', 'BrandsList'] },
    CartEmptyPageTemplate: {
      pageFold: 'EmptyCartSlot',
      slots: ['EmptyCartSlot'],
    },
    CartPageTemplate: {
      pageFold: 'TopContent',
      slots: ['TopContent', 'CenterRightContentSlot', 'bottom_content'],
    },
    CheckoutPageTemplate: {
      slots: ['CheckoutSlot'],
      header: { slots: ['CheckoutHeader'] },
    },
    ContactUsPageTemplate: {
      slots: ['ContactUs'],
    },
    ContentPageTemplate: {
      pageFold: 'main_content',
      slots: ['main_content'],
    },
    CoreContentPageTemplate: {
      slots: ['hero_banner', 'content_navigation', 'main_content'],
    },
    GiftCardsPageTemplate: {
      slots: [
        'form_top_content',
        'GiftCardsSlot',
        'form_bottom_content',
        'ad_slot_1',
      ],
    },
    HomePageTemplate: {
      slots: ['home_page_section_1', 'FeaturedBrandsSlot'],
    },
    LoginPageTemplate: {
      pageFold: 'LoginSlotI',
      slots: ['LoginSlotI'],
    },
    NotFoundTemplate: {
      pageFold: 'NotFoundMain',
      slots: ['NotFoundMain'],
    },
    OrderStatusPageTemplate: {
      slots: ['OrderStatusSlot'],
    },
    OrderSummaryPageTemplate: {
      slots: ['OrderSummarySlot'],
      header: { slots: ['CheckoutHeader'] },
    },
    ProductDetailsPageTemplate: {
      pageFold: 'MainProductDetails',
      slots: ['MainProductDetails'],
    },
    ProductListingPageTemplate: {
      pageFold: 'ProductList',
      slots: ['top_content', 'ProductList', 'ProductListBottom'],
    },
    ResetPasswordPageTemplate: { slots: ['ResetPasswordSlot'] },
    StoreDetailsPageTemplate: {
      pageFold: 'StoreDetailsSlot',
      slots: ['StoreDetailsSlot'],
    },
    StoreFinderPageTemplate: {
      pageFold: 'StoreFinderSlot',
      slots: ['BreadcrumbsSlot', 'StoreFinderSlot'],
    },
    WriteReviewPageTemplate: { slots: [] },
  },
};

export { rrsLayoutConfig };
