import {
  CartActions,
  CartVoucherConnector,
  CartVoucherService,
} from '@spartacus/cart/base/core';
import {
  normalizeHttpError,
  StateWithProcess,
  UserIdService,
} from '@spartacus/core';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';

@Injectable({
  providedIn: 'root',
})
export class RrsCartCouponService extends CartVoucherService {
  constructor(
    protected store: Store<StateWithProcess<void>>,
    protected activeCartService: RrsActiveCartService,
    protected userIdService: UserIdService,
    private cartVoucherConnector: CartVoucherConnector
  ) {
    super(store, activeCartService, userIdService);
  }

  couponStepCompleted = new BehaviorSubject(false);

  addVoucher(voucherId: string, cartId?: string): Observable<void> {
    return this.combineUserAndCart(cartId).pipe(
      switchMap(([userID, cartID]) => {
        return this.cartVoucherConnector.add(userID, cartID, voucherId).pipe(
          map(() => {
            this.store.dispatch(
              new CartActions.LoadCart({
                userId: userID,
                cartId: cartID,
              })
            );
          }),
          catchError((error) => {
            const errorNormalized = normalizeHttpError(error)?.details;

            return throwError({
              error: errorNormalized,
            });
          })
        );
      })
    );
  }

  combineUserAndCart(cartId?: string): Observable<[string, string]> {
    if (cartId) {
      return this.userIdService.getUserId().pipe(
        take(1),
        map((userId) => [userId, cartId])
      );
    } else {
      return combineLatest([
        this.userIdService.getUserId(),
        this.activeCartService.getActiveCartId(),
      ]).pipe(take(1));
    }
  }
}
