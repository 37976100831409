import { OccConfig } from '@spartacus/core';

export const defaultOccUserConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        addresses: 'users/${userId}/addresses?fields=DEFAULT',
      },
    },
  },
};
