import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  InterceptorUtil,
  OccEndpointsService,
  USE_CLIENT_TOKEN,
} from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class RrsNewsletterAdapter {
  constructor(
    private http: HttpClient,
    private occEndpointsService: OccEndpointsService
  ) {}

  singupForNewsletter(email: string, firstName: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    const endpoint = this.occEndpointsService.buildUrl('userjoinus');
    return this.http.post(
      endpoint,
      {
        email,
        firstName,
      },
      { headers }
    );
  }
}
