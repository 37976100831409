import { ChangeDetectionStrategy, Component } from '@angular/core';
import { rrsAccountNavigation } from '@app/custom/features/rrs-account/configs';
import { RrsCmsHeaderMenuComponent } from '@app/custom/features/rrs-cms/components/rrs-cms-header';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cms-header-menu-mobi',
  styleUrls: ['./rrs-cms-header-menu-mobi.component.scss'],
  templateUrl: './rrs-cms-header-menu-mobi.component.html',
})
export class RrsCmsHeaderMenuMobiComponent extends RrsCmsHeaderMenuComponent {
  accountNavigation = rrsAccountNavigation;
}
