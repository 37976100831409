<div class="modal-header">
  <h4 class="modal-title">{{ 'rrs.wishlist.wishlist' | cxTranslate }}</h4>

  <button
    class="btn-close"
    [attr.aria-label]="'rrs.common.closeModal' | cxTranslate"
    type="button"
    cxModal="dismiss"
    cxModalReason="Cross click"></button>
</div>

<div class="modal-body">
  <form [formGroup]="form" (submit)="createWishlist()" #ngForm="ngForm">
    <ng-container *ngIf="cartList$ | async as cartList">
      <div class="mb-3">
        <label class="form-label" for="savedList">{{
          'rrs.wishlist.selectWishlist' | cxTranslate
        }}</label>
        <ng-select
          id="savedList"
          [clearable]="false"
          [searchable]="false"
          [items]="cartList"
          bindLabel="name"
          formControlName="savedCartCode"
          (change)="addProductToWishlist()"
          placeholder="Select Wishlist">
        </ng-select>
      </div>
      <h6 class="my-4">OR</h6>
    </ng-container>

    <div class="my-3">
      <label class="form-label" for="addToWishlistName">
        {{ 'rrs.savedCart.name' | cxTranslate }}
      </label>
      <input
        [placeholder]="'rrs.savedCart.name' | cxTranslate"
        id="addToWishlistName"
        formControlName="name"
        aria-required="true"
        required
        class="form-control"
        type="text"
        [ngClass]="
          ngForm.submitted ? (form.get('name') | rrsIsControlValid) : ''
        " />
      <rrs-form-errors
        [control]="form.get('name')"
        *ngIf="ngForm.submitted"></rrs-form-errors>
    </div>

    <div class="my-3">
      <label class="form-label" for="addToWishlistDescription">
        {{ 'rrs.savedCart.description' | cxTranslate }}
      </label>
      <input
        [placeholder]="'rrs.savedCart.description' | cxTranslate"
        id="addToWishlistDescription"
        formControlName="description"
        class="form-control"
        rows="5" />
    </div>
    <ng-container *ngIf="{ isLoading: isLoading$ | async } as loadingState">
      <button
        [class.no-pointer-events]="loadingState.isLoading"
        type="submit"
        class="btn btn-primary w-100 btn-sm mt-3">
        <ng-container
          *ngIf="loadingState.isLoading === true; then load; else text">
        </ng-container>

        <ng-template #load>
          <div
            class="spinner-border spinner-border-sm"
            role="status"
            style="width: 1.5rem; height: 1.5rem"></div>
        </ng-template>

        <ng-template #text>
          {{ 'rrs.wishlist.addToWishlist' | cxTranslate }}
        </ng-template>
      </button>
    </ng-container>
  </form>
</div>
