import { OccConfig, OccEndpoint } from '@spartacus/core';

declare module '@spartacus/core' {
  interface OccEndpoints {
    orderStatus?: string | OccEndpoint;
  }
}

export const orderStatusOccConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        orderStatus: '/orderHistory/${orderNumber}/email/${email}',
      },
    },
  },
};
