<div
  class="mega-menu container-fluid"
  data-cy="header-mega-menu"
  data-cy="mega-menu-item">
  <div class="row">
    <div class="mega-menu__cols mega-menu__cols--banner col-3">
      <div
        class="d-flex align-items-center flex-column py-3"
        *ngIf="banner?.description_copy">
        <img
          [attr.alt]="banner?.alt_text"
          [attr.src]="banner.image?.url"
          class="mega-menu__banner-image mb-3"
          loading="lazy" />

        <p class="fs-7 fst-italic">{{ banner?.description_copy }}</p>

        <a
          [attr.title]="banner.url_link.text"
          [routerLink]="banner.url_link.url | rrsUrlTrimParams"
          [queryParams]="banner.url_link.url | rrsUrlQueryParams"
          target="{{ banner.url_link.new_tab ? '_blank' : '_self' }}"
          class="btn btn-primary btn-sm"
          hideMegaMenu>
          {{ banner.url_link.text }}
        </a>
      </div>
    </div>
    <div class="mega-menu__cols mega-menu__cols--menu col-9">
      <div class="row row-cols-6">
        <nav
          *ngFor="let item of menu[0].sections; let first = first"
          class="py-3 px-1"
          [class.ps-2]="first"
          data-cy="mega-menu-navi">
          <h6 class="pb-1 text-uppercase">{{ item.section.section_title }}</h6>

          <ul class="list-none">
            <li *ngFor="let link of item.section.links">
              <a
                [attr.title]="link.url_link.text"
                [class.fst-italic]="
                  link.url_link.text.toUpperCase() === titleViewAll
                "
                [routerLink]="link.url_link.url | rrsUrlTrimParams"
                [queryParams]="link.url_link.url | rrsUrlQueryParams"
                target="{{ link.url_link.new_tab ? '_blank' : '_self' }}"
                class="d-flex pb-1"
                hideMegaMenu>
                {{ link.url_link.text }}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
