import { catchError, first, switchMap } from 'rxjs/operators';
import {
  GlobalMessageService,
  GlobalMessageType,
  normalizeHttpError,
  OccEndpointsService,
  UserIdService,
} from '@spartacus/core';
import { Observable, of, throwError } from 'rxjs';
import { ActivitiesInterface } from '@app/custom/features/rrs-activity/models/activity.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RrsActivityService {
  constructor(
    protected readonly globalMessage: GlobalMessageService,
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected userIdService: UserIdService
  ) {}

  getUserActivity(): Observable<ActivitiesInterface> {
    return this.userIdService.getUserId().pipe(
      first(),
      switchMap((userId) => {
        const url = this.occEndpointsService.buildUrl('activity', {
          urlParams: {
            userId,
          },
        });

        return this.http.get(url).pipe(
          catchError((error) => {
            this.globalMessage.add(
              { key: 'httpHandlers.badRequestActivity' },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            return throwError(normalizeHttpError(error));
          })
        );
      })
    );
  }
}
