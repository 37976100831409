import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpErrorHandler, I18nModule } from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RrsCartCouponAppliedComponent } from './components/rrs-cart-coupon-applied/rrs-cart-coupon-applied.component';
import { RrsCartCouponComponent } from './components/rrs-cart-coupon/rrs-cart-coupon.component';
import { RrsCartHttpErrorHandlerService } from '@app/custom/features/rrs-cart-coupon/services/rrs-cart-http-error.handler';
import { RrsFormErrorsModule } from '@app/shared/components/rrs-form-errors/rrs-form-errors.module';
import { RrsTagsModule } from '@app/shared/components/rrs-tag/rrs-tags.module';

@NgModule({
  declarations: [RrsCartCouponComponent, RrsCartCouponAppliedComponent],
  exports: [RrsCartCouponComponent, RrsCartCouponAppliedComponent],
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    ReactiveFormsModule,
    RrsFormErrorsModule,
    RrsTagsModule,
  ],
  providers: [
    {
      provide: HttpErrorHandler,
      useExisting: RrsCartHttpErrorHandlerService,
      multi: true,
    },
  ],
})
export class RrsCartCouponModule {}
