import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { rrsStorefinderRoutingConfig } from '@app/custom/features/rrs-storefinder/configs/rrs-storefinder-routing.config';

const routes: Routes = [
  {
    // @ts-ignore
    path: null,
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard],
    data: {
      cxRoute: 'storeFinder',
      pageLabel: 'store-finder',
    },
  },
  {
    // @ts-ignore
    path: null,
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard],
    data: {
      cxRoute: 'storeDetails',
      pageLabel: 'store-details',
    },
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [provideConfig(rrsStorefinderRoutingConfig)],
})
export class RrsStoreFinderRoutingModule {}
