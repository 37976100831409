import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RrsLoyaltyService } from '@app/custom/features/rrs-account/services/rrs-loyalty.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-account-loyalty',
  styleUrls: ['./rrs-account-loyalty.component.scss'],
  templateUrl: './rrs-account-loyalty.component.html',
})
export class RrsAccountLoyaltyComponent {
  constructor(public rrsLoyaltyService: RrsLoyaltyService) {}
}
