import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FulfillmentConfig,
  FulfillmentOptions,
} from '@app/custom/features/rrs-product-details/models/rrs-delivery-options.model';
import { StockLevelStatus } from '@app/custom/features/rrs-product-details/models/rrs-product.model';
import { RrsOccProductStoreStockAdapter } from '@app/custom/features/rrs-product-details/occ/rrs-occ-product-stock.adapter';
import { MyStoreServiceScope } from '@app/custom/features/rrs-product-listing/model/rrs-search-stores.model';
import { RrsStoreFacetService } from '@app/custom/features/rrs-product-listing/services/rrs-store-facet/rrs-store-facet.service';
import { RrsPointOfService } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { RrsStoreFinderService } from '@app/custom/features/rrs-storefinder/services/rrs-store-finder.service';
import { getFormattedDistance } from '@app/custom/features/rrs-storefinder/utils/rrs-store-finder.utils';
import { WindowRef } from '@spartacus/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'rrs-pickup-delivery-option',
  templateUrl: './rrs-pickup-delivery-option.component.html',
  styleUrls: ['./rrs-pickup-delivery-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsPickupDeliveryOptionComponent implements OnInit, OnChanges {
  @Input() productCode!: string;
  @Input() fulfillment!: FulfillmentConfig;
  @Output() select = new EventEmitter<FulfillmentConfig>();

  productCodeChanged = new BehaviorSubject<string>(this.productCode);

  deliveryOptions = FulfillmentOptions;
  store$!: Observable<RrsPointOfService | null>;
  stockLevelStatus = StockLevelStatus;

  constructor(
    protected winRef: WindowRef,
    protected storeFacetService: RrsStoreFacetService,
    protected productStockAdapter: RrsOccProductStoreStockAdapter,
    protected storeFinderService: RrsStoreFinderService
  ) {}

  ngOnInit(): void {
    if (this.winRef.isBrowser()) {
      this.initStore();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.productCode &&
      changes.productCode?.previousValue !== changes.productCode?.currentValue
    ) {
      this.productCodeChanged.next(this.productCode);
    }
  }

  openFindStoresDialog(e: Event): void {
    e.stopPropagation();
    this.storeFacetService.openSearchStoresDialog(
      MyStoreServiceScope.PICKUP_IN_STORE,
      this.productCode
    );
  }

  private initStore(): void {
    this.store$ = combineLatest([
      this.storeFacetService.getSelectedStore('pickupInStore'),
      this.storeFinderService.getGeolocation(),
      this.productCodeChanged,
    ]).pipe(
      map(([store, geolocation]) =>
        store
          ? {
              ...store,
              formattedDistance:
                store?.formattedDistance ??
                (store?.geoPoint && geolocation
                  ? getFormattedDistance(store.geoPoint, geolocation)
                  : undefined),
            }
          : store
      ),
      tap((store) => {
        this.select.emit({ isPickupStoreSelected: !!store });
      }),
      switchMap((store) =>
        store?.name && this.productCode
          ? combineLatest([
              of(store),
              this.productStockAdapter.getStoreStockLevel(
                this.productCode!,
                store?.name!
              ),
            ]).pipe(
              tap(([_, productStock]) => {
                if (
                  productStock.stockLevel === 0 ||
                  productStock.stockLevelStatus ===
                    StockLevelStatus.OUT_OF_STOCK
                ) {
                  this.select.emit({ pickupOutOfStock: true });
                } else {
                  this.select.emit({ pickupOutOfStock: false });
                }
              }),
              map(([store, productStock]) => ({
                ...store,
                productStock,
              }))
            )
          : of(store)
      )
    );
  }

  selectFulfillment(): void {
    this.select.emit({ selectedMethod: FulfillmentOptions.PICKUP });
  }
}
