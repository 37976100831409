import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomerEmulationComponent } from '@spartacus/asm/components';

@Component({
  selector: 'rrs-customer-emulation',
  templateUrl: './rrs-customer-emulation.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsCustomerEmulationComponent extends CustomerEmulationComponent {}
