import { ModalModule } from '@app/custom/features/modal';
import {
  CmsConfig,
  I18nModule,
  PageMetaResolver,
  POINT_OF_SERVICE_NORMALIZER,
  provideConfig,
  RoutingModule,
  UrlModule,
} from '@spartacus/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MediaModule, PageSlotModule } from '@spartacus/storefront';
import { RrsAccordionModule, RrsIconModule } from '@app/shared/components';
import {
  RrsStoreDetailsComponent,
  RrsStoreFinderComponent,
  RrsStoreFinderListComponent,
  RrsStoreFinderListItemDetailsComponent,
  RrsStoreFinderMapComponent,
  RrsStoreFinderSearchComponent,
} from '@app/custom/features/rrs-storefinder/components';
import {
  StoreFinderAdapter,
  StoreFinderConfig,
  StoreFinderConnector,
  StoreFinderService,
  STORE_FINDER_SEARCH_PAGE_NORMALIZER,
} from '@spartacus/storefinder/core';
import { environment } from '@environments/environment';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RrsFindStoreDialogComponent } from '@app/custom/features/rrs-storefinder/components/rrs-find-store-dialog/rrs-find-store-dialog.component';
import { RrsPaginationStatsPipe } from './pipes/rrs-pagination-stats.pipe';
import { RrsSharedPipesModule } from '@app/shared/pipes';
import { RrsStoreFinderAdapter } from '@app/custom/features/rrs-storefinder/occ/rrs-store-finder.adapter';
import { RrsStoreFinderConnector } from '@app/custom/features/rrs-storefinder/occ/rrs-store-finder.connector';
import { RrsStoreFinderRoutingModule } from '@app/custom/features/rrs-storefinder/rrs-storefinder-routing.module';
import { RrsStoreFinderService } from '@app/custom/features/rrs-storefinder/services/rrs-store-finder.service';
import { StoreFinderComponentsModule } from '@spartacus/storefinder/components';
import { StoreOpeningSchedulePipe } from '@app/custom/features/rrs-storefinder/pipes/rrs-store-opening-schedule.pipe';
import { UserAccountModule } from '@spartacus/user';
import { RrsSearchStoresNormalizer } from '@app/custom/features/rrs-storefinder/occ/rrs-search-stores.normalizer';
import { RrsStoreNormalizer } from '@app/custom/features/rrs-storefinder/occ/rrs-store.normalizer';
import { RrsStoreLogoComponent } from './components/rrs-store-logo/rrs-store-logo.component';
import { RrsSpesificStoresListComponent } from './components/rrs-spesific-stores-list/rrs-spesific-stores-list.component';
import { RrsCmsStoreLocationComponent } from './components/rrs-cms-store-location/rrs-cms-store-location.component';
import { RrsStoreDetailsPageMetaResolver } from '@app/custom/features/rrs-storefinder/services/rrs-store-details-page-meta.resolver';
import { RrsSharedDirectivesModule } from '@app/shared/directives';
import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';
import { RrsMediaModule } from '@app/custom/features/rrs-media/rrs-media.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    StoreFinderComponentsModule,
    ReactiveFormsModule,
    RrsIconModule,
    RoutingModule,
    RrsStoreFinderRoutingModule,
    UrlModule,
    RrsAccordionModule,
    RrsSharedPipesModule,
    ModalModule,
    UserAccountModule,
    PageSlotModule,
    MediaModule,
    RrsSharedDirectivesModule,
    RrsMediaModule,
  ],
  providers: [
    provideConfig({
      slots: {
        StoreFinderSlot: ['RrsStoreFinderComponent'],
        StoreDetailsSlot: ['StoreDetailsComponent'],
      },
    } as CmsComponentsConfig),
    provideConfig({
      googleMaps: {
        apiKey: environment.googleMaps.apiKey,
      },
    } as StoreFinderConfig),
    provideConfig({
      cmsComponents: {
        RrsStoreFinderComponent: {
          component: RrsStoreFinderComponent,
        },
        StoreDetailsComponent: {
          component: RrsStoreDetailsComponent,
        },
        store_location: {
          component: RrsCmsStoreLocationComponent,
        },
      },
    } as CmsConfig),
    DatePipe,
    {
      provide: StoreFinderService,
      useExisting: RrsStoreFinderService,
    },
    {
      provide: StoreFinderConnector,
      useExisting: RrsStoreFinderConnector,
    },
    {
      provide: StoreFinderAdapter,
      useExisting: RrsStoreFinderAdapter,
    },
    {
      provide: STORE_FINDER_SEARCH_PAGE_NORMALIZER,
      useExisting: RrsSearchStoresNormalizer,
      multi: true,
    },
    {
      provide: POINT_OF_SERVICE_NORMALIZER,
      useExisting: RrsStoreNormalizer,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useExisting: RrsStoreDetailsPageMetaResolver,
      multi: true,
    },
  ],
  declarations: [
    RrsStoreFinderComponent,
    RrsStoreFinderSearchComponent,
    RrsStoreFinderListComponent,
    RrsStoreFinderListItemDetailsComponent,
    RrsStoreFinderMapComponent,
    RrsStoreDetailsComponent,
    RrsPaginationStatsPipe,
    RrsFindStoreDialogComponent,
    StoreOpeningSchedulePipe,
    RrsStoreLogoComponent,
    RrsSpesificStoresListComponent,
    RrsCmsStoreLocationComponent,
  ],
  exports: [
    RrsStoreFinderComponent,
    RrsStoreFinderSearchComponent,
    RrsStoreFinderListComponent,
    RrsStoreFinderListItemDetailsComponent,
    RrsStoreFinderMapComponent,
    RrsStoreDetailsComponent,
    RrsFindStoreDialogComponent,
    RrsStoreLogoComponent,
    StoreOpeningSchedulePipe,
  ],
})
export class RrsStoreFinderComponentsModule {}
