import { RrsSharedPipesModule } from '@app/shared/pipes';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import {
  RrsCmsHeaderBannerBottomComponent,
  RrsCmsHeaderBannerTopComponent,
  RrsCmsHeaderComponent,
  RrsCmsHeaderLogoComponent,
  RrsCmsHeaderMegaMenuComponent,
  RrsCmsHeaderMenuComponent,
  RrsCmsHeaderMenuMobiComponent,
  RrsCmsHeaderMenuMobiItemComponent,
  RrsCmsHeaderNaviComponent,
} from '@app/custom/features/rrs-cms/components/rrs-cms-header/components';
import { CommonModule } from '@angular/common';
import { HideMegaMenuDirective } from '@app/custom/features/rrs-cms/components/rrs-cms-header/directives/hide-mega-menu.directive';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RrsIconModule } from '@app/shared/components';
import { RrsMiniCartModule } from '@app/custom/features/rrs-mini-cart/rrs-mini-cart.module';
import { RrsSearchModule } from '@app/custom/features/rrs-search/rrs-search.module';
import { SwiperModule } from 'swiper/angular';
import {
  HEADER_BANNER_ID,
  HEADER_BANNER_SLOT,
  HEADER_COMPONENT_ID,
} from '@app/custom/features/rrs-cms/configs/contentstack.config';
import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';

@NgModule({
  declarations: [
    HideMegaMenuDirective,
    RrsCmsHeaderBannerBottomComponent,
    RrsCmsHeaderBannerTopComponent,
    RrsCmsHeaderComponent,
    RrsCmsHeaderLogoComponent,
    RrsCmsHeaderMegaMenuComponent,
    RrsCmsHeaderMenuComponent,
    RrsCmsHeaderMenuMobiComponent,
    RrsCmsHeaderMenuMobiItemComponent,
    RrsCmsHeaderNaviComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    RrsIconModule,
    RrsMiniCartModule,
    RrsSearchModule,
    SwiperModule,
    UrlModule,
    RrsSharedPipesModule,
  ],
  providers: [
    provideConfig({
      slots: {
        [HEADER_BANNER_SLOT]: [HEADER_BANNER_ID],
      },
    } as CmsComponentsConfig),
    provideConfig({
      cmsComponents: {
        [HEADER_COMPONENT_ID]: {
          component: RrsCmsHeaderComponent,
        },
        [HEADER_BANNER_ID]: {
          component: RrsCmsHeaderBannerBottomComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class RrsCmsHeaderModule {}
