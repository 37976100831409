import { Injectable } from '@angular/core';
import { RrsBasePageMetaResolver } from '@app/custom/features/seo/resolvers/rrs-base-page-meta.resolver';
import { RrsContentPageMetaResolver } from '@app/custom/features/seo/resolvers/rrs-content-page-meta.resolver';
import { interpolate, titleCase } from '@app/shared/utils/string';
import {
  CmsService,
  ImageGroup,
  Page,
  ProductScope,
  RoutingService,
  WindowRef,
} from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable, defer, of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsProductDetailsPageMetaResolver extends RrsContentPageMetaResolver {
  constructor(
    protected currentProductService: CurrentProductService,
    protected basePageMetaResolver: RrsBasePageMetaResolver,
    protected cmsService: CmsService,
    protected routingService: RoutingService,
    protected winRef: WindowRef
  ) {
    super(basePageMetaResolver);
    this.pageTemplate = 'ProductDetailsPageTemplate';
  }

  protected page$: Observable<Page> = defer(() =>
    this.cmsService.getCurrentPage()
  ).pipe(filter((p) => Boolean(p)));

  resolveTitle(): Observable<string | undefined> {
    return this.currentProductService.getProduct([ProductScope.DETAILS]).pipe(
      withLatestFrom(this.basePageMetaResolver.resolveTitle()),
      map(([product, title]) => {
        const productTitle = titleCase(product?.name);
        const color = titleCase(
          product?.variantOptions?.find((variantOption) =>
            variantOption.hasOwnProperty('colorPrimary')
          )?.colorPrimary
        );
        const category = titleCase(
          product?.categories?.find((category) =>
            category.code?.includes('brand')
          )?.name
        );
        return interpolate(title, { product: productTitle, color, category });
      })
    );
  }

  resolveDescription(): Observable<string | undefined> {
    return this.currentProductService.getProduct([ProductScope.DETAILS]).pipe(
      withLatestFrom(this.basePageMetaResolver.resolveDescription()),
      map(([product, description]) => {
        const brand = titleCase(
          product?.categories?.find((category) =>
            category.code?.includes('brand')
          )?.name
        );
        const productTitle = titleCase(product?.name);

        return interpolate(description, {
          product: `${brand} ${productTitle}`,
        });
      })
    );
  }

  resolveImage(): Observable<string | undefined> {
    return this.currentProductService.getProduct([ProductScope.DETAILS]).pipe(
      map((product) => {
        const ogUrl = (product?.images?.PRIMARY as ImageGroup)?.zoom?.url;
        return `${ogUrl}`;
      })
    );
  }

  resolveCanonicalUrl(): Observable<string> {
    return this.page$.pipe(
      switchMap((page) => {
        if (page.canonicalUrl?.length) {
          return of(page.canonicalUrl);
        }
        return this.basePageMetaResolver.resolveCanonicalUrl();
      })
    );
  }
}
