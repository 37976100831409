import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { Product, ProductService } from '@spartacus/core';
import { AddToCartStatus } from '@app/custom/features/rrs-add-to-cart/models/add-to-cart.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-donation-variant',
  templateUrl: './rrs-donation-variant.component.html',
})
export class RrsDonationVariantComponent {
  @Input() currentProduct!: Product;

  form = new FormGroup({
    donation: new UntypedFormControl(null),
  });

  constructor(protected productService: ProductService) {}

  get addToCartStatus(): AddToCartStatus {
    return {
      disabled: !this.selectedVariant,
      message: 'rrs.addToCart.addToCart',
    };
  }

  get selectedVariant(): string {
    return this.form.get('donation')?.value;
  }
}
