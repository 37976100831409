<ng-container *ngIf="messages$ | async as messages">
  <!-- Danger -->
  <div
    class="alert alert-danger"
    data-cy="global-message-danger"
    @fadeInFadeOutAnimation
    *ngFor="let confMsg of messages[messageType.MSG_TYPE_ERROR]; let i = index">
    <rrs-icon
      [icon]="iconTypeList.ALERT_DANGER"
      [size]="24"
      [classList]="'text-danger'"></rrs-icon>

    <div class="alert__text">
      {{ confMsg | cxTranslate }}
    </div>

    <button
      (click)="clear(messageType.MSG_TYPE_ERROR, i)"
      aria-label="Close"
      class="alert__close btn-close"
      type="button"></button>
  </div>

  <!-- Info -->
  <div
    class="alert alert-info"
    data-cy="global-message-info"
    @fadeInFadeOutAnimation
    *ngFor="let confMsg of messages[messageType.MSG_TYPE_INFO]; let i = index">
    <rrs-icon
      [icon]="iconTypeList.ALERT_INFO"
      [size]="24"
      [classList]="'text-info'"></rrs-icon>

    <div class="alert__text">
      {{ confMsg | cxTranslate }}
    </div>

    <button
      (click)="clear(messageType.MSG_TYPE_INFO, i)"
      aria-label="Close"
      class="alert__close btn-close"
      type="button"></button>
  </div>

  <!-- Success -->
  <div
    class="alert alert-success"
    data-cy="global-message-success"
    @fadeInFadeOutAnimation
    *ngFor="
      let confMsg of messages[messageType.MSG_TYPE_CONFIRMATION];
      let i = index
    ">
    <rrs-icon
      [icon]="iconTypeList.ALERT_SUCCESS"
      [size]="24"
      [classList]="'text-success'"></rrs-icon>

    <div class="alert__text">
      {{ confMsg | cxTranslate }}
    </div>

    <button
      (click)="clear(messageType.MSG_TYPE_CONFIRMATION, i)"
      aria-label="Close"
      class="alert__close btn-close"
      type="button"></button>
  </div>

  <!-- Warning -->
  <div
    class="alert alert-warning"
    data-cy="global-message-warning"
    @fadeInFadeOutAnimation
    *ngFor="
      let confMsg of messages[messageType.MSG_TYPE_WARNING];
      let i = index
    ">
    <rrs-icon
      [icon]="iconTypeList.ALERT_WARN"
      [size]="24"
      [classList]="'text-warning'"></rrs-icon>

    <div class="alert__text">
      {{ confMsg | cxTranslate }}
    </div>

    <button
      (click)="clear(messageType.MSG_TYPE_WARNING, i)"
      aria-label="Close"
      class="alert__close btn-close"
      type="button"></button>
  </div>
</ng-container>
