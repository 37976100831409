import { OccConfig, OccEndpoint } from '@spartacus/core';

declare module '@spartacus/core' {
  interface OccEndpoints {
    updatedStores?: string | OccEndpoint;
  }
}

export const rrsOccStoreFinderConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        store:
          'stores/${storeId}?fields=name,displayName,formattedDistance,openingHours(weekDayOpeningList(FULL),specialDayOpeningList(FULL)),geoPoint(latitude,longitude),address(line1,line2,town,region(FULL),postalCode,phone,country,email),features,storeImages(FULL)',
        stores:
          'stores?fields=stores(name,displayName,formattedDistance,openingHours(weekDayOpeningList(FULL),specialDayOpeningList(FULL)),geoPoint(latitude,longitude),address(line1,line2,town,region(FULL),postalCode,phone,country,email), features),pagination(DEFAULT),sorts(DEFAULT)',
        updatedStores: 'stores/updated?fields=FULL',
      },
    },
  },
};
