<div class="claim-rewards">
  <header
    class="claim-rewards__header d-flex align-items-center px-2 py-3 p-lg-0 mb-4">
    <h1
      class="fs-2 fw-normal d-inline-flex align-items-center mb-0"
      data-cy="claim-rewards-header">
      {{ 'rrs.account.claimRewards.claimRewards' | cxTranslate }}

      <rrs-icon
        [size]="32"
        [icon]="iconTypeList.POST"
        class="d-none d-lg-flex align-items-center justify-content-center ps-1"></rrs-icon>
    </h1>
  </header>

  <div class="px-4 px-lg-0">
    <div class="row row-cols-1 row-cols-lg-2">
      <div class="mb-4">
        <p data-cy="claim-rewards-description">
          {{ 'rrs.account.claimRewards.claimRewardsDescription' | cxTranslate }}
        </p>

        <form
          #ngForm="ngForm"
          [formGroup]="form"
          (ngSubmit)="sendForm()"
          autocomplete="off"
          data-cy="claim-rewards">
          <div class="form-group mb-3 w-100">
            <label for="salesDate" class="form-label">
              {{ 'rrs.account.claimRewards.salesDate' | cxTranslate }}
            </label>
            <input
              aria-required="true"
              class="form-control"
              [ngClass]="
                ngForm.submitted
                  ? (form.get('salesDate') | rrsIsControlValid)
                  : ''
              "
              data-cy="salesDate"
              placeholder="MM-DD-YYYY"
              mask="00-00-0000"
              formControlName="salesDate"
              id="salesDate"
              type="text" />

            <rrs-form-errors
              *ngIf="ngForm.submitted"
              [control]="form.get('salesDate')"
              aria-atomic="true"
              aria-live="assertive">
            </rrs-form-errors>
          </div>

          <div class="form-group mb-3 w-100">
            <label for="storeNo" class="form-label">
              {{ 'rrs.account.claimRewards.storeNo' | cxTranslate }}
            </label>
            <input
              aria-required="true"
              class="form-control"
              [ngClass]="
                ngForm.submitted
                  ? (form.get('storeNo') | rrsIsControlValid)
                  : ''
              "
              data-cy="storeNo"
              formControlName="storeNo"
              id="storeNo"
              mask="0{4}"
              type="text" />

            <rrs-form-errors
              *ngIf="ngForm.submitted"
              [control]="form.get('storeNo')"
              aria-atomic="true"
              aria-live="assertive">
            </rrs-form-errors>
          </div>

          <div class="form-group mb-3 w-100">
            <label for="registerNo" class="form-label">
              {{ 'rrs.account.claimRewards.registerNo' | cxTranslate }}
            </label>
            <input
              aria-required="true"
              class="form-control"
              [ngClass]="
                ngForm.submitted
                  ? (form.get('registerNo') | rrsIsControlValid)
                  : ''
              "
              data-cy="registerNo"
              formControlName="registerNo"
              id="registerNo"
              mask="0"
              type="text" />

            <rrs-form-errors
              *ngIf="ngForm.submitted"
              [control]="form.get('registerNo')"
              aria-atomic="true"
              aria-live="assertive">
            </rrs-form-errors>
          </div>

          <div class="form-group mb-4 w-100">
            <label for="transactionNo" class="form-label">
              {{ 'rrs.account.claimRewards.transactionNo' | cxTranslate }}
            </label>
            <input
              aria-required="true"
              class="form-control"
              [ngClass]="
                ngForm.submitted
                  ? (form.get('transactionNo') | rrsIsControlValid)
                  : ''
              "
              data-cy="transactionNo"
              formControlName="transactionNo"
              id="transactionNo"
              mask="0{6}"
              type="text" />

            <rrs-form-errors
              *ngIf="ngForm.submitted"
              [control]="form.get('transactionNo')"
              aria-atomic="true"
              aria-live="assertive">
            </rrs-form-errors>
          </div>

          <button
            class="btn btn-primary"
            type="submit"
            data-cy="claim-rewards-submit">
            {{ 'common.submit' | cxTranslate }}
          </button>
        </form>
      </div>

      <div>
        <img
          alt="Receipt"
          class="w-100"
          data-cy="claim-rewards-image"
          [src]="'/assets/images/receipt.jpeg'" />
      </div>
    </div>
  </div>
</div>
