import { AuthService, isNotNullable, PointOfService } from '@spartacus/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { RrsDefaultStoreService } from '@app/custom/features/rrs-account/services/rrs-default-store.service';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'rrs-store-finder-list-item-details',
  templateUrl: './rrs-store-finder-list-item-details.component.html',
  styleUrls: ['./rrs-store-finder-list-item-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsStoreFinderListItemDetailsComponent implements OnInit {
  @Input() store!: PointOfService | null;

  isLoggedIn$!: Observable<boolean>;
  defaultStoreId$!: Observable<string>;
  makeMyStoreControl = new FormControl(false);

  constructor(
    protected authService: AuthService,
    protected defaultStoreService: RrsDefaultStoreService,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isUserLoggedIn();
    this.defaultStoreId$ = this.defaultStoreService.getDefaultStoreId().pipe(
      filter(isNotNullable),
      tap((storeId) => {
        this.makeMyStoreControl.setValue(storeId === this.store?.name);
      })
    );
  }

  setAsDefaultStore(storeId: string, e: Event): void {
    if ((e.target as HTMLInputElement).checked) {
      this.defaultStoreService.setDefaultStoreId(storeId);
    } else {
      this.defaultStoreService.removeDefaultStore(storeId);
    }
  }
}
