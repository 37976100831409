<ng-container *ngIf="currentProduct as product">
  <form [formGroup]="form" class="mt-2">
    <div class="form-group mb-2">
      <label class="form-label" for="donation">
        {{ 'rrs.donation.selectPrice' | cxTranslate }}
      </label>

      <ng-select
        [clearable]="false"
        [searchable]="false"
        formControlName="donation"
        id="donation"
        labelForId="donation"
        placeholder="Select Donation">
        <ng-option
          *ngFor="let variant of product.variantOptions"
          [value]="variant.code">
          ${{ variant?.priceData?.value }}
        </ng-option>
      </ng-select>
    </div>
  </form>

  <rrs-add-to-cart
    [addToCartStatus]="addToCartStatus"
    [productCode]="selectedVariant"
    [product]="product"
    [showQuantity]="false"></rrs-add-to-cart>
</ng-container>
