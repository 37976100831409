import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RrsIconModule } from '@app/shared/components';
import { I18nModule } from '@spartacus/core';
import { RrsSavedPaymentsComponent } from '@app/custom/features/rrs-account/components/rrs-saved-payments/components/rrs-saved-payments/rrs-saved-payments.component';
import { RrsSavedPaymentsConfirmationComponent } from '@app/custom/features/rrs-account/components/rrs-saved-payments/components/rrs-saved-payments-confirmation/rrs-saved-payments-confirmation.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RrsFormErrorsModule } from '@app/shared/components/rrs-form-errors/rrs-form-errors.module';
import { RrsInputModule } from '@app/shared/components/rrs-input';
import { RrsSavedPaymentsFormComponent } from '@app/custom/features/rrs-account/components/rrs-saved-payments/components/rrs-saved-payments-form/rrs-saved-payments-form.component';

@NgModule({
  declarations: [
    RrsSavedPaymentsComponent,
    RrsSavedPaymentsConfirmationComponent,
    RrsSavedPaymentsFormComponent,
  ],
  imports: [
    CommonModule,
    RrsIconModule,
    I18nModule,
    ReactiveFormsModule,
    RrsFormErrorsModule,
    RrsInputModule,
  ],
})
export class RrsSavedPaymentsModule {}
