import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RrsLocateModalComponent } from './components/rrs-locate-modal/rrs-locate-modal.component';
import { I18nModule } from '@spartacus/core';
import { ModalModule } from '@app/custom/features/modal';
import { RrsIconModule } from '@app/shared/components';
import { RrsSharedPipesModule } from '@app/shared/pipes';
import { PageSlotModule } from '@spartacus/storefront';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [RrsLocateModalComponent],
  imports: [
    CommonModule,
    I18nModule,
    ModalModule,
    RrsIconModule,
    RrsSharedPipesModule,
    PageSlotModule,
    FormsModule,
  ],
})
export class RrsLoqateModule {}
