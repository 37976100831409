import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RrsIconModule } from '@app/shared/components';
import { RrsScrollToTopComponent } from '@app/shared/components/rrs-scroll-to-top/components';
import { I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [RrsScrollToTopComponent],
  exports: [RrsScrollToTopComponent],
  imports: [CommonModule, RrsIconModule, I18nModule],
})
export class RrsScrollToTopModule {}
