import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { AsmMainUiComponent } from '@spartacus/asm/components';

@Component({
  selector: 'rrs-asm-ui',
  templateUrl: './rrs-asm-ui.component.html',
  styles: [
    `
      :host {
        &.hidden {
          display: none;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsAsmUiComponent extends AsmMainUiComponent {
  icon = ICON_TYPE_LIST;
}
