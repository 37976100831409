import { Injectable } from '@angular/core';
import { AuthService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';
import { RrsCustomerGroupsService } from './rrs-customer-groups.service';

@Injectable({
  providedIn: 'root',
})
export class RrsDefaultStoreService {
  private defaultStoreId$ = new BehaviorSubject<string | null>(null);

  constructor(
    private customerGroupsService: RrsCustomerGroupsService,
    private authService: AuthService
  ) {}

  getDefaultStoreId(): Observable<string | null> {
    return this.authService.isUserLoggedIn().pipe(
      distinctUntilChanged(),
      tap((isLoggedIn) => {
        if (isLoggedIn && this.defaultStoreId$.getValue() === null) {
          this.getDefaultStoreCustomerGroup();
        } else if (!isLoggedIn) {
          this.defaultStoreId$.next(null);
        }
      }),
      switchMap(() => this.defaultStoreId$.asObservable())
    );
  }

  setDefaultStoreId(storeId: string): void {
    this.customerGroupsService
      .addToCustomerGroup(`ds${storeId}UG`)
      .pipe(
        take(1),
        tap(() => {
          this.defaultStoreId$.next(storeId);
        })
      )
      .subscribe();
  }

  removeDefaultStore(storeId: string): void {
    this.customerGroupsService
      .removeFromCustomerGroup(`ds${storeId}UG`)
      .pipe(
        take(1),
        tap(() => {
          this.defaultStoreId$.next('');
        })
      )
      .subscribe();
  }

  private getDefaultStoreCustomerGroup(): void {
    this.customerGroupsService
      .getCustomerGroups()
      .pipe(take(1))
      .subscribe((groups) => {
        const defaultStoreId =
          groups?.userGroups
            ?.find((group) => group.uid.startsWith('ds'))
            ?.uid.replace(/^ds|UG$/g, '') ?? '';
        this.defaultStoreId$.next(defaultStoreId);
      });
  }
}
