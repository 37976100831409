import {
  MediaModule,
  PageSlotModule,
  SCHEMA_BUILDER,
} from '@spartacus/storefront';
import {
  RrsProductDescriptionComponent,
  RrsProductDetailsContainerComponent,
  RrsProductDetailsImagesComponent,
  RrsProductDetailsZoomComponent,
} from '@app/custom/features/rrs-product-details/components';
import {
  rrsProductDetailsCmsConfig,
  rrsProductDetailsRoutingConfig,
} from '@app/custom/features/rrs-product-details/configs';
import { CommonModule } from '@angular/common';
import {
  I18nModule,
  PageMetaResolver,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RrsAddToCartModule } from '@app/custom/features/rrs-add-to-cart/rrs-add-to-cart.module';
import { RrsDonationModule } from '@app/custom/features/rrs-donation/rrs-donation.module';
import { RrsIconModule } from '@app/shared/components';
import { RrsProductVariantsModule } from '@app/custom/features/rrs-product-variants/rrs-product-variants.module';
import { SwiperModule } from 'swiper/angular';
import { RrsStoreFinderComponentsModule } from '@app/custom/features/rrs-storefinder/rrs-storefinder-components.module';
import { RouterModule } from '@angular/router';
import { rrsOccProductConfig } from '@app/custom/features/rrs-product-details/configs/rrs-occ-product.config';
import { RrsProductCategoryComponent } from './components/rrs-product-category/rrs-product-category.component';
import { RrsProductDetailsPageMetaResolver } from '@app/custom/features/rrs-product-details/services/rrs-product-details-page-meta.resolver';
import { RrsProductSchemaBuilder } from '@app/custom/features/rrs-product-details/services/rrs-product-schema.builder';
import { RrsSharedDirectivesModule } from '@app/shared/directives';
import { RrsSharedPipesModule } from '@app/shared/pipes';
import { RrsMediaModule } from '@app/custom/features/rrs-media/rrs-media.module';

@NgModule({
  declarations: [
    RrsProductDescriptionComponent,
    RrsProductDetailsContainerComponent,
    RrsProductDetailsImagesComponent,
    RrsProductDetailsZoomComponent,
    RrsProductCategoryComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    MediaModule,
    NgbModule,
    PageSlotModule,
    RouterModule,
    RrsAddToCartModule,
    RrsDonationModule,
    RrsIconModule,
    RrsProductVariantsModule,
    RrsStoreFinderComponentsModule,
    SwiperModule,
    UrlModule,
    RrsSharedDirectivesModule,
    RrsSharedPipesModule,
    RrsMediaModule,
  ],
  providers: [
    rrsProductDetailsRoutingConfig,
    ...rrsProductDetailsCmsConfig,
    provideConfig(rrsOccProductConfig),
    {
      provide: PageMetaResolver,
      useExisting: RrsProductDetailsPageMetaResolver,
      multi: true,
    },
    {
      provide: SCHEMA_BUILDER,
      useExisting: RrsProductSchemaBuilder,
      multi: true,
    },
  ],
})
export class RrsProductDetailsModule {}
