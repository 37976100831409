import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { AsmSessionTimerComponent } from '@spartacus/asm/components';

@Component({
  selector: 'rrs-asm-session-timer',
  templateUrl: './rrs-asm-session-timer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsAsmSessionTimerComponent extends AsmSessionTimerComponent {
  icon = ICON_TYPE_LIST;
}
