export const rrsCartOccConfig = {
  backend: {
    occ: {
      endpoints: {
        carts:
          'users/${userId}/carts?fields=carts(FULL,potentialProductPromotions,appliedProductPromotions,pickupOrderGroups(FULL),potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description,deliveryAddress(FULL,region(FULL)))',
        cart: 'users/${userId}/carts/${cartId}?fields=FULL,potentialProductPromotions,appliedProductPromotions,pickupOrderGroups(FULL,entries(deliveryPointOfService(FULL))),potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),deliveryPointOfService(name),deliveryMode(code,name),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description,paymentTenderDataList,deliveryAddress(FULL,region(FULL))',
        setDeliveryAddress:
          'users/${userId}/carts/${cartId}/addresses/delivery',
      },
    },
  },
};
