import { CmsConfig, provideConfig } from '@spartacus/core';
import {
  RrsCartContainerComponent,
  RrsCartSummaryComponent,
} from '@app/custom/features/rrs-cart/components';
import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';

export const rrsCartCmsConfig = [
  provideConfig({
    slots: {
      TopContent: ['CartContainerComponent'],
      CenterRightContentSlot: ['CartSummaryComponent'],
    },
  } as CmsComponentsConfig),
  provideConfig({
    cmsComponents: {
      CartContainerComponent: {
        component: RrsCartContainerComponent,
      },
      CartSummaryComponent: {
        component: RrsCartSummaryComponent,
      },
    },
  } as CmsConfig),
];
