import { ModalService } from '@app/custom/features/modal';
import { Cart } from '@spartacus/cart/base/root';
import { TranslationService } from '@spartacus/core';
import {
  ChangeDetectionStrategy,
  Component,
  ViewContainerRef,
} from '@angular/core';
import { LaunchDialogService } from '@spartacus/storefront';
import {
  SavedCartDetailsOverviewComponent,
  SavedCartDetailsService,
} from '@spartacus/cart/saved-cart/components';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { RrsSavedCartFormDialogComponent } from '@app/custom/features/rrs-saved-cart/components/rrs-saved-cart-form-dialog/rrs-saved-cart-form-dialog.component';
import { SavedCartFormType } from '@spartacus/cart/saved-cart/root';

@Component({
  selector: 'rrs-saved-cart-details-overview',
  templateUrl: './rrs-saved-cart-details-overview.component.html',
  styleUrls: ['./rrs-saved-cart-details-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsSavedCartDetailsOverviewComponent extends SavedCartDetailsOverviewComponent {
  iconTypeList = ICON_TYPE_LIST;

  constructor(
    protected savedCartDetailsService: SavedCartDetailsService,
    protected translation: TranslationService,
    protected vcr: ViewContainerRef,
    protected launchDialogService: LaunchDialogService,
    private modalService: ModalService
  ) {
    super(savedCartDetailsService, translation, vcr, launchDialogService);
  }

  openDialog(cart: Cart): void {
    const modalRef = this.modalService.open(RrsSavedCartFormDialogComponent, {
      windowClass: 'rrs-add-to-saved-cart-dialog',
    });
    modalRef.componentInstance.data = {
      cart,
      layoutOption: SavedCartFormType.EDIT,
    };
  }
}
