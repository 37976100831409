import {
  RrsCmsBreadcrumbsModule,
  RrsCmsContentNavigationModule,
  RrsCmsFooterModule,
  RrsCmsHeaderModule,
  RrsCmsNeedHelpModule,
  RrsCmsPageNotFoundModule,
  RrsCmsPlaceholderModule,
  RrsCmsRichTextModule,
} from '@app/custom/features/rrs-cms/components';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    RrsCmsBreadcrumbsModule,
    RrsCmsContentNavigationModule,
    RrsCmsFooterModule,
    RrsCmsHeaderModule,
    RrsCmsNeedHelpModule,
    RrsCmsPageNotFoundModule,
    RrsCmsPlaceholderModule,
    RrsCmsRichTextModule,
  ],
})
export class RrsCmsComponentsModule {}
