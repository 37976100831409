import { Cart } from '@spartacus/cart/base/root';
import { Price } from '@spartacus/core';

export interface RrsPayment {
  amount: number;
  paymentInfoId: string;
  tenderId: string;
  tenderType: string;
  cardNumber?: string;
  nickName?: string;
  expiryMonthYear?: string;
  expired?: boolean;
}

export enum RrsPaymentTypes {
  GIFT_CARD = 'RRGIFTCARD',
  PAYPAL = 'BTPP',
  KLARNA = 'KL',
}

export interface RrsCart extends Cart {
  totalAmountDueRemaining?: Price;
}

export const RrsPaymentTranslations: { [paymentType: string]: string } = {
  [RrsPaymentTypes.GIFT_CARD]: 'rrs.giftCards.giftCard',
  [RrsPaymentTypes.PAYPAL]: 'rrs.checkout.paypal',
  [RrsPaymentTypes.KLARNA]: 'rrs.checkout.klarna',
};

export const RrsPaymentTypeNameMap: { [type: string]: string } = {
  [RrsPaymentTypes.GIFT_CARD]: 'Gift card',
  [RrsPaymentTypes.PAYPAL]: 'Paypal',
  [RrsPaymentTypes.KLARNA]: 'Klarna',
};

export enum CheckoutSteps {
  DELIVERY_ADDRESS = 'delivery-address',
  DELIVERY_MODE = 'delivery-mode',
  PAYMENT_DETAILS = 'payment-details',
  REVIEW_ORDER = 'review-order',
  FORM_CONTACT = 'form-contact',
  FORM_DELIVERY = 'form-delivery',
}
