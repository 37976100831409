import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RrsUrlTrimParamsPipe } from '@app/shared/pipes/rrs-url-trim-params.pipe';
import { RrsUrlQueryParamsPipe } from '@app/shared/pipes/rrs-url-query-params.pipe';
import { RrsFindYearPipePipe } from './rrs-find-year-pipe.pipe';
import { RrsIsControlValidPipe } from './rrs-is-control-valid.pipe';
import { RrsPhoneNumberFormatterPipe } from './rrs-phone-number-formatter';
import { RrsSafeUrlPipe } from '@app/shared/pipes/rrs-safe-url/rrs-safe-url.pipe';
import { SafeSanitizePipe } from './safe-sanitize.pipe';
import { RrsBoldPricePipe } from '@app/shared/pipes/rrs-bold-price.pipe';
import { RrsCartEmailPipe } from './rrs-cart-email.pipe';
import { RrsPaymentLabelPipe } from './rrs-payment-label/rrs-payment-label.pipe';
import { RrsProductImagesPipe } from '@app/shared/pipes/rrs-product-images.pipe';
import { RrsStoreOpenStatePipe } from '@app/shared/pipes/rrs-store-open-state.pipe';

@NgModule({
  declarations: [
    RrsBoldPricePipe,
    RrsFindYearPipePipe,
    RrsIsControlValidPipe,
    RrsPhoneNumberFormatterPipe,
    RrsSafeUrlPipe,
    SafeSanitizePipe,
    RrsCartEmailPipe,
    RrsPaymentLabelPipe,
    RrsProductImagesPipe,
    RrsUrlTrimParamsPipe,
    RrsUrlQueryParamsPipe,
    RrsStoreOpenStatePipe,
  ],
  exports: [
    RrsBoldPricePipe,
    RrsFindYearPipePipe,
    RrsIsControlValidPipe,
    RrsPhoneNumberFormatterPipe,
    RrsSafeUrlPipe,
    SafeSanitizePipe,
    RrsCartEmailPipe,
    RrsPaymentLabelPipe,
    RrsProductImagesPipe,
    RrsUrlTrimParamsPipe,
    RrsUrlQueryParamsPipe,
    RrsStoreOpenStatePipe,
  ],
  imports: [CommonModule],
})
export class RrsSharedPipesModule {}
