import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { I18nModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { RrsAddressBookComponent } from '@app/custom/features/rrs-account/components/rrs-address-book/components/rrs-address-book/rrs-address-book.component';
import { RrsAddressBookConfirmationComponent } from './components/rrs-address-book-confirmation/rrs-address-book-confirmation.component';
import { RrsAddressBookFormComponent } from './components/rrs-address-book-form/rrs-address-book-form.component';
import { RrsFormErrorsModule } from '@app/shared/components/rrs-form-errors/rrs-form-errors.module';
import { RrsIconModule } from '@app/shared/components';
import { RrsSharedDirectivesModule } from '@app/shared/directives';
import { RrsSharedPipesModule } from '@app/shared/pipes';

@NgModule({
  declarations: [
    RrsAddressBookComponent,
    RrsAddressBookConfirmationComponent,
    RrsAddressBookFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    NgSelectModule,
    ReactiveFormsModule,
    RrsFormErrorsModule,
    RrsIconModule,
    RrsSharedDirectivesModule,
    RrsSharedPipesModule,
  ],
  exports: [RrsAddressBookComponent],
})
export class RrsAddressBookModule {}
