import { DOCUMENT, isPlatformServer } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@environments/environment';

declare var dT_: any;

@Injectable({
  providedIn: 'root',
})
export class RrsDynatraceService {
  constructor(
    @Inject(DOCUMENT) protected document: Document,
    @Inject(PLATFORM_ID) private platformId: string,
    private http: HttpClient
  ) {}

  initDynatrace(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const dynatraceScript = this.document.createElement('script');
    dynatraceScript.async = true;
    dynatraceScript.crossOrigin = 'anonymous';
    dynatraceScript.src = environment.dynatrace.oneAgentScriptUrl;
    this.document.head.appendChild(dynatraceScript);
    dynatraceScript.addEventListener('load', () => {
      if (typeof dT_ != 'undefined' && dT_.initAngularNg) {
        dT_.initAngularNg(this.http, HttpHeaders);
      }
    });
  }
}
