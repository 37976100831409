<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'rrs.loqateModal.addressConfirmation' | cxTranslate }}
  </h4>
  <button
    class="btn-close"
    [attr.aria-label]="'rrs.common.closeModal' | cxTranslate"
    type="button"
    cxModal="dismiss"
    (click)="modal.dismissActiveModal('Cross click')"
    cxModalReason="Cross click"></button>
</div>
<div class="modal-body">
  <div class="infoBox d-flex py-2 px-3 mb-4 rounded-1">
    <rrs-icon
      [icon]="icon.ALERT_INFO"
      [size]="24"
      class="text-info me-2"></rrs-icon>
    <div>
      <div class="fw-bold">
        {{ 'rrs.loqateModal.verifyErrorMsg' | cxTranslate }}
      </div>
      <div>{{ 'rrs.loqateModal.verifyErrorQuestion' | cxTranslate }}</div>
    </div>
  </div>
  <div class="mb-2 px-2">
    <div class="fw-bold mb-1">
      {{ 'rrs.loqateModal.recommended' | cxTranslate }}
    </div>
    <div class="d-flex align-items-center flex-grow-1 py-2">
      <input
        class="form-check-input mx-2"
        name="address"
        type="radio"
        id="recommendedAddress"
        [(ngModel)]="isRecommendedAddress"
        [value]="true" />
      <label class="cursor-pointer address-txt" for="recommendedAddress">
        <div>
          {{ recommendedAddress.line1 }}
          <span *ngIf="recommendedAddress.line2">{{
            recommendedAddress.line2
          }}</span>
        </div>
        <div>
          {{ recommendedAddress.town }},&nbsp;{{
            recommendedAddress.region?.isocodeShort
          }}&nbsp;{{ recommendedAddress.postalCode }}
        </div>
      </label>
    </div>
  </div>
  <div class="mb-4 px-2">
    <div class="fw-bold mb-1">
      {{ 'rrs.loqateModal.useOriginalAddress' | cxTranslate }}
    </div>
    <div class="d-flex align-items-center flex-grow-1 py-2">
      <input
        class="form-check-input mx-2"
        type="radio"
        name="address"
        id="useOriginalAddress"
        [value]="false"
        [(ngModel)]="isRecommendedAddress" />
      <label class="cursor-pointer address-txt" for="useOriginalAddress">
        <div>
          {{ address.line1 }}
          <span *ngIf="address.line2">{{ address.line2 }}</span>
        </div>
        <div>
          {{ address.town }},&nbsp;{{ address.region?.isocodeShort }}&nbsp;{{
            address.postalCode
          }}
        </div>
      </label>
    </div>
  </div>
  <button (click)="saveAddress()" class="btn btn-primary w-100 mb-3">
    {{ 'rrs.common.continue' | cxTranslate }}
  </button>
  <div *ngIf="cartId" class="text-center text-light w-100">
    {{ 'rrs.loqateModal.cartId' | cxTranslate }}{{ cartId }}
  </div>
  <cx-page-slot position="need_help_dropdown"></cx-page-slot>
</div>
