import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rrsPhoneNumber',
})
export class RrsPhoneNumberFormatterPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value?.length || value?.length > 12) return value ?? '';

    let regex = /^(\d{0,3})(\d{0,3})(\d{0,4})/;
    let pattern = '($1) $2-$3';

    if ([11, 12].includes(value.length)) {
      pattern = '+$1 ($2) $3-$4';
      regex = new RegExp(
        `^(\\d{0,${
          value.length === 11 ? '1' : '2'
        }})(\\d{0,3})(\\d{0,3})(\\d{0,4})`
      );
    }

    return value.replace(regex, pattern);
  }
}
