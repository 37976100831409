<ng-container>
  <cx-page-slot position="BreadcrumbsSlot"></cx-page-slot>
  <div
    class="d-flex flex-wrap justify-content-between"
    style="max-width: 1400px">
    <div class="col-lg-6 px-3">
      <rrs-gift-card-balance></rrs-gift-card-balance>
    </div>
    <div class="col-lg-6 px-3">
      <rrs-available-gift-cards></rrs-available-gift-cards>
    </div>
  </div>
</ng-container>
