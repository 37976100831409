import { Pipe, PipeTransform } from '@angular/core';
import { UserGroup } from '@app/custom/features/rrs-account/models/customer-groups.model';

@Pipe({
  name: 'rrsMonthOfBirth',
})
export class RrsMonthOfBirthPipe implements PipeTransform {
  transform(value: UserGroup): string {
    switch (value?.uid) {
      case 'birthMonth01UG':
        return 'rrs.monthOfBirth.january';

      case 'birthMonth02UG':
        return 'rrs.monthOfBirth.february';

      case 'birthMonth03UG':
        return 'rrs.monthOfBirth.march';

      case 'birthMonth04UG':
        return 'rrs.monthOfBirth.april';

      case 'birthMonth05UG':
        return 'rrs.monthOfBirth.may';

      case 'birthMonth06UG':
        return 'rrs.monthOfBirth.june';

      case 'birthMonth07UG':
        return 'rrs.monthOfBirth.july';

      case 'birthMonth08UG':
        return 'rrs.monthOfBirth.august';

      case 'birthMonth09UG':
        return 'rrs.monthOfBirth.september';

      case 'birthMonth10UG':
        return 'rrs.monthOfBirth.october';

      case 'birthMonth11UG':
        return 'rrs.monthOfBirth.november';

      case 'birthMonth12UG':
        return 'rrs.monthOfBirth.december';

      default:
        return 'rrs.monthOfBirth.notSelected';
    }
  }
}
