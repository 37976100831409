<div
  *ngIf="entry.product as product"
  class="py-3 px-2 d-flex justify-content-between w-100 border-bottom">
  <a
    [routerLink]="product.url"
    [attr.aria-label]="product.name"
    class="col-3 mx-3">
    <cx-media
      [container]="product.images?.PRIMARY"
      [alt]="product.name"></cx-media>
  </a>
  <div class="w-100">
    <a
      [routerLink]="product.url"
      [attr.aria-label]="product.name"
      class="d-block pb-2 text-decoration-none text-truncate--2">
      {{ product.name }}
    </a>
    <ng-container *ngIf="product">
      <div *ngIf="!product.isProductWithoutSize">
        {{ product.size }}&nbsp;{{ product.width }}
      </div>
      <div>{{ product.colorPrimary }}</div>
    </ng-container>
    <div>SKU: {{ product.baseProduct }}</div>
    <div class="fw-bold">{{ entry.basePrice.formattedValue }}</div>
  </div>
  <button
    (click)="removeCartEntry(entry)"
    [attr.aria-label]="'rrs.common.remove' | cxTranslate"
    type="button"
    class="self-end d-flex mx-3">
    <rrs-icon [icon]="icon" [size]="24"></rrs-icon>
  </button>
</div>
