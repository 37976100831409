import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { rrsAccountNavigation } from '@app/custom/features/rrs-account/configs';
import { RrsAuthService } from '@app/custom/features/rrs-auth/services/rrs-auth.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-account-navigation',
  templateUrl: './rrs-account-navigation.component.html',
})
export class RrsAccountNavigationComponent {
  accountNavigation = rrsAccountNavigation;
  iconTypeList = ICON_TYPE_LIST;

  constructor(protected authService: RrsAuthService) {}

  customAction(action: string): void {
    switch (action) {
      case 'signOut':
        this.authService.signOut();
        break;
      default:
        break;
    }
  }
}
