export enum StockLevelStatus {
  IN_STOCK = 'inStock',
  OUT_OF_STOCK = 'outOfStock',
}

export interface PdpAvailability {
  code: string;
  hasExpeditedAvailability: boolean;
  hasPickupAvailability: boolean;
  hasShippingAvailability: boolean;
}
