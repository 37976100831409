import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@spartacus/core';
import { CmsPageGuard } from '@spartacus/storefront';
import { NgModule } from '@angular/core';
import { RrsAccountContentComponent } from '@app/custom/features/rrs-account/components/rrs-account-content/rrs-account-content.component';
import { RrsAddressBookComponent } from '@app/custom/features/rrs-account/components/rrs-address-book/components/rrs-address-book/rrs-address-book.component';
import { RrsClaimRewardsComponent } from '@app/custom/features/rrs-rewards/components/rrs-claim-rewards/rrs-claim-rewards.component';
import { RrsOrderDetailsComponent } from '@app/custom/features/rrs-account/components/rrs-orders/components/rrs-order-details/rrs-order-details.component';
import { RrsOrdersComponent } from '@app/custom/features/rrs-account/components/rrs-orders/components/rrs-orders/rrs-orders.component';
import { RrsProfileComponent } from '@app/custom/features/rrs-profile/components/rrs-profile/rrs-profile.component';
import { RrsRewardsComponent } from '@app/custom/features/rrs-rewards/components/rrs-rewards/rrs-rewards.component';
import { RrsSavedCartDetailsComponent } from '@app/custom/features/rrs-saved-cart/components/rrs-saved-cart-details/rrs-saved-cart-details.component';
import { RrsSavedCartsComponent } from '@app/custom/features/rrs-saved-cart/components/rrs-saved-carts/rrs-saved-carts.component';
import { RrsSavedPaymentsComponent } from '@app/custom/features/rrs-account/components/rrs-saved-payments/components/rrs-saved-payments/rrs-saved-payments.component';

const routes: Routes = [
  {
    path: 'my-account',
    component: RrsAccountContentComponent,
    canActivate: [CmsPageGuard, AuthGuard],
    data: { pageLabel: 'my-account' },
    children: [
      {
        path: 'profile',
        component: RrsProfileComponent,
        data: { pageLabel: 'my-account' },
      },
      {
        path: 'rewards',
        component: RrsRewardsComponent,
        data: { pageLabel: 'my-account' },
      },
      {
        path: 'address-book',
        component: RrsAddressBookComponent,
        data: { pageLabel: 'my-account' },
      },
      {
        path: 'payment-details',
        component: RrsSavedPaymentsComponent,
        data: { pageLabel: 'my-account' },
      },
      {
        path: 'wishlists',
        component: RrsSavedCartsComponent,
        data: { pageLabel: 'my-account' },
      },
      {
        path: 'wishlists/:savedCartId',
        component: RrsSavedCartDetailsComponent,
        data: { pageLabel: 'my-account' },
      },
      {
        path: 'order-history',
        component: RrsOrdersComponent,
        data: { pageLabel: 'my-account' },
      },
      {
        path: 'claim-rewards',
        component: RrsClaimRewardsComponent,
        data: { pageLabel: 'my-account' },
      },
    ],
  },
  {
    path: 'my-account/order-history/:orderCode',
    component: RrsOrderDetailsComponent,
    canActivate: [CmsPageGuard, AuthGuard],
    data: { pageLabel: 'order-details' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RrsAccountRoutingModule {}
