import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rrsStartWithHash',
})
export class RrsStartWithHashPipe implements PipeTransform {
  transform(value: string): string {
    if (value.startsWith('#')) {
      return value.substring(1);
    } else {
      return value;
    }
  }
}
