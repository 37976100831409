export enum THEME_COLORS {
  ACCENT = 'accent',
  ACCENT_SECONDARY = 'accent-secondary',
  ALERT = 'alert',
  DANGER = 'danger',
  INFO = 'info',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WARNING = 'warning',
}

export enum THEME_COLORS_TEXT_BACKGROUND {
  ACCENT = 'text-bg-accent',
  ACCENT_SECONDARY = 'text-bg-accent-secondary',
  ALERT = 'text-bg-alert',
  DANGER = 'text-bg-danger',
  INFO = 'text-bg-info',
  PRIMARY = 'text-bg-primary',
  SECONDARY = 'text-bg-secondary',
  WARNING = 'text-bg-warning',
}

export enum THEME_COLORS_TEXT {
  ACCENT = 'text-accent',
  ACCENT_SECONDARY = 'text-accent-secondary',
  ALERT = 'text-alert',
  DANGER = 'text-danger',
  INFO = 'text-info',
  PRIMARY = 'text-primary',
  SECONDARY = 'text-secondary',
  WARNING = 'text-warning',
}
