/* eslint-disable @angular-eslint/directive-selector */
import {
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: 'a[href]',
})
export class ExternalLinkDirective implements OnChanges {
  @HostBinding('attr.href') attrHref = '';
  @HostBinding('attr.rel') attrRel = '';
  @HostBinding('attr.target') attrTarget = '';

  @Input() href: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private elementRef: ElementRef
  ) {}

  ngOnChanges(): void {
    this.attrHref = this.href;

    if (this.isLinkExternal()) {
      this.attrRel = 'noopener noreferrer';
      this.attrTarget = '_blank';
    }
  }

  private isLinkExternal(): boolean {
    // TODO: CMake sure that this condition is enought to test the external link
    return isPlatformBrowser(this.platformId) && !this.href.startsWith('/');
  }
}
