<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <div *ngIf="cart.totalItems > 0" class="cart-container">
      <a
        [routerLink]="{ cxRoute: 'home' } | cxUrl"
        class="link link-light fw-normal text-uppercase d-flex mb-3 ms-3 ms-lg-0">
        {{ 'cart.keepShopping' | cxTranslate }}
      </a>

      <header
        class="cart-container__header d-flex align-items-center justify-content-between mb-lg-3 p-3 p-lg-0">
        <h1 class="fs-6 fw-normal mb-0">
          {{ 'cart.cartId' | cxTranslate }}:
          {{ cart.code }}
        </h1>

        <cx-page-slot position="need_help_modal"></cx-page-slot>
      </header>

      <rrs-cart-list
        [cartIsLoading]="(cartLoaded$ | async) === false"
        [items]="entries"
        [options]="{
          isSaveForLater: false
        }"
        [deliveryAddress]="cart.deliveryAddress"
        [promotionLocation]="promotionLocation"
        [appliedPromotions]="cart.appliedProductPromotions">
      </rrs-cart-list>

      <div class="row row-cols-1 g-0 g-lg-4 row-cols-lg-2 my-4">
        <cx-page-slot position="campaign_message_slot_2"></cx-page-slot>
        <cx-page-slot position="campaign_message_slot_3"></cx-page-slot>
      </div>
      <div class="row row-cols-1 g-0 my-4">
        <div class="carousel1"></div>
      </div>
    </div>
  </ng-container>
</ng-container>
