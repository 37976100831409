import {
  AuthService,
  GlobalMessageService,
  RoutingService,
} from '@spartacus/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ResetPasswordComponent } from '@spartacus/user/profile/components';
import { RrsSetPasswordService } from '@app/custom/features/rrs-set-password/services/rrs-set-password.service';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { FocusConfig } from '@spartacus/storefront';

@AutoUnsubscribe()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-set-password',
  styleUrls: ['./rrs-set-password.component.scss'],
  templateUrl: './rrs-set-password.component.html',
  providers: [
    {
      provide: RrsSetPasswordService,
      useClass: RrsSetPasswordService,
      deps: [UserPasswordFacade, RoutingService, GlobalMessageService],
    },
  ],
})
export class RrsSetPasswordComponent
  extends ResetPasswordComponent
  implements OnInit
{
  protected subscriptions = new Subscription();

  constructor(
    protected authService: AuthService,
    protected routingService: RoutingService,
    protected service: RrsSetPasswordService
  ) {
    super(service);
  }

  get focusConfig(): FocusConfig {
    return {
      autofocus: 'input',
      focusOnEscape: false,
    };
  }

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([this.token$, this.authService.isUserLoggedIn()])
        .pipe(
          take(1),
          tap(([token, isAuth]) => {
            if (!token || isAuth) {
              this.routingService.go({ cxRoute: 'home' });
            }
          })
        )
        .subscribe()
    );
  }
}
