import { provideConfig, RoutingConfig } from '@spartacus/core';

export const rrsBrandsRoutingConfig = provideConfig({
  routing: {
    routes: {
      brands: {
        paths: ['brands'],
      },
    },
  },
} as RoutingConfig);
