import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { RrsContactUsService } from '@app/custom/features/rrs-contact-us/services/rrs-contact-us.service';
import { CmsComponentData } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'rrs-contact-us',
  templateUrl: './rrs-contact-us.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsContactUsComponent implements OnInit, OnDestroy {
  subscription!: Subscription;
  data$ = this.componentData.data$;

  constructor(
    protected componentData: CmsComponentData<Contentstack.ContactUs>,
    protected contactUsService: RrsContactUsService
  ) {}

  ngOnInit(): void {
    this.subscription = this.data$
      .pipe(
        tap((data) => {
          this.contactUsService.initializeWidget(
            data.open_on_load,
            data.show_launcher
          );
        })
      )
      .subscribe();
  }

  open(): void {
    this.contactUsService.openWidget();
  }

  ngOnDestroy(): void {
    this.contactUsService.destroyWidget();
    this.subscription.unsubscribe();
  }
}
