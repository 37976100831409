export enum RrsDynamicYieldPageTypes {
  homepage = 'HOMEPAGE',
  cart = 'CART',
  category = 'CATEGORY',
  product = 'PRODUCT',
  OTHER = 'OTHER',
}

export interface RrsDynamicYieldContext {
  pageType: RrsDynamicYieldPageTypes;
  data?: any;
}
