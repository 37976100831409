import { Component, inject } from '@angular/core';
import { AsmService } from '@spartacus/asm/core';
import { AsmEnablerService } from '@spartacus/asm/root';
import { StorefrontComponent } from '@spartacus/storefront';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'rrs-storefront',
  styleUrls: ['./rrs-storefront.component.scss'],
  templateUrl: './rrs-storefront.component.html',
})
export class RrsStorefrontComponent extends StorefrontComponent {
  private asmEnablerService: AsmEnablerService = inject(AsmEnablerService);
  private asmService: AsmService = inject(AsmService);
  private router = inject(Router);
  isAsmEnabled = this.asmEnablerService.isEnabled();
  isAsmOpened$ = this.asmService
    .getAsmUiState()
    .pipe(map((asmUi) => !asmUi.collapsed));
  isCheckout$: Observable<boolean> = this.router.events.pipe(
    map((event) => event instanceof NavigationEnd),
    filter((isNavigationEndEvent) => isNavigationEndEvent),
    map(() => this.router.url.includes('/checkout')),
    distinctUntilChanged()
  );
}
