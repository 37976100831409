import { NgModule } from '@angular/core';
import { AepModule } from '@spartacus/tracking/tms/aep';
import { BaseTmsModule, TmsService } from '@spartacus/tracking/tms/core';
import { RrsTmsService } from '@app/custom/features/rrs-tms/rrs-tms.service';
import { RrsAepEventsModule } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/rrs-aep-events.module';

@NgModule({
  imports: [BaseTmsModule.forRoot(), AepModule, RrsAepEventsModule],
  providers: [
    {
      provide: TmsService,
      useExisting: RrsTmsService,
    },
  ],
})
export class RrsAdobeExperiencePlatform {}
