export const OrderHistoryOccConfig = {
  backend: {
    occ: {
      endpoints: {
        orderHistory: 'users/${userId}/orderHistory',
        orderDetail: 'users/${userId}/orderHistory/${orderId}',
      },
    },
  },
};
