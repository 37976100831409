import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RrsPointOfService } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';

@Component({
  selector: 'rrs-store-logo',
  templateUrl: './rrs-store-logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsStoreLogoComponent {
  @Input() store!: RrsPointOfService;
  @Input() size!: number;
}
