import { RoutingConfig } from '@spartacus/core';

export const orderStatusRoutingConfig: RoutingConfig = {
  routing: {
    routes: {
      orderStatus: {
        paths: ['order-status'],
      },
      orderStatusDetails: {
        paths: ['order-details/:email/:orderCode'],
        paramsMapping: { orderCode: 'orderCode', email: 'email' },
      },
    },
  },
};
