import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { RrsCmsHeaderUiService } from '@app/custom/features/rrs-cms/components/rrs-cms-header/services/rrs-cms-header-ui.service';
import { THEME_COLORS_TEXT } from '@app/shared/models';
import { RrsAuthService } from '@app/custom/features/rrs-auth/services/rrs-auth.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cms-header-menu-mobi-item',
  styleUrls: ['./rrs-cms-header-menu-mobi-item.component.scss'],
  templateUrl: './rrs-cms-header-menu-mobi-item.component.html',
})
export class RrsCmsHeaderMenuMobiItemComponent {
  iconTypeList = ICON_TYPE_LIST;
  isItemOpen = false;
  themeColorsText = THEME_COLORS_TEXT;

  @Input() item!: Contentstack.HeaderTopLevelNav & Contentstack.SingleLevelNav;
  @Input() isAccountNavigation: boolean = false;
  @Input() level: number = 1;

  constructor(
    public headerUi: RrsCmsHeaderUiService,
    protected authService: RrsAuthService
  ) {}

  toggIsItemOpen(): void {
    this.isItemOpen = !this.isItemOpen;
  }

  customAction(action: string): void {
    switch (action) {
      case 'signOut':
        this.authService.signOut();
        break;
      default:
        break;
    }
    this.headerUi.toggMenuMobi();
  }
}
