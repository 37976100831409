import { CmsConfig, provideConfig } from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RrsCmsContentNavigationComponent } from './components/rrs-cms-content-navigation/rrs-cms-content-navigation.component';
import { CONTENT_NAVIGATION_COMPONENT_ID } from '@app/custom/features/rrs-cms/configs/contentstack.config';

@NgModule({
  declarations: [RrsCmsContentNavigationComponent],
  imports: [CommonModule, RouterModule],
  providers: [
    provideConfig({
      cmsComponents: {
        [CONTENT_NAVIGATION_COMPONENT_ID]: {
          component: RrsCmsContentNavigationComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class RrsCmsContentNavigationModule {}
