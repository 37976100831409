import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ModalService } from '@app/custom/features/modal';
import { SavedCartFormType } from '@spartacus/cart/saved-cart/root';
import { combineLatest, Subscription } from 'rxjs';

import {
  EventService,
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
} from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { map } from 'rxjs/operators';
import {
  SavedCartFormDialogComponent,
  SavedCartFormDialogOptions,
} from '@spartacus/cart/saved-cart/components';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { RrsSavedCartService } from '@app/custom/features/rrs-saved-cart/services/rrs-saved-cart.service';

@AutoUnsubscribe()
@Component({
  selector: 'rrs-saved-cart-form-dialog',
  templateUrl: './rrs-saved-cart-form-dialog.component.html',
  styleUrls: ['./rrs-saved-cart-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsSavedCartFormDialogComponent
  extends SavedCartFormDialogComponent
  implements OnInit
{
  private subscriptions = new Subscription();
  iconTypeList = ICON_TYPE_LIST;
  isFormSubmitted = false;

  @Input() set data(options: SavedCartFormDialogOptions) {
    this.cart = options.cart;
    this.layoutOption = options.layoutOption;

    this.build(this.cart);
  }

  constructor(
    launchDialogService: LaunchDialogService,
    el: ElementRef,
    protected savedCartService: RrsSavedCartService,
    eventService: EventService,
    routingService: RoutingService,
    globalMessageService: GlobalMessageService,
    private modalService: ModalService
  ) {
    super(
      launchDialogService,
      el,
      savedCartService,
      eventService,
      routingService,
      globalMessageService
    );
  }

  ngOnInit(): void {
    this.savedCartService.resetSavedCartStates();

    this.isLoading$ = this.savedCartService.getSaveCartProcessLoading();

    // this.isDisableDeleteButton$ = merge(
    //   this.eventService.get(DeleteSavedCartEvent).pipe(take(1), mapTo(true)),
    //   this.eventService
    //     .get(DeleteSavedCartFailEvent)
    //     .pipe(take(1), mapTo(false))
    // );

    this.isDisableRestoreButton$ = combineLatest([
      this.savedCartService.getCloneSavedCartProcessLoading(),
      this.savedCartService.getRestoreSavedCartProcessLoading(),
    ]).pipe(
      map(
        ([isCloneLoading, isRestoreLoading]) =>
          isCloneLoading || isRestoreLoading
      )
    );

    this.subscriptions.add(
      this.savedCartService
        .getSaveCartProcessSuccess()
        .subscribe((success) => this.onComplete(success))
    );

    // this.subscriptions.add(
    //   this.eventService
    //     .get(DeleteSavedCartSuccessEvent)
    //     .pipe(take(1), mapTo(true))
    //     .subscribe((success) => this.onComplete(success))
    // );

    this.subscriptions.add(
      this.savedCartService
        .getRestoreSavedCartProcessSuccess()
        .subscribe((success) => this.onComplete(success))
    );
  }

  deleteCart(cartId: string): void {
    this.isFormSubmitted = true;
    this.savedCartService.deleteSavedCart(cartId);
    this.modalService.closeActiveModal();
  }

  override saveOrEditCart(cartId: string): void {
    this.isFormSubmitted = true;
    super.saveOrEditCart(cartId);
  }

  onComplete(success: boolean): void {
    if (success) {
      switch (this.layoutOption) {
        case SavedCartFormType.DELETE: {
          this.modalService.closeActiveModal();
          this.routingService.go({ cxRoute: 'savedCarts' });
          this.globalMessageService.add(
            {
              key: 'rrs.savedCart.messages.wishlistDeleted',
            },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );

          break;
        }

        case SavedCartFormType.SAVE: {
          this.modalService.closeActiveModal();
          this.savedCartService.clearSaveCart();
          this.globalMessageService.add(
            {
              key: 'rrs.savedCart.messages.wishlistSaved',
              params: {
                cartName: this.form.get('name')?.value || this.cart?.code,
              },
            },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
          this.routingService.go({ cxRoute: 'home' });

          break;
        }

        case SavedCartFormType.EDIT: {
          this.modalService.closeActiveModal();
          this.savedCartService.clearSaveCart();
          this.globalMessageService.add(
            {
              key: 'rrs.savedCart.messages.wishlistEdited',
              params: {
                cartName: this.form.get('name')?.value || this.cart?.code,
              },
            },

            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );

          break;
        }
      }
    }
  }
}
