import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '@app/shared/configs';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { ValidationError } from '@app/shared/utils';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'rrs-password-errors',
  templateUrl: './rrs-password-errors.component.html',
  styleUrls: ['./rrs-password-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsPasswordErrorsComponent {
  @Input() errors: ValidationErrors = {};
  @Input() showErrors: boolean = false;
  @Input() displayMode: 'list' | 'grid' = 'list';

  passwordLengthRange = {
    min: PASSWORD_MIN_LENGTH,
    max: PASSWORD_MAX_LENGTH,
  };

  validationError = ValidationError;
  iconsType = ICON_TYPE_LIST;
}
