import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { RrsAuthService } from '@app/custom/features/rrs-auth/services/rrs-auth.service';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import { RrsGlobalMessageService } from '@app/custom/features/rrs-global-message/rrs-global-message.service';
import {
  AuthRedirectService,
  AuthService,
  GlobalMessageType,
  RoutingService,
} from '@spartacus/core';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsAuthGuard implements CanActivate {
  constructor(
    protected rrsAuthService: RrsAuthService,
    private authService: AuthService,
    private activeCartService: RrsActiveCartService,
    private routingService: RoutingService,
    private authRedirectService: AuthRedirectService,
    protected globalMessagesService: RrsGlobalMessageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    const isLogged$: any = combineLatest([
      this.authService.isUserLoggedIn(),
      this.activeCartService
        .requireLoadedCart()
        .pipe(switchMap(() => this.activeCartService.isGuestCart())), // wait for loaded cart to get correct isGuest value
    ]).pipe(
      take(1),
      switchMap(([logged, isGuest]) => {
        const isCheckoutRoute = route.routeConfig?.path === 'checkout';

        if (logged || (isCheckoutRoute && isGuest)) {
          return of(true);
        }

        this.routingService
          .getRouterState()
          .pipe(take(1))
          .subscribe((state) => {
            if (state?.nextState?.url) {
              this.authRedirectService.setRedirectUrl(state.nextState.url);
            }
          });
        this.routingService.go(
          { cxRoute: 'login' },
          { state: { preserveGlobalMessage: true } }
        );
        this.globalMessagesService.add(
          { key: 'httpHandlers.loginToContinue' },
          GlobalMessageType.MSG_TYPE_INFO
        );
        return of(false);
      })
    );

    return isLogged$;
  }
}
