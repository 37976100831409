import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { rrsCheckoutStepsConfig } from '@app/custom/features/rrs-checkout/configs/config';
import { rrsCheckoutRoutingConfig } from '@app/custom/features/rrs-checkout/configs/router.config';
import { RrsCheckoutGuard } from '@app/custom/features/rrs-checkout/services/rrs-checkout.guard';
import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';
import {
  checkoutTranslationChunksConfig,
  checkoutTranslations,
} from '@spartacus/checkout/base/assets';
import { CartNotEmptyGuard } from '@spartacus/checkout/base/components';
import {
  CHECKOUT_FEATURE,
  CheckoutRootModule,
} from '@spartacus/checkout/base/root';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';

@NgModule({
  declarations: [],
  imports: [
    CheckoutRootModule,
    RouterModule.forChild([
      {
        path: 'checkout',
        pathMatch: 'full',
        redirectTo: 'checkout/delivery-address',
      },
      {
        path: 'checkout',
        canActivate: [CartNotEmptyGuard, CmsPageGuard, RrsCheckoutGuard],
        children: [
          {
            path: ':step',
            canActivate: [],
            component: PageLayoutComponent,
          },
        ],
      },
    ]),
    RouterModule.forChild([
      {
        path: 'checkout',
        pathMatch: 'full',
        redirectTo: 'checkout/delivery-address',
      },
      {
        path: 'checkout',
        canActivate: [CartNotEmptyGuard, CmsPageGuard, RrsCheckoutGuard],
        children: [
          {
            path: ':step',
            canActivate: [],
            component: PageLayoutComponent,
          },
        ],
      },
    ]),
  ],
  providers: [
    rrsCheckoutRoutingConfig,
    rrsCheckoutStepsConfig,
    provideConfig({
      featureModules: {
        [CHECKOUT_FEATURE]: {
          module: () =>
            import(
              '../../../custom/features/rrs-checkout/rrs-checkout.module'
            ).then((m) => m.RrsCheckoutModule),
          cmsComponents: [
            'checkout_header_component',
            'RrsCheckoutContainer',
            'OrderSummaryComponent',
          ],
        },
      },
    } as CmsConfig),
    provideConfig({
      i18n: {
        resources: checkoutTranslations,
        chunks: checkoutTranslationChunksConfig,
      },
    } as I18nConfig),
    provideConfig({
      slots: {
        CheckoutHeader: ['checkout_header_component'],
        CheckoutSlot: ['RrsCheckoutContainer'],
        OrderSummarySlot: ['OrderSummaryComponent'],
      },
    } as CmsComponentsConfig),
  ],
})
export class CheckoutFeatureModule {}
