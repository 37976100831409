import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { I18nModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { RrsSelectRewardsComponent } from '@app/custom/features/rrs-rewards/modules/rrs-select-rewards/components/rrs-select-rewards/rrs-select-rewards.component';
import { RrsSharedPipesModule } from '@app/shared/pipes';

@NgModule({
  declarations: [RrsSelectRewardsComponent],
  exports: [RrsSelectRewardsComponent],
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    ReactiveFormsModule,
    RrsSharedPipesModule,
  ],
})
export class RrsSelectRewardsModule {}
