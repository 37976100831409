import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RrsScriptLoadService {
  loadScript(url: string): Promise<boolean> {
    return new Promise((res) => {
      const script = document.createElement('script') as any;
      script.src = url;
      script.onload = (): void => {
        res(true);
      };
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }
}
