import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RrsContentstackService {
  constructor(private http: HttpClient) {}

  loadContent<T>(
    contentType: string,
    params: { [key: string]: string | string[] } = {}
  ): Observable<T> {
    const {
      contentstackAccessToken,
      contentstackApiKey,
      contentstackEnvironment,
    } = environment;

    params.environment = contentstackEnvironment;

    const queryParams = new HttpParams({ fromObject: params });

    const contentstackUrl = `${environment.contentstackUrl}/content_types/${contentType}/entries?${queryParams}`;
    const contentstackHttpOptions = {
      headers: new HttpHeaders({
        api_key: contentstackApiKey,
        access_token: contentstackAccessToken,
      }),
    };

    return this.http.get<T>(contentstackUrl, contentstackHttpOptions).pipe();
  }
}
