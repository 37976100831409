<ng-container *ngIf="{ store: store$ | async } as data">
  <div
    class="pickup px-4 py-3"
    [attr.aria-label]="
      'rrs.product.deliveryOptions.inStorePickup' | cxTranslate
    ">
    <div class="form-check d-flex align-items-center flex-grow-1">
      <input
        class="form-check-input me-3"
        type="radio"
        (change)="selectFulfillment()"
        (keydown.enter)="selectFulfillment()"
        [checked]="
          fulfillment.selectedMethod === deliveryOptions.PICKUP &&
          !fulfillment.pickupOutOfStock
        "
        [disabled]="fulfillment.pickupOutOfStock"
        id="pickupInStoreRadio" />
      <label
        class="form-check-label opacity-100 cursor-pointer d-flex align-items-center flex-grow-1"
        for="pickupInStoreRadio">
        <div
          class="d-flex flex-column justify-content-between align-items-start flex-grow-1">
          <p class="pickup__title mb-0 fw-bold">
            {{
              (data.store
                ? 'rrs.product.deliveryOptions.inStorePickup'
                : 'rrs.product.deliveryOptions.pickup'
              ) | cxTranslate
            }}<span class="text-accent">*</span>
          </p>
          <div class="pickup__store-details my-1">
            <ng-container *ngIf="data.store; else noStore">
              <p class="fw-bold my-0">
                {{ data.store.displayName | titlecase }}
              </p>
              <div class="d-flex align-items-center">
                <p *ngIf="data.store.formattedDistance" class="my-0 fs-7">
                  {{ data.store.formattedDistance }} &mdash;&nbsp;
                </p>
                <p
                  [ngClass]="
                    data.store.productStock?.stockLevel > 0 ||
                    data.store.productStock?.stockLevelStatus ===
                      stockLevelStatus.IN_STOCK
                      ? 'text-success'
                      : 'text-danger'
                  "
                  class="my-0">
                  {{
                    data.store.productStock?.stockLevel > 0
                      ? ('rrs.product.deliveryOptions.numberInStock'
                        | cxTranslate
                          : {
                              stockLevel: data.store.productStock?.stockLevel
                            })
                      : data.store.productStock?.stockLevelStatus ===
                        stockLevelStatus.IN_STOCK
                      ? ('rrs.product.deliveryOptions.inStock' | cxTranslate)
                      : ('rrs.product.deliveryOptions.storeOutOfStock'
                        | cxTranslate)
                  }}
                </p>
              </div>
            </ng-container>
            <ng-template #noStore>
              <p class="my-0">
                {{ 'rrs.product.deliveryOptions.selectStoreMsg' | cxTranslate }}
              </p>
            </ng-template>
          </div>
          <div class="pickup__foter d-flex">
            <button
              (click)="openFindStoresDialog($event)"
              class="btn btn-link link-primary fw-normal p-0 me-2">
              {{
                (data.store
                  ? 'rrs.product.deliveryOptions.changeStore'
                  : 'rrs.product.deliveryOptions.findMyStore'
                ) | cxTranslate
              }}
            </button>
            <a
              *ngIf="data.store?.name?.length > 0"
              class="text-decoration-underline"
              target="_blank"
              [routerLink]="
                {
                  cxRoute: 'storeDetails',
                  params: { storeId: data.store.name }
                } | cxUrl
              "
              >{{ 'rrs.product.deliveryOptions.storeDetails' | cxTranslate }}</a
            >
          </div>
        </div>
        <rrs-store-logo
          *ngIf="data.store"
          class="pickup__store-logo"
          [store]="data.store"
          [size]="65"></rrs-store-logo>
      </label>
    </div>
  </div>
  <div class="pickup__message d-flex p-2">
    <span class="text-accent">*&nbsp;</span>
    <p>
      <ng-container *ngIf="data.store"
        >{{
          (data.store.curbsidePickupEnabled
            ? 'rrs.product.deliveryOptions.bopuisAndCurbsideEligible'
            : 'rrs.product.deliveryOptions.bopuisEligible'
          ) | cxTranslate
        }}
      </ng-container>
      {{
        (data.store
          ? 'rrs.product.deliveryOptions.pickupInStoreMsg'
          : 'rrs.product.deliveryOptions.hoursVaryByLocation'
        ) | cxTranslate
      }}
    </p>
  </div>
</ng-container>
