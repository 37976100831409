import { Injectable } from '@angular/core';
import { RrsUserProfileConnector } from '@app/custom/features/rrs-profile/services/rrs-user-profile.connector';
import { UserIdService, CommandService } from '@spartacus/core';
import { UserPasswordService } from '@spartacus/user/profile/core';

@Injectable({
  providedIn: 'root',
})
export class RrsUserPasswordService extends UserPasswordService {
  constructor(
    protected userProfileConnector: RrsUserProfileConnector,
    userIdService: UserIdService,
    command: CommandService
  ) {
    super(userProfileConnector, userIdService, command);
  }
}
