import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  MODULE_INITIALIZER,
  OccConfig,
  PRODUCT_NORMALIZER,
  provideConfig,
  provideDefaultConfig,
  UrlModule,
} from '@spartacus/core';
import { MediaModule, PageSlotModule } from '@spartacus/storefront';
import { RrsAccordionModule, RrsIconModule } from '@app/shared/components';
import {
  rrsCartCmsConfig,
  rrsCartOccConfig,
} from '@app/custom/features/rrs-cart/configs';
import {
  RrsCartContainerComponent,
  RrsCartSummaryComponent,
} from '@app/custom/features/rrs-cart/components';
import { CART_NORMALIZER } from '@spartacus/cart/base/root';
import {
  ActiveCartService,
  CartEntryConnector,
  MultiCartService,
} from '@spartacus/cart/base/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { OccCartEntryAdapter } from '@spartacus/cart/base/occ';
import { RouterModule } from '@angular/router';
import { RrsCartCouponModule } from '@app/custom/features/rrs-cart-coupon/rrs-cart-coupon.module';
import { RrsCartEntryConnector } from '@app/custom/features/rrs-cart/occ/rrs-cart-entry.connector';
import { RrsCartEventListenersService } from '@app/custom/features/rrs-cart/services/rrs-cart-event-listeners.service';
import { RrsCartEventsService } from '@app/custom/features/rrs-cart/services/rrs-cart-events.service';
import { RrsCartNormalizer } from '@app/custom/features/rrs-cart/normalizers/rrs-cart.normalizer';
import { RrsCartSharedModule } from '@app/custom/features/rrs-cart/components/rrs-cart-shared.module';
import { RrsDonationModule } from '@app/custom/features/rrs-donation/rrs-donation.module';
import { RrsOccCartEntryAdapter } from '@app/custom/features/rrs-cart/occ/rrs-occ-cart-entry.adapter';
import { RrsOrderSummaryModule } from '@app/custom/features/rrs-order-summary/rrs-order-summary.module';
import { RrsSavedCartModule } from '@app/custom/features/rrs-saved-cart/rrs-saved-cart.module';
import { RrsSelectRewardsModule } from '@app/custom/features/rrs-rewards/modules/rrs-select-rewards/rrs-select-rewards.module';
import { CmsComponentsConfig } from '@app/custom/features/rrs-cms/configs/cms-components.config';
import { RrsEmptyCartComponent } from '@app/custom/features/rrs-cart/components/rrs-empty-cart/rrs-empty-cart.component';
import { RrsMultiCartService } from '@app/custom/features/rrs-cart/services/rrs-multi-cart.service';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import { RrsProductNormalizer } from '@app/shared/normalizers/rrs-product.normalizer';

@NgModule({
  declarations: [
    RrsCartContainerComponent,
    RrsCartSummaryComponent,
    RrsEmptyCartComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cart: {
        selectiveCart: {
          enabled: false,
        },
      },
    }),
    I18nModule,
    MediaModule,
    NgbModalModule,
    PageSlotModule,
    RouterModule.forRoot([], {
      onSameUrlNavigation: 'reload',
    }),
    RrsAccordionModule,
    RrsCartCouponModule,
    RrsCartSharedModule,
    RrsDonationModule,
    RrsIconModule,
    RrsOrderSummaryModule,
    RrsSavedCartModule,
    RrsSelectRewardsModule,
    UrlModule,
  ],
  providers: [
    provideConfig(rrsCartOccConfig as OccConfig),
    provideDefaultConfig({
      routing: {
        routes: {
          cart: { paths: ['cart'] },
        },
      },
    }),
    ...rrsCartCmsConfig,
    RrsCartEventsService,
    {
      multi: true,
      provide: MODULE_INITIALIZER,
      useFactory: RrsCartEventsService.factory,
      deps: [RrsCartEventsService],
    },
    RrsCartEventListenersService,
    {
      multi: true,
      provide: MODULE_INITIALIZER,
      useFactory: RrsCartEventListenersService.factory,
      deps: [RrsCartEventListenersService],
    },
    {
      provide: OccCartEntryAdapter,
      useExisting: RrsOccCartEntryAdapter,
    },
    {
      provide: CartEntryConnector,
      useExisting: RrsCartEntryConnector,
    },
    {
      provide: ActiveCartService,
      useExisting: RrsActiveCartService,
    },
    {
      provide: MultiCartService,
      useExisting: RrsMultiCartService,
    },
    {
      provide: CART_NORMALIZER,
      useExisting: RrsCartNormalizer,
      multi: true,
    },
    {
      provide: PRODUCT_NORMALIZER,
      useExisting: RrsProductNormalizer,
      multi: true,
    },
    provideConfig({
      slots: {
        EmptyCartSlot: ['RrsEmptyCartComponent'],
      },
    } as CmsComponentsConfig),
    provideConfig({
      cmsComponents: {
        RrsEmptyCartComponent: {
          component: RrsEmptyCartComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class RrsCartModule {}
