import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsService, ContentSlotData } from '@spartacus/core';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import SwiperCore, { Pagination, SwiperOptions } from 'swiper';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';

SwiperCore.use([Pagination]);

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-brands-tabs',
  styleUrls: ['./rrs-brands-tabs.component.scss'],
  templateUrl: './rrs-brands-tabs.component.html',
})
export class RrsBrandsTabsComponent {
  data$ = this.cmsService.getContentSlot('brands_tabs').pipe(
    switchMap((slots: ContentSlotData) => {
      const list = (slots.components || []).map((slot) => {
        return slot.uid as string;
      });

      return combineLatest([
        ...list?.map((item) =>
          this.cmsService.getComponentData<Contentstack.BrandsList>(item)
        ),
      ]);
    })
  );

  isDesktop$ = this.breakpointService.isUp(BREAKPOINT.lg);

  constructor(
    protected breakpointService: BreakpointService,
    protected cmsService: CmsService
  ) {}

  swiperConfig: SwiperOptions = {
    pagination: true,
    slidesPerGroup: 1,
    slidesPerView: 2,
    spaceBetween: 16,
    threshold: 10,
  };
}
