import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccCartEntryAdapter } from '@spartacus/cart/base/occ';
import {
  CartModification,
  CART_MODIFICATION_NORMALIZER,
  OrderEntry,
} from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RrsOccCartEntryAdapter extends OccCartEntryAdapter {
  public addOrderEntry(
    userId: string,
    cartId: string,
    orderEntry: OrderEntry
  ): Observable<CartModification> {
    const url = this.occEndpointsService.buildUrl('addEntries', {
      urlParams: { userId, cartId },
    });

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .post<CartModification>(url, orderEntry, { headers })
      .pipe(this.converterService.pipeable(CART_MODIFICATION_NORMALIZER));
  }
}
