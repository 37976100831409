import { CmsConfig } from '@spartacus/core/src/cms/config/cms-config';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { RrsCmsPlaceholderComponent } from './components/rrs-cms-placeholder/rrs-cms-placeholder.component';

@NgModule({
  declarations: [RrsCmsPlaceholderComponent],
  imports: [CommonModule],
  providers: [
    provideConfig({
      cmsComponents: {
        placeholder: {
          component: RrsCmsPlaceholderComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class RrsCmsPlaceholderModule {}
