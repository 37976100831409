import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SpecificStoresList } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { PageSlotComponent } from '@spartacus/storefront';

@Component({
  selector: 'rrs-specific-stores-list',
  templateUrl: './rrs-spesific-stores-list.component.html',
  styleUrls: ['./rrs-spesific-stores-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RrsSpesificStoresListComponent {
  @Input() stores!: SpecificStoresList;
  hasRemodels: boolean = false;

  @ViewChild('remodels') set remodels(value: PageSlotComponent) {
    if (value) {
      this.hasRemodels = value.hasComponents;
    }
  }
}
