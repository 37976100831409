import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const ONLY_NUMBERS = /^[0-9]+$/;

export function isEmailOrPhone(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const isValidEmail = EMAIL_REGEXP.test(value);
    const onlyNumbers = ONLY_NUMBERS.test(value);
    const isValidPhone = onlyNumbers && value.toString().length === 10;

    const passwordValid = isValidEmail || isValidPhone;

    return !passwordValid
      ? {
          wrongEmail: !onlyNumbers && !isValidEmail,
          wrongPhone: onlyNumbers && !isValidPhone,
        }
      : null;
  };
}
