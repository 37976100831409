<ng-container *ngIf="banner$ | async as banner">
  <div
    *ngIf="headerUi.isBannerShow | async"
    class="header-banner-bottom"
    [ngStyle]="{
      'background-color':
        banner.background_text_color.background_color || '#e5ecea'
    }">
    <div class="container h-100">
      <div class="row h-100">
        <div class="header-banner-bottom__col col-12 col-lg-4">
          <ul
            class="list-none d-flex align-items-center justify-content-center h-100">
            <ng-container
              *ngFor="let item of banner.first_line_static_messages_links">
              <ng-container
                *ngIf="item?.message; then itemMessage; else itemLink">
              </ng-container>
              <ng-template #itemMessage>
                <li
                  class="text-nowrap"
                  [ngStyle]="{
                    color: banner.background_text_color.text_color || '#757575'
                  }">
                  {{ item.message.text }}
                </li>
              </ng-template>
              <ng-template #itemLink>
                <li>
                  <a
                    [attr.title]="item.url_link.text"
                    [routerLink]="item.url_link.url"
                    class="text-center text-truncate text-uppercase"
                    [ngStyle]="{
                      color:
                        banner.background_text_color.text_color || '#757575'
                    }">
                    {{ item.url_link.text }}
                  </a>
                </li>
              </ng-template>
            </ng-container>
          </ul>
        </div>

        <div class="header-banner-bottom__col col-12 col-lg-4">
          <swiper [config]="swiperConfig" class="h-100">
            <ng-template
              swiperSlide
              *ngFor="let item of banner.center_line_rotating_messages">
              <div
                class="d-flex align-items-center justify-content-center h-100 w-100">
                <p
                  class="fs-5 fs-lg-3 fw-bold mb-0"
                  [ngStyle]="{
                    color: banner.background_text_color.text_color || '#757575'
                  }">
                  {{ item.message.text }}
                </p>
              </div>
            </ng-template>
          </swiper>
        </div>

        <div class="header-banner-bottom__col col-12 col-lg-4">
          <ul
            class="list-none d-flex align-items-center justify-content-center h-100">
            <ng-container
              *ngFor="let item of banner.last_line_static_messages_links">
              <ng-container
                *ngIf="item?.message; then itemMessage; else itemLink">
              </ng-container>
              <ng-template #itemMessage>
                <li
                  class="text-nowrap"
                  [ngStyle]="{
                    color: banner.background_text_color.text_color || '#757575'
                  }">
                  {{ item.message.text }}
                </li>
              </ng-template>
              <ng-template #itemLink>
                <li
                  [ngStyle]="{
                    color: banner.background_text_color.text_color || '#757575'
                  }">
                  <a
                    [attr.title]="item.url_link.text"
                    [routerLink]="item.url_link.url"
                    class="text-center text-truncate text-uppercase">
                    {{ item.url_link.text }}
                  </a>
                </li>
              </ng-template>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-container>
