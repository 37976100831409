import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsService, ContentSlotData } from '@spartacus/core';
import { combineLatest } from 'rxjs';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { switchMap } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cms-breadcrumbs',
  styleUrls: ['./rrs-cms-breadcrumbs.component.scss'],
  templateUrl: './rrs-cms-breadcrumbs.component.html',
})
export class RrsCmsBreadcrumbsComponent {
  data$ = this.cmsService.getContentSlot('breadcrumbs').pipe(
    switchMap((slots: ContentSlotData) => {
      const list = (slots.components || []).map((slot) => {
        return slot.uid as string;
      });

      return combineLatest([
        ...list?.map((item) =>
          this.cmsService.getComponentData<Contentstack.Breadcrumb>(item)
        ),
      ]);
    })
  );

  constructor(protected cmsService: CmsService) {}
}
