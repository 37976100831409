<ng-container *ngIf="savedCart$ | async as cart">
  <div class="mt-4" *ngIf="cart?.entries?.length > 0">
    <rrs-cart-list
      [cartIsLoading]="(cartLoaded$ | async) === false"
      [cartId]="cart?.code"
      [items]="cart?.entries"
      [deliveryAddress]="cart?.deliveryAddress"
      [options]="{
        isSavedCart: true
      }"
      [promotionLocation]="promotionLocation"></rrs-cart-list>
  </div>
</ng-container>
