import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalService } from '@app/custom/features/modal';
import {
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  take,
} from 'rxjs/operators';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { RrsDefaultStoreService } from '@app/custom/features/rrs-account/services/rrs-default-store.service';
import { RrsFindStoreDialogComponent } from '@app/custom/features/rrs-storefinder/components/rrs-find-store-dialog/rrs-find-store-dialog.component';
import {
  RrsPointOfService,
  SelectedStoreDialogData,
} from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { RrsStoreFinderService } from '@app/custom/features/rrs-storefinder/services/rrs-store-finder.service';
import { RrsStoreFinderAdapter } from '@app/custom/features/rrs-storefinder/occ/rrs-store-finder.adapter';
import { RrsEventsDispatcherService } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/services/rrs-events.dispatcher';

@Component({
  selector: 'rrs-account-store',
  templateUrl: './rrs-account-store.component.html',
  styleUrls: ['./rrs-account-store.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsAccountStoreComponent implements OnInit {
  iconTypeList = ICON_TYPE_LIST;

  store$!: Observable<RrsPointOfService | null>;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchQuery: string = '';
  currentStore: string | null;

  constructor(
    protected defaultStoreService: RrsDefaultStoreService,
    protected modalService: ModalService,
    protected storeFinderService: RrsStoreFinderService,
    protected storeFinderAdapter: RrsStoreFinderAdapter,
    protected eventsDispatcher: RrsEventsDispatcherService
  ) {
    this.currentStore = '';
  }

  ngOnInit(): void {
    this.initStore();
  }

  private initStore(): void {
    this.store$ = this.defaultStoreService.getDefaultStoreId().pipe(
      distinctUntilChanged(),
      filter((storeId) => !!storeId?.length),
      switchMap((storeId) => {
        this.currentStore = storeId;
        this.isLoading$.next(true);
        return this.storeFinderAdapter
          .load(storeId!)
          .pipe(finalize(() => this.isLoading$.next(false)));
      })
    );
  }

  openStoresDialog(): void {
    this.eventsDispatcher.dispatchEditAccountInfoEvent();
    const modal = this.modalService.open(RrsFindStoreDialogComponent, {
      animation: true,
      windowClass: 'find-store-dialog',
    });
    modal.componentInstance.initialSearchValue = this.searchQuery;
    modal.componentInstance.initialSetAsMyStore = true;
    modal.componentInstance.storeSelected
      .pipe(take(1))
      .subscribe(({ currentSearchQuery }: SelectedStoreDialogData) => {
        this.searchQuery = currentSearchQuery;
      });
    modal.componentInstance.cancel.pipe(take(1)).subscribe((query: string) => {
      this.searchQuery = query;
    });
    modal.componentInstance.currentStore = this.currentStore;
  }
}
