import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RssKlarnaBannerInitializer } from '@app/custom/features/rrs-klarna-banner/rss-klarna-banner.initializer';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [RssKlarnaBannerInitializer],
})
export class RrsKlarnaBannerModule {}
