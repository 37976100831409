import { AuthService, isNotNullable, WindowRef } from '@spartacus/core';
import { BehaviorSubject, combineLatest, Observable, of, race } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  getFacetsUrlParams,
  milesToMeters,
} from '@app/custom/features/rrs-product-listing/utils/rrs-facet.utils';
import {
  RrsPointOfService,
  SelectedStoreDialogData,
} from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import {
  FacetCodes,
  STORE_FACET_SESSION_STORAGE_KEY,
} from '@app/custom/features/rrs-product-listing/model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RrsDefaultStoreService } from '@app/custom/features/rrs-account/services/rrs-default-store.service';
import { RrsFacetService } from '@app/custom/features/rrs-product-listing/services/rrs-facet-service/rrs-facet.service';
import { RrsFindStoreDialogComponent } from '@app/custom/features/rrs-storefinder/components/rrs-find-store-dialog/rrs-find-store-dialog.component';
import { RrsProductFacetService } from '@app/custom/features/rrs-product-listing/services/rrs-product-facet-service.service';
import { RrsStoreFinderService } from '@app/custom/features/rrs-storefinder/services/rrs-store-finder.service';
import { ModalRef, ModalService } from '@app/custom/features/modal';
import { MyStoreServiceScope } from '@app/custom/features/rrs-product-listing/model/rrs-search-stores.model';

@Injectable({
  providedIn: 'root',
})
export class RrsStoreFacetService {
  modalRef!: ModalRef | undefined;
  private currentSearchQuery: string = '';
  private _store$ = new BehaviorSubject<RrsPointOfService | null>(null);
  isSelected$!: Observable<boolean>;
  shouldDisplayDefaultStore: boolean = false;
  geolocationNotAvailable$ = this.storeFinderService.geolocationNotAvailable;

  constructor(
    protected winRef: WindowRef,
    protected storeFinderService: RrsStoreFinderService,
    protected facetService: RrsFacetService,
    protected modalService: ModalService,
    protected productFacetService: RrsProductFacetService,
    protected defaultStoreService: RrsDefaultStoreService,
    protected authService: AuthService,
    protected router: Router
  ) {
    this.initIsSelected();
  }

  selectStore(store: RrsPointOfService): void {
    this._store$.next(store);
  }

  getSelectedStore(
    scope: 'storeFacet' | 'pickupInStore'
  ): Observable<RrsPointOfService | null> {
    if (this._store$.getValue() === null) {
      this.storeFinderService.clearStoresData();
      switch (scope) {
        case 'storeFacet':
          this.getDefaultSelectedFacetStore();
          break;
        case 'pickupInStore':
          this.getDefaultStoreForPickup();
          break;
        default:
          break;
      }
    }

    return this._store$
      .asObservable()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  private getDefaultSelectedFacetStore(): void {
    combineLatest([
      this.authService.isUserLoggedIn().pipe(
        switchMap((isLoggedIn) =>
          isLoggedIn
            ? this.defaultStoreService
                .getDefaultStoreId()
                .pipe(filter(isNotNullable))
            : of(null)
        ),
        map(
          (defaultStoreId) =>
            defaultStoreId ||
            this.winRef.localStorage?.getItem(STORE_FACET_SESSION_STORAGE_KEY)
        )
      ),
      this.facetService.facetList$.pipe(
        map(
          (facetList) =>
            facetList.activeFacets?.find(
              (facet) => facet.facetName === FacetCodes.STORE_ID
            )?.facetValueName
        )
      ),
    ])
      .pipe(
        map(([defaultStoreId, storeIdFacet]) => {
          if (!storeIdFacet?.length) {
            this.shouldDisplayDefaultStore = true;
            return defaultStoreId?.length
              ? defaultStoreId
              : this.winRef.localStorage?.getItem(
                  STORE_FACET_SESSION_STORAGE_KEY
                );
          }
          return storeIdFacet;
        }),
        tap((storeId) => {
          if (storeId) {
            this.storeFinderService.viewStoreById(storeId);
          } else {
            this.storeFinderService.findStoresAction(
              '',
              { pageSize: 1, currentPage: 0 },
              undefined,
              undefined,
              true,
              milesToMeters(100)
            );
          }
        }),
        switchMap((storeId) => {
          return race(
            this.storeFinderService.getStoresLoaded().pipe(filter(Boolean)),
            this.storeFinderService.getStoresError().pipe(filter(Boolean)),
            this.geolocationNotAvailable$.pipe(filter(Boolean))
          ).pipe(
            switchMap(() => {
              if (storeId) {
                return this.storeFinderService.getFindStoreById();
              }
              return this.storeFinderService
                .getFindStoresEntities()
                .pipe(map((storesEntities) => storesEntities?.stores?.[0]));
            })
          );
        }),
        take(1)
      )
      .subscribe((store) => {
        if (store?.name) {
          this.selectStore(store);
        }
      });
  }

  private getDefaultStoreForPickup(): void {
    this.authService
      .isUserLoggedIn()
      .pipe(
        switchMap((isLoggedIn) =>
          isLoggedIn
            ? this.defaultStoreService
                .getDefaultStoreId()
                .pipe(filter(isNotNullable))
            : of(null)
        ),
        map(
          (defaultStoreId) =>
            defaultStoreId ||
            this.winRef.localStorage?.getItem(STORE_FACET_SESSION_STORAGE_KEY)
        ),
        tap((storeId) => {
          if (storeId) {
            this.storeFinderService.viewStoreById(storeId);
          } else {
            this.storeFinderService.findStoresAction(
              '',
              { pageSize: 1, currentPage: 0 },
              undefined,
              undefined,
              true,
              milesToMeters(100)
            );
          }
        }),
        switchMap((storeId) => {
          return race(
            this.storeFinderService.getStoresLoaded().pipe(filter(Boolean)),
            this.storeFinderService.getStoresError().pipe(filter(Boolean)),
            this.geolocationNotAvailable$.pipe(filter(Boolean))
          ).pipe(
            switchMap(() => {
              if (storeId) {
                return this.storeFinderService.getFindStoreById();
              }
              return this.storeFinderService
                .getFindStoresEntities()
                .pipe(map((storesEntities) => storesEntities?.stores?.[0]));
            })
          );
        }),
        take(1)
      )
      .subscribe((store) => {
        if (store?.name) {
          this.selectStore(store);
        }
      });
  }

  openSearchStoresDialog(
    scope: MyStoreServiceScope,
    productCode?: string
  ): void {
    this.modalRef = this.modalService.open(RrsFindStoreDialogComponent, {
      animation: true,
      windowClass: 'find-store-dialog',
    });
    const componentInstance = this.modalRef.componentInstance;
    componentInstance.scope = scope;
    componentInstance.productCode = productCode;
    componentInstance.initialSearchValue = this.currentSearchQuery;
    this._store$.pipe(take(1)).subscribe((store) => {
      componentInstance.selectedStore = store;
    });
    componentInstance.storeSelected
      .pipe(withLatestFrom(this.isSelected$), take(1))
      .subscribe(
        ([{ store, setAsDefault, currentSearchQuery }, isSelected]: [
          SelectedStoreDialogData,
          boolean
        ]) => {
          this.shouldDisplayDefaultStore = !!setAsDefault;
          this.currentSearchQuery = currentSearchQuery;
          this.selectStore(store);
          if (isSelected) {
            this.selectFacet();
          }
          if (store?.name) {
            this.winRef.localStorage?.setItem(
              STORE_FACET_SESSION_STORAGE_KEY,
              store.name
            );
          }
        }
      );
    componentInstance.cancel.pipe(take(1)).subscribe((query: string) => {
      this.currentSearchQuery = query;
    });
  }

  selectFacet(): void {
    combineLatest([this._store$, this.productFacetService.result$])
      .pipe(
        filter(([store]) => !!store),
        map(([store, searchResult]) => {
          let query = searchResult.breadcrumbs?.find(
            (activeFacet) =>
              activeFacet.facetName === FacetCodes.STORE_ID &&
              activeFacet.facetValueName === store!.name
          )?.removeQuery?.query?.value;
          if (query === undefined) {
            const selectedFacets = {
              ...(searchResult.selectedFacets ?? {}),
              [FacetCodes.STORE_ID]: [store!.name ?? ''],
            };
            query = getFacetsUrlParams(selectedFacets);
          }

          return [query, searchResult.currentQuery?.query?.value ?? ''];
        }),
        take(1)
      )
      .subscribe(([query, currentSearchQuery]) => {
        this.router.navigate(['/search', currentSearchQuery], {
          queryParams: {
            facetFilters: query?.length ? query : null,
            currentPage: query?.length ? 0 : null,
          },
          queryParamsHandling: 'merge',
        });
      });
  }

  private initIsSelected(): void {
    this.isSelected$ = combineLatest([
      this._store$,
      this.facetService.facetList$,
    ]).pipe(
      map(
        ([store, facetList]) =>
          !!store &&
          !!facetList.activeFacets?.find(
            (facet) =>
              facet.facetName === FacetCodes.STORE_ID &&
              facet.facetValueName === store.name
          )
      ),
      startWith(false)
    );
  }
}
