import { APP_INITIALIZER } from '@angular/core';
import { RrsUserIdService } from '@app/custom/features/rrs-user-id/services/rrs-user-id.service';
import { scriptsCookieGroups } from '@app/custom/features/rrs-onetrust/rrs-onetrust.config';
import { RrsOnetrustService } from '@app/custom/features/rrs-onetrust/rrs-onetrust.service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { WindowRef } from '@spartacus/core';

export const RrsUserIdInitializer = {
  provide: APP_INITIALIZER,
  useFactory: appInitializer,
  deps: [RrsUserIdService, RrsOnetrustService, WindowRef],
  multi: true,
};

export function appInitializer(
  rrsUserIdService: RrsUserIdService,
  rrsOnetrustService: RrsOnetrustService,
  winRef: WindowRef
): Function {
  return (): Subscription =>
    rrsOnetrustService
      .checkConsents(scriptsCookieGroups.dynatrace)
      .subscribe((hasConsent) => {
        if (!hasConsent) return;
        if (winRef.isBrowser()) {
          rrsUserIdService
            .loadUserDeviceId()
            .pipe(take(1))
            .subscribe(
              () => {},
              (err) => console.error(err)
            );
        }
      });
}
