<div class="order-status container">
  <div class="order-status__breadcrumbs">
    <cx-page-slot position="BreadcrumbsSlot"></cx-page-slot>
  </div>
  <div class="row mt-2">
    <div class="col-lg-6">
      <h1 rrsCmsHeading class="order-status__title fs-4">
        {{ 'rrs.orderStatus.orderStatus' | cxTranslate }}
      </h1>
      <form
        [formGroup]="form"
        #ngForm="ngForm"
        class="mb-4"
        (ngSubmit)="submitForm()">
        <cx-page-slot position="form_top_content"></cx-page-slot>
        <div class="mb-2">
          <label for="orderId" class="form-label">
            {{ 'rrs.orderStatus.orderNumber' | cxTranslate }}
          </label>

          <input
            aria-required="true"
            class="form-control"
            [ngClass]="
              ngForm.submitted ? (form.get('orderId') | rrsIsControlValid) : ''
            "
            formControlName="orderId"
            name="orderId"
            type="text" />

          <rrs-form-errors
            *ngIf="ngForm.submitted"
            [control]="form.get('orderId')"
            aria-live="assertive"
            aria-atomic="true">
          </rrs-form-errors>
        </div>

        <div class="mb-2">
          <label for="email" class="form-label">
            {{ 'rrs.orderStatus.emailAddress' | cxTranslate }}
          </label>

          <input
            aria-required="true"
            class="form-control"
            [ngClass]="
              ngForm.submitted ? (form.get('email') | rrsIsControlValid) : ''
            "
            formControlName="email"
            name="email"
            type="text" />

          <rrs-form-errors
            *ngIf="ngForm.submitted"
            [control]="form.get('email')"
            aria-live="assertive"
            aria-atomic="true">
          </rrs-form-errors>
        </div>

        <cx-page-slot position="form_bottom_content"></cx-page-slot>
        <button type="submit" class="btn btn-primary text-uppercase fs-4">
          {{ 'rrs.orderStatus.trackMyOrder' | cxTranslate }}
        </button>
      </form>
    </div>
    <div class="col-lg-6">
      <cx-page-slot
        class="mobile-app-banner"
        position="ad_slot_1"></cx-page-slot>
    </div>
  </div>
</div>
