import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Product } from '@spartacus/core';

@Component({
  selector: 'rrs-product-description',
  templateUrl: './rrs-product-description.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsProductDescriptionComponent {
  @Input()
  product!: Product;
}
