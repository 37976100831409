import { createSelector, MemoizedSelector } from '@ngrx/store';
import {
  StateWithStoreFinder,
  StoreFinderSelectors,
} from '@spartacus/storefinder/core';
import { RrsPointOfService } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { StateUtils } from '@spartacus/core';

export const getFindStoreByIdEntity: MemoizedSelector<
  StateWithStoreFinder,
  RrsPointOfService
> = createSelector(StoreFinderSelectors.getFindStoresState, (state) => {
  return StateUtils.loaderValueSelector(state)
    .findStoreEntityById as RrsPointOfService;
});

export const getStoresError: MemoizedSelector<StateWithStoreFinder, boolean> =
  createSelector(StoreFinderSelectors.getFindStoresState, (state) =>
    StateUtils.loaderErrorSelector(state)
  );
