import { GeoPoint } from '@spartacus/core';

export const getDistance = (
  firstLocation: GeoPoint,
  secondLocation: GeoPoint
): number => {
  if (
    !firstLocation.latitude ||
    !firstLocation.longitude ||
    !secondLocation.latitude ||
    !secondLocation.longitude
  )
    return 0;
  const R = 6371e3; // metres
  const φ1 = (firstLocation.latitude * Math.PI) / 180; // φ, λ in radians
  const φ2 = (secondLocation.latitude * Math.PI) / 180;
  const Δφ =
    ((secondLocation.latitude - firstLocation.latitude) * Math.PI) / 180;
  const Δλ =
    ((secondLocation.longitude - firstLocation.longitude) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // in metres
};

export const metresToMiles = (meters: number): number =>
  Math.round((meters / 1609.344) * 10) / 10;

export const getFormattedDistance = (
  firstLocation: GeoPoint,
  secondLocation: GeoPoint
): string => {
  const distance = getDistance(firstLocation, secondLocation);
  return `${metresToMiles(distance)} Miles`;
};

export const getDaysBeforeToday = (date?: string): number | null => {
  if (!date) return null;
  const today = new Date();
  const dateToCompare = new Date(date);
  const diffInTime = today.getTime() - dateToCompare.getTime();
  const diffInDays = diffInTime / (1000 * 3600 * 24);
  return Math.floor(diffInDays);
};
