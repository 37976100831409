import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  FREE_DELIVERY_THRESHOLD,
  FulfillmentConfig,
  FulfillmentOptions,
} from '@app/custom/features/rrs-product-details/models/rrs-delivery-options.model';
import { Price } from '@spartacus/core';

@Component({
  selector: 'rrs-delivery-option',
  templateUrl: './rrs-delivery-option.component.html',
  styleUrls: ['./rrs-delivery-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsDeliveryOptionComponent {
  @Input() productCode!: string;
  @Input() fulfillment!: FulfillmentConfig;
  @Input() productPrice!: Price;
  @Output() select = new EventEmitter<FulfillmentConfig>();
  deliveryOptions = FulfillmentOptions;

  freeShippingThreshold = FREE_DELIVERY_THRESHOLD;

  selectDeliveryOption(): void {
    this.select.emit({ selectedMethod: FulfillmentOptions.DELIVERY });
  }

  getTitleAndDescription(): { title: string; description: string } {
    let title = 'freeShipping';
    let description = 'itemShipsForFree';
    if (this.fulfillment.deliveryOutOfStock) {
      title = 'unavailableForDelivery';
      description = 'unavailableForDeliveryDesc';
    } else if (
      this.productPrice?.value &&
      this.productPrice.value < this.freeShippingThreshold.value
    ) {
      title = 'delivery';
      description = 'freeShippingConidition';
    }

    return {
      title: 'rrs.product.deliveryOptions.' + title,
      description: 'rrs.product.deliveryOptions.' + description,
    };
  }
}
