import { provideConfig, RoutingConfig } from '@spartacus/core';

export const rrsResetPasswordRoutingConfig = provideConfig({
  routing: {
    routes: {
      resetPassword: {
        paths: ['login/reset-password'],
      },
    },
  },
} as RoutingConfig);
