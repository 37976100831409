import { GeoPoint } from '@spartacus/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RrsStoreFinderAdapter } from '@app/custom/features/rrs-storefinder/occ/rrs-store-finder.adapter';
import { StoreFinderConnector } from '@spartacus/storefinder/core';

@Injectable({
  providedIn: 'root',
})
export class RrsStoreFinderConnector extends StoreFinderConnector {
  constructor(protected adapter: RrsStoreFinderAdapter) {
    super(adapter);
  }

  getGeolocationFromIp(): Observable<GeoPoint> {
    return this.adapter.getGeolocationFromIp();
  }
}
