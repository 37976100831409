import { OneTrust, OneTrustService } from '@altack/ngx-onetrust';
import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RrsOnetrustService {
  constructor(
    private oneTrust: OneTrustService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  oneTrustInstance!: OneTrust;
  activeConsents$ = this.oneTrust.consentChanged$();

  load(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.oneTrust.loadOneTrust();
    this.oneTrust
      .oneTrustInstance$()
      .pipe(take(1))
      .subscribe((instance) => {
        this.oneTrustInstance = instance;
        let isConsentSet = this.oneTrustInstance.IsAlertBoxClosedAndValid();
        this.oneTrustInstance.OnConsentChanged(() => {
          if (!isConsentSet) {
            isConsentSet = true;
            return;
          }
          window.location.reload();
        });
      });
  }

  showModal(): void {
    this.oneTrustInstance.ToggleInfoDisplay();
  }

  checkConsents(scriptsCookieGroup: string[]): Observable<boolean> {
    // Return false for consentGiven on the server
    if (isPlatformServer(this.platformId)) {
      return of(false);
    }
    return this.activeConsents$.pipe(take(1)).pipe(
      switchMap((activeConsents) => {
        let consentGiven = true;
        scriptsCookieGroup.forEach((cookieGroup: any) => {
          if (activeConsents.get(cookieGroup)) {
            return;
          }
          consentGiven = false;
        });
        return of(consentGiven);
      })
    );
  }
}
