import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RrsContactUsComponent } from './components/rrs-contact-us/rrs-contact-us.component';
import { rrsContactUsCmsConfig } from '@app/custom/features/rrs-contact-us/configs/cms.config';
import { rrsContactUsRoutingConfig } from '@app/custom/features/rrs-contact-us/configs/router.config';

@NgModule({
  declarations: [RrsContactUsComponent],
  imports: [CommonModule],
  exports: [RrsContactUsComponent],
  providers: [...rrsContactUsCmsConfig, rrsContactUsRoutingConfig],
})
export class RrsContactUsModule {}
